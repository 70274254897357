/* eslint-disable no-console */
import "./filters";

import "@mdi/font/css/materialdesignicons.css";

import * as Integrations from "@sentry/integrations";
import * as Sentry from "@sentry/browser";

import App from "./App";
import Vue from "vue";
import VueApollo from "vue-apollo";
import VuetifyToast from "vuetify-toast-snackbar";
import { abilitiesPlugin } from "@casl/vue";
import {ability} from "./ability";
import apolloClient from "./ApolloClient";
import getEnv from "./utils/env";
import router from "./router";
import setUserPermissions from "./userPermissions";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

// Enable Sentry only for selected environments
if (getEnv("VUE_APP_SENTRY") === true) {
    Sentry.init({
        "dsn": "https://9e5e098abbcc49339dcbef98351502f9@sentry.io/1421881",
        "integrations": [
            new Integrations.Vue({
                Vue,
                "attachProps": true
            }),
            new Integrations.Dedupe()
        ],
        "environment": process.env.NODE_ENV
    });
}

Vue.use(VueApollo);
Vue.use(abilitiesPlugin, ability);

const apolloProvider = new VueApollo({
    "defaultClient": apolloClient,
    "defaultOptions": {
        "$query": {
            "loadingKey": "loading",
            "fetchPolicy": "no-cache"
        }
    }
});

Vue.config.productionTip = false;

Vue.use(
    VuetifyToast,
    {
        "x": "middle",
        "y": "top",
        "color": "info",
        "icon": "mdi-info",
        "iconColor": "",
        "classes": ["body-2"],
        "timeout": 4000,
        "dismissable": true,
        "multiLine": true,
        "vertical": false,
        "queueable": true,
        "showClose": true,
        "closeText": "",
        "closeIcon": "mdi-close",
        "closeColor": "",
        "slot": [],
        "shorts": {"custom": {"color": "purple"}},
        "property": "$toast"
    }
);

// Subscribe to store updates
store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    if ([
        "listFilters/SET_APPLICATION_FILTERS",
        "listFilters/SET_REBATES_FILTERS",
        "listFilters/SET_TELESALES_FILTERS",
        "listFilters/SET_USERS_FILTERS",
        "listFilters/SET_SITE_FILTERS",
        "listFilters/SET_ACCOUNTING_CLERK_FILTERS",
        "listFilters/SET_ZFPAYERS_FILTERS",
        "listFilters/SET_ZLPAYERS_FILTERS",
        "listFilters/SET_ZRPAYERS_FILTERS",
        "listFilters/ADD_EDIT_PERSONAL_FILTER",
        "listFilters/DELETE_PERSONAL_FILTER"
    ].includes(mutation.type)) {
        window.localStorage.setItem(
            "listFilters",
            JSON.stringify(state.listFilters)
        );
    }
    if (mutation.type === "session/SET_USER") {
        window.localStorage.setItem("jwt", state.session.user.jwt);
        window.localStorage.setItem("user", JSON.stringify(state.session.user));
    }
});

setUserPermissions().then(() => {
    /* eslint-disable no-new */
    new Vue({
        apolloProvider,
        router,
        store,
        vuetify,
        beforeCreate () {
            this.$store.dispatch("initialiseStore");
        },
        "render": (h) => h(App)
    }).$mount("#app");
});


