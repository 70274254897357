import Vue from "vue";
const state = {"exports": []};

const mutations = {
    SET_EXPORTS (currentState, countries) {
        Vue.set(currentState, "exports", countries);
    }
};

const getters = {
    "getExport": (currentState) => (filename) => currentState.exports.find((object) => object.filename === filename),
    "getExports": (currentState) => currentState
};

const actions = {
    async setExports ({commit}, exports) {
        await commit(
            "SET_EXPORTS",
            exports
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
