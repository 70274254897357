<template>
    <div>
        <v-row>
            <v-col>
                <v-data-table :loading="loading"
                              :headers="headersWithActionsFirstRow"
                              :items="filterDeleted(individuals)"
                              :disable-pagination="true"
                              :hide-default-footer="true"
                              :expanded.sync="expanded"
                              @item-expanded="rowExpanded"
                              show-expand
                              item-key="id">

                    <template v-slot:item.first_name="{ item }">
                        {{ item.first_name || "-" }}
                    </template>

                    <template v-slot:item.last_name="{ item }">
                        {{ item.last_name || "-" }}
                    </template>

                    <template v-slot:item.email="{ item }">
                        {{ item.email || "-" }}
                    </template>

                    <template v-slot:item.address_line_1="{ item }">
                        {{ item.address_line_1 || "-" }}
                    </template>

                    <template v-slot:item.isContactPerson="{item}">
                        <v-icon v-if="item.isContactPerson" color="green" dense>mdi-check-circle</v-icon>
                        <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
                    </template>

                    <template v-slot:item.isSignee="{item}">
                        <v-icon v-if="item.isSignee" color="green" dense>mdi-check-circle</v-icon>
                        <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
                    </template>

                    <template v-slot:item.isLegalRepresentative="{item}">
                        <v-icon v-if="item.isLegalRepresentative" color="green" dense>mdi-check-circle</v-icon>
                        <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
                    </template>

                    <template v-slot:item.isUbo="{item}">
                        <v-icon v-if="item.isUbo" color="green" dense>mdi-check-circle</v-icon>
                        <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <div class="d-flex align-center">
                            <v-btn class="mx-2"
                                   text icon
                                   fab
                                   small
                                   color="primary"
                                   @click="openIndividualDialog(item, false)"
                                   :disabled="!canEdit">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn class="error--text mx-2"
                                   :id="`remove_card_btn${item.id}`"
                                   text icon
                                   @click="removeIndividuals(item)"
                                   :disabled="!canEdit">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </template>

                    <template v-slot:expanded-item="{ item }">
                        <td width="49"></td>
                        <td class="pl-0 pr-0" colspan="9">
                            <v-data-table :disable-pagination="true"
                                          :headers="headersSecondRow"
                                          :hide-default-footer="true"
                                          :items="newestExpanded[item.id]"
                                          :loading="loading"
                                          item-key="id">
                                <template v-slot:item.address_line_2="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).address_line_2 || "-" }}
                                </template>

                                <template v-slot:item.address_postal_code="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).address_postal_code || "-" }}
                                </template>

                                <template v-slot:item.address_city="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).address_city || "-" }}
                                </template>

                                <template v-slot:item.address_state="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).address_state || "-" }}
                                </template>

                                <template v-slot:item.address_country="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).address_country || "-" }}
                                </template>

                                <template v-slot:item.nationality="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).nationality || "-" }}
                                </template>

                                <template v-slot:item.country_id="{ item }">
                                    {{ getCountryNameById(getItemsBasedOnIndividualState(item).country_id) || "-" }}
                                </template>
                            </v-data-table>

                            <v-data-table
                                :disable-pagination="true"
                                :headers="headersThirdRow"
                                :hide-default-footer="true"
                                :items="newestExpanded[item.id]"
                                :loading="loading"
                                item-key="id">

                                <template v-slot:item.phone_number_country_code="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).phone_number_country_code || "-" }}
                                </template>

                                <template v-slot:item.phone_number="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).phone_number || "-" }}
                                </template>

                                <template v-slot:item.birth_date="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).birth_date|formatDate }}
                                </template>

                                <template v-slot:item.signature_location="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).signature_location || "-" }}
                                </template>

                                <template v-slot:item.job_function="{ item }">
                                    {{ getItemsBasedOnIndividualState(item).job_function || "-" }}
                                </template>

                                <template v-slot:item.share_capital="{ item }">
                                    {{  getMarketingAgreeValue(item) || 0 }}%
                                </template>

                                <template v-slot:item.marketing_agree="{item}">
                                    <v-icon
                                        v-if=" getItemsBasedOnIndividualState(item).marketing_agree"
                                        color="green"
                                        dense>
                                        mdi-check-circle
                                    </v-icon>
                                    <v-icon
                                        v-else
                                        color="red"
                                        dense>
                                        mdi-close-circle
                                    </v-icon>
                                </template>
                            </v-data-table>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-btn
                class="text-capitalize mr-5"
                right rounded color="primary"
                @click.stop="openIndividualDialog({'id': individualId}, true)">Add
            </v-btn>
        </v-row>
        <IndividualListDialog
            :editIndividualDialog.sync="dialogOpen"
            :items="item"
            :isNew="isNew"
            :tableHeaders="this.field.tableHeaders"
        >
        </IndividualListDialog>
    </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import IndividualListDialog from "@/components/form/elements/IndividualListDialog.vue";
import gql from "graphql-tag";


export default {
    "components": {IndividualListDialog},
    "extends": BaseElement,
    "apollo": {
        "getIndividuals": {
            // eslint-disable-next-line consistent-return
            query () {
                if (this.formId) {
                    return gql` {
                    getRoles: role { data { id role }}
                    individual(form_id: ${this.formId})
                        {
                            data {
                                form_id
                                id
                                first_name
                                last_name
                                email
                                address_line_1
                                address_line_2
                                address_postal_code
                                address_city
                                address_state
                                address_country
                                birth_date
                                country_id
                                phone_number
                                phone_number_country_code
                                signature_location
                                job_function
                                share_capital
                                nationality
                                marketing_agree
                            }
                        }
                    }`;
                }
            },
            async update (data) {
                const individuals = data?.individual?.data;
                const roles = data?.getRoles?.data;
                const fetchIndividualRolesPromises =
                    individuals.map((individual) => this.fetchIndividualRoles(individual.id));
                const allIndividualsRoles = await Promise.all(fetchIndividualRolesPromises);

                allIndividualsRoles.forEach((getIndividualsRoles, index) => {
                    const individual = individuals[index];

                    individual.roles = getIndividualsRoles.map((role) => {
                        const roleName = roles.find((r) => r.id === role.role_id)?.role;
                        return roleName || "";
                    });

                    individual.isSignee = individual.roles.includes("Signee");
                    individual.isUbo = individual.roles.includes("Ubo");
                    individual.isLegalRepresentative = individual.roles.includes("LegalRepresentative");
                    individual.isContactPerson = individual.roles.includes("ContactPerson");
                });

                await this.$store.dispatch(
                    "applicationFormValues/setIndividuals",
                    individuals
                );
            },
            skip () {
                return !this.formId;
            }
        }
    },
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        },
        individuals () {
            return this.$store.state.applicationFormValues.individuals;
        },
        headersWithActionsFirstRow () {
            const firstRowOfTableHeaders = this.tableHeaders.slice(0, 9);
            const newHeaders = JSON.parse(JSON.stringify(firstRowOfTableHeaders));
            newHeaders.shift();
            newHeaders.push({
                "value": "action",
                "text": "",
                "sortable": false,
                "width": "52px"
            });

            return newHeaders;
        },
        headersSecondRow () {
            const secondRowOfTableHeaders = this.tableHeaders.slice(9, 16);

            return JSON.parse(JSON.stringify(secondRowOfTableHeaders));
        },
        headersThirdRow () {
            const thirdRowOfTableHeaders = this.tableHeaders.slice(16, 23);

            return JSON.parse(JSON.stringify(thirdRowOfTableHeaders));
        }
    },
    "methods": {
        openIndividualDialog (item, isNew) {
            this.item = item;
            this.dialogOpen = true;
            this.isNew = isNew;
        },
        getItemsBasedOnIndividualState (item) {
            return this.$store.state.applicationFormValues.individuals.find((individual) => individual.id === item.id);
        },
        getMarketingAgreeValue (item) {
            const shareCapital = this.getItemsBasedOnIndividualState(item).share_capital;

            return shareCapital !== null ? shareCapital : "0";
        },
        getCountryNameById (countryId) {
            const country = this.$store.state.countries.countries.find((c) => c.id === countryId);
            return country ? country.name : "No data available";
        },

        filterDeleted (individuals) {
            return individuals?.filter((individual) => !individual.deleted);
        },
        rowExpanded (value) {
            if (value.value) {
                this.newestExpanded[value.item.id] = [this.$store.getters["applicationFormValues/filteredIndividual"](value.item.id)];
            }
        },
        async fetchIndividualRoles (individualId) {
            const GET_INDIVIDUALS_ROLES_QUERY =
                gql`query Individual_role {individual_role(individual_id: ${individualId})
                { data { role_id individual_id }}}`;

            const response = await this.$apollo.query({
                "query": GET_INDIVIDUALS_ROLES_QUERY,
                "variables": { individualId }
            });

            return response.data.individual_role.data;
        },
        removeIndividuals (item) {
            item.isSignee = false;
            item.isUbo = false;
            // eslint-disable-next-line camelcase
            item.share_capital = 0;
            this.$store.dispatch(
                "applicationFormValues/deleteIndividuals",
                item.id
            );
        }
    },
    data () {
        return {
            "expanded": [],
            "newestExpanded": {},
            "error": null,
            "dialogOpen": false,
            "item": {},
            "isNew": null,
            "loading": false,
            "individualId": 0,
            "tableHeaders": this.field.tableHeaders
        };
    }
};
</script>
