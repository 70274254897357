<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
                      :loading="loading"
                      :headers="headersWithActions"
                      :items="filterDeleted(fees)"
                      :disable-pagination="true"
                      :hide-default-footer="true"
                      item-key="id"
                      id="fees_data_table">
          <template v-slot:item.category="{item}">
            <v-edit-dialog
                class="card-select-style-wide card-select-font-size"
                @open="open(item.category)"
              @close="save({id: item.id, category: currentEdit})"
            > {{item.category|formatNoValue}}
              <template v-slot:input>
                <v-text-field
                  :maxlength=100
                  v-model="currentEdit"
                  :value="item.category"
                  placeholder="Category"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.amount="{item}">
            <v-edit-dialog
              @open="open(item.amount)"
              @close="save({id: item.id, amount: parseFloat(currentEdit)})"
            > {{ item.amount|formatNoValue }}
              <template v-slot:input>
                <v-text-field
                  v-model="currentEdit"
                  :value="item.amount"
                  placeholder="Amount"
                  single-line
                  type="number"
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.frequency="{item}">
              <v-select
                  class="card-select-style-wide card-select-font-size"
                  :value="item.frequency"
                  @change="save({id: item.id, frequency: $event})"
                  :items="frequencies"
                  :disabled="!canEdit || item.fee_option === 'L'"
                  item-text="label"
              ></v-select>
          </template>

          <template v-slot:item.fee_option="{item}">
            <v-select
              class="card-select-style-wide card-select-font-size"
              :value="item.fee_option"
              @change="saveFeeOption({id: item.id, fee_option: $event})"
              :items="feeOptions"
              :disabled="!canEdit"
              item-text="label"
            ></v-select>
          </template>

          <template v-slot:item.activation_delay="{item}">
            <v-edit-dialog
              @open="open(item.activation_delay)"
              @close="save({id: item.id, activation_delay: currentEdit})"
            > {{ item.activation_delay|formatNoValue }}
              <template v-slot:input>
                <v-text-field
                  :maxlength=14
                  v-model="currentEdit"
                  :value="item.activation_delay"
                  placeholder="Activation delay"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.duration="{item}">
            <v-edit-dialog
              @open="open(item.duration)"
              @close="save({id: item.id, duration: currentEdit})"
            > {{ item.duration|formatNoValue }}
              <template v-slot:input>
                <v-text-field
                  :maxlength=14
                  v-model="currentEdit"
                  :value="item.duration"
                  placeholder="Duration"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.type="{item}">
              <v-select
                  class="card-select-style-wide card-select-font-size"
                  :value="item.type"
                  @change="save({id: item.id, type: parseInt($event)})"
                  :items="feeTypes"
                  :disabled="!canEdit"
                  item-text="label"
              ></v-select>
          </template>

          <template v-slot:item.action="{item}">
            <v-btn class="error--text mx-2"
                   :id="`remove_fee_btn${item.id}`"
                   text icon
                   @click="removeFee(item)"
                   :disabled="!canEdit">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn id="add_fee_btn"
             class="text-capitalize mr-5"
             right rounded color="primary"
             @click.stop="addFee"
             :disabled="!canEdit">Add
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import {getFieldsFromTableHeaders} from "@/service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "extends": BaseElement,
    "apollo": {
        "getFees": {
            // eslint-disable-next-line consistent-return
            query () {
                if (this.formId) {
                    const fields = getFieldsFromTableHeaders(this.tableHeaders);
                    this.loading = true;
                    return gql`
                        query GetFees($formId: Int!) {
                            getFees: fee(form_id: $formId) {
                                data {
                                    ${fields}
                                }
                            }
                        }
                    `;
                }
            },
            variables () {
                return {
                    "formId": this.formId
                };
            },
            update (data) {
                this.loading = false;
                if (window.location.pathname.includes("create")) {
                    return;
                }
                this.$store.dispatch(
                    "applicationFormValues/setFees",
                    data?.getFees?.data || []
                );
                this.skipFeeQuery = true;
            },
            skip () {
                if (window.location.pathname.includes("create") || !this.formId) {
                    this.loading = false;
                    return true;
                }
                return this.skipFeeQuery;
            }
        }
    },
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        },
        fees () {
            return this.$store.state.applicationFormValues.fees;
        },
        feeTypes () {
            return this.$store.state.dropdownOptions.options.feeType;
        },
        feeOptions () {
            return [
                {
                    "label": "None",
                    "value": null
                },
                {
                    "label": "L",
                    "value": "L"
                },
                {
                    "label": "5",
                    "value": "5"
                },
                {
                    "label": "7",
                    "value": "7"
                },
                {
                    "label": "O",
                    "value": "O"
                }
            ];
        },
        frequencies () {
            return [
                {
                    "label": "monthly",
                    "value": "monthly"
                },
                {
                    "label": "once",
                    "value": "once"
                }
            ];
        },

        headersWithActions () {
            const newHeaders = JSON.parse(JSON.stringify(this.tableHeaders));
            newHeaders.shift();
            newHeaders.push({
                "value": "action",
                "text": "",
                "sortable": false
            });
            return newHeaders;
        }
    },
    "methods": {
        filterDeleted (fees) {
            return fees.filter((fee) => !fee.deleted);
        },
        addFee () {
            this.$store.dispatch("applicationFormValues/addEmptyFees");
        },
        removeFee (item) {
            this.$store.dispatch(
                "applicationFormValues/deleteFee",
                item.id
            );
        },
        open (value) {
            this.currentEdit = value;
        },
        saveFeeOption (item) {
            if (item.fee_option === "L") {
                item.frequency = "monthly";
            }
            this.save(item);
        },
        save (item) {
            this.$store.dispatch(
                "applicationFormValues/updateFees",
                item
            );
            this.currentEdit = null;
        }
    },
    data () {
        return {
            "dialog": false,
            "error": null,
            "counter": 1,
            "currentEdit": null,
            "filterText": "",
            "loading": false,
            "tableHeaders": this.field.tableHeaders,
            "showDatePicker": {
                "start_date": {},
                "end_date": {}
            },
            "typeOptions": [],
            "skipFeeQuery": false
        };
    }
};
</script>

<style>
    .card-select-style-wide {
        width: 180px;
    }
    .card-select-font-size {
        font-size: 14px;
    }
</style>
