<template>
    <v-autocomplete
        v-bind="field"
        @input="updateValue($event)"
        :value="formValue"
        :name="name"
        :label="field.label"
        :rules="setRules(field.rules)"
        :items="getItems"
        :item-text="item => getText(item)"
        persistent-hint
        :ref="this.name"
        :data-nw="this.name"
        :data-nw-value="formValue"
        :clearable="isClearable"
        :hint="getValue()"
        :disabled="!canEdit"
    />
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";

export default {
    "extends": BaseElement,
    "components": {},
    "props": ["defaultValue"],
    "methods": {
        getText (item) {
            if (item.text) {
                return item.text;
            }
            return item.value;
        },
        getValue () {
            if (this.getHint()) {
                return this.getHint();
            }
            if (this.formValue && !this.valueInItems) {
                return String(this.formValue);
            }
            return "";
        },
        parseItems (items) {
            if (items.length === 0) {
                return items;
            }
            const parsedItems = [];
            for (const item of items) {
                parsedItems.push(this.parseValue(this.field.getterParseTo, item));
            }
            return parsedItems;
        },
        defaultValueOrder () {
            if (this.formValue === null) {
                const value = this.defaultValue || this.$store.getters[this.field.defaultValGetter];
                if (value) {
                    this.updateValue(value);
                }
            }
        }
    },
    mounted () {
        this.defaultValueOrder();
    },
    "computed": {
        getItems () {
            let items = [];
            if (this.field.getter) {
                if (this.field.getterParameter) {
                    items = this.$store.getters[this.field.getter](this.field.getterParameter);
                } else {
                    items = this.$store.getters[this.field.getter];
                }
            } else {
                items = this.field.items;
            }
            if (this.field.getterParseTo) {
                return this.parseItems(items);
            }
            return items;
        },
        isClearable () {
            if (typeof this.field.clearable === "boolean") {
                return this.field.clearable;
            }
            return false;
        },
        valueInItems () {
            return this.getItems
                ? this.getItems.find((item) => item.value ? item.value === this.formValue : item === this.formValue)
                : false;
        }
    }
};

</script>
