<template>
  <v-btn
      v-if="activeMandate"
      id="mandate_preview_button"
      rounded color="primary"
      @click="previewMandate"
  >Preview mandate</v-btn>
</template>

<script>
import apolloClient from "../../../ApolloClient";
import gql from "graphql-tag";

export default {
    "components": {},
    data () {
        return {
            "mandate": null,
            "activeMandate": false
        };
    },
    "computed": {
        values () {
            return this.$store.state.applicationFormValues.values;
        }
    },
    "methods": {
        isFunction (functionToCheck) {
            return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
        },
        async previewMandate () {
            // eslint-disable-next-line max-len
            const query = gql` {previewMandate(documentId: "${this.mandate.id}" formId: "${this.values.id}"){
              mandate{contentType content}
            }}`;
            const result = await apolloClient.query({query});
            const pdfWindow = window.open("");
            pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,
            ${encodeURI(result.data.previewMandate.mandate.content)}'></iframe>`);
            pdfWindow.document.close();
        }
    },
    "watch": {
        async values () {
            const query = gql`{getSlimPayMandate(formId: "${this.values.id}"){responseString
                  mandate{label id paymentScheme reference dateSigned}
              }}`;

            const result = await apolloClient.query({query});

            if (result.data.getSlimPayMandate && result.data.getSlimPayMandate.mandate) {
                this.mandate = result.data.getSlimPayMandate.mandate;
                this.activeMandate = true;
            }
        }
    }
};

</script>

