<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-dialog scrollable :value="dialog" persistent max-width="90vw">
        <v-card id="audit-log-card" height="90vh" data-nw="auditLogCard">
            <v-card-title>
                <v-col lg="2">
                    Audit log
                </v-col>
                <v-col lg="9">
                    <v-btn rounded color="primary"
                           class="text-capitalize btn-block float-right"
                           @click="goToAuditLogApplication">
                        Audit log to print</v-btn>
                </v-col>
                <v-col lg="1">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                           data-nw="closeAuditLogDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    sort-by="modified_at"
                    :sort-desc="true"
                    :expanded.sync="expanded"
                    show-expand single-expand
                    :headers="headers"
                    :items="auditLogs"
                    :items-per-page="25"
                    :footer-props="{'items-per-page-options': [5,10,15,25,50,100]}"
                    @click:row="toggleExpand"
                    class="table-cursor">
                    <template v-slot:item.modified_at="{ item }">
                        {{item.modified_at|formatDateTimeSecond}}
                    </template>
                    <template v-slot:item.modified_by="{ item }">
                        <div  class="text-truncate" style="max-width: 25vw">{{item.modified_by}}</div>
                    </template>
                    <template v-slot:expanded-item="{ item }">
                        <td/>
                        <td/>
                        <td colspan="4" style="white-space: normal!important">{{item.modified_by}}</td>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import importedHeaders from "@/components/views/dialogs/headers/AuditLogDialogHeaders.json";
export default {
    "name": "AuditLogDialog",
    "props": {"dialog": {"default": false}},
    "computed": {
        auditLogs () {
            return this.$store.state.auditLogs.auditLogs;
        }
    },
    "created" () {
        this.$store.dispatch(
            "auditLogs/setAuditLogs",
            this.$route.params.id
        );
    },
    "data" () {
        return {
            "expanded": [],
            "headers": importedHeaders
        };
    },
    "methods": {
        goToAuditLogApplication () {
            this.$router.push({
                "name": "AuditLogApplication"
            });
        },
        "toggleExpand" (value) {
            // Sets the expanded row in the data table
            this.expanded = this.expanded.length > 0 && this.expanded[0].id === value.id ? [] : [value];
        },
        closeDialog () {
            this.$emit(
                "update:dialog",
                false
            );
        }
    }
};
</script>
