import Vue from "vue";


/**
 * Convert value to readable user role
 *
 * @param {string} value The string value of a user role
 * @returns {string} the formatted user role
 */
export const roleFormat = (value) => {
    if (value) {
        const str = value.replace(
            "ROLE_",
            ""
        ).replace(
            "_",
            " "
        ).toLowerCase();
        // Capitalize the first letter of each word
        const splitStr = str.toLowerCase().split(" ");
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(" ");
    }
    return "";
};

Vue.filter(
    "formatRole",
    roleFormat
);
