import Vue from "vue";
const state = {"telesalesAgencyId": null};

const getters = {"getTelesalesAgencyId": (currentState) => currentState.telesalesAgencyId};

const mutations = {
    SET_TELESALES_AGENCY_ID (currentState, telesalesAgencyId) {
        Vue.set(currentState, "telesalesAgencyId", telesalesAgencyId);
    }
};

const actions = {
    async setTelesalesAgencyId ({commit}, telesalesAgencyId) {
        await commit(
            "SET_TELESALES_AGENCY_ID",
            telesalesAgencyId
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
