<template>
    <v-dialog :value="isDialogOpen" max-width="1600" persistent>
        <v-card :loading="loading">
            <v-card-title>
                <v-col lg="10">
                    Salesforce Account Search
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                           data-nw="fraudCheckDialogClose"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-container class="px-2">
                    <v-row id="salesforce-fields">
                        <v-col lg="4" md="4" v-for="field in salesforceFields" :key="field.label">
                            <v-row :no-gutters="true">
                                <v-checkbox
                                    v-model="field.ticked"
                                />
                                <v-text-field
                                    :disabled="!field.ticked"
                                    :label="field.label"
                                    v-model="field.value"
                                    outlined
                                />
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn
                            :disabled="!isTickedValue"
                            id="search-customer-button"
                            rounded color="primary"
                            class="text-capitalize"
                            @click.stop="searchCustomer"
                        >
                            Search customer
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                        <v-data-table
                            v-if="searched"
                            v-model="selectedCustomer"
                            style="width: 100%"
                            id="search_customer_table"
                            :headers="headers"
                            :items="customers"
                            class="table-cursor"
                            :single-select="true"
                            item-key="accountERPID"
                            show-select
                            hide-default-footer
                            disable-pagination
                        />
                    </v-row>
                    <v-row>
                        <v-btn
                            v-if="customers.length !== 0"
                            :disabled="selectedCustomer.length === 0"
                            id="choose-customer-button"
                            rounded color="primary"
                            class="text-capitalize mt-1"
                            @click.stop="chooseCustomer"
                        >
                            Map to Existing Customer
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import gql from "graphql-tag";
import importedHeaders from "@/components/views/dialogs/headers/SalesforceAccountSearchDialogHeaders.json";

export default {
    // eslint-disable-next-line max-lines-per-function
    "data" () {
        return {
            "salesforceFields": {
                "mdm_sold_to_number": {
                    "ticked": true,
                    "label": "Sold to",
                    "value": null
                },
                "mdm_payer_number": {
                    "ticked": true,
                    "label": "Payer Number",
                    "value": null
                },
                "authority_number": {
                    "ticked": true,
                    "label": "Authority Number",
                    "value": null
                },
                "company_name": {
                    "ticked": false,
                    "label": "Company Name",
                    "value": null
                },
                "city": {
                    "ticked": false,
                    "label": "City",
                    "value": null
                },
                "country": {
                    "ticked": false,
                    "label": "Country",
                    "value": null
                },
                "zipcode": {
                    "ticked": false,
                    "label": "Zipcode",
                    "value": null
                },
                "sales_organization_siebel": {
                    "ticked": false,
                    "label": "Sales Organization",
                    "value": null
                },
                "vat_number": {
                    "ticked": false,
                    "label": "VAT Number",
                    "value": null
                },
                "coc_nr": {
                    "ticked": false,
                    "label": "Coc number",
                    "value": null
                },
                "dnb_duns_number": {
                    "ticked": false,
                    "label": "Duns number",
                    "value": null
                }
            },
            "headers": importedHeaders,
            "customers": [],
            "selectedCustomer": [],
            "disableCreateBtn": false,
            "searched": false,
            "loading": false
        };
    },
    "computed": {
        isDialogOpen () {
            return this.$store.state.salesforceFields.salesforceAccountSearchDialogOpen;
        },
        formValues () {
            return this.$store.state.applicationFormValues.values;
        },
        isTickedValue () {
            const fields = Object.keys(this.salesforceFields);
            return fields.find((fieldName) => this.salesforceFields[fieldName].ticked);
        }
    },
    "methods": {
        closeDialog () {
            this.customers = [];
            this.searched = false;
            this.$store.dispatch("salesforceFields/openDialog", {"open": false, "dialog": "search"});
        },
        fillFields () {
            const fieldNames = Object.keys(this.salesforceFields);
            fieldNames.forEach((fieldName) => {
                this.salesforceFields[fieldName].value = this.formValues[fieldName];
            });
        },
        searchCustomer () {
            if (this.createQueryArgs()) {
                // this.$apollo.queries.getSalesforceCustomer.skip = false;
            } else {
                this.$toast(
                    "Sold To, Payer No., Authority No. are can't be empty",
                    {
                        "color": "softWarning"
                    }
                );
            }
        },
        async chooseCustomer () {
            await this.saveCustomer("Chosen customer");
            this.$root.$emit("submitBaseForm", false, true);
        },
        async saveCustomer (toastMessage) {
            // save function
            this.$toast(
                toastMessage,
                {
                    "color": "success"
                }
            );
            await this.$store.dispatch("salesforceFields/openDialog", {"open": false, "dialog": "search"});
        },
        validateFields () {
            let error = false;
            const columnNames = Object.keys(this.salesforceFields);
            columnNames.forEach((fieldName) => {
                const fields = this.salesforceFields[fieldName];
                if (fieldName === "mdm_sold_to_number" && fields.value === "") {
                    error = true;
                } else if (fieldName === "mdm_payer_number" && fields.value === "") {
                    error = true;
                } else if (fieldName === "authority_number" && fields.value === "") {
                    error = true;
                }
            });

            return error;
        },
        createQueryArgs () {
            let queryArgs = "";
            if (this.validateFields() === false) {
                const columnNames = Object.keys(this.salesforceFields);
                columnNames.forEach((fieldName) => {
                    const fields = this.salesforceFields[fieldName];
                    if (fields.ticked && fields.value) {
                        if (fieldName === "dnb_duns_number") {
                            queryArgs = queryArgs.concat(`DUNSNumber: "${fields.value}"`);
                        } else if (fieldName === "vat_number") {
                            queryArgs = queryArgs.concat(`VATNumber: "${fields.value}"`);
                        } else {
                            queryArgs = queryArgs.concat(`${fieldName}: "${fields.value}"`);
                        }
                    }
                });
            }
            return queryArgs;
        },
        createQueryFields () {
            let queryFields = "";
            this.headers.forEach((header) => {
                queryFields = queryFields.concat(`${header.value} `);
            });
            return queryFields;
        }
    },
    "watch": {
        "isDialogOpen" () {
            if (this.isDialogOpen) {
                this.fillFields();
                // this.$apollo.queries.getSalesforceCustomer.skip = this.salesforceFields.dnb_duns_number.value === null;
            }
        }
    },
    "apollo": {
        "getSalesforceCustomer": {
            query () {
                this.loading = true;
                const queryArgs = this.createQueryArgs();
                const queryFields = this.createQueryFields();
                return gql`query {salesforceAccountDetails (${queryArgs}) {data
                   { ${queryFields} } responseString error}}`;
            },
            update (data) {
                this.customers = data.salesforceAccountDetails.data;
                this.$apollo.queries.getSalesforceCustomer.skip = true;
                this.searched = true;
                this.loading = false;
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
