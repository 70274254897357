import AuditLogApplication from "@/components/views/application/AuditLogApplication.vue";
import Dashboard from "../components/views/Dashboard";
import EditAccountingClerk from "../components/views/systemtools/accountingClerks/EditAccountingClerk";
import EditApplication from "../components/views/application/EditApplication";
import EditDunningClerk from "../components/views/systemtools/dunningClerks/EditDunningClerk";
import EditGroup from "../components/views/aclGroups/EditGroup.vue";
import EditRebate from "../components/views/rebates/EditRebate";
import EditSite from "../components/views/sites/EditSite";
import EditTelesales from "../components/views/telesales/EditTelesales";
import EditUser from "../components/views/users/EditUser";
import EditZFPayer from "../components/views/systemtools/zfPayers/EditZFPayer";
import EditZLPayer from "../components/views/systemtools/zlPayers/EditZLPayer";
import EditZRPayer from "../components/views/systemtools/zrPayers/EditZRPayer";
import JwtLogin from "../components/views/JwtLogin";
import ListAccountingClerks from "../components/views/systemtools/accountingClerks/ListAccountingClerks";
import ListAlertList from "../components/views/alertlist/ListAlertlist";
import ListApplication from "../components/views/application/ListApplication";
import ListDunningClerks from "../components/views/systemtools/dunningClerks/ListDunningClerks";
import ListExports from "@/components/views/systemtools/exports/ListExports";
import ListGroups from "../components/views/aclGroups/ListGroups.vue";
import ListRebates from "../components/views/rebates/ListRebates";
import ListSites from "../components/views/sites/ListSites";
import ListTelesales from "../components/views/telesales/ListTelesales";
import ListUsers from "../components/views/users/ListUsers";
import ListZFPayers from "../components/views/systemtools/zfPayers/ListZFPayers";
import ListZLPayers from "../components/views/systemtools/zlPayers/ListZLPayers";
import ListZRPayers from "../components/views/systemtools/zrPayers/ListZRPayers";
import Login from "../components/views/Login";
import Logout from "../components/views/Logout";
import MetaHealth from "../components/views/MetaHealth";
import NewAccountingClerk from "../components/views/systemtools/accountingClerks/NewAccountingClerk";
import NewApplication from "../components/views/application/NewApplication";
import NewCampaignApplication from "@/components/views/application/NewCampaignApplication.vue";
import NewDunningClerk from "../components/views/systemtools/dunningClerks/NewDunningClerk";
import NewGroup from "../components/views/aclGroups/NewGroup.vue";
import NewRebate from "../components/views/rebates/NewRebate";
import NewSite from "../components/views/sites/NewSite";
import NewTelesales from "../components/views/telesales/NewTelesales";
import NewUser from "../components/views/users/NewUser";
import NewZFPayer from "../components/views/systemtools/zfPayers/NewZFPayer";
import NewZLPayer from "../components/views/systemtools/zlPayers/NewZLPayer";
import NewZRPayer from "../components/views/systemtools/zrPayers/NewZRPayer";
import ResignQuotationQueue from "@/components/views/systemtools/ResignQuotationQueue";
import Router from "vue-router";
import SystemTools from "../components/views/systemtools/SystemTools";
import Vue from "vue";
import WinshuttleBulkReset from "../components/views/systemtools/WinshuttleBulkReset";
import {ability} from "../ability";
import goTo from "vuetify/es5/services/goto";

/* eslint-disable max-lines */

Vue.use(Router);

const router = new Router({
    "mode": "history",
    "scrollBehavior": (to, from, savedPosition) => {
        let scrollTo = 0;

        if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }

        return goTo(scrollTo);
    },
    "routes": [
        {
            "path": "/jwt-login",
            "name": "Login JWT",
            "component": JwtLogin
        },
        {
            "path": "/_meta/health",
            "name": "Meta Health",
            "component": MetaHealth
        },
        {
            "path": "/",
            "name": "Dashboard",
            "component": Dashboard,
            "meta": {
                "requiresAuth": true
            }
        },
        {
            "path": "/applications",
            "name": "ListApplication",
            "component": ListApplication,
            "meta": {
                "requiresAuth": true
            }
        },
        {
            "path": "/applications/edit/:id",
            "name": "EditApplication",
            "component": EditApplication,
            "meta": {
                "requiresAuth": true
            }
        },
        {
            "path": "/applications/edit/:id/audit-log-print",
            "name": "AuditLogApplication",
            "component": AuditLogApplication,
            "meta": {
                "requiresAuth": true
            }
        },
        {
            "path": "/applications/create/:template_id",
            "name": "CreateApplication",
            "component": NewApplication,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "applications"
                }
            }
        },
        {
            "path": "/applications/campaignCreate/:campaign_id",
            "name": "CreateCampaignApplication",
            "component": NewCampaignApplication,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "applications"
                }
            }
        },
        {
            "path": "/alertlist",
            "name": "ListAlertlist",
            "component": ListAlertList,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "alertlist"
                }
            }
        },
        {
            "path": "/rebates",
            "name": "ListRebates",
            "component": ListRebates,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "rebate"
                }
            }
        },
        {
            "path": "/rebates/edit/:id",
            "name": "EditRebate",
            "component": EditRebate,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "rebate"
                }
            }
        },
        {
            "path": "/rebates/create",
            "name": "CreateRebate",
            "component": NewRebate,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "rebate"
                }
            }
        },
        {
            "path": "/sites",
            "name": "ListSites",
            "component": ListSites,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "sites"
                }
            }
        },
        {
            "path": "/sites/edit/:id",
            "name": "EditSite",
            "component": EditSite,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "sites"
                }
            }
        },
        {
            "path": "/sites/create",
            "name": "CreateSite",
            "component": NewSite,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "sites"
                }
            }
        },
        {
            "path": "/telesales",
            "name": "ListTelesales",
            "component": ListTelesales,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "use",
                    "subject": "telesales_agency"
                }
            }
        },
        {
            "path": "/telesales/edit/:id",
            "name": "EditTelesales",
            "component": EditTelesales,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "use",
                    "subject": "telesales_agency"
                }
            }
        },
        {
            "path": "/telesales/create",
            "name": "CreateTelesales",
            "component": NewTelesales,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "telesales_agency"
                }
            }
        },
        {
            "path": "/groups",
            "name": "ListGroups",
            "component": ListGroups,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "acl_group"
                }
            }
        },
        {
            "path": "/group/edit/:id",
            "name": "EditGroup",
            "component": EditGroup,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "acl_group"
                }
            }
        },
        {
            "path": "/group/create",
            "name": "CreateAclGroup",
            "component": NewGroup,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "acl_group"
                }
            }
        },
        {
            "path": "/users",
            "name": "ListUsers",
            "component": ListUsers,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "user"
                }
            }
        },

        {
            "path": "/users/edit/:id",
            "name": "EditUser",
            "component": EditUser,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "user"
                }
            }
        },
        {
            "path": "/users/create",
            "name": "CreateUser",
            "component": NewUser,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "user"
                }
            }
        },
        {
            "path": "/SystemTools",
            "name": "SystemTools",
            "component": SystemTools,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "use",
                    "subject": "systemtools"
                }
            }
        },
        {
            "path": "/SystemTools/ResignQueue",
            "name": "ResignQueue",
            "component": ResignQuotationQueue,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "resignQueue",
                    "subject": "systemtools"
                }
            }
        },
        {
            "path": "/SystemTools/WinshuttleBulkReset",
            "name": "WinshuttleBulkReset",
            "component": WinshuttleBulkReset,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "winshuttleBulkReset",
                    "subject": "systemtools"
                }
            }
        },
        {
            "path": "/SystemTools/AccountingClerks",
            "name": "ListAccountingClerks",
            "component": ListAccountingClerks,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "option_accounting_clerk"
                }
            }
        },
        {
            "path": "/SystemTools/AccountingClerks/edit/:id",
            "name": "EditAccountingClerk",
            "component": EditAccountingClerk,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "option_accounting_clerk"
                }
            }
        },
        {
            "path": "/SystemTools/AccountingClerks/create",
            "name": "CreateAccountingClerk",
            "component": NewAccountingClerk,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "option_accounting_clerk"
                }
            }
        },
        {
            "path": "/SystemTools/DunningClerks",
            "name": "ListDunningClerks",
            "component": ListDunningClerks,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "option_dunning_clerk"
                }
            }
        },
        {
            "path": "/SystemTools/DunningClerks/edit/:id",
            "name": "EditDunningClerk",
            "component": EditDunningClerk,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "option_dunning_clerk"
                }
            }
        },
        {
            "path": "/SystemTools/Exports",
            "name": "ListExports",
            "component": ListExports,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "export"
                }
            }
        },
        {
            "path": "/SystemTools/Exports/download/:filename",
            "name": "DownloadExport",
            "component": ListExports,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "export"
                }
            }
        },
        {
            "path": "/SystemTools/DunningClerks/create",
            "name": "CreateDunningClerk",
            "component": NewDunningClerk,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "option_dunning_clerk"
                }
            }
        },
        {
            "path": "/SystemTools/ZFPayers",
            "name": "ListZFPayers",
            "component": ListZFPayers,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "option_partner_zf"
                }
            }
        },
        {
            "path": "/SystemTools/ZFPayers/edit/:id",
            "name": "EditZFPayer",
            "component": EditZFPayer,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "option_partner_zf"
                }
            }
        },
        {
            "path": "/SystemTools/ZFPayers/create",
            "name": "CreateZFPayer",
            "component": NewZFPayer,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "option_partner_zf"
                }
            }
        },
        {
            "path": "/SystemTools/ZLPayers",
            "name": "ListZLPayers",
            "component": ListZLPayers,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "option_partner_zl"
                }
            }
        },
        {
            "path": "/SystemTools/ZLPayers/edit/:id",
            "name": "EditZLPayer",
            "component": EditZLPayer,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "option_partner_zl"
                }
            }
        },
        {
            "path": "/SystemTools/ZLPayers/create",
            "name": "CreateZLPayer",
            "component": NewZLPayer,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "option_partner_zl"
                }
            }
        },
        {
            "path": "/SystemTools/ZRPayers",
            "name": "ListZRPayers",
            "component": ListZRPayers,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "read",
                    "subject": "option_partner_zr"
                }
            }
        },
        {
            "path": "/SystemTools/ZRPayers/edit/:id",
            "name": "EditZRPayer",
            "component": EditZRPayer,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "update",
                    "subject": "option_partner_zr"
                }
            }
        },
        {
            "path": "/SystemTools/ZRPayers/create",
            "name": "CreateZRPayer",
            "component": NewZRPayer,
            "meta": {
                "requiresAuth": true,
                "ability": {
                    "action": "create",
                    "subject": "option_partner_zr"
                }
            }
        },
        {
            "path": "/login",
            "name": "Login",
            "component": Login
        },
        {
            "path": "/logout",
            "name": "Logout",
            "component": Logout,
            "meta": {
                "requiresAuth": true
            }
        }
    ]
});

// eslint-disable-next-line consistent-return,max-statements
router.beforeEach((to, from, next) => {

    try {
        const jwt = window.localStorage.getItem("jwt");
        if (to.matched.some((record) => record.meta.requiresAuth) && jwt === null) {
            if (window.localStorage.getItem("loggedOut") !== null) {
                return next({
                    "path": "/login?msg=logout"
                });
            }
            return next({
                "path": "/login"
            });
        }
        // eslint-disable-next-line no-extra-parens
        if ((to.name === "Login" && jwt !== null) || to.matched.length === 0) {
            return next({
                "path": "/"
            });
        }
        if (typeof to.meta.ability !== "undefined") {
            if (ability.cannot(to.meta.ability.action, to.meta.ability.subject)) {
                return next(false);
            }
        }
        return next();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return next();
});

export default router;
