import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {"auditLogs": null};

const mutations = {
    SET_AUDITLOGS (currentState, auditLogs) {
        Vue.set(currentState, "auditLogs", auditLogs);
    }
};

const getters = {};

const actions = {
    async setAuditLogs ({commit}, auditLogs) {
        // eslint-disable-next-line max-len
        const auditGql = gql`query {data: audit_log(form_id: ${auditLogs} first: -1) {data{id, field, modified_by, modified_by_type, modified_at, new_value}}}`;
        const audit = await apolloClient.query({"query": auditGql});
        await commit(
            "SET_AUDITLOGS",
            audit?.data?.data?.data
        );
    },
    async clearAuditLogs ({commit}) {
        await commit(
            "SET_AUDITLOGS",
            []
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};

