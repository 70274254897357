<template>
    <v-container fluid style="height: 100%">
        <iframe allowFullScreen="allowfullscreen" frameborder="0" height="100%"
                :src="'https://app.powerbi.com/reportEmbed?reportId=6c2bd713-838b-452a-af7c-26cf5023ff12'
                +'&autoAuth=true'
                +'&ctid=ea80952e-a476-42d4-aaf4-5457852b0f7e'"
                title="CAPI Operational report MVP" width="100%"></iframe>
    </v-container>
</template>
<script>

export default {
    "name": "Dashboard",
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": true,
                    "to": {"name": "Dashboard"}
                }
            ]
        };
    }
};
</script>
