<template>
    <v-container fluid v-if="$can('create', 'option_partner_zf')">
        <HeaderBar
                :breadcrumbs="breadcrumbs"
                title="New ZF Payer"
        >
            <template v-slot:button>
                <v-btn
                        class="text-capitalize btn-block"
                        rounded color="primary"
                        @click="sumbitZFPayerForm()">
                    Save ZF Payer Partner
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
                type="create"
                store-module="zfPayerFormValues"
                :form-segments=formSegments
                :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../../baseComponents/BaseForm";
import HeaderBar from "../../../uiElements/HeaderBar";

export default {
    "name": "NewZFPayer",
    "components": {
        BaseForm,
        HeaderBar
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "ZF Payers",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListZFPayers"}
                },
                {
                    "text": "New ZF Payer",
                    "disabled": true
                }
            ]
        };
    },
    "methods": {
        sumbitZFPayerForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.zfPayerFormSegments;
        }
    },
    created () {
        this.$store.dispatch("zfPayerFormValues/deleteFormValues");
    }
};
</script>
