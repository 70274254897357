import {isCombinedShareCapitalValid, isUboShareCapitalFiledGiven} from "@/components/validators/individual/ubo/uboShareCapitalValidator";
import {isSigneeRequiredFieldsGiven} from "@/components/validators/individual/signee/signeeRequiredFieldsValidator";

const generalValidationRuleFields = [
    "phone_number_country_code",
    "phone_number",
    "email",
    "share_capital"
];

const generalValidationRuleFieldsMapping = {
    "phone_number_country_code": "countryCode",
    "phone_number": "phoneNumber",
    "email": "email",
    "share_capital": "shareCapital"
};

function getMappedFieldName (field) {
    return generalValidationRuleFieldsMapping[field] || field;
}

function generalFieldValidator (field) {
    return !generalValidationRuleFields.includes(field);
}

function isGeneralFieldsValid (field, rules, data) {
    if (!generalFieldValidator(field) && data.validationHardRules[getMappedFieldName(field)]) {
        rules.push(...data.validationHardRules[getMappedFieldName(field)]);
    }
}

export function individualRulesValidator (data, field) {
    const rules = [];
    isGeneralFieldsValid(field, rules, data);
    isSigneeRequiredFieldsGiven(data, field, rules);
    isUboShareCapitalFiledGiven(field, data, rules);
    isCombinedShareCapitalValid(field, data, rules);

    return rules;
}
