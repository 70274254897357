import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {
    "values": {},
    "userId": null
};


const mutations = {
    SET_FORM_VALUES (currentState, data) {
        currentState.values = {
            ...currentState.values,
            ...data
        };
    },
    SET_GROUP_IDS (currentState, data) {
        Vue.set(currentState.values, "aclgroup_ids", data.aclgroup_ids);
    },
    UPDATE_FORM_VALUE (currentState, data) {
        Vue.set(currentState.values, data.name, data.value);
    },
    DELETE_FORM_VALUES (currentState) {
        const clearedForm = {
            "active": 1,
            "last_login": null,
            "aclgroup_ids": []
        };
        Vue.set(currentState, "values", clearedForm);
        Vue.set(currentState, "userId", null);
    },
    SET_USER_ID (currentState, data) {
        Vue.set(currentState, "userId", data);
    }
};

/**
 * Function that creates JSON object based on the similarities between the keys of the "values" object of this module and the provided argument
 * @param {object} fieldsObject Object that is used to filter the "values" object based on it's keys
 * @returns {object} Filtered object
 */
function filterObject (fieldsObject) {
    const keyArray = [];
    Object.keys(fieldsObject).forEach((key) => {
        keyArray.push(key);
    });

    const keyValueObject = {};
    Object.keys(state.values).forEach((item) => {
        if (keyArray.find((key) => key === item)) {
            keyValueObject[item] = state.values[item];
        }
    });

    return keyValueObject;
}

/**
 *
 * @param {Promise} mutation The mutation that needs to be executed
 * @returns {Promise<T>} Result of the mutation
 */
function executeMutation (mutation) {
    return apolloClient.mutate({mutation}).then((response) => {
        if (response.data[Object.keys(response.data)[0]].errors) {
            throw Error(response.data[Object.keys(response.data)[0]].errors);
        }
        return response;
    }).catch((error) => {
        throw Error(error);
    });
}

/**
 * @param userId
 * @param groupIds
 * @returns {Promise} result of the mutation
 */
async function mutateUserGroup (userId, groupIds) {
    const userGroupMutation =
        gql`mutation{ create_acl_group_user (user_id:${userId} aclGroupIds: [${groupIds}]){responseString}}`;

    await executeMutation(userGroupMutation);
}

const getters = {};

const actions = {
    async setFormValues ({commit}, data) {
        await commit(
            "SET_FORM_VALUES",
            data
        );
    },
    async updateFormValue ({commit}, data) {
        await commit(
            "UPDATE_FORM_VALUE",
            data
        );
    },
    async deleteFormValues ({commit}) {
        await commit("DELETE_FORM_VALUES");
    },
    async setGroupIds ({commit}, data) {
        await commit(
            "SET_GROUP_IDS",
            data
        );
    },
    async setUserIdValue ({commit}, data) {
        await commit(
            "SET_USER_ID",
            data
        );
    },
    async saveForm ({rootState}, type) {
        const userData = filterObject(rootState.formSegments.userFormSegments[0].fields);
        if (type === "update") {
            userData.id = state.userId;
        } else if (type === "create") {
            userData.created = new Date().toISOString().slice(0, 19).replace("T", " ");
        }

        let formMutationQuery = "";
        for (const key of Object.keys(userData)) {
            const fieldValue = userData[key];
            formMutationQuery += typeof fieldValue === "string" ? `${key}: "${fieldValue}" ` : `${key}: ${fieldValue} `;
        }
        const queryType = `${type}_user`;
        const userMutation = gql`mutation{ ${type}_user(${formMutationQuery}){responseString insertedData {id}}}`;
        const mutationResult = await executeMutation(userMutation);

        if (mutationResult.data[queryType].insertedData) {
            await mutateUserGroup(
                mutationResult.data[queryType].insertedData[0].id,
                state.values.aclgroup_ids
            );
        } else {
            throw new Error(mutationResult.data[queryType].responseString);
        }
        return mutationResult;
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
