<template>
  <v-treeview :items="itemArray"
              :value="selectedItems"
              selectable
              selected-color="primary"
              hoverable
              selection-type="leaf"
              @input="setSelectedTreeIds($event)"
              open-on-click
              item-key="treeID"
              dense>
  </v-treeview>
</template>

<script>

export default {
    "props": [
        "itemArray",
        "selectedItems"
    ],
    "methods": {
        setSelectedTreeIds (event) {
            this.$emit(
                "setSelectedItems",
                event
            );
        }
    }
};
</script>

