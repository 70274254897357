import Vue from "vue";
import apolloClient from "../../ApolloClient";
import editRebateForm from "../../formConfig/editRebateForm";
import gql from "graphql-tag";

const state = {
    "values": {},
    "siteId": null,
    "rebateId": null
};

const editRebateFormRebateIndices = [0, 1];
const editRebateFormSiteIndices = [2];

const mutations = {
    SET_FORM_VALUES (currentState, data) {
        currentState.values = {
            ...currentState.values,
            ...data
        };
        // eslint-disable-next-line camelcase
        Vue.set(currentState.values, "site_id", currentState.siteId);
    },
    UPDATE_FORM_VALUE (currentState, data) {
        Vue.set(currentState.values, data.name, data.value);
    },
    DELETE_FORM_VALUES (currentState) {
        Vue.set(currentState, "values", {});
        Vue.set(currentState, "siteId", null);
        Vue.set(currentState, "rebateId", null);
    },
    SET_SITE_ID (currentState, data) {
        Vue.set(currentState, "siteId", data);
    },
    SET_REBATE_ID (currentState, data) {
        Vue.set(currentState, "rebateId", data);
    },
    CLEAR_SITE (currentState) {
        const data = currentState.values;
        const fieldsData = {};
        for (const index of editRebateFormSiteIndices) {
            Object.assign(fieldsData, editRebateForm.formSegments[index].fields);
        }
        const configFieldKeys = Object.keys(fieldsData);
        Object.keys(currentState.values).forEach((storeValueKey) => {
            const found = configFieldKeys.find((configFieldKey) => configFieldKey === storeValueKey);
            if (found) {
                data[storeValueKey] = "";
            }
        });
        Vue.set(currentState, "values", data);
        Vue.set(currentState, "siteId", null);
    }
};

const getters = {};

/**
 * Function that creates JSON object based on the similarities between the keys of the "values" object of this module and the provided argument
 * @param {object} fieldsObject Object that is used to filter the "values" object based on it's keys
 * @returns {object} Filtered object
 */
function filterObject (fieldsObject) {
    const keyArray = [];
    Object.keys(fieldsObject).forEach((key) => {
        keyArray.push(key);
    });

    const keyValueObject = {};
    Object.keys(state.values).forEach((item) => {
        if (keyArray.find((key) => key === item)) {
            keyValueObject[item] = state.values[item];
        }
    });

    return keyValueObject;
}

const actions = {
    async setFormValues ({commit}, data) {
        await commit(
            "SET_FORM_VALUES",
            data
        );
    },
    async deleteFormValues ({commit}) {
        await commit("DELETE_FORM_VALUES");
    },
    async updateFormValue ({commit}, data) {
        await commit(
            "UPDATE_FORM_VALUE",
            data
        );
    },
    async setSiteIdValue ({commit}, data) {
        await commit(
            "SET_SITE_ID",
            data
        );
    },
    async setRebateIdValue ({commit}, data) {
        await commit(
            "SET_REBATE_ID",
            data
        );
    },
    // eslint-disable-next-line max-statements
    async saveForm ({rootState}, type) {
        const rebateData = {};

        for (const index of editRebateFormRebateIndices) {
            Object.assign(rebateData, rootState.formSegments.rebatesFormSegments[index].fields);
        }

        const rebateDataFiltered = filterObject(rebateData);

        rebateDataFiltered.id = state.rebateId;

        let formMutationQuery = `site_id: ${state.siteId} `;

        for (const key of Object.keys(rebateDataFiltered)) {
            const fieldValue = rebateDataFiltered[key];
            formMutationQuery += typeof fieldValue === "string" ? `${key}: "${fieldValue}" ` : `${key}: ${fieldValue} `;
        }
        const queryType = `${type}_rebate`;
        const mutation = gql`mutation{ ${queryType}(${formMutationQuery}){responseString insertedData {id}}}`;
        const mutationResult = await apolloClient.mutate({mutation});

        if (!mutationResult.data[queryType].insertedData) {
            throw new Error(mutationResult.data[queryType].responseString);
        }
        return mutationResult;
    },
    async clearSite ({commit}) {
        await commit("CLEAR_SITE");
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
