<template>
    <v-navigation-drawer
        app
        permanent
        class="d-print-none"
    >
        <v-row class="fill-height" no-gutters>
            <v-navigation-drawer
                permanent id="globalSideBar" :mini-variant.sync="mini"
                v-model="navigationDrawer"
                class="float-left not-fixed">
                <v-list-item class="pa-2" width="199px">
                    <v-row no-gutters align="center" justify="center">
                        <img center :src="logo" class="ma-1" style="max-width: 100%">
                    </v-row>
                </v-list-item>
                <v-list dense>
                    <v-list-item-group mandatory v-model="currentRouteIndex">
                        <v-list-item class="pa-3"
                                     active-class="primary" color="white"
                                     v-for="(route, i) in routes" :key="i" link @click="navigateRoute(route)"
                                     :data-nw="`sidebar${route.routeName}`">
                            <v-list-item-icon>
                                <v-icon>{{ route.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ route.title }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon
                                    v-if="route.routeName !== 'Login' && route.routeName !== 'Logout'">
                                    mdi-chevron-right
                                </v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            <v-list dense
                    v-if="(
                        this.currentRoutePath.startsWith('/applications/edit/', 0)
                        || this.currentRoutePath.startsWith('/applications/create/', 0)
                        || this.currentRoutePath.startsWith('/applications/campaignCreate', 0)
                    )
                    && this.mini === true" id="applicationFormSideBar"
                    v-model="formNavigationDrawer"
                    class="navigation--sub not-fixed">
                <v-list-item-group v-model="currentSegmentIndex" mandatory>
                    <template v-for="(segment, i) in formSegments">
                        <v-list-item class="pl-3 scrollactive-item" ref="scrollactive-item"
                                     v-bind:class="{ 'global-error': hasGlobalWarning }"
                                     active-class="primary" color="white"
                                     v-show="!segment.hideInSideBar && segment.visible"
                                     :elementid="'form_segment_'+segment.id"
                                     :data-nw="'sidebar_'+segment.id"
                                     :key="i" link @click="navigateSegment(segment)">
                            <v-list-item-content>
                                <v-list-item-title>{{ segment.title }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon v-if="hasWarnings(segment)"
                                        class="sidebarSegmentError px-0 m-0"
                                        dense>
                                    mdi-exclamation-thick</v-icon>
                                <v-icon v-else>mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-list dense
                    v-model="systemToolsNavigationDrawer"
                    class="not-fixed navigation--sub"
                    v-if="this.currentRoutePath.startsWith('/SystemTools', 0) && this.mini === true"
            >
                <v-list-item-group v-model="currentSegmentIndex" mandatory>
                    <v-list-item class="pl-3"
                                 active-class="primary" color="white"
                                 v-for="(subMenu, i) in systemToolbarMenu"
                                 :key="i" link @click="navigateRoute(subMenu)"
                                 :data-nw="`submenu${subMenu.routeName}`">
                        <v-list-item-content>
                            <v-list-item-title>{{ subMenu.title }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-row>
    </v-navigation-drawer>
</template>

<script>
import {ability} from "../../ability";
import logo from "../../assets/bp_logo.svg";
import routeConfig from "../../../src/routeConfig";

export default {
    "methods": {
        navigateRoute (itemClicked) {
            if (this.$router.currentRoute.name !== itemClicked.routeName) {
                this.$router.push({"name": itemClicked.routeName});
            }
        },
        navigateSegment (itemClicked) {
            const options = {
                "duration": 300,
                "offset": 40,
                "easing": "easeInOutCubic"
            };
            window.removeEventListener("scroll", this.handleScroll);
            this.$vuetify.goTo(
                `#form_segment_${itemClicked.id}`,
                options
            ).then(() => {
                window.addEventListener("scroll", this.handleScroll);
            });
        },
        setCurrentRoute () {
            this.currentRoutePath = this.$router.currentRoute.fullPath;
            const currentRouteName = this.$router.currentRoute.name;
            this.currentRouteName = currentRouteName;
            this.currentRouteIndex =
                this.routes.findIndex((route) => currentRouteName === route.routeName);

            // If route is not found (e.g. when route is "edit" or "create", check the list of sub routes)
            if (this.currentRouteIndex === -1) {
                const subRoute = this.subRoutes.find((subroute) => currentRouteName === subroute.routeName);
                if (subRoute) {
                    if ([
                        "SystemTools",
                        "ListAccountingClerks",
                        "ListDunningClerks",
                        "ListZFPayers",
                        "ListZLPayers",
                        "ListZRPayers",
                        "ListExports"
                    ].includes(subRoute.parentRouteName)) {
                        this.currentRouteName = "SystemTools";
                        this.currentRouteIndex =
                            this.routes.findIndex((route) => route.routeName === this.currentRouteName);
                    } else {
                        this.currentRouteName = subRoute.parentRouteName;
                        this.currentRouteIndex =
                            this.routes.findIndex((route) => route.routeName === subRoute.parentRouteName);
                    }
                }
            }
        },
        setMini () {
            this.mini = ["EditApplication", "CreateApplication", "CreateCampaignApplication"].includes(this.$router.currentRoute.name) || this.currentRouteName === "SystemTools";
        },
        handleScroll () {
            const elementTarget = this.getItemInsideWindow();
            if (elementTarget) {
                const elementId = elementTarget.$el.attributes.elementid.value;
                this.currentSegmentIndex = this.formSegments.findIndex((segment) => segment.id === elementId.split("form_segment_").pop());
            }
        },

        getItemInsideWindow () {
            let currentItem = null;
            // Must be called with 'call' to prevent bugs on some devices
            // eslint-disable-next-line complexity,max-statements
            [].forEach.call(this.$refs["scrollactive-item"], (item) => {
                if (item.$el.style.display === "none") {
                    return;
                }
                const target = document.getElementById(decodeURI(item.$el.attributes.elementid.value));
                if (!target) {
                    return;
                }
                const distanceFromTop = window.pageYOffset;
                const offset = 250;
                const isScreenPastSection = distanceFromTop >= this.getOffsetTop(target) - offset;
                if (isScreenPastSection) {
                    currentItem = item;
                }
            });
            return currentItem;
        },
        getOffsetTop (element) {
            let yPosition = 0;
            let nextElement = element;

            while (nextElement) {
                yPosition += nextElement.offsetTop;
                nextElement = nextElement.offsetParent;
            }

            return yPosition;
        },
        hasWarnings (segment) {
            return segment.alert.length > 0;
        }
    },
    "computed": {
        formSegments () {
            return this.$store.getters.getFormSegments("applicationForm");
        },
        systemToolbarMenu () {
            let subRoutes = this.subRoutes.filter((subroute) => subroute.parentRouteName === "SystemTools");
            this.permissions.forEach((permission) => {
                if (ability.cannot(permission.action, permission.subject)) {
                    subRoutes = subRoutes.filter((item) => item.routeName !== permission.routeName);
                }
            });
            return subRoutes;
        },
        routes () {
            let routes = routeConfig.routes;

            this.permissions.forEach((permission) => {
                if (ability.cannot(permission.action, permission.subject)) {
                    routes = routes.filter((item) => item.routeName !== permission.routeName);
                }
            });

            if (this.isLoggedIn) {
                return routes.filter((item) => item.routeName !== "Login");
            }
            return routes.filter((item) => item.routeName === "Login");
        },
        isLoggedIn () {
            if (this.$store.state.session.user !== null) {
                return typeof this.$store.state.session.user.id !== "undefined" && window.localStorage.getItem("jwt") !== null;
            }
            return false;
        },
        hasGlobalWarning () {
            return this.$store.state.formSegments.globalWarnings > 0;
        }
    },
    created () {
        this.setCurrentRoute();
        this.setMini();
    },
    "mounted" () {
        window.addEventListener("scroll", this.handleScroll);
    },
    "beforeDestroy" () {
        window.removeEventListener("scroll", this.handleScroll);
    },
    "watch": {
        "$route.name" () {
            this.setCurrentRoute();
            this.currentSegmentIndex = 0;
            this.setMini();
        }
    },
    data () {
        return {
            logo,
            "currentRouteIndex": 0,
            "currentRouteName": null,
            "items": [],
            "currentRoutePath": null,
            "currentSegmentIndex": 0,
            "navigationDrawer": true,
            "formNavigationDrawer": true,
            "systemToolsNavigationDrawer": true,
            "subRoutes": routeConfig.sub_routes,
            "mini": false,
            "abilityRules": ability,
            "permissions": routeConfig.permissions
        };
    }
};
</script>
<style lang="scss">
.navigation--sub.v-list {
    position: absolute;
    transform: translateX(56px);
    width: 200px;
    height: 100vh;
    overflow-y:auto
}

.sidebarSegmentError {
    color: red !important;
}
</style>
