import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";
import listApplicationFilters from "../../predefinedFilters/listApplicationFilters.json";
import lodash from "lodash";

const state = {
    "applications": {
        "tableName": "applications",
        "sortField": "created",
        "sortOrder": "desc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null,
        "selectedFilter": null,
        "predefinedFilters": listApplicationFilters.predefinedFilters,
        "personalFilters": [],
        "campaignFilterTree": null
    },
    "rebates": {
        "tableName": "rebates",
        "sortField": "start_date",
        "sortOrder": "desc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    },
    "site": {
        "tableName": "site",
        "sortField": "code",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    },
    "telesales": {
        "tableName": "telesales",
        "sortField": "name",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    },
    "users": {
        "tableName": "users",
        "sortField": "username",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null,
        "groupTree": null
    },
    "aclGroups": {
        "tableName": "aclGroups",
        "sortField": "name",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null,
        "campaignTree": null,
        "categoryTree": null
    },
    "accountingClerks": {
        "tableName": "accountingClerks",
        "sortField": "sales_organization_siebel",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    },
    "dunningClerks": {
        "tableName": "dunningClerks",
        "sortField": "sales_organization_siebel",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    },
    "exports": {
        "tableName": "exports",
        "sortField": "filename",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    },
    "zfPayers": {
        "tableName": "zfPayers",
        "sortField": "sales_organization_siebel",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    },
    "zlPayers": {
        "tableName": "zlPayers",
        "sortField": "sales_organization_siebel",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    },
    "zrPayers": {
        "tableName": "zrPayers",
        "sortField": "sales_organization_siebel",
        "sortOrder": "asc",
        "offset": 0,
        "currentPage": 1,
        "searchFieldQuery": null
    }
};

const getters = {"getAllFilters": (currentState) => currentState.applications.predefinedFilters.concat(currentState.applications.personalFilters)};

const mutations = {
    SET_APPLICATION_FILTERS (currentState, filters) {
        Object.assign(
            currentState.applications,
            filters
        );
    },
    SET_REBATE_FILTERS (currentState, filters) {
        Object.assign(
            currentState.rebates,
            filters
        );
    },
    "SET_SITE_FILTERS" (currentState, filters) {
        Object.assign(
            currentState.site,
            filters
        );
    },
    SET_TELESALES_FILTERS (currentState, filters) {
        Object.assign(
            currentState.telesales,
            filters
        );
    },
    SET_USERS_FILTERS (currentState, filters) {
        Object.assign(
            currentState.users,
            filters
        );
    },
    SET_ACL_GROUPS_FILTERS (currentState, filters) {
        Object.assign(
            currentState.aclGroups,
            filters
        );
    },
    SET_ZFPAYERS_FILTERS (currentState, filters) {
        Object.assign(
            currentState.zfPayers,
            filters
        );
    },
    SET_ZLPAYERS_FILTERS (currentState, filters) {
        Object.assign(
            currentState.zlPayers,
            filters
        );
    },
    SET_ZRPAYERS_FILTERS (currentState, filters) {
        Object.assign(
            currentState.zrPayers,
            filters
        );
    },
    SET_ACCOUNTING_CLERKS_FILTERS (currentState, filters) {
        Object.assign(
            currentState.accountingClerks,
            filters
        );
    },
    SET_DUNNING_CLERKS_FILTERS (currentState, filters) {
        Object.assign(
            currentState.dunningClerks,
            filters
        );
    },
    SET_EXPORTS_FILTERS (currentState, filters) {
        Object.assign(
            currentState.exports,
            filters
        );
    },
    ADD_PERSONAL_FILTERS (currentState, newFilter) {
        currentState.applications.personalFilters.push(newFilter);
    },
    PUSH_PERSONAL_FILTERS (currentState, filters) {
        Vue.set(currentState.applications, "personalFilters", filters);
    },
    EDIT_PERSONAL_FILTER (currentState, editedFilter) {
        currentState.applications.personalFilters = [
            ...currentState.applications.personalFilters.filter((filter) => filter.id !== editedFilter.id),
            editedFilter
        ];
    },
    SET_CAMPAIGN_TREE (currentState, tree) {
        Vue.set(currentState.aclGroups, "campaignTree", tree);
    },

    SET_CAMPAIGN_FILTER_TREE (currentState, tree) {
        Vue.set(currentState.applications, "campaignFilterTree", tree);
    },
    SET_CATEGORY_TREE (currentState, tree) {
        Vue.set(currentState.aclGroups, "categoryTree", tree);
    },
    SET_USER_GROUP_TREE (currentState, tree) {
        Vue.set(currentState.users, "groupTree", tree);
    },
    DELETE_PERSONAL_FILTER (currentState, currentFilter) {
        const filteredPersonalFilterArray = lodash.filter(
            currentState.applications.personalFilters,
            (filter) => filter.id !== currentFilter.id
        );
        Vue.set(currentState.applications, "personalFilters", filteredPersonalFilterArray);
    }
};

const tableNamesFilters = {
    "applications": "SET_APPLICATION_FILTERS",
    "rebates": "SET_REBATE_FILTERS",
    "site": "SET_SITE_FILTERS",
    "telesales": "SET_TELESALES_FILTERS",
    "users": "SET_USERS_FILTERS",
    "aclGroups": "SET_ACL_GROUPS_FILTERS",
    "zfPayers": "SET_ZFPAYERS_FILTERS",
    "zlPayers": "SET_ZLPAYERS_FILTERS",
    "zrPayers": "SET_ZRPAYERS_FILTERS",
    "accountingClerks": "SET_ACCOUNTING_CLERKS_FILTERS",
    "dunningClerks": "SET_DUNNING_CLERKS_FILTERS",
    "exports": "SET_EXPORTS_FILTERS"
};

const actions = {
    async setFilters ({commit}, filters) {
        if (typeof filters.tableName === "undefined") {
            throw Error("Tablename is not set to setFilters");
        }
        await commit(tableNamesFilters[filters.tableName], filters);
    },
    async addEditPersonalFilter (context, newFilter) {
        const newFilterJson = JSON.stringify(newFilter);
        const encodedNewFilterJson = btoa(newFilterJson);
        const userId = context.rootState.session.user.id;
        if (!newFilter.id) {
            // eslint-disable-next-line max-len
            const mutation = gql`mutation{ create_user_filters (user_id: ${userId}, personal_filters: "${encodedNewFilterJson}"){responseString insertedData { personal_filters id}}}`;
            await apolloClient.mutate({mutation}).then((response) => {
                const result = Object.assign(
                    newFilter,
                    {"id": response.data.create_user_filters.insertedData[0].id}
                );
                context.commit(
                    "ADD_PERSONAL_FILTERS",
                    result
                );
            }).catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
            });
        } else {
            // eslint-disable-next-line max-len
            const mutation = gql`mutation{ update_user_filters (id: ${newFilter.id}, personal_filters: "${encodedNewFilterJson}"){responseString insertedData { personal_filters id}}}`;
            await apolloClient.mutate({mutation}).then(() => {
                context.commit(
                    "EDIT_PERSONAL_FILTER",
                    newFilter
                );
            }).catch((error) => {
            // eslint-disable-next-line no-console
                console.error(error);
            });
        }
    },
    async deletePersonalFilter ({commit}, currentFilter) {
        // eslint-disable-next-line max-len
        const mutation = gql`mutation{ delete_user_filters (id: ${currentFilter.id}){responseString}}`;
        await apolloClient.mutate({mutation}).catch((error) => {
        // eslint-disable-next-line no-console
            console.error(error);
        });
        await commit(
            "DELETE_PERSONAL_FILTER",
            currentFilter
        );
    },
    async setCampaignTree ({commit}, tree) {
        await commit(
            "SET_CAMPAIGN_TREE",
            tree
        );
    },
    async setCampaignFilterTree ({commit}, tree) {
        await commit(
            "SET_CAMPAIGN_FILTER_TREE",
            tree
        );
    },
    async setCategoryTree ({commit}, tree) {
        await commit(
            "SET_CATEGORY_TREE",
            tree
        );
    },
    async setUserGroupTree ({commit}, tree) {
        await commit(
            "SET_USER_GROUP_TREE",
            tree
        );
    },
    async setPersonalFilters ({commit, rootState}) {
        const userId = rootState.session.user.id;
        const query = gql`query{ user_filters (user_id: ${userId}, first: -1){data{id, personal_filters}}}`;
        const personalFilterArray = [];
        await apolloClient.query({query}).then((result) => {
            result.data.user_filters.data.forEach((filter) => {
                const decodedFilters = atob(filter.personal_filters);
                const parsedFilters = JSON.parse(decodedFilters);
                const filtersWithId = Object.assign(
                    parsedFilters,
                    {"id": filter.id}
                );
                personalFilterArray.push(filtersWithId);
            });
            commit(
                "PUSH_PERSONAL_FILTERS",
                personalFilterArray
            );
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
