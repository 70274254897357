import Vue from "vue";
import apolloClient from "@/ApolloClient";
import gql from "graphql-tag";

const state = {
    "solarisBankBusiness": {},
    "idNow": null,
    "idNowURL": null,
    "expirationDate": null
};

const mutations = {
    SET_IDNOW_EXPIRATION (currentState, data) {
        Vue.set(currentState, "idNow", data.idNow);
        Vue.set(currentState, "idNowURL", data.idNowURL);
        Vue.set(currentState, "expirationDate", data.expirationDate);
    },
    SET_SOLARIS_BANK_BUSINESS (currentState, solarisBankBusiness) {
        Vue.set(currentState, "solarisBankBusiness", solarisBankBusiness);
    },
    "CLEAR_OLD_VALUES" (currentState) {
        Vue.set(currentState, "idNow", "");
        Vue.set(currentState, "idNowURL", "");
        Vue.set(currentState, "expirationDate", "");
    }
};

const getters = {
    "getIdNow": (currentState) => () => ({"name": currentState.idNow ? currentState.idNow : "No data available"}),
    "getIdNowURL": (currentState) => () => ({"name": currentState.idNowURL ? currentState.idNowURL : "No data available"}),
    "getExpirationDate": (currentState) => () => ({"name": currentState.expirationDate ? currentState.expirationDate : "No data available"})
};

const actions = {
    async fetchIdNow ({ commit }, formId) {
        const query = gql`query {solaris_bank_info(form_id: ${formId}) {data {expirationDate idNow idNowURL} error}}`;
        const response = await apolloClient.query({
            query
        });
        if (response.data.solaris_bank_info.data) {
            await commit("SET_IDNOW_EXPIRATION", response.data.solaris_bank_info.data);
        }
    },
    async fetchSolarisBankBusiness ({ commit }, formId) {
        const query = gql`query {solaris_bank_business(form_id: ${formId}) {data {id reference screening_progress
            customer_vetting_status risk_classification_status cdd_color}}}`;
        const response = await apolloClient.query({
            query
        });
        if (response.data.solaris_bank_business.data) {
            await commit("SET_SOLARIS_BANK_BUSINESS", response.data.solaris_bank_business.data[0]);
        }
    },
    async clearOldValues ({commit}) {
        await commit("CLEAR_OLD_VALUES");
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
