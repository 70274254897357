<script>

import FilterManagementDialog from "../views/dialogs/FilterManagementDialog";
import axios from "axios";
import getEnv from "@/utils/env";
import {getFieldsFromTableHeaders} from "../../service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "name": "BaseList",
    "components": {FilterManagementDialog},
    "created" () {
        this.filterText = this.filters.searchFieldQuery ?? "";
        this.options.sortBy = [this.filters.sortField];
        this.statusses = this.storeStatusses;
        this.options.sortDesc = [this.filters.sortOrder === "desc"];
    },
    "apollo": {
        "items": {
            query () {
                let searchGQLQuery = "";
                if (this.filters.searchFieldQuery !== null) {
                    searchGQLQuery = ` search: {fieldName:${JSON.stringify(this.searchFields)},
                        searchQuery:"${this.filters.searchFieldQuery}"} `;
                }
                let usePredefinedFilter = "";
                if (this.filters.selectedFilter && this.filters.selectedFilter.filterQuery) {
                    usePredefinedFilter = this.filters.selectedFilter.filterQuery;
                }
                const fields = getFieldsFromTableHeaders(this.tableHeaders);

                return gql`{${this.tableName}: ${this.gqlModel}(
                                first: ${this.itemsPerPage},
                                offset: ${this.filters.offset},
                                sort: { fieldName: "${this.filters.sortField}", sortOrder: "${this.filters.sortOrder}"},
                                ${searchGQLQuery},
                                ${usePredefinedFilter},
                                ) {totalCount, data{id ${fields}}}}`;
            },
            update (data) {
                this.loading = false;
                this.totalCount = data[this.tableName].totalCount;
                this.numberOfPages = Math.ceil(data[this.tableName].totalCount / this.itemsPerPage);
                return data[this.tableName].data;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "computed": {
        filters () {
            return this.$store.state.listFilters[this.tableName];
        },
        storeStatusses () {
            return this.$store.state.statusses;
        },
        telesalesAgencyId () {
            return this.$store.state.telesalesAgencyId.telesalesAgencyId;
        },
        "filtersCurrentPage": {
            "get" () {
                return this.$store.state.listFilters[this.tableName].currentPage;
            },
            "set" (newPageValue) {
                const currentPageValue = this.$store.state.listFilters[this.tableName].currentPage;
                if (newPageValue === currentPageValue) {
                    return;
                }
                this.loading = true;
                this.$store.dispatch(
                    "listFilters/setFilters",
                    {
                        "tableName": this.tableName,
                        "currentPage": newPageValue,
                        "offset": (newPageValue - 1) * this.itemsPerPage
                    }
                );
            }
        }
    },
    "methods": {
        goToEdit (item) {
            this.$router.push({
                "name": this.editURL,
                "params": {"id": item.id}
            });
        },
        setSortData (options) {
            const filterParameters = {
                "tableName": this.tableName,
                "offset": 0,
                "currentPage": 1,
                "sortField": options.sortBy[0],
                "sortOrder": options.sortDesc[0] ? "desc" : "asc"
            };

            // Options differ from current settings in the store, so update the store
            if (this.filters.sortField !== filterParameters.sortField ||
                this.filters.sortOrder !== filterParameters.sortOrder) {
                if (options.sortBy.length > 0) {
                    this.loading = true;
                    this.$store.dispatch(
                        "listFilters/setFilters",
                        filterParameters
                    );
                }
            }
        },
        searchData (searchFieldValue) {
            const newSearchValue = searchFieldValue.target.value;
            const currentSearchedValue = this.$store.state.listFilters[this.tableName].searchFieldQuery;
            if (newSearchValue === currentSearchedValue) {
                return;
            }
            this.loading = true;
            this.$store.dispatch(
                "listFilters/setFilters",
                {
                    "tableName": this.tableName,
                    "searchFieldQuery": newSearchValue,
                    "offset": 0,
                    "currentPage": 1
                }
            );
        },
        downloadS3File (filename) {
            const token = window.localStorage.getItem("jwt");
            const serverUrl = getEnv("VUE_APP_SERVER");
            const safeFilename = filename.replace("&", "and");
            const requestUrl = `${serverUrl}/download?filename=${safeFilename}`;

            axios
                .get(requestUrl, {
                    "headers": {
                        "authorization": `Bearer ${token}`
                    },
                    "responseType": "blob"
                })
                .then((response) => {
                    const blob = new Blob([response.data]);
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();
                });
        }
    },
    data () {
        return {
            "tableName": null,
            "dialog": false,
            "showTextFilter": true,
            "error": null,
            "filterText": "",
            "itemsPerPage": 25,
            "loading": true,
            "numberOfPages": 0,
            "options": {},
            "statusses": [],
            "totalCount": 0
        };
    }
};
</script>
