import Vue from "vue";
import moment from "moment";

export const dateTimeSecondFormat = (value) => moment(value).format("DD-MM-YYYY HH:mm:ss");
export const dateTimeFormat = (value) => moment(value).format("DD-MM-YYYY HH:mm");
export const dateFormat = (value) => value ? moment(value).format("DD-MM-YYYY") : "No date available";

Vue.filter(
    "formatDateTimeSecond",
    dateTimeSecondFormat
);
Vue.filter(
    "formatDateTime",
    dateTimeFormat
);
Vue.filter(
    "formatDate",
    dateFormat
);
