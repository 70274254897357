import Vue from "vue";
const state = {"formTypes": null};

const getters = {"formTypes": (currentState) => currentState.formTypes};

const mutations = {
    SET_FORM_TYPES (currentState, formTypes) {
        Vue.set(currentState, "formTypes", formTypes);
    }
};

const actions = {
    async setFormTypes ({commit}, formTypes) {
        await commit(
            "SET_FORM_TYPES",
            JSON.parse(formTypes)
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
