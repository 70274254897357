<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table :loading="loading"
                      :headers="headersWithActionsFirstRow"
                      :items="filterDeleted(cards)"
                      :disable-pagination="true"
                      :hide-default-footer="true"
                      :expanded.sync="expanded"
                      @item-expanded="rowExpanded"
                      show-expand
                      item-key="id"
                      id="cards_data_table">
          <template v-slot:item.driver_name="{item}">
            <v-edit-dialog
              @open="open(item.driver_name)"
              @close="save({id: item.id, driver_name: currentEdit})"
            > {{item.driver_name|formatNoValue}}
              <template v-slot:input>
                <v-text-field
                  :maxlength="maxInputLengthDriverName"
                  v-model="currentEdit"
                  :value="item.driver_name"
                  placeholder="Driver name"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.card_text="{item}">
            <v-edit-dialog
              @open="open(item.card_text)"
              @close="save({id: item.id, card_text: currentEdit})"
            > {{item.card_text|formatNoValue}}
              <template v-slot:input>
                <v-text-field
                  :maxlength="maxInputLength"
                  v-model="currentEdit"
                  :value="item.card_text"
                  placeholder="Card text"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.purchase_option="{item}">
            <v-select
              class="card-select-style-wide card-select-font-size"
              :value="item.purchase_option"
              @change="save({id: item.id, purchase_option: $event})"
              :items="purchaseOptionsItems"
              :disabled="!canEdit"
            ></v-select>
          </template>
          <template v-slot:item.international="{item}">
            <v-select
              class="card-select-style-narrow card-select-font-size"
              :value="item.international"
              @change="save({id: item.id, international: $event})"
              :items="internationalItems"
              :disabled="!canEdit"
            ></v-select>
          </template>
          <template v-slot:item.pin_type="{item}">
            <v-select
              class="card-select-style-wide card-select-font-size"
              :value="item.pin_type"
              @change="save({id: item.id, pin_type: $event})"
              :items="pincodeOptionsItems"
              :disabled="!canEdit"
            ></v-select>
          </template>
            <template v-slot:item.bp_me_enabled="{item}">
                {{formatBPMeEnabled(item.bp_me_enabled)}}
            </template>
          <template v-slot:item.action="{item}">
            <v-btn class="error--text mx-2"
                   :id="`remove_card_btn${item.id}`"
                   text icon
                   @click="removeCards(item)"
                   :disabled="!canEdit">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ item }">
              <td width="49"></td>
            <td colspan="5" class="pl-0 pr-0">
              <v-data-table :loading="loading"
                            :headers="headersSecondRow"
                            :items="newestExpanded[item.id]"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            item-key="id">

                <template v-slot:item.license_plate>
                  <v-edit-dialog
                    @open="open(item.license_plate)"
                    @close="save({id: item.id, license_plate: currentEdit})"
                  > {{item.license_plate|formatNoValue}}
                    <template v-slot:input>
                      <v-text-field
                        :maxlength="maxInputLength"
                        v-model="currentEdit"
                        :value="item.license_plate"
                        placeholder="License plate"
                        single-line
                        autofocus
                        :disabled="!canEdit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.use_sum_kilometer>
                  <v-select
                    class="card-select-style-narrow card-select-font-size"
                    :value="item.use_sum_kilometer"
                    @change="save({id: item.id, use_sum_kilometer: $event})"
                    :items="kmSumItems"
                    :disabled="!canEdit"
                  ></v-select>
                </template>
                <template v-slot:item.cost_centre>
                  <v-edit-dialog
                    @open="open(item.cost_centre)"
                    @close="save({id: item.id, cost_centre: currentEdit})"
                  > {{item.cost_centre|formatNoValue}}
                    <template v-slot:input>
                      <v-text-field
                        :maxlength="maxInputLength"
                        v-model="currentEdit"
                        :value="item.cost_centre"
                        placeholder="Cost centre"
                        single-line
                        autofocus
                        :disabled="!canEdit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.card_comment1>
                  <v-edit-dialog
                    @open="open(item.card_comment1)"
                    @close="save({id: item.id, card_comment1: currentEdit})"
                  > {{item.card_comment1|formatNoValue}}
                    <template v-slot:input>
                      <v-text-field
                        :maxlength="maxInputLength"
                        v-model="currentEdit"
                        :value="item.card_comment1"
                        placeholder="Card comment 1"
                        single-line
                        autofocus
                        :disabled="!canEdit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.card_comment2>
                  <v-edit-dialog
                    @open="open(item.card_comment2)"
                    @close="save({id: item.id, card_comment2: currentEdit})"
                  > {{item.card_comment2|formatNoValue}}
                    <template v-slot:input>
                      <v-text-field
                        :maxlength="maxInputLength"
                        v-model="currentEdit"
                        :value="item.card_comment2"
                        placeholder="Card comment 2"
                        single-line
                        autofocus
                        :disabled="!canEdit"
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.card_plastic_option>
                    <v-edit-dialog
                        @open="open(item.card_plastic_option)"
                        @close="save({id: item.id, card_plastic_option: currentEdit})"
                    > {{item.card_plastic_option|formatNoValue}}
                        <template v-slot:input>
                            <v-text-field
                                :maxlength="maxInputLength"
                                v-model="currentEdit"
                                :value="item.card_plastic_option"
                                placeholder="Card plastic option"
                                single-line
                                autofocus
                                :disabled="!canEdit"
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>
              </v-data-table>
              <TollBoxRowField
                  :headers="headersThirdRow"
                  :items="[item]"
                  :loading="loading"
                  :max-input-length="maxInputLength"
                  :disabled="!canEdit"
              />
          </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn id="add_card_btn"
             class="text-capitalize mr-5"
             right rounded color="primary"
             @click.stop="addCard"
             :disabled="!canEdit">Add
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import TollBoxRowField from "./TollBoxRowField";
import {getFieldsFromTableHeaders} from "../../../service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "components": {TollBoxRowField},
    "extends": BaseElement,
    "apollo": {
        "getCards": {
            // eslint-disable-next-line consistent-return
            "query" () {
                if (this.formId) {
                    const fields = getFieldsFromTableHeaders(this.tableHeaders);
                    this.loading = true;
                    return gql`
                        query GetCards($formId: Int!) {
                            getCards: fuel_card(form_id: $formId) {
                                data { ${fields}}}}`;
                }
            },
            variables () {
                return {
                    "formId": this.formId
                };
            },
            update (data) {
                this.loading = false;
                this.$store.dispatch(
                    "applicationFormValues/setCards",
                    data?.getCards?.data
                );
            },
            skip () {
                return !this.formId;
            }
        }
    },
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        },
        cards () {
            return this.$store.state.applicationFormValues.cards;
        },
        headersWithActionsFirstRow () {
            const firstRowOfTableHeaders = this.tableHeaders.slice(
                0,
                7
            );
            const newHeaders = JSON.parse(JSON.stringify(firstRowOfTableHeaders));
            newHeaders.shift();
            newHeaders.push({
                "value": "action",
                "text": "",
                "sortable": false,
                "width": "52px"
            });

            return newHeaders;
        },
        headersSecondRow () {
            const secondRowOfTableHeaders = this.tableHeaders.slice(
                7,
                13
            );
            return JSON.parse(JSON.stringify(secondRowOfTableHeaders));
        },
        headersThirdRow () {
            const thirdRowOfTableHeaders = this.tableHeaders.slice(
                13,
                15
            );
            return JSON.parse(JSON.stringify(thirdRowOfTableHeaders));
        }
    },
    "methods": {
        filterDeleted (cards) {
            return cards.filter((card) => !card.deleted);
        },
        rowExpanded (value) {
            if (value.value) {
                this.newestExpanded[value.item.id] = [this.$store.getters["applicationFormValues/filteredCard"](value.item.id)];
            }
        },
        addCard () {
            // If "Pincode option" (fuel_cards_pin_options) is set to wish pin and a new card is added, change to generated pin
            if (this.$store.state.applicationFormValues.values.fuel_cards_pin_options === "1") {
                this.$store.dispatch(
                    "applicationFormValues/updateFormValue",
                    {
                        "name": "fuel_cards_pin_options",
                        "value": "0"
                    }
                );
            }
            this.$store.dispatch("applicationFormValues/addCards");
        },
        removeCards (item) {
            this.$store.dispatch(
                "applicationFormValues/deleteCards",
                item.id
            );
        },
        open (value) {
            this.currentEdit = value;
        },
        save (item) {
            this.$store.dispatch(
                "applicationFormValues/updateCards",
                item
            );
            this.currentEdit = null;
        },
        formatBPMeEnabled (text) {
            switch (text) {
            case 1:
                return "Yes";
            case 0:
                return "No";
            default:
                return "N/A";
            }
        }
    },
    data () {
        return {
            "expanded": [],
            "newestExpanded": {},
            "dialog": false,
            "error": null,
            "counter": 1,
            "currentEdit": null,
            "filterText": "",
            "loading": false,
            "tableHeaders": this.field.tableHeaders,
            "showDropdown": {},
            "maxInputLength": 30,
            "maxInputLengthDriverName": 14,
            "purchaseOptionsItems": [
                {"text": "Diesel and adblue", "value": "60"},
                {"text": "All fuels", "value": "61"},
                {"text": "All fuels and lubricants", "value": "62"},
                {"text": "All fuels and lubricants and carwash", "value": "63"},
                {"text": "Fuel, lubricants, carwash and services for the vehicle", "value": "64"},
                {"text": "All products", "value": "65"}
            ],
            "pincodeOptionsItems": [
                {"text": "Pincode", "value": "1"},
                {"text": "Pincode and odometer", "value": "2"},
                {"text": "Pincode, odometer and vehicle number", "value": "3"},
                {"text": "Pincode and vehicle number", "value": "4"}
            ],
            "kmSumItems": [
                {"text": "No", "value": "no"},
                {"text": "Yes", "value": "yes"}
            ],
            "internationalItems": [
                {"text": "No", "value": "no"},
                {"text": "Yes", "value": "yes"}
            ]
        };
    }
};
</script>
<style>
    .card-select-style-narrow {
        width: 70px;
    }
    .card-select-style-wide {
       width: 180px;
    }
    .card-select-font-size {
       font-size: 14px;
    }
</style>
