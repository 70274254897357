import "vuetify/dist/vuetify.min.css";

import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
    "theme": {
        "themes": {
            "light": {
                "primary": "#007F00",
                "secondary": "#99CC00",
                "accent": "#99FF00",
                "error": "#EB0000",
                "softWarning": "#FF8100",
                "info": "#2196F3",
                "success": "#4CAF50",
                "warning": "#FFE600",
                "grey": "#F7F7F7",
                "white": "#ffffff"
            }
        }
    }
});
