<template>
  <v-container fluid v-if="$can('create', 'applications')">
    <HeaderBar
        class="pt-3"
        :breadcrumbs="breadcrumbs"
        title="New application"
    >
      <template v-slot:button>
        <v-btn
            id="submit-application-button"
            class="text-capitalize btn-block"
            rounded color="primary"
            @click="submitApplicationForm()">
          Save Application
        </v-btn>
      </template>
    </HeaderBar>
    <BaseForm
        class="mt-5"
        store-module="applicationFormValues"
        :form-segments=formSegments
        :editable="true"
    ></BaseForm>
  </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import {extractFees} from "@/service/convertTemplate.services";
import gql from "graphql-tag";

export default {
    "name": "NewApplication",
    "components": {
        BaseForm,
        HeaderBar
    },
    "mounted" () {
        this.$store.dispatch("applicationFormValues/clearOldValues");
        this.$store.dispatch("opal/clearOldValues");
        this.$store.dispatch("sap/clearOldValues");
        this.$store.dispatch("solarisBank/clearOldValues");
        this.$store.dispatch("applicationFormValues/clearFees");
        this.$store.dispatch("fleetField/clearFleetField");
        this.$store.dispatch("auditLogs/clearAuditLogs");
        this.$store.dispatch("onefleet/clearOldValues");
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Applications",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListApplication"}
                },
                {
                    "text": "New application",
                    "disabled": true
                }
            ],
            "templateValuesResponse": null,
            "templateValues": {},
            "categoryId": null
        };
    },
    "methods": {
        submitApplicationForm () {
            this.$root.$emit("submitBaseForm");
        },
        setTemplateValues () {
            // eslint-disable-next-line camelcase
            this.templateValues.application_template_id = this.$route.params.template_id;
            const category = this.$store.getters["categories/getFormCategory"](this.categoryId);
            if (typeof category !== "undefined") {
                const countryName = this.$store.getters["countries/getCountry"](category.country_id).iso_country_code;
                this.templateValuesResponse = {
                    "template_values": this.templateValues,
                    "category": this.categoryId,
                    "country": countryName
                };
                if ("clearFees" in this.templateValues) {
                    this.$store.dispatch("applicationFormValues/clearFees");
                }
                if (this.$store.state.formTypes.formTypes) {
                    this.$store.dispatch("applicationFormValues/setNewFormValues", this.templateValuesResponse);
                }
                const mappedFees = extractFees(this.templateValues);
                if (mappedFees) {
                    this.$store.dispatch("applicationFormValues/editFees", mappedFees);
                }
            }
        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.applicationFormSegments;
        },
        applicationStatus () {
            return this.$store.state.applicationFormValues.values.status;
        },
        formTypes () {
            return this.$store.state.formTypes.formTypes;
        }
    },
    "watch": {
        "formTypes" () {
            if (this.templateValuesResponse) {
                this.$store.dispatch("applicationFormValues/setNewFormValues", this.templateValuesResponse);
            }
        },
        "formValues" () {
            return this.$store.state.applicationFormValues.values;
        }
    },
    "apollo": {
        "templateFormValues": {
            query () {
                this.$store.dispatch("applicationFormValues/setNewFormValues", {});
                // eslint-disable-next-line max-len
                return gql`query {templateFormValues: application_template (id: ${this.$route.params.template_id}) {data{category_id template_values}}}`;
            },
            update (data) {
                this.categoryId = data.templateFormValues.data[0].category_id;
                // eslint-disable-next-line no-control-regex,require-unicode-regexp
                const content = data.templateFormValues.data[0].template_values.replace(/[^\x00-\x7F]/g, "");
                this.templateValues = JSON.parse(content);

                this.$apollo.queries.defaultValues.skip = false;
            },
            "fetchPolicy": "no-cache"
        },
        "defaultValues": {
            query () {
                return gql`query {default_values_for_category (category_id:${this.categoryId})
                                    {defaultValues responseString}}`;
            },
            update (data) {
                const response = data.default_values_for_category;
                if (response.responseString) {
                    this.$toast(response.responseString, {"color": "error"});
                } else {
                    this.$store.dispatch("applicationFormValues/setDefaultValues", response.defaultValues.Form[0].properties);
                    response.defaultValues.Fee.forEach((fee) => this.$store.dispatch("applicationFormValues/addFees", fee.properties));
                    if (response.defaultValues.FleetField) {
                        response.defaultValues.FleetField.forEach((fleetField) => this.$store.dispatch("fleetField/addFleetfield", fleetField.properties));
                    }
                    if (response.defaultValues.SalesforceFormFields &&
                        response.defaultValues.SalesforceFormFields[0].properties) {
                        this.$store.dispatch(
                            "salesforceFields/setSalesforceFields",
                            response.defaultValues.SalesforceFormFields[0].properties
                        );
                    }
                    this.$store.dispatch("applicationFormValues/fetchPhoneNumberCountryCodes");
                    this.setTemplateValues();
                }
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "created" () {
    // Clear any dnb search params still in the store
        this.$store.dispatch(
            "applicationFormValues/setDnbSearchParams",
            {
                "formValues":
              {
                  "data":
                    [{}]
              }
            }
        );
    }
};
</script>
