var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"id":"form_address_list","disable-pagination":true,"headers":_vm.headersWithActions,"hide-default-footer":true,"items":_vm.formAddresses,"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.address_type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"form-address-select-style-wide",attrs:{"value":item.address_type,"items":_vm.addressTypeOptions,"disabled":!_vm.canEdit},on:{"change":function($event){return _vm.save({id: item.id, address_type: $event})}}})]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"close":function($event){return _vm.save({id: item.id, address: _vm.activeEdit})},"open":function($event){return _vm.open(item.address)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"disabled":!_vm.canEdit,"value":item.address,"autofocus":"","placeholder":"Address","single-line":""},model:{value:(_vm.activeEdit),callback:function ($$v) {_vm.activeEdit=$$v},expression:"activeEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.address))+" ")])]}},{key:"item.zipcode",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"close":function($event){return _vm.save({id: item.id, zipcode: _vm.activeEdit})},"open":function($event){return _vm.open(item.zipcode)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"disabled":!_vm.canEdit,"value":item.zipcode,"autofocus":"","placeholder":"Zipcode","single-line":""},model:{value:(_vm.activeEdit),callback:function ($$v) {_vm.activeEdit=$$v},expression:"activeEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.zipcode))+" ")])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"close":function($event){return _vm.save({id: item.id, city: _vm.activeEdit})},"open":function($event){return _vm.open(item.city)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"disabled":!_vm.canEdit,"value":item.city,"autofocus":"","placeholder":"City","single-line":""},model:{value:(_vm.activeEdit),callback:function ($$v) {_vm.activeEdit=$$v},expression:"activeEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.city))+" ")])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"form-address-select-style",attrs:{"value":item.country,"items":_vm.countries,"disabled":!_vm.canEdit},on:{"change":function($event){return _vm.save({id: item.id, country: $event})}}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"error--text mx-2",attrs:{"id":("remove_form_address_btn" + (item.id)),"disabled":!_vm.canEdit,"icon":"","text":""},on:{"click":function($event){return _vm.removeFormAddress(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"text-capitalize mr-5",attrs:{"id":"add_form_address_btn","disabled":!_vm.canEdit,"color":"primary","right":"","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.addFormAddress.apply(null, arguments)}}},[_vm._v("Add ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }