<template>
  <v-container>
    <BaseSearchModal :config="this.field.config"
                     placeholder="Site name, Site code or City"
      v-on:newItemSelected="setNewSite">
      Lookup Site
      <template v-slot:dialogHeader>
        <h3>Fuel Site Lookup</h3>
      </template>
    </BaseSearchModal>
  </v-container>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import BaseSearchModal from "../../baseComponents/BaseSearchModal";

export default {
    "extends": BaseElement,
    "components": {BaseSearchModal},
    "methods": {
        setNewSite (data) {
            this.$store.dispatch(
                "rebateFormValues/setSiteIdValue",
                data.id
            );
        }
    }
};

</script>
