<template>
    <v-dialog
        :value="newApplicationDialog"
        max-width="400"
        :persistent="true"
        v-if="$can('create', 'applications')">
        <v-card id="new-application-dialog" color="primary">
            <v-card-title class="white--text">New Application</v-card-title>
            <v-card-text>
                <v-select
                        id="new-application-select-country"
                        v-model="selectedCountry"
                        dark
                        :items=linkedCountries
                        item-text="name"
                        item-value="id"
                        label="Choose country">
                </v-select>
                <v-checkbox dark v-model="campaignBased" label="Campaign based" />
                <v-select
                        v-if="!campaignBased"
                        id="new-application-select-template"
                        v-model="selectedTemplate"
                        dark
                        :items=templatesForCountry
                        item-text="name"
                        item-value="id"
                        :label=templateSelectLabel
                        :disabled=disabledTemplateSelect>
                </v-select>
                <v-select
                    v-if="campaignBased"
                    id="new-application-select-campaign"
                    v-model="selectedCampaign"
                    dark
                    :items=campaignsForCountry
                    item-text="name"
                    item-value="id"
                    :label=campaignSelectLabel
                    :disabled=disabledCampaignSelect>
                </v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id="new-application-cancel-button"
                       class="text-capitalize" color="white" text @click.stop="closeDialog">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn id="new-application-create-button"
                       class="text-capitalize" color="white" text @click.stop="createApplication"
                       :disabled="disabledCreateButton">Create
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    "props": {"newApplicationDialog": {"default": false}},
    "methods": {
        closeDialog () {
            this.$emit(
                "update:newApplicationDialog",
                false
            );
        },
        createApplication () {
            if (this.campaignBased) {
                this.$router.push({
                    "name": "CreateCampaignApplication",
                    "params": {"campaign_id": this.selectedCampaign}
                });
            } else {
                this.$router.push({
                    "name": "CreateApplication",
                    "params": {"template_id": this.selectedTemplate}
                });
            }
        },
        setTemplateOptions (templates) {
            this.selectedTemplate = null;
            if (templates.length === 0) {
                this.templateSelectLabel = "No template available";
            } else {
                this.templateSelectLabel = "Choose template";
            }
            this.disabledTemplateSelect = templates.length === 0;
        },
        setCampaignOptions (campaigns) {
            this.selectedCampaign = null;
            if (campaigns.length === 0) {
                this.campaignSelectLabel = "No campaigns available";
            } else {
                this.campaignSelectLabel = "Choose campaign";
            }
            this.disabledCampaignSelect = campaigns.length === 0;
        }
    },
    "data" () {
        return {
            "disabledTemplateSelect": true,
            "disabledCampaignSelect": true,
            "disabledCreateButton": true,
            "selectedCountry": null,
            "campaignBased": null,
            "selectedTemplate": null,
            "selectedCampaign": null,
            "templateSelectLabel": "Choose template",
            "campaignSelectLabel": "Choose campaign",
            "categoryIds": {}
        };
    },
    "computed": {
        "user" () {
            return this.$store.state.session.user;
        },
        "countries" () {
            return this.$store.state.countries.countries;
        },
        "categories" () {
            return this.$store.state.categories.categories;
        },
        "templates" () {
            return this.$store.state.templates.templates;
        },
        "linkedCountries" () {

            /**
             * Retrieve the necessary category objects to see what country they are linked to
             * and filter countries based on that information
             */
            return this.$store.getters["countries/getLinkedCountries"];
        },
        "templatesForCountry" () {
            const templates = this.$store.getters["templates/templateForCountry"](this.selectedCountry);
            this.setTemplateOptions(templates);
            return templates;
        },
        "campaignsForCountry" () {
            const campaigns = this.$store.getters["campaigns/campaignsForCountry"](this.selectedCountry);
            this.setCampaignOptions(campaigns);
            return campaigns;
        }
    },
    "watch": {
        "selectedTemplate" () {
            this.disabledCreateButton = !this.selectedTemplate;
        },
        "selectedCampaign" () {
            this.disabledCreateButton = !this.selectedCampaign;
        }
    }
};
</script>
