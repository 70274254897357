import Vue from "vue";
const state = {"telesalesAgencies": null};

const getters = {"getTelesalesAgencies": (currentState) => currentState.telesalesAgencies};

const mutations = {
    SET_TELESALES_AGENCIES (currentState, telesalesAgencies) {
        Vue.set(currentState, "telesalesAgencies", telesalesAgencies);
    }
};

const actions = {
    async setTelesalesAgencies ({commit}, telesalesAgencies) {
        const formattedTeleSalesAgencies = telesalesAgencies.map((item) => ({
            "value": item.id,
            "text": item.name
        }));
        await commit(
            "SET_TELESALES_AGENCIES",
            formattedTeleSalesAgencies
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
