var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","loading":_vm.loading,"headers":_vm.headersWithActions,"items":_vm.filterDeleted(_vm.shareHolders),"disable-pagination":true,"hide-default-footer":true,"item-key":"id","id":"shareholders_data_table"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"open":function($event){return _vm.open(item.name)},"close":function($event){return _vm.save({id: item.id, name: _vm.currentEdit})}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"maxlength":_vm.maxInputLength,"value":item.name,"placeholder":"Name","single-line":"","autofocus":"","disabled":!_vm.canEdit},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.name))+" ")])]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({on:{"open":function($event){return _vm.open(item.birthday)}}},on),[_vm._v(_vm._s(_vm._f("formatDate")(item.birthday)))])]}}],null,true),model:{value:(_vm.showDatePicker[item.id]),callback:function ($$v) {_vm.$set(_vm.showDatePicker, item.id, $$v)},expression:"showDatePicker[item.id]"}},[(_vm.showDatePicker[item.id])?_c('v-date-picker',{attrs:{"value":_vm.formatDatePickerValue(item.birthday),"color":"primary","disabled":!_vm.canEdit},on:{"click:date":function($event){return _vm.saveAndCloseDate($event, item.id)},"open":function($event){return _vm.open(item.birthday)}},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}}):_vm._e()],1)]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"open":function($event){return _vm.open(item.address)},"close":function($event){return _vm.save({id: item.id, address: _vm.currentEdit})}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"maxlength":_vm.maxInputLength,"value":item.address,"placeholder":"Address","single-line":"","autofocus":"","disabled":!_vm.canEdit},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.address))+" ")])]}},{key:"item.zipcode",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"open":function($event){return _vm.open(item.zipcode)},"close":function($event){return _vm.save({id: item.id, zipcode: _vm.currentEdit})}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"maxlength":_vm.maxInputLength,"value":item.zipcode,"placeholder":"Zipcode","single-line":"","autofocus":"","disabled":!_vm.canEdit},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.zipcode))+" ")])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"open":function($event){return _vm.open(item.city)},"close":function($event){return _vm.save({id: item.id, city: _vm.currentEdit})}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"maxlength":_vm.maxInputLength,"value":item.city,"placeholder":"City","single-line":"","autofocus":"","disabled":!_vm.canEdit},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.city))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"error--text mx-2",attrs:{"id":("remove_share_holder_btn" + (item.id)),"text":"","icon":"","disabled":!_vm.canEdit},on:{"click":function($event){return _vm.removeShareholder(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"text-capitalize mr-5",attrs:{"id":"add_share_holder_btn","disabled":(_vm.shareHolders && _vm.shareHolders.length >= 6) || !_vm.canEdit,"right":"","rounded":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.addShareholder.apply(null, arguments)}}},[_vm._v("Add ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }