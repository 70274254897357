var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.localTasks,"disable-pagination":true,"hide-default-footer":true,"item-key":"id","id":"tasks_data_table"},scopedSlots:_vm._u([{key:"item.required",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"20","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({attrs:{"input-value":item.required,"disabled":_vm.isTaskEnabled(item.label, 'required'),"off-icon":"mdi-checkbox-blank-off-outline"},on:{"change":function($event){return _vm.updateRequiredValue(item)}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getToolTipRequired(item)))])])]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"20","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-checkbox',{attrs:{"input-value":item.status,"disabled":true,"off-icon":"mdi-checkbox-blank-off-outline"}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getToolTipStatus(item)))])])]}},{key:"item.completedOverride",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","nudge-left":"20","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({attrs:{"indeterminate":item.statusOverride === null,"indeterminate-icon":"mdi-checkbox-blank-outline","input-value":item.statusOverride,"disabled":_vm.isTaskEnabled(item.label, 'override'),"off-icon":"mdi-checkbox-blank-off-outline","on-icon":"mdi-checkbox-marked"},on:{"change":function($event){return _vm.switchValue(item)}}},on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getToolTipStatusOverride(item)))])])]}},{key:"item.screeningStatus",fn:function(ref){
var item = ref.item;
return [(_vm.getScreeningType(item) !== 'isNotScreening')?_c('div',[(_vm.getScreeningType(item) === 'isCompanyScreening')?_c('div',[_c('v-icon',{staticClass:"screening-icon-font-size",class:_vm.getCompanyColor(),on:{"click":function($event){$event.stopPropagation();return _vm.verifiedScreeningStatusResponse()}}},[_vm._v(" mdi-information ")])],1):(_vm.getScreeningType(item) === 'isPersonScreening')?_c('div',[_c('v-icon',{staticClass:"screening-icon-font-size",class:_vm.getOverallPersonsColor(),on:{"click":function($event){$event.stopPropagation();return _vm.verifiedScreeningStatusResponse()}}},[_vm._v(" mdi-information ")])],1):_vm._e()]):_vm._e()]}},{key:"item.label",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.label)+" ")]}}])})],1)],1),_c('KYCScreeningStatusDialog',{attrs:{"dialog":_vm.dialog,"screeningHits":_vm.screeningHits()},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }