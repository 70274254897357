<template>
    <div v-if="showButton" class="container pl-0">
        <v-btn :id="`mdg-${field.dialog}-button`"
               rounded color="primary"
               class="text-capitalize"
               @click.stop="openMdgDialog"
        >
            {{ field.label }}
        </v-btn>

        <MdgValidateDialog v-if="isValidateDialog" :dialog="isValidateDialogOpen"/>
        <MdgSearchDialog v-else :dialog="isSearchDialogOpen"/>
    </div>
</template>

<script>
import MdgSearchDialog from "@/components/views/dialogs/MdgSearchDialog";
import MdgValidateDialog from "@/components/views/dialogs/MdgValidateDialog";

export default {
    "props": ["field"],
    "components": {
        MdgSearchDialog,
        MdgValidateDialog
    },
    "methods": {
        openMdgDialog () {
            this.$store.dispatch("mdg/openDialog", {"open": true, "dialog": this.field.dialog});
        }
    },
    "computed": {
        isSearchDialogOpen () {
            return this.$store.state.mdg.mdgSearchDialogOpen;
        },
        isValidateDialogOpen () {
            return this.$store.state.mdg.mdgValidateDialogOpen;
        },
        isValidateDialog () {
            return this.field.dialog === "validate";
        },
        showButton () {
            const mdmNonValidateStatuses = [6, 7, 8, 9];
            return !(this.isValidateDialog &&
                !mdmNonValidateStatuses.includes(this.$store.state.applicationFormValues.values.mdm_search_status));
        }
    }
};

</script>
