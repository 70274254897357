import moment from "moment";

/**
 * @param {Object} filter filter
 * @returns {object} filter
 */
function getStatusFilter (filter) {
    let statusFilter = [];
    if (filter.status && filter.status.length > 0) {
        statusFilter = {"status": {"type": "list", "value": filter.status}};
    }
    return statusFilter;
}

function getSlaFilter (filter) {
    const slaFilters = [];
    let slaFilter = {};
    if (Array.isArray(filter.sla_warning) && filter.sla_warning.length > 0) {
        slaFilters.push({"sla_warning": {"value": true}});
    }
    if (Array.isArray(filter.sla_escalation) && filter.sla_escalation.length > 0) {
        slaFilters.push({"sla_escalation": {"value": true}});
    }
    if (slaFilters.length > 0) {
        slaFilter = {"or": slaFilters};
    }
    return slaFilter;
}

function getSalesRepFilter (filter) {
    const SalesRepList = [];
    let filterString = "";
    if (filter.salesRep) {
        filter.salesRep.forEach((lastSalesRep) => {
            SalesRepList.push(lastSalesRep);
        });
        filterString = {"sales_rep": {"type": "list", "value": SalesRepList}};
    }
    return filterString;
}

function createIdFilter (treeIds, fieldName, filterName) {
    const filter = treeIds.filter((treeId) => treeId.startsWith(
        fieldName,
        0
    )).map((value) => parseInt(
        value.substring(
            fieldName.length,
            value.length
        ),
        10
    ));
    return filter.length > 0 ? {
        [`${filterName}`]: {
            "type": "list",
            "value": filter
        }
    } : null;
}

/**
 * @param {Object} filter filter
 * @returns {object} filter
 */

function getTreeIDFilter (filter) {
    const treeFilter = [];
    if (filter.treeIds) {
        [
            {
                "fieldName": "category",
                "filterName": "category_id"
            }, {
                "fieldName": "campaign",
                "filterName": "campaign"
            }
        ].forEach(({fieldName, filterName}) => {
            const idFilter = createIdFilter(filter.treeIds, fieldName, filterName);
            if (idFilter) {
                treeFilter.push(idFilter);
            }
        });
    }
    return treeFilter.length > 0 ? {"or": treeFilter} : {};
}


/**
 * @param {Object} filter filter
 * @returns {object} filter
 */
function getLastEditedFilter (filter) {
    const editedFilter = [];
    let editedFilters = "";
    if (filter.lastEditedBy) {
        filter.lastEditedBy.forEach((lastEditor) => {
            editedFilter.push(lastEditor);
        });
        editedFilters = {"last_edited_by": {"type": "list", "value": editedFilter}};
    }
    return editedFilters;
}

function orderDate (dates) {
    const orderedDate = dates.sort((dateA, dateB) => moment(dateA).format("YYYYMMDD") - moment(dateB).format("YYYYMMDD"));
    orderedDate[1] = moment(dates[1]).add({"hours": 23, "minutes": 59, "seconds": 59}).format("YYYY-MM-DD HH:mm:ss");
    return orderedDate;
}

function createDateFilter (dates, field) {
    const dateFilters = [];
    let dateFilter = {};
    if (dates && dates.length === 2) {
        const createdOrder = orderDate(dates);
        dateFilters.push({[`${field}`]: {"type": "greaterThanOrEqual", "value": createdOrder[0]}});
        dateFilters.push({[`${field}`]: {"type": "lessThanOrEqual", "value": createdOrder[1]}});
        dateFilter = {"and": dateFilters};
    }
    return dateFilter;
}

/**
 * @param {Object} filter filter
 * @returns {object} filter
 */
function getCreatedFilter (filter) {
    return createDateFilter(filter.created, "created");
}

/**
 * @param {Object} filter filter
 * @returns {object} filter
 */
function getModifiedFilter (filter) {
    return createDateFilter(filter.modified, "modified");
}

function createQueryLine (parameters) {
    let query = JSON.stringify(parameters.filter((value) => JSON.stringify(value).length > 2), null, 2);

    query = query.replace(/"(list|greaterThanOrEqual|lessThanOrEqual)"/ugm, "$1");
    query = query.replace(/^[\t ]*"[^:\n\r]+(?<!\\)":/ugm, (match) => match.replace(/"/ug, ""));
    return `filters: ${query}`;
}

/**
 * Create GraphQL-query for filter
 *
 * @param {array} selectedFilter filter to create query
 * @returns {string} query
 */
export function buildFilterQuery (selectedFilter) {
    let query = "";
    if (selectedFilter) {
        const parameters = [];
        const filter = JSON.parse(JSON.stringify(selectedFilter));
        parameters.push(getStatusFilter(filter));
        parameters.push(getTreeIDFilter(filter));
        parameters.push(getLastEditedFilter(filter));

        parameters.push(getCreatedFilter(filter));
        parameters.push(getModifiedFilter(filter));

        parameters.push(getSlaFilter(filter));

        parameters.push(getSalesRepFilter(filter));

        query = createQueryLine(parameters);
    }
    return query;
}
