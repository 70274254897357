<template>
  <v-container fluid>
    <HeaderBar
      class="pt-12"
      :breadcrumbs="breadcrumbs"
      title="System Tools"
      data-nw="systemtools"
    >
    </HeaderBar>
  </v-container>
</template>
<script>
import HeaderBar from "../../uiElements/HeaderBar";
import routeConfig from "../../../routeConfig";

export default {
    "name": "SystemTool",
    "components": {HeaderBar},
    created () {
        const [firstSubRoute] = routeConfig.sub_routes.filter((route) => route.parentRouteName === "SystemTools");
        this.$router.push(`${firstSubRoute.parentRouteName}/${firstSubRoute.routeName}`);
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                }
            ]
        };
    }
};
</script>
