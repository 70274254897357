export const IdNowStatus = {
    "CREATED": "created",
    "PENDING": "pending",
    "PENDING_SUCCESSFUL": "pending_successful",
    "PENDING_FAILED": "pending_failed",
    "SUCCESSFUL": "successful",
    "ABORTED": "aborted",
    "CANCELED": "canceled",
    "FAILED": "failed"
};

export function getStatusColor (status) {
    switch (status) {
    case IdNowStatus.CREATED:
        return "#3498db";
    case IdNowStatus.PENDING:
        return "#f1c40f";
    case IdNowStatus.SUCCESSFUL:
    case IdNowStatus.PENDING_SUCCESSFUL:
        return "#007F00";
    case IdNowStatus.FAILED:
    case IdNowStatus.PENDING_FAILED:
        return "#e74c3c";
    case IdNowStatus.CANCELED:
    case IdNowStatus.ABORTED:
        return "#687373";
    default:
        return null;
    }
}
