<template>
  <v-text-field
    v-bind="field"
    @input="updateValue($event)"
    :value="formValue"
    :name="this.name"
    :rules="setRules(field.rules)"
    :label="field.label"
    :persistent-hint="true"
    :hint="getHint()"
    :maxlength="field.maxlength"
    :ref="this.name"
    :data-nw="this.name"
    :type="this.type"
    :readonly="field.permissioned ? !$can(field.ability.action, field.ability.object) : false"
    :disabled="unableToEdit()"
    ></v-text-field>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";

export default {
    "extends": BaseElement,
    "methods": {
        unableToEdit () {
            if (this.field.createOnly && this.pageType !== "create") {
                return true;
            }
            return !this.canEdit;
        }
    }
};


</script>
