<template>
    <div v-if="isSolarisEnabled" class="d-flex">
        <div class="mr-2">
            <SendToSolarisButton />
        </div>
        <div v-if="hasIdNowValue && $can('read', 'applications')">
            <SolarisEventLogDialog v-model="auditLogDialog" />
            <v-btn
                data-nw="event-log-button"
                @click.stop="auditLogDialog = true"
                class="text-capitalize"
                rounded
                color="primary"
            >
                Solaris event log
            </v-btn>
        </div>
    </div>
</template>

<script>
import SendToSolarisButton from "@/components/form/elements/SendToSolarisButton.vue";
import SolarisEventLogDialog from "@/components/views/dialogs/SolarisEventLogDialog.vue";

export default {
    "components": { SendToSolarisButton, SolarisEventLogDialog },
    "data": () => ({
        "auditLogDialog": false
    }),
    "computed": {
        isSolarisEnabled () {
            return this.$store.state.applicationFormValues.values.is_solarisbank_enabled &&
                this.$store.state.solarisBank.solarisBankBusiness;
        },
        hasIdNowValue () {
            return this.$store.getters["solarisBank/getIdNow"]();
        }
    }
};
</script>
