<template>
    <v-container class="pt-5 mt-5" fluid>
        <v-col>
            <v-card>
                <v-card-title>Login</v-card-title>
                <v-card-text v-if="message" :class="this.messageClass">
                    <div class="read-only-label-size text--white error-message pt-10" v-if="message">
                        <label class="technical-error-message error--text--white">Response from server:</label>
                        <p class="error--text--white">{{message}}</p>
                    </div>
                </v-card-text>
                <v-card-text>
                    <v-row align="center" v-if="formAuth">
                        <v-col lg="4">
                            <v-text-field
                                label="Email"
                                type="email"
                                v-model="form.emailaddress"
                            />
                        </v-col>
                        <v-col lg="4">
                            <v-text-field
                                label="password"
                                type="password"
                                v-model="form.password"
                            />
                        </v-col>
                        <v-col lg="4">
                            <v-btn
                                rounded
                                color="primary"
                                @click="login"
                            >
                                Login
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-btn
                                rounded
                                color="primary"
                                @click="ssoRestCall"
                        >
                            Login with SSO
                        </v-btn>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-container>
</template>

<script>
import axios from "axios";
import getEnv from "../../utils/env";
import gql from "graphql-tag";

export default {
    "name": "Login",
    data () {
        return {
            "form": {},
            "message": "",
            "messageClass": "",
            "formAuth": false
        };
    },
    mounted () {
        this.message = this.$route.query.msg;
        if (typeof this.message !== "undefined") {
            if (this.message === "logout") {
                window.localStorage.removeItem("loggedOut");
                this.message = "You have been logged out successfully";
                this.messageClass = "success";
            } else {
                this.messageClass = "error";
            }
        }

        const login = getEnv("VUE_APP_LOGIN");
        if (login === "formAuth") {
            this.formAuth = true;
        } else if (typeof this.message === "undefined") {
            this.ssoRestCall();
        }
    },
    "methods": {
        async login () {
            // eslint-disable-next-line max-len
            const mutation = gql`mutation login($email: String, $password: String) {loginMutation (emailaddress: $email password: $password) {responseString jwt emailaddress first_name role last_name id}}`;
            await this.$apollo.mutate({mutation, "variables": {"email": this.form.emailaddress, "password": this.form.password}})
                .then((data) => {
                    if (data.data.loginMutation.responseString !== "Successful Login" || typeof data.data.loginMutation.jwt === "undefined") {
                        this.$toast(
                            "Warning: Incorrect login",
                            {
                                "color": "softWarning",
                                "timeout": 1000
                            }
                        );
                    } else {
                        const user = data.data.loginMutation;
                        this.$store.dispatch("session/buildSession", user).then(() => this.$router.push("/"));
                    }
                }).catch(() => {
                    this.$toast(
                        "Something went wrong with logging in.",
                        {
                            "color": "error",
                            "timeout": 10000
                        }
                    );
                });
        },
        async ssoRestCall () {
            const requestUrl = getEnv("VUE_APP_SAML_REDIRECT");
            await axios
                .get(requestUrl)
                .then((response) => {
                    window.location.href = response.data.responseUrl;
                });
        }
    }
};
</script>

<style scoped>
.error--text--white{
    color:white;
}
</style>
