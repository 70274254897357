<template>
    <div>
        <v-row>
            <v-col>
                <v-data-table
                    :loading="loading"
                    :headers="headersWithActions"
                    :items="filterDeleted(paymentMechanisms)"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    show-expand
                    item-key="id"
                    id="payment_mechanisms_data_table">
                    <template v-slot:item.payment_mechanism_id="{item}">
                        {{ item.payment_mechanism_id|formatNoValue }}
                    </template>

                    <template v-slot:item.card_holder="{item}">
                        <v-edit-dialog
                            @open="open(item.card_holder)"
                            @close="save({id: item.id, card_holder: currentEdit})"
                        > {{ item.card_holder|formatNoValue }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="currentEdit"
                                    :value="item.card_holder"
                                    placeholder="Card holder..."
                                    single-line
                                    type="text"
                                    autofocus
                                    :disabled="!canEdit"
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.vehicle_registration_number="{item}">
                        <v-edit-dialog
                            @open="open(item.vehicle_registration_number)"
                            @close="save({id: item.id, vehicle_registration_number: currentEdit})"
                        > {{ item.vehicle_registration_number|formatNoValue }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="currentEdit"
                                    :value="item.vehicle_registration_number"
                                    placeholder="Vehicle registration number..."
                                    single-line
                                    type="text"
                                    autofocus
                                    :disabled="!canEdit"
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.additional_data_prompts="{item}">
                        <v-select
                            class="card-select-style-wide card-select-font-size"
                            :value="item.additional_data_prompts"
                            @change="save({id: item.id, additional_data_prompts: $event})"
                            :items="dataPromptOptions"
                            item-text="label"
                            :disabled="!canEdit"
                        ></v-select>
                    </template>

                    <template v-slot:item.service_code="{item}">
                        <v-select
                            class="card-select-style-wide card-select-font-size"
                            :value="item.service_code"
                            @change="save({id: item.id, service_code: $event})"
                            :items="serviceCodeOptions"
                            :disabled="!canEdit"
                            item-text="label"
                        ></v-select>
                    </template>

                    <template v-slot:item.association_type="{item}">
                        <v-select
                            class="card-select-style-wide card-select-font-size"
                            :value="item.association_type"
                            @change="save({id: item.id, association_type: $event})"
                            :items="associationTypeOptions"
                            item-text="label"
                        ></v-select>
                    </template>

                    <template v-slot:item.action="{item}">
                        <v-btn class="error--text mx-2"
                               :id="`remove_payment_mechanism_btn${item.id}`"
                               text icon
                               @click="removePaymentMechanism(item)"
                               :disabled="!canEdit">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:expanded-item="{item: row}">
                        <td class="inner-table" colspan="8">
                            <v-data-table
                                :loading="loading"
                                :headers="headersSecondRow"
                                :items="[row]"
                                disable-pagination
                                hide-default-footer
                                item-key="id"
                                id="payment_mechanisms_data_table_2">
                                <template v-slot:item.additional_details="{item}">
                                    <v-edit-dialog
                                        @open="open(item.additional_details)"
                                        @close="save({id: item.id, additional_details: currentEdit})"
                                    > {{ item.additional_details|formatNoValue }}
                                        <template v-slot:input>
                                            <v-textarea
                                                v-model="currentEdit"
                                                :value="item.additional_details"
                                                placeholder="Additional details..."
                                                rows="2"
                                                autofocus
                                                :disabled="!canEdit"
                                            ></v-textarea>
                                        </template>
                                    </v-edit-dialog>
                                </template>
                            </v-data-table>
                        </td>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-btn id="add_payment_mechanism_btn"
                   class="text-capitalize mr-5"
                   right rounded color="primary"
                   @click.stop="addPaymentMechanism"
                   :disabled="!canEdit">Add
            </v-btn>
        </v-row>
    </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import {getFieldsFromTableHeaders} from "@/service/queryBuilder.service";
import gql from "graphql-tag";

const additionalDataPromptOptions = [
    {
        "value": "None",
        "label": "None"
    },
    {
        "value": "Odometer",
        "label": "Odometer"
    },
    {
        "value": "AdditionalInfo",
        "label": "Additional information"
    },
    {
        "value": "OdometerAndAdditionalInfo",
        "label": "Odometer & Additional information"
    }
];

const serviceCodeOptions = [
    {
        "value": "SERVICES_INCLUSIVE",
        "label": "Services inclusive"
    },
    {
        "value": "SHOP_GOODS_INCLUSIVE",
        "label": "Shop Goods Inclusive"
    }
];

const associationTypeOptions = [
    {
        "value": "VehicleOnly",
        "label": "Vehicle only"
    },
    {
        "value": "DriverOnly",
        "label": "Driver only"
    }
];

export default {
    "extends": BaseElement,
    "apollo": {
        "getPaymentMechanisms": {
            query () {
                if (!this.formId) {
                    this.loading = false;
                    return null;
                }
                // Assume `getFieldsFromTableHeaders` is already defined
                const fields = getFieldsFromTableHeaders(this.tableHeaders);

                return gql`
                query GetPaymentMechanisms($formId: Int!) {
                    getPaymentMechanisms: payment_mechanisms(form_id: $formId) {
                        data {
                            ${fields}
                        }
                    }
                }
            `;
            },
            variables () {
                if (this.formId) {
                    return {
                        "formId": this.formId
                    };
                }
                return null;
            },
            update (data) {
                this.loading = false;
                this.$store.dispatch(
                    "applicationFormValues/setPaymentMechanisms",
                    data?.getPaymentMechanisms?.data || []
                );
            },
            error () {
                this.loading = false;
            },
            skip () {
                return !this.formId;
            }
        }
    },
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        },
        paymentMechanisms () {
            return this.$store.state.applicationFormValues.paymentMechanisms;
        },
        dataPromptOptions () {
            return additionalDataPromptOptions;
        },
        serviceCodeOptions () {
            return serviceCodeOptions;
        },
        associationTypeOptions () {
            return associationTypeOptions;
        },
        headersWithActions () {
            const newHeaders = JSON.parse(JSON.stringify(this.tableHeaders.slice(0, 7)));
            newHeaders.push({
                "value": "action",
                "text": "",
                "sortable": false
            });
            return newHeaders;
        },
        headersSecondRow () {
            return this.tableHeaders.slice(7);
        }
    },
    "methods": {
        filterDeleted (paymentMechanisms) {
            return paymentMechanisms.filter((paymentMechanism) => !paymentMechanism.deleted);
        },
        addPaymentMechanism () {
            this.$store.dispatch("applicationFormValues/addPaymentMechanism");
        },
        removePaymentMechanism (item) {
            this.$store.dispatch(
                "applicationFormValues/deletePaymentMechanism",
                item.id
            );
        },
        open (value) {
            this.currentEdit = value;
        },
        save (item) {
            this.$store.dispatch(
                "applicationFormValues/updatePaymentMechanisms",
                item
            );
            this.currentEdit = null;
        }
    },
    data () {
        return {
            "currentEdit": null,
            "loading": true,
            "tableHeaders": this.field.tableHeaders
        };
    }
};
</script>

<style>
.card-select-style-wide {
    width: 180px;
}

.card-select-font-size {
    font-size: 14px;
}

#payment_mechanisms_data_table_2 {
    margin: 1rem 0;
}
</style>
