<template>
    <v-container fluid v-if="$can('update', 'acl_group')">
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="Access Setting"
            data-nw="editGroupHeader"
        >
            <template v-slot:button>
                <LogDialog
                    label="Group Data Logs"
                    :id="groupId"
                    table="acl_group"
                    v-if="$can('log', 'acl_group')"
                />
                <v-row>
                    <v-btn
                        id="delete-group-button"
                        class="text-capitalize btn-block mx-2"
                        rounded color="error"
                        @click="openDeleteDialog"
                        data-nw="deleteGroup"
                    >
                        Delete Group
                    </v-btn>
                    <v-btn
                        id="submit-group-button"
                        class="text-capitalize btn-block mx-2"
                        rounded color="primary"
                        @click="submitGroupForm"
                        data-nw="saveGroup"
                    >
                        Save Group
                    </v-btn>
                </v-row>
            </template>
        </HeaderBar>
        <v-dialog v-model="deleteDialog" max-width="40%">
            <v-card>
                <v-card-title class="headline">Confirm Delete</v-card-title>
                <v-card-text>
                    Please note that the below list of users are mapped to the Group.
                    Are you comfortable to delete the group
                </v-card-text>
                <v-card-text v-if="usersInGroup().length">
                    Users assigned to this Group:
                    <ul>
                        <li v-for="user in getUsersFullName()" :key="user">{{ user }}</li>
                    </ul>
                </v-card-text>
                <v-card-text v-else>
                    This group has no assigned users.
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="deleteDialog = false">Cancel</v-btn>
                    <v-btn color="error" text @click="deleteGroup">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <BaseForm
            type="update"
            store-module="groupFormValues"
            :form-segments="formSegments"
            :editable="true"
        />
    </v-container>
</template>

<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import LogDialog from "../dialogs/LogDialog";
import gql from "graphql-tag";
import { toNumber } from "lodash";

export default {
    "name": "EditGroup",
    "components": {
        LogDialog,
        BaseForm,
        HeaderBar
    },
    "methods": {
        submitGroupForm () {
            this.$root.$emit("submitBaseForm");
        },
        openDeleteDialog () {
            this.deleteDialog = true;
        },
        usersInGroup () {
            const users = this.$store.state.groupFormValues.values.users;
            if (!users || users.length === 0) {
                return [];
            }
            return users;
        },

        async deleteGroup () {
            await this.$apollo.mutate({
                "mutation": gql`mutation DeleteGroup($id: Int!) { delete_acl_group(id: $id) {responseString}}`,
                "variables": { "id": this.groupId }
            });

            this.deleteDialog = false;
            await this.$router.push({"name": "ListGroups"});
        },
        getUsersFullName () {
            return this.usersInGroup().map((user) => `${user.first_name} ${user.last_name}`);
        }
    },
    "computed": {
        formSegments () {
            return this.$store.getters.getFormSegments("groupForm");
        },
        groupId () {
            return toNumber(this.$route.params.id);
        }
    },
    "apollo": {
        "groupFormValues": {
            query () {
                return gql`query { groupFormValues: acl_group ( id: ${this.groupId}) { data{ id name }}}`;
            },
            update (data) {
                this.$store.dispatch(
                    "groupFormValues/setGroupName",
                    data.groupFormValues.data[0]
                );
            },
            "fetchPolicy": "no-cache"
        },
        "usersInGroup": {
            query () {
                return gql`query Acl_group_user($aclgroupId: Int)
                    { acl_group_user ( aclgroup_id: $aclgroupId) { data { user { id first_name last_name }}}}`;
            },
            variables () {
                return {
                    "aclgroupId": this.groupId
                };
            },
            update (data) {
                const users = data.acl_group_user.data.map((user) => ({
                    "id": user.user.id,
                    "first_name": user.user.first_name,
                    "last_name": user.user.last_name
                }));
                this.$store.dispatch("groupFormValues/setUsersInGroup", users);
            },
            "fetchPolicy": "no-cache"
        }
    },
    data () {
        return {
            "deleteDialog": false,
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": { "name": "Dashboard" }
                },
                {
                    "text": "Groups",
                    "disabled": false,
                    "exact": true,
                    "to": { "name": "ListGroups" }
                },
                {
                    "text": "Edit Group",
                    "disabled": true,
                    "exact": true
                }
            ]
        };
    }
};
</script>
