<template>
    <v-container fluid v-if="$can('create', 'sites')">
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="New site"
        >
            <template v-slot:button>
                <v-btn
                    class="text-capitalize btn-block"
                    rounded color="primary"
                    @click="sumbitSiteForm()">
                    Save Site
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
            type="create"
            store-module="siteFormValues"
            :form-segments=formSegments
            :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";

export default {
    "name": "NewUser",
    "components": {
        BaseForm,
        HeaderBar
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Sites",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListSites"}
                },
                {
                    "text": "New site",
                    "disabled": true
                }
            ]
        };
    },
    "methods": {
        sumbitSiteForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.siteFormSegments;
        }
    },
    created () {
        this.$store.dispatch("siteFormValues/deleteFormValues");
    }
};
</script>
