import Vue from "vue";
import _ from "lodash";
import apolloClient from "@/ApolloClient";
import gql from "graphql-tag";


const defaultState = {
    "opalId": null
};

const mutations = {
    SAVE_OPAL_ID (currentState, data) {
        Vue.set(currentState, "opalId", data);
    },
    CLEAR_OLD_VALUES (currentState) {
        Vue.set(currentState, "opalId", "");
    }
};

const getters = {
    "getOpalId": (currentState) => () => ({ "name": currentState.opalId})
};

const actions = {
    async opalId ({commit}, formId) {
        if (formId) {
            const query = gql`query {form_export( form_id: ${formId},
             key: "opal_account_number"){data{external_id}}}`;
            const result = await apolloClient.query({query});
            if (!_.isNil(result.data.form_export.data[0])) {
                commit("SAVE_OPAL_ID", result.data.form_export.data[0].external_id);
            }
        }
    },
    async clearOldValues ({commit}) {
        await commit("CLEAR_OLD_VALUES");
    }
};

export default {
    "namespaced": true,
    "state": defaultState,
    getters,
    actions,
    mutations
};
