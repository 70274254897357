import {AbilityBuilder} from "@casl/ability";
import JsonWebtoken from "jsonwebtoken";
import {ability} from "./ability";
import apolloClient from "./ApolloClient";
import gql from "graphql-tag";
import store from "./store";

export default async function userPermission () {
    if (window.localStorage.getItem("jwt")) {
        try {
            const query = gql` { permissions } `;
            const result = await apolloClient.query({query});
            const permissions = result.data.permissions;
            const {can, rules} = new AbilityBuilder();
            const token = window.localStorage.getItem("jwt");
            const decoded = JsonWebtoken.decode(token);
            const role = decoded.role;

            Object.entries(permissions[`${role}`]).forEach((page) => {
                Object.entries(page[1]).forEach((property) => {
                    if (typeof property[1] === "boolean" && property[1] === true) {
                        can(property[0], page[0]);
                    }
                });
            });

            ability.update(rules);

            // Persist role in store
            await store.dispatch(
                "userRole/setUserRole",
                role
            );

            // Persist the telesales agency id
            await store.dispatch(
                "telesalesAgencyId/setTelesalesAgencyId",
                decoded.telesales_agency_id
            );
        } catch (e) {
            // no user permissions
        }
    }
}
