<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog scrollable v-model="dialog" persistent max-width="90vw">
  <template v-slot:activator="{ on }">
      <v-btn
          data-nw="data-log-button"
          v-on="on"
          class="text-capitalize px-0"
          text
      >
          {{label}}
          <v-icon class="primary--text pl-2">mdi-message-text-outline</v-icon>
      </v-btn>
  </template>
    <v-card id="data-log-card" height="90vh">
      <v-card-title>
        <v-col lg="10">
          Data log
        </v-col>
        <v-col lg="2">
          <v-btn class="primary--text float-right mt-3"
                 text
                 @click.stop="closeDialog"
                 data-nw="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-data-table
          sort-by="log_date"
          :sort-desc="true"
          :headers="headers"
          :hide-default-footer="true"
          :items-per-page="-1"
          no-data-text="No changed data available"
          :items="dataLogs">
          <template v-slot:item.log_date="{ item }">
            {{item.log_date|formatDateTimeSecond}}
          </template>
          <template v-slot:item.changes="{ item }">
            <div style="white-space: pre">{{ item.changes.replace(/\),/gu, "),\n") }}</div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import gql from "graphql-tag";
import importedHeaders from "@/components/views/dialogs/headers/LogDialogHeaders.json";

export default {
    "name": "LogDialog",
    "props": {
        "label": String,
        "id": Number,
        "table": String
    },
    "data" () {
        return {
            "dialog": false,
            "dataLogs": [],
            "gqlModel": this.table ? "log" : `log_${this.table}`,
            "headers": importedHeaders
        };
    },
    "computed": {
        filter () {
            let field = "form_id";
            if (this.table !== "log") {
                field = `${this.table}_id`;
            }
            const id = _.isUndefined(this.id) ? "null" : this.id;
            return `${field}: ${id}`;
        }
    },
    "methods": {
        closeDialog () {
            this.dialog = false;
        }
    },
    "watch": {
        dialog (isOpen) {
            if (isOpen) {
                this.$apollo.queries.log.refetch();
            }
        }
    },
    "apollo": {
        "log": {
            query () {
                let table = "log";
                if (this.table !== "log") {
                    table = `log_${this.table}`;
                }
                return gql`query {${table}:${table}(${this.filter} first: -1){data{log_date,username,name,changes}}}`;
            },
            update (data) {
                let table = "log";
                if (this.table !== "log") {
                    table = `log_${this.table}`;
                }
                this.dataLogs = data[table]?.data || [];
            }
        }
    }
};

</script>

