import {get, isUndefined} from "lodash";
import Vue from "vue";

const state = {"zipcodes": [], "countryId": null};

const mutations = {
    SET_ZIPCODES (currentState, zipcodes) {
        Vue.set(currentState, "zipcodes", zipcodes);
    },
    SET_COUNTRY_ID (currentState, countryId) {
        Vue.set(currentState, "countryId", countryId);
    }
};

const getters = {
    "getCounties": (currentState) => currentState.zipcodes
        .filter((zipcode) => zipcode.country_id === currentState.countryId)
        .sort((zipcodeA, zipcodeB) => {
            if (zipcodeA.county_name.toUpperCase() < zipcodeB.county_name.toUpperCase()) {
                return -1;
            } else if (zipcodeA.county_name.toUpperCase() === zipcodeB.county_name.toUpperCase()) {
                return 0;
            }
            return 1;
        })
        .map((zipcode) => ({
            "value": zipcode.county_name,
            "text": `${zipcode.county_code} - ${zipcode.county_name}`
        })),

    "getRegions": (currentState) => currentState.zipcodes
        // eslint-disable-next-line camelcase
        .filter((zipcode) => zipcode.country_id === currentState.countryId)
        .sort((zipcodeA, zipcodeB) => {
            if (zipcodeA.region_name.toUpperCase() < zipcodeB.region_name.toUpperCase()) {
                return -1;
            } else if (zipcodeA.region_name.toUpperCase() === zipcodeB.region_name.toUpperCase()) {
                return 0;
            }
            return 1;
        })
        .map((zipcode) => ({
            "value": zipcode.region_name,
            "text": `${zipcode.region_code} - ${zipcode.region_name}`
        }))
};

const actions = {
    async setZipCodes ({commit}, zipcodes) {
        await commit(
            "SET_ZIPCODES",
            zipcodes
        );
    },
    async setCountry ({rootGetters, commit}, country) {
        let countryId = null;
        if (!isUndefined(country)) {
            const countryObject = rootGetters["countries/getCountryByIsoCode"](country);
            countryId = get(countryObject, "id");
        }
        await commit("SET_COUNTRY_ID", countryId);
    },

    addressLookup ({rootGetters}, payload) {
        const {country, zipcode} = payload;
        if (country && zipcode) {
            const countryObject = rootGetters["countries/getCountryByIsoCode"](country);
            if (countryObject && [5, 7].includes(countryObject.id)) {
                const stringLength = {
                    "5": 2,
                    "7": 4
                };
                const zipStart = zipcode.substring(0, stringLength[countryObject.id]);
                if (zipStart.length === stringLength[countryObject.id]) {
                    const [lookup] = state.zipcodes
                        .filter((row) => row.country_id === countryObject.id && row.zip_code === zipStart);
                    if (lookup) {
                        this.dispatch("applicationFormValues/updateFormValue", {
                            "name": "region",
                            "value": lookup.region_name
                        });
                        this.dispatch("applicationFormValues/updateFormValue", {
                            "name": "county",
                            "value": lookup.county_name
                        });
                        this.dispatch("applicationFormValues/updateFormValue", {
                            "name": "addressLookupZipCode_id",
                            "value": lookup.id
                        });
                    }
                }
            }
        }
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
