<template>
    <v-container fluid v-if="$can('read', 'sites')">
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="Sites"
            data-nw="listSitesHeader"
        >
            <template v-slot:button>
                <v-btn
                    data-nw="new-site-button"
                    v-if="$can('create', 'sites')"
                    class="text-capitalize float-right"
                    rounded color="primary"
                    @click.stop="createSite">
                    New Site
                </v-btn>
            </template>
        </HeaderBar>

        <v-col>
            <v-row align="center">
                <v-col cols="3">
                    <v-text-field
                        data-nw="sites-search-bar"
                        v-if="showTextFilter"
                        v-model="filterText"
                        @keyup.enter="searchData($event)"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        dense
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-card flat class="background-grey" width="100%">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-data-table :must-sort="true"
                                              :loading="loading"
                                              :headers="tableHeaders"
                                              :items="items"
                                              :server-items-length="itemsPerPage"
                                              :disable-pagination="true"
                                              :hide-default-footer="true"
                                              :options.sync="options"
                                              item-key="id"
                                              v-on="{ 'click:row': $can('update', 'sites') ? goToEdit : null}"
                                              :class="$can('update', 'sites') ? 'table-cursor' : ''"

                                              v-on:update:options="setSortData"
                                              data-nw="sites-data-table"
                                >
                                    <template v-slot:item.active="{ item }">
                                        {{item.active|formatActiveState}}
                                    </template>
                                    <template v-slot:item.country_id="{ item }">
                                        <v-img v-if="item.country_id"
                                               :src=item.country_id|formatCountry width="24">
                                        </v-img>
                                        <v-img v-else :src={}|formatCountry width="24">
                                        </v-img>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="total_Count_Records"># of records: {{totalCount}}</v-col>
                            <v-col>
                                <v-pagination
                                    circle
                                    v-model="filtersCurrentPage"
                                    :length="numberOfPages"
                                    total-visible="5"
                                    data-nw="sites-pagination"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import BaseList from "../../baseComponents/BaseList";
import HeaderBar from "../../uiElements/HeaderBar";

export default {
    "extends": BaseList,
    "name": "ListSites",
    "components": {
        HeaderBar
    },
    "methods": {
        createSite () {
            this.$router.push({"name": "CreateSite"});
        }
    },
    // eslint-disable-next-line max-lines-per-function
    data () {
        return {
            "tableName": "site",
            "searchFields": ["name", "code", "city", "r_number"],
            "editURL": "EditSite",
            "gqlModel": "site",
            "tableHeaders": [
                {
                    "value": "code",
                    "text": "Code",
                    "sortable": true,
                    "width": "15%"
                },
                {
                    "value": "name",
                    "text": "Name",
                    "sortable": true,
                    "width": "20%"
                },
                {
                    "value": "city",
                    "text": "City",
                    "sortable": true,
                    "width": "20%"
                },
                {
                    "value": "r_number",
                    "text": "Site R-number",
                    "sortable": true,
                    "width": "15%"
                },
                {
                    "value": "active",
                    "text": "Active",
                    "sortable": true,
                    "width": "15%"
                },
                {
                    "value": "country_id",
                    "text": "Country",
                    "sortable": false,
                    "width": "15%"
                }
            ],
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Sites",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListSites"}
                }
            ]
        };
    }
};
</script>
