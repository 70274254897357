import Vue from "vue";

const defaultState = {
    "fleetFields": []
};

const mutations = {
    ADD_FLEET_FIELD (currentState, data) {
        data.value = data.value ?? 0;
        currentState.fleetFields.push(data);
    },
    SET_FLEET_FIELDS (currentState, data) {
        Vue.set(currentState, "fleetFields", data);
    },
    SAVE_AMOUNT (currentState, data) {
        const fleetField = currentState.fleetFields.find((field) => field.id === data.id);
        fleetField.value = data.value;

        Vue.set(currentState, "fleetFields", currentState.fleetFields);
    },
    CLEAR_FLEET_FIELD (currentState) {
        Vue.set(currentState, "fleetFields", []);
    }
};

const actions = {
    async addFleetfield ({ commit }, data) {
        await commit("ADD_FLEET_FIELD", data);
    },
    async setFleetFields ({ commit }, data) {
        await commit("SET_FLEET_FIELDS", data);
    },
    async clearFleetField ({ commit }) {
        await commit("CLEAR_FLEET_FIELD");
    },
    async saveAmount ({ commit }, data) {
        await commit("SAVE_AMOUNT", data);
    }
};

const getters = {
    "getFleetFields": (currentState) => currentState.fleetFields
};

export default {
    "namespaced": true,
    "state": defaultState,
    actions,
    mutations,
    getters
};
