<template>
    <v-container fluid v-if="$can('read', 'acl_group')">
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="Access Settings"
            data-nw="listGroupHeader"
        >
            <template v-slot:button>
                <v-btn
                    data-nw="new-group-button"
                    v-if="$can('create', 'acl_group')"
                    class="text-capitalize float-right"
                    rounded color="primary"
                    @click.stop="createAclGroup">
                    New Group
                </v-btn>
            </template>
        </HeaderBar>

        <v-col>
            <v-row align="center">
                <v-col cols="3">
                    <v-text-field
                        v-if="showTextFilter"
                        v-model="filterText"
                        @keyup.enter="searchData($event)"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        dense
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-card flat class="background-grey" width="100%">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-data-table :must-sort="true"
                                              :loading="loading"
                                              :headers="tableHeaders"
                                              :items="items"
                                              :server-items-length="itemsPerPage"
                                              :disable-pagination="true"
                                              :hide-default-footer="true"
                                              :options.sync="options"
                                              item-key="id"
                                              v-on="{ 'click:row': $can('read', 'acl_group') ? goToEdit : null}"
                                              v-on:update:options="setSortData"
                                              :class="$can('read', 'acl_group') ? 'table-cursor' : ''"
                                >
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="total_Count_Records"># of records: {{totalCount}}</v-col>
                            <v-col>
                                <v-pagination
                                    circle
                                    v-model="filtersCurrentPage"
                                    :length="numberOfPages"
                                    total-visible="5"
                                ></v-pagination>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import BaseList from "../../baseComponents/BaseList";
import HeaderBar from "../../uiElements/HeaderBar";

export default {
    "extends": BaseList,
    "name": "ListGroups",
    "components": {
        HeaderBar
    },
    "computed": {

    },
    "methods": {
        createAclGroup () {
            this.$router.push({"name": "CreateAclGroup"});
        }
    },
    // eslint-disable-next-line max-lines-per-function
    data () {
        return {
            "tableName": "aclGroups",
            "searchFields": ["name"],
            "editURL": "EditGroup",
            "gqlModel": "acl_group",
            "tableHeaders": [
                {
                    "value": "name",
                    "text": "name",
                    "sortable": true,
                    "width": "5%"
                }
            ],
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Groups",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListGroups"}
                }
            ]
        };
    }
};
</script>
