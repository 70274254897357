<template>
    <v-container class="pt-5 mt-5" fluid>
        <v-col>
            <v-card>
                <v-card-title>JWT Login</v-card-title>
                <div>Login using JWT. Please wait...</div>
            </v-card>
        </v-col>
    </v-container>
</template>

<script>

import JsonWebtoken from "jsonwebtoken";

export default {
    "name": "JwtLogin",
    mounted () {
        const message = this.$route.query.msg;
        const jwt = this.$route.query.jwt;

        if (typeof message !== "undefined") {
            window.location.href = `/login?msg=${message}`;
            return;
        }
        if (typeof jwt === "undefined" || jwt === "") {
            // JWT not present
            this.$router.push("/login");
        }

        // data uit token halen en login dingen doen
        const jwtContent = JsonWebtoken.decode(jwt);
        const user = {
            "id": jwtContent.user_id,
            "first_name": jwtContent.first_name,
            "last_name": jwtContent.last_name,
            "emailaddress": jwtContent.emailaddress,
            "role": jwtContent.role,
            "telesale_id": jwtContent.telesales_agency_id,
            jwt
        };

        this.$store.dispatch("session/buildSession", user).then(() => this.$router.push("/"));

    }
};
</script>

<style scoped>

</style>
