import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {
    "legalForms": []
};

const mutations = {
    SET_LEGAL_FORMS (currentState, legalForms) {
        Vue.set(currentState, "legalForms", legalForms);
    }
};

/**
 * Get the selected country data using the rootGetters
 * @param rootGetters
 * @returns {*}
 */
function getCurrentCountryFromCategory (rootGetters) {
    const categories = rootGetters["categories/getFormCategory"];
    const countries = rootGetters["countries/getCountry"];
    const currentCategoryId = rootGetters["applicationFormValues/categoryId"];
    const categoryId = currentCategoryId();
    if (categoryId) {
        const category = categories(categoryId);
        if (category) {
            return countries(category.country_id);
        }
    }
    return null;
}

const getters = {
    "getLegalFormsDropdownOptionsByCountry": (currentState, currentGetters, rootState, rootGetters) => {
        const country = getCurrentCountryFromCategory(rootGetters);
        if (country) {
            return state.legalForms.filter((legalForm) => legalForm.country_id === country.id)
                .map((legalForm) => ({
                    "value": legalForm.value,
                    "text": legalForm.label
                }));
        }
        return [];
    }
};

const actions = {
    async getLegalForms ({commit}) {
        const optionsGql = await gql`query{option_legal_form (first: -1){
            data{id country_id value label}}}`;
        const result = await apolloClient.query({"query": optionsGql});
        const legalForms = result.data.option_legal_form.data;
        await commit(
            "SET_LEGAL_FORMS",
            legalForms
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
