<template>
  <v-container fluid v-if="$can('read', 'alertlist')">
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="Alertlist"
       data-nw="alertlistHeader"
    >
      <template v-slot:button>
        <v-btn
            v-if="$can('update', 'alertlist')"
            id="update-alertlist-button"
            class="text-capitalize btn-block"
            rounded color="primary"
            @click.stop="updateAlertlist"
            data-nw="saveAlertlist"
        >
          Update Alertlist
        </v-btn>
      </template>
    </HeaderBar>
    <v-col class="pt-12">
      <v-row>
        <v-textarea
          class="pt-11"
          id="alertlist-text-area"
          rows="30"
          outlined
          placeholder="No alertlist items found..."
          v-model="alertListData"
        />
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import ApolloClient from "../../../ApolloClient";
import HeaderBar from "../../uiElements/HeaderBar";
import gql from "graphql-tag";

export default {
    "name": "ListAlertlist",
    "components": {
        HeaderBar
    },
    data () {
        return {
            "alertListData": "",
            "gqlModel": "blacklist",
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Alertlist",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListAlertlist"}
                }
            ]
        };
    },
    "methods": {
        updateAlertlist () {
            this.$store.dispatch(
                "loader/setLoading",
                true
            );
            this.saveAlertlist().then(() => {
                this.$store.dispatch(
                    "loader/setLoading",
                    false
                );
                this.$toast(
                    "The data is successfully saved",
                    {"color": "success"}
                );
            }).catch(() => {
                this.$store.dispatch(
                    "loader/setLoading",
                    false
                );
                this.$toast(
                    "Something went wrong while saving the data",
                    {"color": "error"}
                );
            });
        },

        saveAlertlist () {
            const formattedAlertlistData = `|${this.alertListData.replace(
                /\n/gu,
                "| |"
            )}`;
            const mutation = gql`mutation{update_blacklist (id: 1 list:"${formattedAlertlistData}"){responseString}}`;
            return ApolloClient.mutate({mutation}).then((response) => {
                if (response.data[Object.keys(response.data)[0]].errors) {
                    throw Error(response.data[Object.keys(response.data)[0]].errors);
                }
                return response;
            }).catch((error) => {
                throw Error(error);
            });
        }
    },
    "apollo": {
        "alertlist": {
            query () {
                return gql`query {blacklist: blacklist {data{list}}}`;
            },
            update (data) {
                this.alertListData = (data.blacklist.data[0]?.list || "").replace(
                    /\| \|/gu,
                    "\n"
                ).replace(
                    /\|/gu,
                    ""
                );
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
