import Vue from "vue";
const state = {"campaigns": []};

const mutations = {
    SET_CAMPAIGNS (currentState, campaigns) {
        Vue.set(currentState, "campaigns", campaigns);
    }
};

const getters = {
    "getFormCampaign": (currentState) => (campaignId) => currentState.campaigns.find((campaign) => campaign.id === campaignId),
    "categoryIdsFromCampaigns": (currentState) => currentState.campaigns.map((camp) => camp.category_id),
    "campaignsForCountry": (currentState, currentGetters, rootState, rootGetters) => (selectedCountry) => currentState.campaigns
        .filter((campaign) => rootGetters["categories/forCountry"](selectedCountry).includes(campaign.category_id)),
    "campaignName": (currentState) => (campaignId) => {
        if (typeof campaignId === "undefined") {
            return null;
        }
        const campaign = currentState.campaigns.find((cmpgn) => cmpgn.id === campaignId);
        if (!campaign) {
            return null;
        }
        return { "name": campaign.name};
    }
};

const actions = {
    async setCampaigns ({commit}, campaigns) {
        await commit(
            "SET_CAMPAIGNS",
            campaigns
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
