<template>
    <v-text-field
            v-bind="field"
            :name="name"
            :class="getVatClass"
            v-model="vatNumberFromFormValue"
            :rules="setRules(rulesArray)"
            :label="field.label"
            :hint="getViesCheckDate"
            persistent-hint
            @input="setExecuteViesCheck(true)"
            type="text"
            :ref="this.name"
            :disabled="!canEditVatField"
    ></v-text-field>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import classNames from "classnames";
import gql from "graphql-tag";
import moment from "moment";

export default {
    "extends": BaseElement,
    "components": {},
    "methods": {
        validateVat (value) {
            if (value) {
                this.setExecuteViesCheck(this.checkNotSpainOrUk(value));
                if (!this.executeViesCheck) {
                    return true;
                }

                // eslint-disable-next-line max-len
                const regexResult = (/^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|(FR)[0-9A-Z]{2}[0-9]{9}|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|(IT)[0-9]{11}|(LT)([0-9]{9}|[0-9]{12})|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$/u).test(value);
                if (regexResult) {
                    this.$apollo.queries.checkVatNumber.skip = false;
                    return true;
                }
                this.$apollo.queries.checkVatNumber.skip = true;
                return false;
            }
            return true;
        },
        setExecuteViesCheck (boolean) {
            this.executeViesCheck = boolean;
        },
        checkNotSpainOrUk (value) {
            // Check if vat is spanish or british
            // eslint-disable-next-line max-len
            return !(/^((ES)[A-Z][0-9]{7}[A-Z]|(ES)[0-9]{8}[A-Z])|(GB)([0-9]{9}([0-9]{3})|[A-Z]{2}[0-9]{3})$/u).test(value);
        },
        setRules (ruleData) {
            this.field.rules = ruleData;
        },
        // Temp rules need to be changed because this is not the right way
        setTempRules (ruleData) {
            this.field.rules = ruleData;
        },
        viesDataMatch (response) {
            let hasViesDataMatched = 0;
            const companyName = this.$store.state.applicationFormValues.values.company_name;
            const companyAddress = this.$store.state.applicationFormValues.values.address;
            const companyZipcode = this.$store.state.applicationFormValues.values.zipcode;
            const companyCity = this.$store.state.applicationFormValues.values.city;
            const addressResponse = response.checkVatNumber.address;
            const addressSplitter = addressResponse.split(",");
            const viesAddress = addressSplitter ? addressSplitter[1].trim() : "";
            const [viesZipcode, ...viesCity] = addressSplitter ? addressSplitter[2].trim().split(" ") : "";
            if (addressSplitter) {
                if (companyName.toLowerCase() === response.checkVatNumber.name.toLowerCase() &&
                    // eslint-disable-next-line max-len
                    this.normalizeStreet(viesAddress).toLowerCase() === this.normalizeStreet(companyAddress).toLowerCase() &&
                    viesZipcode.replace(/\s+/gu, "").toLowerCase() === companyZipcode.replace(/\s+/gu, "").toLowerCase() &&
                    viesCity.join(" ").toLowerCase() === companyCity.toLowerCase()
                ) {
                    hasViesDataMatched = 1;
                }
            }
            return hasViesDataMatched;
        },
        // Remove leading zeros from numbers
        normalizeStreet (address) {
            return address.replace(/\b0+(\d+)/gu, "$1");
        }
    },
    data () {
        return {
            "validationHardRules": {
                "vatValidation": [(v) => this.validateVat(v) || "Invalid VAT format! Please use a country code."]
            },
            "skipQuery": true,
            "executeViesCheck": false,
            "isVatNumberChecked": false,
            "tempRuleArray": []
        };
    },
    "computed": {
        "vatNumberFromFormValue": {
            "get" () {
                if (this.$store.state.applicationFormValues.values[this.name]) {
                    return this.$store.state.applicationFormValues.values[this.name];
                }
                return null;
            },
            "set" (value) {
                this.$store.dispatch(
                    "applicationFormValues/updateFormValue",
                    {
                        "name": this.name,
                        value
                    }
                );
            }
        },
        getVatClass () {
            return classNames("vat-field", {
                "vat-field--warning": this.isVatNumberChecked && !this.checkVatNumber
            });
        },
        getViesCheckDate () {
            if (this.isVatNumberChecked && !this.hasNoVatNumber && this.executeViesCheck) {
                if (!this.checkVatNumber) {
                    return "VAT invalid";
                }
                return "VAT not validated";
            }
            const formValue = this.formValueByFieldName("vies_check_date");
            if (formValue && !this.hasNoVatNumber) {
                return "Last checked: ".concat(moment(this.formValueByFieldName("vies_check_date")).format("DD-MM-YYYY HH:mm"));
            }
            return null;
        },
        rulesArray () {
            if (!this.hasNoVatNumber) {
                if (!this.field.rules.includes("required")) {
                    this.setRules(this.tempRuleArray);
                    return this.field.rules;
                }
            }
            if (this.field.rules.includes("required") && this.hasNoVatNumber) {
                this.setTempRules(this.field.rules);
                this.setRules([]);
                return this.field.rules;
            }
            return this.field.rules;
        },
        hasNoVatNumber () {
            return this.$store.state.applicationFormValues.values.has_no_vat_number === 1;
        },
        canEditVatField () {
            if (!this.canEdit) {
                return false;
            }
            return !this.hasNoVatNumber;
        }
    },
    "apollo": {
        "checkVatNumber": {
            query () {
                let formId = 0;
                if (!isNaN(this.$store.state.applicationFormValues.values.id)) {
                    formId = this.$store.state.applicationFormValues.values.id;
                }
                return gql`query customErrorHandling {checkVatNumber: viesCheck (
                        vatNumber: "${this.$store.state.applicationFormValues.values[this.name]}"
                        formId: ${formId})
                        {name address valid}}`;
            },
            update (response) {
                if (response) {
                    this.isVatNumberChecked = true;
                    const checkDate = `${moment().utc().format("YYYY-MM-DDTHH:mm:ss")}.000Z`;
                    if (!this.$store.state.applicationFormValues.values.has_vies_data_matched && typeof this.$route.params.id !== "undefined") {
                        let hasViesDataMatched = 0;
                        if (response.checkVatNumber.address !== "---") {
                            hasViesDataMatched = this.viesDataMatch(response);
                        }

                        this.updateValue(hasViesDataMatched, "has_vies_data_matched");
                    }
                    this.updateValue(
                        checkDate,
                        "vies_check_date"
                    );
                    if (response.checkVatNumber) {
                        return response.checkVatNumber.valid;
                    }
                }
                return false;
            },
            skip () {
                return this.skipQuery;
            },
            error (error) {
                const errorMessage = error.networkError.result.errors[0].message;
                this.$toast(
                    `Something went wrong while performing the VAT check in Vies: ${errorMessage}`,
                    {"color": "error", "timeout": 6000}
                );
            }
        }
    },
    "watch": {
        "hasNoVatNumber" () {
            if (this.hasNoVatNumber) {
                this.isVatNumberChecked = false;
                this.$apollo.queries.checkVatNumber.skip = true;
                this.updateValue(null, "vat_number");
                this.$store.dispatch(
                    "formSegments/removeSegmentAlerts",
                    { "segmentName": this.segment.id, "fieldName": this.name}
                );
                this.validateByGroup();
                return;
            }
            this.validateByGroup();
        },
        "rulesArray" () {
            this.validateByGroup();
        }
    }
};

</script>

<style lang="scss">
    .vat-field {
        &--warning .v-messages.theme--light {
            color: #ff8100;
        }
    }
</style>
