<template>
<div />
</template>

<script>
export default {
    "name": "Logout",
    mounted () {
        this.$store.dispatch("session/logout");
    }
};
</script>

