<template>
    <v-dialog :value="isDialogOpen" max-width="1600" persistent>
        <v-card :loading="loading">
            <v-card-title>
                <v-col lg="10">
                    MDG search
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                           data-nw="fraudCheckDialogClose"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-container class="px-2">
                    <v-row id="mdg-fields">
                        <v-col lg="4" md="4" v-for="field in mdgFields" :key="field.label">
                            <v-row :no-gutters="true">
                                <v-checkbox
                                    v-model="field.ticked"
                                />
                                <v-text-field
                                    :disabled="!field.ticked"
                                    :label="field.label"
                                    v-model="field.value"
                                    outlined
                                />
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn
                            :disabled="!isTickedValue"
                            id="search-customer-button"
                            rounded color="primary"
                            class="text-capitalize"
                            @click.stop="searchCustomer"
                        >
                            Search customer
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            id="create-new-customer-button"
                            rounded color="primary"
                            class="text-capitalize"
                            @click.stop="createNewCustomer"
                            :disabled="disableCreateBtn"
                        >
                            Create new customer
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-data-table
                            v-if="searched"
                            v-model="selectedCustomer"
                            style="width: 100%"
                            id="search_customer_table"
                            :headers="headers"
                            :items="customers"
                            class="table-cursor"
                            :single-select="true"
                            item-key="mdm_sold_to_number"
                            show-select
                            hide-default-footer
                            disable-pagination
                        />
                    </v-row>
                    <v-row>
                        <v-btn
                            v-if="customers.length !== 0"
                            :disabled="selectedCustomer.length === 0"
                            id="choose-customer-button"
                            rounded color="primary"
                            class="text-capitalize"
                            @click.stop="chooseCustomer"
                        >
                            Save
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import gql from "graphql-tag";
import importedHeaders from "@/components/views/dialogs/headers/MdgSearchDialogHeaders.json";

export default {
    // eslint-disable-next-line max-lines-per-function
    "data" () {
        return {
            "mdgFields": {
                "company_name": {
                    "ticked": false,
                    "label": "Company Name",
                    "value": null
                },
                "tax_number": {
                    "ticked": false,
                    "label": "Tax number",
                    "value": null
                },
                "mdm_sold_to_number": {
                    "ticked": false,
                    "label": "Sold to",
                    "value": null
                },
                "vat_number": {
                    "ticked": false,
                    "label": "VAT Number",
                    "value": null
                },
                "address": {
                    "ticked": false,
                    "label": "Address",
                    "value": null
                },
                "city": {
                    "ticked": false,
                    "label": "City",
                    "value": null
                },
                "country": {
                    "ticked": false,
                    "label": "Country",
                    "value": null
                },
                "zipcode": {
                    "ticked": false,
                    "label": "Zipcode",
                    "value": null
                },
                "coc_nr": {
                    "ticked": false,
                    "label": "Coc number",
                    "value": null
                },
                "dnb_duns_number": {
                    "ticked": true,
                    "label": "Duns number",
                    "value": null
                }
            },
            "headers": importedHeaders,
            "customers": [],
            "selectedCustomer": [],
            "disableCreateBtn": false,
            "searched": false,
            "loading": false
        };
    },
    "computed": {
        isDialogOpen () {
            return this.$store.state.mdg.mdgSearchDialogOpen;
        },
        formValues () {
            return this.$store.state.applicationFormValues.values;
        },
        isTickedValue () {
            const fields = Object.keys(this.mdgFields);
            return fields.find((fieldName) => this.mdgFields[fieldName].ticked);
        }
    },
    "methods": {
        closeDialog () {
            this.customers = [];
            this.searched = false;
            this.$store.dispatch("mdg/openDialog", {"open": false, "dialog": "search"});
        },
        fillFields () {
            const fieldNames = Object.keys(this.mdgFields);
            fieldNames.forEach((fieldName) => {
                this.mdgFields[fieldName].value = this.formValues[fieldName];
            });
        },
        searchCustomer () {
            if (this.createQueryArgs()) {
                this.$apollo.queries.getMdgCustomerSearch.skip = false;
            } else {
                this.$toast(
                    "Can't search an empty value",
                    {
                        "color": "softWarning"
                    }
                );
            }
        },
        async createNewCustomer () {
            this.loading = true;
            const customer = {
                "mdm_search_status": 2
            };
            const mutation = gql`mutation{mdg_customer_create(formId: ${this.formValues.id}){
                    responseString
                    soldTo
                    status
                    error {
                        message
                    }
            }}`;
            await this.$apollo.mutate({mutation})
                .then(async (response) => {
                    if (response.data.mdg_customer_create.soldTo) {
                        // eslint-disable-next-line camelcase
                        customer.mdm_sold_to_number = response.data.mdg_customer_create.soldTo;
                        await this.saveCustomer(customer, response.data.mdg_customer_create.responseString);
                        this.$root.$emit("submitBaseForm", false, true);
                    } else {
                        this.$toast(
                            "Missing sold to number in response",
                            {
                                "color": "error",
                                "timeout": 5000
                            }
                        );
                    }
                })
                .catch((error) => {
                    this.$toast(
                        error.message,
                        {
                            "color": "error",
                            "timeout": 5000
                        }
                    );
                });
            this.loading = false;
        },
        async chooseCustomer () {
            const entryFoundStatus = 3;
            const customer = {
                "mdm_search_status": entryFoundStatus,
                "mdm_sold_to_number": this.selectedCustomer[0].mdm_sold_to_number
            };
            await this.saveCustomer(customer, "Chosen customer");
            this.$root.$emit("submitBaseForm", false, true);
        },
        async saveCustomer (customer, toastMessage) {
            await this.$store.dispatch(
                "applicationFormValues/saveMdmSearch",
                {
                    "mdm_search_status": customer.mdm_search_status,
                    "mdm_id": customer.mdm_sold_to_number,
                    "mdm_sold_to_number": customer.mdm_sold_to_number
                }
            );
            this.$toast(
                toastMessage,
                {
                    "color": "success"
                }
            );
            await this.$store.dispatch("mdg/openDialog", {"open": false, "dialog": "search"});
        },
        createQueryArgs () {
            let queryArgs = "";
            const columnNames = Object.keys(this.mdgFields);
            columnNames.forEach((fieldName) => {
                const fields = this.mdgFields[fieldName];
                if (fields.ticked && fields.value) {
                    queryArgs = queryArgs.concat(`${fieldName}: "${fields.value}"`);
                }
            });

            return queryArgs;
        },
        createQueryFields () {
            let queryFields = "";
            this.headers.forEach((header) => {
                queryFields = queryFields.concat(`${header.value} `);
            });
            return queryFields;
        }
    },
    "watch": {
        "isDialogOpen" () {
            if (this.isDialogOpen) {
                this.fillFields();
                this.$apollo.queries.getMdgCustomerSearch.skip = this.mdgFields.dnb_duns_number.value === null;
            }
        }
    },
    "apollo": {
        "getMdgCustomerSearch": {
            query () {
                this.loading = true;
                const queryArgs = this.createQueryArgs();
                const queryFields = this.createQueryFields();
                return gql`query {mdg_customer_search (${queryArgs}) {data
                   { ${queryFields} } responseString error}}`;
            },
            update (data) {
                this.customers = data.mdg_customer_search.data;
                this.$apollo.queries.getMdgCustomerSearch.skip = true;
                this.searched = true;
                this.loading = false;
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
