<template>
    <v-container fluid class="pl-0 pa-0">
        <v-row>
            <v-col class="pa-0">
                <v-data-table
                    sort-by="modified_at"
                    :sort-desc="true"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    :headers="headers"
                    :items="auditLogs"
                    dense
                    class="pl-3 mt-10">
                    <template v-slot:item.modified_by_type="{ item }">
                        <div class="audit-log-row"> {{ item.modified_by_type }} </div>
                    </template>
                    <template v-slot:item.modified_by="{ item }">
                        <div class="text-truncate audit-log-row" style="max-width: 25vw">
                            {{ item.modified_by }}
                        </div>
                    </template>
                    <template v-slot:item.modified_at="{ item }">
                        <div class="audit-log-row">
                            {{ item.modified_at|formatDateTimeSecond }}
                        </div>
                    </template>
                    <template v-slot:item.field="{ item }">
                        <div class="audit-log-row"> {{ item.field }} </div>
                    </template>
                    <template v-slot:item.new_value="{ item }">
                        <div class="audit-log-row"> {{ cutJsonStrings(item.new_value) }} </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>
.audit-log-row {
    font-size: x-small;
}
.audit-log-header {
    font-size: x-small !important;
}
</style>

<script>
import importedHeaders from "./headers/AuditLogApplicationHeaders.json";

export default {
    "computed": {
        auditLogs () {
            return this.auditLogData;
        }
    },
    "name": "AuditLogApplication",
    "props": {"dialog": {"default": false}},
    async created () {
        this.auditLogData = [];
        await this.$store.dispatch("auditLogs/setAuditLogs", this.$route.params.id);
        if (!this.$store.state.auditLogs.auditLogs) {
            this.auditLogData = [];
        } else {
            this.auditLogData = this.$store.state.auditLogs.auditLogs;
        }
    },
    "methods": {
        cutJsonStrings (str) {
            if (str?.includes("{")) {
                return `${str.substring(0, 15)}...`;
            }
            return str;
        }
    },
    "data" () {
        return {
            "auditLogData": null,
            "headers": importedHeaders
        };
    }
};
</script>
