var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","loading":_vm.loading,"headers":_vm.headersWithActions,"items":_vm.legalRepresentatives,"disable-pagination":true,"hide-default-footer":true,"item-key":"id","id":"legal_representative_data_table"},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" ")]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.addres_line_1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.addres_line_1)+" ")]}},{key:"item.address_city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address_city)+" ")]}},{key:"item.address_postal_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address_postal_code)+" ")]}},{key:"item.address_country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address_country)+" ")]}},{key:"item.is_beneficial_owner",fn:function(ref){
var item = ref.item;
return [(item.is_beneficial_owner === 1)?_c('v-icon',{attrs:{"color":"green","dense":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.is_signee",fn:function(ref){
var item = ref.item;
return [(item.is_signee === 1)?_c('v-icon',{attrs:{"color":"green","dense":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.is_legal_representative",fn:function(ref){
var item = ref.item;
return [(item.is_legal_representative === 1)?_c('v-icon',{attrs:{"color":"green","dense":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"id":("open_document_person_btn" + (item.id)),"text":"","icon":"","fab":"","small":"","color":"primary","disabled":!_vm.canEdit},on:{"click":function($event){return _vm.openDocumentPersonDialog(item, false)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasBusiness},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_c('v-btn',{staticClass:"text-capitalize mr-5",attrs:{"id":"add_document_person_btn","disabled":!_vm.hasBusiness,"right":"","rounded":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openDocumentPersonDialog({'business_id': _vm.businessId}, true)}}},[_vm._v("Add "),(!_vm.hasBusiness)?_c('v-icon',[_vm._v("mdi-information")]):_vm._e()],1)],1)]}}])},[_c('span',[_vm._v("Unavailable due to absence of a solaris bank business. Save the application to create one.")])])],1),_c('DocumentPersonDialog',{attrs:{"EditDocuPersonDialog":_vm.dialogOpen,"items":_vm.item,"isNew":_vm.isNew,"tableHeaders":this.field.tableHeaders},on:{"update:EditDocuPersonDialog":function($event){_vm.dialogOpen=$event},"update:edit-docu-person-dialog":function($event){_vm.dialogOpen=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }