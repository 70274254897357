<template>
    <v-dialog scrollable :value="value" persistent max-width="90vw">
        <v-card id="event-log-card" height="90vh">
            <v-card-title>
                <v-col lg="10">
                    Solaris KYC event log
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                           data-nw="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-data-table
                    sort-by="created"
                    :sort-desc="true"
                    :headers="headers"
                    :hide-default-footer="true"
                    :items-per-page="-1"
                    no-data-text="No event data available"
                    :items="events">
                    <template v-slot:item.created="{ item }">
                        {{item.created|formatDateTimeSecond}}
                    </template>
                    <template v-slot:item.status="{ item }">
                        <v-chip :color="getStatusColor(item.status)" text-color="white">
                            {{item.status}}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {getStatusColor} from "@/utils/idNow";
import gql from "graphql-tag";
import importedHeaders from "@/components/views/dialogs/headers/SolarisEventLogDialogHeaders.json";


export default {
    "name": "SolarisEventLogDialog",
    "props": {
        "value": {
            "default": false
        }
    },
    "data" () {
        return {
            "events": [],
            "headers": importedHeaders
        };
    },
    "computed": {
    },
    "methods": {
        getStatusColor,
        closeDialog () {
            this.$emit("input", false);
        }
    },
    "watch": {
        modelValue (isOpen) {
            if (isOpen) {
                this.$apollo.queries.log.refetch();
            }
        }
    },
    "apollo": {
        "events": {
            query () {
                return gql`query solarisKycLog($form_id: Int!){
                    solaris_bank_kyc_log(form_id: $form_id){
                        data{
                            id,
                            status,
                            solaris_bank_person {
                                reference
                            },
                            solaris_bank_kyc_event_failure {
                                value,
                                description
                            },
                            created
                        }
                    }
                }`;
            },
            variables () {
                return {
                    "form_id": Number(this.$route.params.id)
                };
            },
            skip () {
                return typeof this.$route.params.id === "undefined";
            },
            "update": (data) => data?.solaris_bank_kyc_log?.data.map((logEvent) => ({
                "created": logEvent?.created,
                "status": logEvent?.status,
                "person_reference": logEvent?.solaris_bank_person?.reference,
                "failure": logEvent?.solaris_bank_kyc_event_failure?.value,
                "failure_description": logEvent?.solaris_bank_kyc_event_failure?.description
            })) || []
        }
    }
};
</script>
