<template>
    <v-data-table
        :loading="loading"
        :items="getFleetFields"
        :headers="headers"
        hide-default-footer
        disable-pagination
        item-key="id"
        id="fleet-field-list"
    >
        <template slot="no-data">
            <v-alert :value="true" color="background-grey">
                Fleet is empty
            </v-alert>
        </template>
        <template v-slot:item.value="{ item }">
            <v-edit-dialog
                v-if="!item.reference"
                @save="saveAmount(item)"
                @cancel="amount = null"
                persistent
                large
            >
                {{ item.value }}
                <template v-slot:input>
                    <v-text-field
                        v-model="amount"
                        class="mr-5"
                        label="Amount"
                        type="number"
                    ></v-text-field>
                </template>
            </v-edit-dialog>
            <div v-else>
                {{ item.value }}
            </div>
        </template>
    </v-data-table>
</template>

<script>
import gql from "graphql-tag";
import importedHeaders from "@/components/form/elements/headers/FleetFieldListHeaders.json";

export default {
    "data" () {
        return {
            "loading": true,
            "amount": null,
            "headers": importedHeaders
        };
    },
    "created" () {
        this.getQuery();
    },
    "watch": {
        values () {
            this.getQuery();
        }
    },
    "apollo": {
        "fetchFleetFields": {
            query () {
                return gql(`query {fleet_field(form_id: ${this.$route.params.id})
                {data {id, field_name, value, sf_export_field} }}`);
            },
            update (data) {
                if (!this.$apollo.queries.fetchFleetFields.loading) {
                    this.$store.dispatch("fleetField/setFleetFields", data.fleet_field.data);
                    this.loading = false;
                }
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "methods": {
        saveAmount (item) {
            if (this.sendToSalesforce) {
                const modifiedFleetField = {
                    "id": item.id,
                    "value": parseInt(this.amount, 10)
                };
                this.$store.dispatch("fleetField/saveAmount", modifiedFleetField);
                this.amount = null;
            } else {
                const modifiedFleetField = {
                    "sf_export_field": item.sf_export_field,
                    "value": parseInt(this.amount, 10)
                };
                this.$store.dispatch("applicationFormValues/saveFleetAmount", modifiedFleetField);
                this.amount = null;
            }
        },
        getQuery () {
            if (this.sendToSalesforce === 1 && this.$route.params.id) {
                this.$apollo.queries.fetchFleetFields.skip = false;
            }
        },
        setLoading (loadBoolean) {
            this.loading = loadBoolean;
        }
    },
    "computed": {
        getFleetFields () {
            this.setLoading(false);
            if (this.sendToSalesforce === null || typeof this.sendToSalesforce === "undefined") {
                return [];
            }
            if (this.sendToSalesforce) {
                return this.$store.state.fleetField.fleetFields;
            }
            return this.$store.getters["applicationFormValues/fleet"];
        },
        sendToSalesforce () {
            return this.$store.state.applicationFormValues.values.send_to_salesforce;
        },
        values () {
            return this.$store.state.applicationFormValues.values;
        }
    }
};
</script>
