<template>
    <v-container fluid v-if="$can('update', 'user')">
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="User"
            data-nw="editUserHeader"
        >
            <template v-slot:button>
                <LogDailog
                    label="Data Logs"
                    :id="userId"
                    table="user"
                    v-if="$can('log', 'user')"
                />
                <v-btn
                    id="submit-user-button"
                    class="text-capitalize btn-block"
                    rounded color="primary"
                    @click="submitUserForm()"
                    data-nw="saveUser"
                >
                    Save User
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
            type="update"
            store-module="userFormValues"
            :form-segments="formSegments"
            :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import LogDailog from "../dialogs/LogDialog";
import editUserForm from "@/formConfig/editUserForm";
import gql from "graphql-tag";
import lodash from "lodash";

export default {
    "name": "EditUser",
    "components": {
        BaseForm,
        HeaderBar,
        LogDailog
    },
    "methods": {
        submitUserForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        userId () {
            return this.$store.state.userFormValues.values.id;
        },
        formSegments () {
            return this.$store.getters.getFormSegments("userForm");
        }
    },
    data () {
        return {
            "logDialog": false,
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Users",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListUsers"}
                },
                {
                    "text": "Edit User",
                    "disabled": true
                }
            ]
        };
    },
    "apollo": {
        "userFormValues": {
            query () {
                let queryParameters = "";
                const segment = editUserForm.formSegments[0];
                Object.keys(segment.fields).filter((field) => field !== "password").forEach((field) => {
                    queryParameters = queryParameters.concat(
                        " ",
                        field
                    );
                });
                const visibilityFields = this.$store.getters["formSegments/getVisibilityFields"](segment);
                if (visibilityFields.length) {
                    queryParameters = queryParameters.concat(
                        " ",
                        visibilityFields.join(" ")
                    );
                }
                return gql`query {userFormValues: user (id: ${this.$route.params.id}) {data{id ${queryParameters}}}}`;
            },
            update (data) {
                this.$store.dispatch(
                    "userFormValues/setFormValues",
                    data.userFormValues.data[0]
                );
                this.$store.dispatch(
                    "userFormValues/setUserIdValue",
                    data.userFormValues.data[0].id
                );
            },
            "fetchPolicy": "no-cache"
        },
        "userGroupFormValues": {
            query () {
                return gql`
                    query
                        { userGroupFormValues: acl_group_user ( first: 10000, user_id: ${this.$route.params.id} )
                        { data{ aclgroup_id }}}`;
            },
            update (data) {
                const aclGroupIds = {
                    "aclgroup_ids": lodash.map(
                        data.userGroupFormValues.data,
                        "aclgroup_id"
                    )
                };
                this.$store.dispatch(
                    "userFormValues/setGroupIds",
                    aclGroupIds
                );
            },
            "fetchPolicy": "no-cache"
        }
    },

    created () {
        this.$store.dispatch("userFormValues/deleteFormValues");
    }
};
</script>
