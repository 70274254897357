const requiredFieldsForSignee = [
    "first_name",
    "last_name",
    "phone_number_country_code",
    "phone_number",
    "job_function",
    "email",
    "marketing_agree"
];

function signeeRequiredFields (field) {
    return requiredFieldsForSignee.includes(field);
}

function isMarketingAgreeValid (data, field) {
    return field === "marketing_agree" && (data.marketing_agree ?? null) !== null;
}

export function signeeRulesValidator (data, field) {
    if (!data.isSignee || !signeeRequiredFields(field)) {
        return true;
    }

    return isMarketingAgreeValid(data, field);
}

export function isSigneeRequiredFieldsGiven (data, field, rules) {
    if (!signeeRulesValidator(data.tableData, field) && data.validationHardRules.required) {
        rules.push(...data.validationHardRules.required);
    }
}

