<template>
    <v-col :id="'form_segment_'+segment.id" class="form_segment" lg="12">
        <h4>{{ segment.title }}</h4>
        <VCardWithBorderColor :segment="segment">
            <v-layout wrap>
                <v-flex
                    v-for="(field, key) in segment.fields"
                    :id="'element_'+key"
                    :key="key"
                    :class="'form_element element_type_'+field.type+' xs'+field.flex+' '+field.class"
                >
                    <v-flex v-if="visibleCondition(field)">
                        <DocumentsList
                            v-if="field.type === 'documents_list'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <AddDocumentButton
                            v-if="field.type === 'add_document_button'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <TextField
                            v-if="field.type === 'text' || field.type === 'password'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :pageType="pageType"
                            :segment="segment"
                            :store-module="storeModule"
                            :type="field.type"
                        />

                        <CocField
                            v-if="field.type === 'coc'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                            :type="field.type"
                        />

                        <VatField
                            v-if="field.type === 'vat'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <SelectComponent
                            v-if="field.type === 'select'"
                            :default-value="field.defaultValue"
                            :default-val-getter="field.defaultValGetter"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <RadioGroup
                            v-if="field.type === 'radio'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <Checkbox
                            v-if="field.type === 'checkbox'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <PlainText
                            v-if="field.type === 'plain_text'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <Url
                            v-if="field.type === 'url'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <Date
                            v-if="field.type === 'date'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <ButtonComponent
                            v-if="field.type === 'button'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <GoogleMapsButton
                            v-if="field.type === 'google_maps_button'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <ShareholdersList
                            v-if="field.type === 'shareholder_list'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <DocumentPersons
                            v-if="field.type === 'document_persons_list'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <FormAddressList
                            v-if="field.type === 'form_address_list'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <QuickLinks
                            v-if="field.type === 'quick_links'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <FeesList
                            v-if="field.type === 'fee_list'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <CardsList
                            v-if="field.type === 'card_list'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <IndividualsList
                            v-if="field.type === 'individual_list'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <PaymentMechanismsList
                            v-if="field.type === 'payment_mechanism_list'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <UserTelesalesList
                            v-if="field.type === 'user_telesales_list'"
                            :segment="segment"
                        />

                        <SiteLookupButton
                            v-if="field.type === 'site_lookup_button' && $can('read', 'sites')"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <MdmInfoButton
                            v-if="field.type === 'mdm_info_button' && $can('mdmInfo', 'applications')"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <AclGroupTreeView
                            v-if="field.type === 'group_treeview'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <CategoryTreeView
                            v-if="field.type === 'category_treeview'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <CampaignTreeView
                            v-else-if="field.type === 'campaign_treeview'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <DnbLookupButton
                            v-if="field.type === 'dnb_lookup_button' && $can('dunsLookup', 'applications')"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <FileUpload
                            v-if="field.type === 'file_upload'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <PreviewUploadButton
                            v-if="field.type === 'preview_button'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <SlimpayPreviewSelect
                            v-if="field.type === 'slimpay_preview'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <MandatePreviewButton
                            v-if="field.type === 'mandate_preview'"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <QuotationPreviewButton
                            v-if="field.type === 'quotation_preview'"
                            :name="key"
                            :store-module="storeModule"
                        />

                        <FraudCheckButton
                            v-if="field.type === 'fraud_button' && $can('senseChecks', 'applications')"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <ChangeTemplateButton
                            v-if="field.type === 'change_template_button'
                                && $can('changeApplicationTemplate', 'applications')"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <ChangeCampaignButton
                            v-if="field.type === 'change_campaign_button'
                                && $can('changeApplicationTemplate', 'applications')"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <StatusSelect
                            v-if="field.type === 'status_select'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <TaskList
                            v-if="field.type === 'task_list'"
                            :field="field"
                            :last-updated="lastUpdated"
                            :store-module="storeModule"
                        />

                        <TextArea
                            v-if="field.type === 'text_area'"
                            :editable="editable"
                            :field="field"
                            :name="key"
                            :number-of-rows="field.numberOfRows"
                            :segment="segment"
                            :store-module="storeModule"
                        />

                        <BankSecurityPreviewButton
                            v-if="field.type === 'bank_security_preview'"
                            :name="key"
                            :store-module="storeModule"
                        />

                        <SalesforceFields
                            v-if="field.type === 'salesforce_fields'"
                            :field="field"
                            :fields="field.fields"
                            :name="key"
                        />

                        <CompanySearch
                            v-if="field.type === 'company_search'"
                            :name="key"
                        />

                        <Spacing
                            v-if="field.type === 'spacing'"
                        />

                        <FleetFieldList
                            v-if="field.type === 'fleet_field_list'"
                        />

                        <MdgDialogButton
                            v-if="field.type === 'mdg_dialog_button'"
                            :field="field"
                        />

                        <SalesforceAccountSearchButton
                            v-if="field.type === 'salesforce_account_search_dialog_button'
                            && salesforceAccountSearchButtonEnabled === 'true'"
                            :field="field"
                        />

                        <SolarisButtons
                            v-if="field.type === 'solaris_buttons'"
                        />

                        <SendGuaranteeMailButton
                            v-if="field.type === 'send_guarantee_mail_button'"
                        />
                    </v-flex>
                </v-flex>
            </v-layout>
        </VCardWithBorderColor>
    </v-col>
</template>

<script>
/* eslint max-lines: off */
import AclGroupTreeView from "@/components/form/elements/AclGroupTreeView";
import AddDocumentButton from "../form/elements/AddDocumentButton";
import BankSecurityPreviewButton from "../form/elements/BankSecurityPreviewButton";
import ButtonComponent from "../form/elements/Button";
import CampaignTreeView from "@/components/form/elements/CampaignTreeView.vue";
import CardsList from "../form/elements/CardsList";
import CategoryTreeView from "../form/elements/CategoryTreeView";
import ChangeCampaignButton from "@/components/form/elements/ChangeCampaignButton.vue";
import ChangeTemplateButton from "../form/elements/ChangeTemplateButton";
import Checkbox from "../form/elements/Checkbox";
import CocField from "../form/elements/CocField.vue";
import CompanySearch from "../form/elements/Companysearch";
import Date from "../form/elements/Date";
import DnbLookupButton from "../form/elements/DnbLookupButton";
import DocumentPersons from "../form/elements/DocumentPersons";
import DocumentsList from "../form/elements/DocumentsList";
import FeesList from "../form/elements/FeesList";
import FileUpload from "../form/elements/FileUpload";
import FleetFieldList from "@/components/form/elements/FleetFieldList";
import FormAddressList from "../form/elements/FormAddressList";
import FraudCheckButton from "../form/elements/FraudCheckButton";
import GoogleMapsButton from "../form/elements/GoogleMapsButton";
import IndividualsList from "@/components/form/elements/IndividualsList.vue";
import MandatePreviewButton from "../form/elements/MandatePreviewButton";
import MdgDialogButton from "@/components/form/elements/MdgDialogButton";
import MdmInfoButton from "../form/elements/MdmInfoButton";
import PaymentMechanismsList from "@/components/form/elements/PaymentMechanismsList.vue";
import PlainText from "../form/elements/PlainText";
import PreviewUploadButton from "../form/elements/PreviewUploadButton";
import QuickLinks from "../form/elements/QuickLinks";
import QuotationPreviewButton from "../form/elements/QuotationPreviewButton";
import RadioGroup from "../form/elements/RadioGroup";
import SalesforceAccountSearchButton from "../form/elements/SalesforceAccountSearchButton";
import SalesforceFields from "../form/elements/SalesforceFields";
import SelectComponent from "../form/elements/Select";
import SendGuaranteeMailButton from "../form/elements/SendGuaranteeMailButton";
import ShareholdersList from "../form/elements/ShareholdersList";
import SiteLookupButton from "../form/elements/SiteLookupButton";
import SlimpayPreviewSelect from "../form/elements/SlimpayPreviewSelect";
import SolarisButtons from "@/components/form/elements/SolarisButtons.vue";
import Spacing from "../form/elements/Spacing";
import StatusSelect from "../form/elements/StatusSelect";
import TaskList from "../form/elements/TaskList";
import TextArea from "../form/elements/TextArea";
import TextField from "../form/elements/TextField";
import Url from "../form/elements/Url";
import UserTelesalesList from "../form/elements/UserTelesalesList";
import VCardWithBorderColor from "../uiElements/VCardWithBorderColor";
import VatField from "../form/elements/VatField";
import getEnv from "../../utils/env";

export default {
    "components": {
        IndividualsList,
        PaymentMechanismsList,
        AclGroupTreeView,
        CampaignTreeView,
        MdgDialogButton,
        FleetFieldList,
        AddDocumentButton,
        BankSecurityPreviewButton,
        SlimpayPreviewSelect,
        MandatePreviewButton,
        UserTelesalesList,
        ButtonComponent,
        CardsList,
        CategoryTreeView,
        ChangeTemplateButton,
        ChangeCampaignButton,
        Checkbox,
        Date,
        DnbLookupButton,
        DocumentPersons,
        FormAddressList,
        FeesList,
        FileUpload,
        FraudCheckButton,
        GoogleMapsButton,
        MdmInfoButton,
        ShareholdersList,
        SiteLookupButton,
        QuickLinks,
        RadioGroup,
        PlainText,
        PreviewUploadButton,
        SelectComponent,
        TaskList,
        TextArea,
        TextField,
        VatField,
        VCardWithBorderColor,
        StatusSelect,
        Spacing,
        QuotationPreviewButton,
        CompanySearch,
        DocumentsList,
        SalesforceFields,
        CocField,
        SolarisButtons,
        SendGuaranteeMailButton,
        Url,
        SalesforceAccountSearchButton
    },
    data () {
        return {
            "salesforceAccountSearchButtonEnabled": false
        };
    },
    "methods": {
        visibleCondition (field) {
            let visible = true;
            if (typeof field !== "undefined" && field.fieldVisibilityCondition) {
                visible = field.fieldVisibilityCondition.all.every((condition) => {
                    const fieldValue = this.$store.state.applicationFormValues.values[condition.field];
                    if (typeof fieldValue !== "undefined") {
                        visible = this.getVisibleCondResult(condition, fieldValue);
                    } else {
                        visible = true;
                    }
                    return visible;
                });
            }
            return visible;
        },
        getVisibleCondResult (condition, fieldValue) {
            const value = fieldValue;
            let result = null;
            const operators = {
                "===": () => value === condition.value,
                "!==": () => value !== condition.value,
                "<": () => value < condition.value,
                "<=": () => value <= condition.value,
                ">": () => value > condition.value,
                ">=": () => value >= condition.value,
                "length_lt": () => value?.length < condition.value,
                "length_lte": () => value?.length <= condition.value,
                "length_eq": () => value?.length === condition.value,
                "length_gte": () => value?.length >= condition.value,
                "length_gt": () => value?.length > condition.value
            };
            if (condition.operator in operators) {
                result = operators[condition.operator]();
            }
            return result;
        }
    },
    "created" () {
        this.visibleCondition();
        this.salesforceAccountSearchButtonEnabled = getEnv("VUE_APP_SALESFORCE_ACCOUNT_SEARCH_ENABLED");
    },
    "props": ["segment", "storeModule", "editable", "pageType", "lastUpdated"]
};
</script>
