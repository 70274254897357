<template>
    <ul v-if="countryCode" class="list-unstyled">
        <li v-for="(link, index) in field.items[countryCode]" :key="index">
            <a :href="link.href" target="_blank">{{link.text}}</a>
        </li>
    </ul>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";

export default {
    data () {
        return {"countryCode": null};
    },
    "computed": {
        categoryId () {
            return this.$store.state.applicationFormValues.values.category_id;
        }
    },
    "watch": {
        categoryId () {
            const category = this.$store.getters["categories/getFormCategory"](this.categoryId);
            if (typeof category !== "undefined") {
                const country = this.$store.getters["countries/getCountry"](category.country_id);
                this.countryCode = country.iso_country_code;
            }
        }
    },
    "extends": BaseElement,
    "components": {}
};

</script>
