<template>
    <v-container fluid v-if="$can('create', 'option_partner_zr')">
        <HeaderBar
                :breadcrumbs="breadcrumbs"
                title="New ZR Payer"
        >
            <template v-slot:button>
                <v-btn
                        class="text-capitalize btn-block"
                        rounded color="primary"
                        @click="sumbitZRPayerForm()">
                    Save ZR Payer Partner
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
                type="create"
                store-module="zrPayerFormValues"
                :form-segments=formSegments
                :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../../baseComponents/BaseForm";
import HeaderBar from "../../../uiElements/HeaderBar";

export default {
    "name": "NewUser",
    "components": {
        BaseForm,
        HeaderBar
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "ZR Payers",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListZRPayers"}
                },
                {
                    "text": "New ZR Payer",
                    "disabled": true
                }
            ]
        };
    },
    "methods": {
        sumbitZRPayerForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.zrPayerFormSegments;
        }
    },
    created () {
        this.$store.dispatch("zrPayerFormValues/deleteFormValues");
    }
};
</script>
