import Vue from "vue";
const state = {"loading": false};

const mutations = {
    SET_LOADING (currentState, value) {
        Vue.set(currentState, "loading", value ? value : false);
    }
};

const getters = {};

const actions = {
    async setLoading ({commit}, value) {
        await commit(
            "SET_LOADING",
            value
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
