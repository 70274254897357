<template>
    <v-container fluid v-if="$can('create', 'acl_group')">
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="New Group"
        >
            <template v-slot:button>
                <v-btn
                    class="text-capitalize btn-block"
                    rounded color="primary"
                    @click="submitAclGroupForm()">
                    Save Group
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
            type="create"
            store-module="groupFormValues"
            :form-segments=formSegments
            :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";

export default {
    "name": "NewGroup",
    "components": {
        BaseForm,
        HeaderBar
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Groups",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListGroups"}
                },
                {
                    "text": "New Group",
                    "disabled": true
                }
            ]
        };
    },
    "methods": {
        submitAclGroupForm () {
            this.$root.$emit("submitBaseForm");

        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.groupFormSegments;
        }
    },
    created () {
        this.$store.dispatch("groupFormValues/deleteFormValues");
    }
};
</script>
