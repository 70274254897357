<template>
    <div>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="getDocuments"
            item-key="id"
            id="documents_data_table"
            hide-default-footer
            class="table-cursor"
            disable-pagination>
            <template slot="no-data">
                <v-alert :value="true" color="background-grey">
                    Form has no documents
                </v-alert>
            </template>
            <template v-slot:item.is_required="{ item }">
                <v-icon v-if="item.is_required === 'Required' && item.name === 'Missing file'" color="red" dense>
                    mdi-close-circle
                </v-icon>
                <v-icon v-else-if="item.is_required === 'Required'" color="green" dense>mdi-check-circle</v-icon>
            </template>
            <template v-slot:item.name="{ item }">
                <v-edit-dialog
                    v-if="!item.reference"
                    @save="saveFile(item)"
                    @cancel="newFile = null; NTid = null"
                    persistent
                    large
                >
                    {{ item.name }}
                    <template v-slot:input>
                        <v-file-input
                            v-model="newFile"
                            label="File"
                            accept=".pdf,.doc,.docx"
                            :rules="documentRules"
                            :clearable="false"
                        ></v-file-input>
                        <v-text-field
                            v-if="item.document_solaris_name === 'ID_DOCUMENT'"
                            v-model="NTid"
                            class="mr-5"
                            label="NTid"
                        ></v-text-field>
                    </template>
                </v-edit-dialog>
                <div v-else>
                    {{ item.name }}
                </div>
            </template>
            <template v-slot:item.controls="{ item }">
                <template v-if="item.name !== 'Missing file'">
                    <v-icon
                        medium
                        color="primary"
                        @click="openDocument(item)"
                    >
                        mdi-eye
                    </v-icon>
                </template>
            </template>
        </v-data-table>

        <DocumentWarningDialog/>
        <DocumentDialog
            :docu="clickedDocu">
        </DocumentDialog>
    </div>
</template>

<script>
import DocumentDialog from "../../views/dialogs/DocumentDialog";
import DocumentWarningDialog from "../../views/dialogs/DocumentWarningDialog";
import gql from "graphql-tag";
import importedHeaders from "@/components/form/elements/headers/DocumentsListHeaders.json";

export default {
    "components": {
        DocumentWarningDialog,
        DocumentDialog
    },
    "mounted" () {
        // eslint-disable-next-line no-unused-expressions
        this.$route.params.id ? this.$apollo.queries.getSolarisId.skip = false : this.loading = false;

        this.$store.dispatch("solarisDocuments/clearDocuments");
        this.$store.dispatch("legalTypes/getLegalTypes");
    },
    "data" () {
        return {
            "loading": true,
            "clickedDocu": {},
            "businessId": null,
            "newFile": null,
            "legalTypeFromForm": null,
            "NTid": null,
            "showNTid": false,
            "documentRules": [
                (v) => !v || v.name.endsWith(".docx") || v.name.endsWith(".doc") || v.name.endsWith(".pdf") || "Wrong document type",
                (v) => !v || v.size < 5000000 || "Document should be less than 5 MB"
            ],
            "headers": importedHeaders
        };
    },
    "watch": {
        "getLegalTypeFromForm": {
            handler () {
                if (this.getLegalTypeFromForm && this.getPossibleLegalTypes.length > 0) {
                    this.legalTypeFromForm = this.getLegalTypeFromForm;
                    this.fetchDocuments();
                }
            }
        },
        "getPossibleLegalTypes": {
            handler () {
                if (this.getLegalTypeFromForm && this.getPossibleLegalTypes.length > 0) {
                    this.legalTypeFromForm = this.getLegalTypeFromForm;
                    this.fetchDocuments();
                }
            }
        }
    },
    "apollo": {
        "getDocumentTypes": {
            query () {
                return gql`query {document_type (legal_form_id: ${this.legalTypeFromForm})
                {data {document_type_id document_translation is_required is_sensitive document_solaris_name}}}`;
            },
            update (data) {
                this.$store.dispatch("solarisDocuments/setDocumentTypes", data.document_type.data).then(() => {
                    this.checkMissingDocuments();
                });
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        },
        "getSolarisId": {
            query () {
                return gql`query {getSolarisId:
                                solaris_bank_business(form_id: ${this.$route.params.id}) {data {id}}}`;
            },
            update (data) {
                if (!this.$apollo.queries.getSolarisId.loading) {
                    if (data.getSolarisId.data.length !== 0) {
                        this.businessId = data.getSolarisId.data[0].id;
                        this.$apollo.queries.getSolarisDocuments.skip = false;
                    } else {
                        this.loading = false;
                    }
                }
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        },
        "getSolarisDocuments": {
            query () {
                if (this.businessId !== null) {
                    return gql`query {getSolarisDocuments: solaris_bank_business_document
                          (business_id: ${this.businessId}){data {id name reference business_document_type document_type
                             {document_translation is_required is_sensitive document_solaris_name}}}}`;
                }
                return "";
            },
            update (data) {
                if (!this.$apollo.queries.getSolarisDocuments.loading) {
                    const documents = [];
                    data.getSolarisDocuments.data.forEach((docu) => {
                        const tempDocu = {
                            "id": docu.id,
                            "name": docu.name,
                            "document_type": docu.document_type.document_translation,
                            "is_sensitive": docu.document_type.is_sensitive,
                            "is_required": docu.document_type.is_required ? "Required" : "",
                            "business_document_type": docu.business_document_type,
                            "reference": docu.reference,
                            "business_id": this.businessId,
                            "document_solaris_name": docu.document_type.document_solaris_name
                        };
                        documents.push(tempDocu);
                    });
                    this.$store.dispatch("solarisDocuments/setDocuments", documents);

                    this.loading = false;
                }
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "methods": {
        "fetchDocuments" () {
            const hasSolarisBankBusiness = this.$store.state.solarisBank.solarisBankBusiness?.reference ?? null;
            if (this.getLegalTypeFromForm && this.getPossibleLegalTypes.length > 0) {
                this.legalTypeFromForm = this.getLegalTypeFromForm;
                if (hasSolarisBankBusiness !== null) {
                    this.$apollo.queries.getDocumentTypes.skip = false;
                } else {
                    this.$store.dispatch("solarisDocuments/clearDocuments");
                }
            }
        },
        "openDocument" (item) {
            if (item.name.endsWith(".docx") || item.name.endsWith(".doc") || item.name.endsWith(".pdf")) {
                if (item.is_sensitive) {
                    this.$store.dispatch("solarisDocuments/setWarningDialog", true);
                } else {
                    this.$store.dispatch("solarisDocuments/setDocumentDialog", true);
                }
                this.clickedDocu = item;
            } else {
                this.$toast("File doesn't exist", {"color": "error"});
            }
        },
        "checkMissingDocuments" () {
            for (let ind = 0; ind < this.getDocuments.length; ind++) {
                if (this.getDocuments[ind].name === "Missing file") {
                    this.$store.dispatch("solarisDocuments/deleteDocumentByIndex", ind);
                }
            }
            this.getAllDocumentTypes.forEach((docuType) => {
                let exists = false;
                // eslint-disable-next-line no-redeclare
                const docu = this.getDocuments.find((_docu) => docuType.value === _docu.business_document_type);
                if (docu) {
                    exists = true;
                }
                if (!exists) {
                    const isRequired = docuType.is_required ? "Required" : "";

                    this.createAndAddDocument({
                        "text": docuType.text,
                        "value": docuType.value,
                        isRequired,
                        "is_sensitive": docuType.is_sensitive,
                        "document_solaris_name": docuType.document_solaris_name
                    });
                }
            });
            this.loading = false;
        },
        "saveFile" (item) {
            if (this.newFile && (this.newFile.name.endsWith(".docx") || this.newFile.name.endsWith(".doc") || this.newFile.name.endsWith(".pdf"))) {
                const updatedDocument = {
                    "id": item.id,
                    "name": this.newFile.name
                };
                if (item.document_solaris_name === "ID_DOCUMENT" && this.NTid) {
                    // eslint-disable-next-line camelcase
                    updatedDocument.verified_by = this.NTid;
                } else if (item.document_solaris_name === "ID_DOCUMENT" && !this.NTid) {
                    this.$toast("NTid is missing", {"color": "error"});
                    this.newFile = null;
                    return;
                }
                const fileReader = new FileReader();
                fileReader.readAsDataURL(this.newFile);
                fileReader.onload = () => {
                    updatedDocument.mimetype = this.newFile.type;
                    updatedDocument.size = this.newFile.size;
                    // eslint-disable-next-line camelcase
                    updatedDocument.file_content = fileReader.result.split(",")[1];
                    this.$store.dispatch("solarisDocuments/setDocumentById", updatedDocument);
                    this.NTid = null;
                    this.newFile = null;
                };
            } else {
                this.NTid = null;
                this.newFile = null;
                this.$toast("File type is incorrect or file is missing", {"color": "error"});
            }
        },
        "createAndAddDocument" (docu) {
            const tempDocu = {
                "business_id": this.businessId,
                "document_type": docu.text,
                "business_document_type": docu.value,
                "name": "Missing file",
                "is_required": docu.isRequired,
                "is_sensitive": docu.isSensitive,
                "document_solaris_name": docu.document_solaris_name
            };
            this.$store.dispatch(
                "solarisDocuments/addDocument",
                tempDocu
            );
        }
    },
    "computed": {
        warningOpen () {
            return this.$store.state.solarisDocuments.warningDialog;
        },
        getDocuments () {
            return this.$store.state.solarisDocuments.documents;
        },
        getLegalTypeFromForm () {
            return this.$store.state.applicationFormValues.values.legal_type_id;
        },
        getPossibleLegalTypes () {
            return this.$store.state.legalTypes.legalTypes;
        },
        getLoadingTypes () {
            return this.$store.state.solarisDocuments.loadingTypes;
        },
        getAllDocumentTypes () {
            return this.$store.state.solarisDocuments.documentTypes;
        }
    }
};
</script>
