<template>
    <v-textarea
        v-bind="field"
        @input="updateValue($event)"
        :value="formValue"
        :name="this.name"
        :rules="setRules(field.rules)"
        :label="field.label"
        :persistent-hint="true"
        :hint="getHint()"
        :ref="this.name"
        :data-nw="this.name"
        :type="this.type"
        :readonly="(field.permissioned ? !$can(field.ability.action, field.ability.object) : false) || field.readonly"
        :disabled="!canEdit"
        :rows="numberOfRows"
        outlined
    />
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";

export default {
    "extends": BaseElement,
    "props": ["numberOfRows"]
};
</script>
