<template>
  <v-container fluid v-if="$can('create', 'rebate')">
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="New rebate"
      data-nw="newRebateHeader"
    >
      <template v-slot:button>
        <v-btn
          id="submit-rebate-button"
          class="text-capitalize float-right"
          rounded color="primary"
          @click="submitRebateForm()">
          Save Rebate
        </v-btn>
      </template>
    </HeaderBar>
    <BaseForm
      type="create"
      store-module="rebateFormValues"
      :form-segments=formSegments
      :editable="true"
    />
  </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import editRebateForm from "../../../formConfig/editRebateForm";
import gql from "graphql-tag";

export default {
    "name": "NewRebate",
    "components": {
        BaseForm,
        HeaderBar
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Rebates",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListRebates"}
                },
                {
                    "text": "New rebate",
                    "disabled": true
                }
            ]
        };
    },
    "methods": {
        submitRebateForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.rebatesFormSegments;
        },
        siteId () {
            return this.$store.state.rebateFormValues.siteId;
        }
    },
    mounted () {
        this.$store.dispatch("rebateFormValues/deleteFormValues");
    },
    "apollo": {
        "siteFormValues": {
            query () {
                let queryParameters = "";
                Object.keys(editRebateForm.formSegments[2].fields).forEach((field) => {
                    if (!editRebateForm.formSegments[2].fields[field].exclude) {
                        queryParameters = queryParameters.concat(
                            " ",
                            field
                        );
                    }
                });
                return gql`query {siteFormValues: site (id: ${this.siteId} ) {data{id ${queryParameters}}}}`;
            },
            update (data) {
                this.$store.dispatch(
                    "rebateFormValues/setFormValues",
                    data.siteFormValues.data[0]
                );
            }
        }
    }
};
</script>
