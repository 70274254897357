import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {
    "legalTypes": []
};

const mutations = {
    SET_LEGAL_TYPES (currentState, legalTypes) {
        Vue.set(currentState, "legalTypes", legalTypes);
    },
    SET_COUNTRY_CODE (currentState, countryCode) {
        Vue.set(currentState, "countryCode", countryCode);
    }
};

/**
 * Get the selected country data using the rootGetters
 * @param rootGetters
 * @returns {*}
 */
function getCurrentCountryFromCategory (rootGetters) {
    const categories = rootGetters["categories/getFormCategory"];
    const countries = rootGetters["countries/getCountry"];
    const currentCategoryId = rootGetters["applicationFormValues/categoryId"];
    const categoryId = currentCategoryId();
    if (categoryId) {
        const category = categories(categoryId);
        if (category) {
            return countries(category.country_id);
        }
    }
    return null;
}

const getters = {
    "getLegalTypeDropdownOptionsByCountry": (currentState, currentGetters, rootState, rootGetters) => {
        const country = getCurrentCountryFromCategory(rootGetters);
        if (country) {
            return state.legalTypes.filter((legalType) => legalType.country_code === country.iso_country_code)
                .map((legalType) => ({
                    "value": legalType.legal_type_id,
                    "text": legalType.legal_type_translation
                }));
        }
        return [];
    }
};

const actions = {
    async getLegalTypes ({commit}) {
        const optionsGql = await gql`query{legal_type (first: -1){
                data{legal_type_id legal_type_translation country_code}}}`;
        const result = await apolloClient.query({"query": optionsGql});
        const legalTypes = result.data.legal_type.data;
        await commit(
            "SET_LEGAL_TYPES",
            legalTypes
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
