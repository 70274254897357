<template>
    <v-dialog
        v-model="getDialogOpen"
        fullscreen>
        <v-card height="100%">
            <v-card-title>
                <v-col lg="10">
                    <pre class="tab" style="font-family: Roboto, sans-serif"
                    ><b>Name: </b>{{ docu.name }}       <b>Type: </b>{{ docu.document_type }}
                    </pre>
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text style="padding-bottom: 0">
                <div>
                    <div v-if="loading && pdfContent === '' && wordContent === ''"
                    >
                        Loading...
                    </div>
                    <iframe
                        v-else-if="pdfContent !== ''"
                        class="iframe-class"
                        :src="pdfContent"
                        id="iframe">
                    </iframe>
                    <div v-else-if="wordContent !== ''">
                        <h4>File can't be previewed</h4>
                        <v-btn rounded
                               :href="wordContent"
                               target="_blank"
                               :download="docu.name"
                               color="primary">
                            download
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    "data" () {
        return {
            "loading": true,
            "wordContent": "",
            "pdfContent": "",
            "fileContent": ""
        };
    },
    "props": {
        "docu": {"default": {}}
    },
    "watch": {
        "getDialogOpen": {
            handler () {
                if (this.getDialogOpen) {
                    this.loading = true;
                    this.refetchContent();
                }
            }
        }
    },
    "methods": {
        closeDialog () {
            this.wordContent = "";
            this.pdfContent = "";
            this.$store.dispatch("solarisDocuments/setDocumentDialog", false);
        },
        dataURLtoFile (document) {
            const bstr = atob(document.content);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File(
                [u8arr],
                document.filename,
                {"type": document.mimetype}
            );
        },
        // eslint-disable-next-line max-statements
        refetchContent () {
            if (this.docu.file_content) {
                const file = this.$store.getters["solarisDocuments/getDocumentById"](this.docu.id).file_content;
                this.convertFile(file);
            } else {
                this.$apollo.queries.getDocumentContent.skip = false;
            }
        },
        convertFile (file) {
            if (this.docu.name.endsWith(".pdf")) {
                this.pdfContent = `data:application/pdf;base64,
                        ${encodeURI(file)}`;
            } else {
                const tempDocu = this.docu;
                tempDocu.content = file;
                const fileObject = this.dataURLtoFile(tempDocu);
                this.oldURL = "";
                this.wordContent = URL.createObjectURL(fileObject);
            }
            this.$apollo.queries.getDocumentContent.skip = true;
            this.loading = false;
        }
    },
    "apollo": {
        "getDocumentContent": {
            query () {
                return gql`query {solaris_bank_business_document(id: ${this.docu.id}) {data {file_content}}}`;
            },
            update (data) {
                if (data.solaris_bank_business_document.data[0]) {
                    this.convertFile(data.solaris_bank_business_document.data[0].file_content);
                }
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "computed": {
        getDialogOpen () {
            return this.$store.state.solarisDocuments.documentDialog;
        }
    }
};
</script>

<style type="scss">
.iframe-class {
    width: 100%;
    height: calc(100vh - 125px);
}
</style>
