<template>
  <v-btn
      id="quotation_preview_button"
      rounded color="primary"
      class="text-capitalize btn-block"
      @click="previewQuotationDocument"
      v-if="formId"
  >Preview quotation</v-btn>
</template>

<script>
import apolloClient from "../../../ApolloClient";
import gql from "graphql-tag";

export default {
    "components": {},
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        }
    },
    "methods": {
        async previewQuotationDocument () {
            this.$store.dispatch(
                "loader/setLoading",
                true
            );
            const query = gql` {previewQuotationDocument(formId: "${this.formId}"){
              document{content} responseString
            }}`;
            const result = await apolloClient.query({query});
            const pdfWindow = window.open("");
            pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,
            ${encodeURI(result.data.previewQuotationDocument.document.content)}'></iframe>`);
            pdfWindow.document.close();
            this.$store.dispatch(
                "loader/setLoading",
                false
            );
        }
    }
};

</script>

