<template>
    <v-dialog :value="isDialogOpen" max-width="1600" persistent>
        <v-card>
            <v-card-title>
                <v-col lg="10">
                    MDG validate
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                           data-nw="fraudCheckDialogClose"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-container class="pl-2">
                    <v-row>
                        <v-col>
                            <v-data-table
                                :headers="headers"
                                :items="items"
                                :loading="loading"
                                hide-default-footer
                            >
                                <template v-slot:item.arrow>
                                    ->
                                </template>
                                <template v-slot:item.field="{ item }">
                                    <tr :style="{ color: item.capiValue === item.mdgValue ? 'black' : 'red' }">
                                        {{ item.field }}
                                    </tr>
                                </template>
                                <template v-slot:item.divider>
                                    <v-divider vertical></v-divider>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="col-auto mr-auto">
                            <v-btn
                                id="update-mdg-button"
                                rounded
                                color="primary"
                                @click.stop="updateMdg"
                            >
                                Update MDG
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col class="col-auto">
                            <v-btn
                                id="dont-update-mdg-button"
                                rounded
                                color="primary"
                                @click.stop="dontUpdate"
                            >
                                Don't update
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import apolloClient from "@/ApolloClient";
import gql from "graphql-tag";
import importedHeaders from "@/components/views/dialogs/headers/MdgValidateDialogHeaders.json";

export default {
    "mounted" () {
        if (this.isDialogOpen) {
            this.$apollo.queries.getMdgCustomerSearch.skip = false;
        }
    },
    // eslint-disable-next-line max-lines-per-function
    "data" () {
        return {
            "headers": importedHeaders,
            "items": [],
            "loading": false,
            "fields": {
                "company_name": "Company name",
                "tax_number": "Tax number",
                "mdm_sold_to_number": "Sold to number",
                "vat_number": "VAT number",
                "address": "Address",
                "city": "City",
                "country": "Country",
                "zipcode": "Zipcode",
                "coc_nr": "COC number",
                "dnb_duns_number": "Duns number"
            },
            "mdgCustomer": {}
        };
    },
    "methods": {
        closeDialog () {
            this.items = [];
            this.$store.dispatch("mdg/openDialog", {"open": false, "dialog": "validate"});
        },
        fillItems () {
            this.items = [];
            const fieldNames = Object.keys(this.fields);
            fieldNames.forEach((fieldName) => {
                this.items.push({
                    "field": this.fields[fieldName],
                    "capiValue": this.formValues[fieldName],
                    "mdgValue": this.mdgCustomer[fieldName]
                });
            });
        },
        async sendUpdateQuery (mdmSearchStatus) {
            const formId = this.formValues.id;
            const query = gql`query {mdg_customer_update (form_id: ${formId} mdm_search_status: ${mdmSearchStatus})
                                {status responseString error}}`;

            await apolloClient.query({query})
                .then((response) => {
                    this.$store.dispatch("applicationFormValues/saveMdmSearch", {"mdm_search_status": mdmSearchStatus});

                    this.closeDialog();
                    this.$toast(
                        response.data.mdg_customer_update.responseString,
                        {
                            "color": "success",
                            "timeout": 5000
                        }
                    );
                }).catch((error) => {
                    this.$toast(
                        error.data.mdg_customer_update.error,
                        {
                            "color": "error",
                            "timeout": 5000
                        }
                    );
                });
        },
        dontUpdate () {
            const mdmDontUpdateStatus = 9;
            this.sendUpdateQuery(mdmDontUpdateStatus);
        },
        updateMdg () {
            const mdmUpdateStatus = 8;
            this.sendUpdateQuery(mdmUpdateStatus);
        }
    },
    "computed": {
        isDialogOpen () {
            return this.$store.state.mdg.mdgValidateDialogOpen;
        },
        formValues () {
            return this.$store.state.applicationFormValues.values;
        },
        createQueryFields () {
            let queryFields = "";
            const fieldNames = Object.keys(this.fields);
            fieldNames.forEach((fieldName) => {
                queryFields = queryFields.concat(`${fieldName} `);
            });
            return queryFields;
        },
        getDunsNumber () {
            return this.formValues.dnb_duns_number;
        }
    },
    "watch": {
        isDialogOpen () {
            if (this.isDialogOpen) {
                this.$apollo.queries.getMdgCustomerSearch.skip = false;
            }
        }
    },
    "apollo": {
        "getMdgCustomerSearch": {
            query () {
                this.loading = true;
                return gql`query {mdg_customer_search (dnb_duns_number: "${this.getDunsNumber}") {data
                   { ${this.createQueryFields} } responseString error}}`;
            },
            update (data) {
                if (data.mdg_customer_search.data.length === 0) {
                    this.$toast(
                        "No customer was found",
                        {
                            "color": "error",
                            "timeout": 5000
                        }
                    );
                } else {
                    this.mdgCustomer = data.mdg_customer_search.data[0];
                    this.fillItems();
                }
                this.$apollo.queries.getMdgCustomerSearch.skip = true;
                this.loading = false;
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
