<template>
    <v-container fluid >
        <HeaderBar
                :breadcrumbs="breadcrumbs"
                title="ZL Payer Partner"
                data-nw="editZLPayer"
        >
            <template v-slot:button>
                <LogDialog
                        data-nw="edit-zl-payer-data-logs"
                        label="ZL Payer Partner Data Logs"
                        :id="zlPayerId"
                        table="option_partner_zl"
                        v-if="$can('log', 'option_partner_zl')"
                >
                </LogDialog>
                <v-btn
                        data-nw="edit-zl-payer-submit-button"
                        v-if="$can('update', 'option_partner_zl')"
                        class="text-capitalize btn-block"
                        rounded color="primary"
                        @click="submitZLPayerForm()">
                    Save ZL Payer Partner
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
                type="update"
                store-module="zlPayerFormValues"
                :form-segments="formSegments"
                :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../../baseComponents/BaseForm";
import HeaderBar from "../../../uiElements/HeaderBar";
import LogDialog from "../../dialogs/LogDialog";
import editZLPayerForm from "../../../../formConfig/editZLPayerForm.json";
import gql from "graphql-tag";

export default {
    "name": "EditZLPayer",
    "components": {
        BaseForm,
        HeaderBar,
        LogDialog
    },
    "methods": {
        submitZLPayerForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.getters.getFormSegments("zlPayerForm");
        },
        zlPayerId () {
            return this.$store.state.zlPayerFormValues.values.id;
        }
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "ZL Payers",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListZLPayers"}
                },
                {
                    "text": "Edit ZL Payer",
                    "disabled": true
                }
            ]
        };
    },
    "apollo": {
        "zlPayerFormValues": {
            query () {
                let queryParameters = "";
                const segment = editZLPayerForm.formSegments[0];
                Object.keys(segment.fields).forEach((field) => {
                    queryParameters = queryParameters.concat(
                        " ",
                        field
                    );
                });
                const visibilityFields = this.$store.getters["formSegments/getVisibilityFields"](segment);
                if (visibilityFields.length) {
                    queryParameters = queryParameters.concat(
                        " ",
                        visibilityFields.join(" ")
                    );
                }
                // eslint-disable-next-line max-len
                return gql`query {zlPayerFormValues: option_partner_zl (id: ${this.$route.params.id}) {data{id ${queryParameters}}}}`;
            },
            update (data) {
                this.$store.dispatch("zlPayerFormValues/deleteFormValues");
                this.$store.dispatch(
                    "zlPayerFormValues/setFormValues",
                    data.zlPayerFormValues.data[0]
                );
                this.$store.dispatch(
                    "zlPayerFormValues/setZLPayerIdValue",
                    data.zlPayerFormValues.data[0].id
                );
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
