var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headersWithActionsFirstRow,"items":_vm.filterDeleted(_vm.individuals),"disable-pagination":true,"hide-default-footer":true,"expanded":_vm.expanded,"show-expand":"","item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.rowExpanded},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name || "-")+" ")]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last_name || "-")+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email || "-")+" ")]}},{key:"item.address_line_1",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address_line_1 || "-")+" ")]}},{key:"item.isContactPerson",fn:function(ref){
var item = ref.item;
return [(item.isContactPerson)?_c('v-icon',{attrs:{"color":"green","dense":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.isSignee",fn:function(ref){
var item = ref.item;
return [(item.isSignee)?_c('v-icon',{attrs:{"color":"green","dense":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.isLegalRepresentative",fn:function(ref){
var item = ref.item;
return [(item.isLegalRepresentative)?_c('v-icon',{attrs:{"color":"green","dense":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.isUbo",fn:function(ref){
var item = ref.item;
return [(item.isUbo)?_c('v-icon',{attrs:{"color":"green","dense":""}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"mx-2",attrs:{"text":"","icon":"","fab":"","small":"","color":"primary","disabled":!_vm.canEdit},on:{"click":function($event){return _vm.openIndividualDialog(item, false)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"error--text mx-2",attrs:{"id":("remove_card_btn" + (item.id)),"text":"","icon":"","disabled":!_vm.canEdit},on:{"click":function($event){return _vm.removeIndividuals(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"49"}}),_c('td',{staticClass:"pl-0 pr-0",attrs:{"colspan":"9"}},[_c('v-data-table',{attrs:{"disable-pagination":true,"headers":_vm.headersSecondRow,"hide-default-footer":true,"items":_vm.newestExpanded[item.id],"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.address_line_2",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).address_line_2 || "-")+" ")]}},{key:"item.address_postal_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).address_postal_code || "-")+" ")]}},{key:"item.address_city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).address_city || "-")+" ")]}},{key:"item.address_state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).address_state || "-")+" ")]}},{key:"item.address_country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).address_country || "-")+" ")]}},{key:"item.nationality",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).nationality || "-")+" ")]}},{key:"item.country_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCountryNameById(_vm.getItemsBasedOnIndividualState(item).country_id) || "-")+" ")]}}],null,true)}),_c('v-data-table',{attrs:{"disable-pagination":true,"headers":_vm.headersThirdRow,"hide-default-footer":true,"items":_vm.newestExpanded[item.id],"loading":_vm.loading,"item-key":"id"},scopedSlots:_vm._u([{key:"item.phone_number_country_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).phone_number_country_code || "-")+" ")]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).phone_number || "-")+" ")]}},{key:"item.birth_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.getItemsBasedOnIndividualState(item).birth_date))+" ")]}},{key:"item.signature_location",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).signature_location || "-")+" ")]}},{key:"item.job_function",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getItemsBasedOnIndividualState(item).job_function || "-")+" ")]}},{key:"item.share_capital",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMarketingAgreeValue(item) || 0)+"% ")]}},{key:"item.marketing_agree",fn:function(ref){
var item = ref.item;
return [( _vm.getItemsBasedOnIndividualState(item).marketing_agree)?_c('v-icon',{attrs:{"color":"green","dense":""}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"red","dense":""}},[_vm._v(" mdi-close-circle ")])]}}],null,true)})],1)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"text-capitalize mr-5",attrs:{"right":"","rounded":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openIndividualDialog({'id': _vm.individualId}, true)}}},[_vm._v("Add ")])],1),_c('IndividualListDialog',{attrs:{"editIndividualDialog":_vm.dialogOpen,"items":_vm.item,"isNew":_vm.isNew,"tableHeaders":this.field.tableHeaders},on:{"update:editIndividualDialog":function($event){_vm.dialogOpen=$event},"update:edit-individual-dialog":function($event){_vm.dialogOpen=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }