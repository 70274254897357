import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {
    "values": {},
    "accountingClerkId": null
};


const mutations = {
    SET_FORM_VALUES (currentState, data) {
        currentState.values = {
            ...currentState.values,
            ...data
        };
    },
    UPDATE_FORM_VALUE (currentState, data) {
        Vue.set(currentState.values, data.name, data.value);
        if (typeof data.value === "undefined") {
            Vue.set(currentState.values, data.name, null);
        }
    },
    DELETE_FORM_VALUES (currentState) {
        const clearedForm = {
            "sales_organization_siebel": "",
            "value": ""
        };
        Vue.set(currentState, "values", clearedForm);
        Vue.set(currentState, "accountingClerkId", null);
    },
    SET_ACCOUNTING_CLERK_ID (currentState, data) {
        Vue.set(currentState, "accountingClerkId", data);
    }
};

/**
 * Function that creates JSON object based on the similarities between the keys of the "values" object of this module and the provided argument
 * @param {object} fieldsObject Object that is used to filter the "values" object based on it's keys
 * @returns {object} Filtered object
 */
function filterObject (fieldsObject) {
    const keyArray = [];
    Object.keys(fieldsObject).forEach((key) => {
        keyArray.push(key);
    });

    const keyValueObject = {};
    Object.keys(state.values).forEach((item) => {
        if (keyArray.find((key) => key === item)) {
            keyValueObject[item] = state.values[item];
        }
    });

    return keyValueObject;
}

/**
 *
 * @param {Promise} mutation The mutation that needs to be executed
 * @returns {Promise<T>} Result of the mutation
 */
function executeMutation (mutation) {
    return apolloClient.mutate({mutation}).then((response) => {
        if (response.data[Object.keys(response.data)[0]].errors) {
            throw Error(response.data[Object.keys(response.data)[0]].errors);
        }
        return response;
    }).catch((error) => {
        throw Error(error);
    });
}

/**
 *
 * @param {Integer} accountingClerkId accounting clerk id to update
 * @returns {Promise} result of the mutation
 */
async function mutateAccountingClerkCategory (accountingClerkId) {
    // eslint-disable-next-line max-len
    const accountingClerkCategoryMutation = gql`mutation{ create_option_accounting_clerk(id:${accountingClerkId} ){responseString}}`;

    await executeMutation(accountingClerkCategoryMutation);
}

const getters = {};

const actions = {
    async setFormValues ({commit}, data) {
        await commit(
            "SET_FORM_VALUES",
            data
        );
    },
    async updateFormValue ({commit}, data) {
        await commit(
            "UPDATE_FORM_VALUE",
            data
        );
    },
    async deleteFormValues ({commit}) {
        await commit("DELETE_FORM_VALUES");
    },
    async setAccountingClerkIdValue ({commit}, data) {
        await commit(
            "SET_ACCOUNTING_CLERK_ID",
            data
        );
    },
    async saveForm ({rootState}, type) {
        const accountingClerkData = filterObject(rootState.formSegments.accountingClerkFormSegments[0].fields);
        if (type === "update") {
            accountingClerkData.id = state.accountingClerkId;
        }
        let formMutationQuery = "";
        for (const key of Object.keys(accountingClerkData)) {
            const fieldValue = accountingClerkData[key];
            formMutationQuery += typeof fieldValue === "string" ? `${key}: "${fieldValue}" ` : `${key}: ${fieldValue} `;
        }
        const queryType = `${type}_option_accounting_clerk`;
        // eslint-disable-next-line max-len
        const accountingClerkMutation = gql`mutation{ ${queryType} (${formMutationQuery}){responseString insertedData {id}}}`;
        const mutationResult = await executeMutation(accountingClerkMutation);

        if (mutationResult.data[queryType].insertedData) {
            await mutateAccountingClerkCategory(
                mutationResult.data[queryType].insertedData[0].id,
                state.values.category_ids
            );
        } else {
            throw new Error(mutationResult.data[queryType].responseString);
        }
        return mutationResult;
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
