<template>
  <v-container class="pl-0">
    <v-btn rounded color="primary"
           id="mdm_info_button"
           class="text-capitalize"
           @click="openDialog"
           data-nw="mdmInfoBtn"
    >MDM Info
    </v-btn>
    <v-dialog scrollable :value="dialog" persistent max-width="1300">
      <v-card height="80vh" data-nw="mdmInfoCard">
        <v-card-title>
          <v-col lg="10">
            MDM Info
          </v-col>
          <v-col lg="2">
            <v-btn class="primary--text float-right mt-3"
                   text
                   @click.stop="closeDialog"
                   data-nw="closeMdmDialogBtn"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-data-table
            id="mdm_info_data_table"
            :sort-desc="true"
            :headers="headers"
            :items="formatMdmData(mdmInfo)"
            :items-per-page="-1">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";

export default {
    "extends": BaseElement,
    "methods": {
        openDialog () {
            this.dialog = true;
        },
        closeDialog () {
            this.dialog = false;
        },
        formatMdmData (mdmInfoString) {
            if (mdmInfoString) {
                // eslint-disable-next-line max-len
                const dateTimeRegex = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]/gu;
                const mdmInfoArray = mdmInfoString.replace(
                    dateTimeRegex,
                    "<<$&"
                ).split("<<");
                const formattedMdmInfoArray = [];
                mdmInfoArray.forEach((temp) => {
                    if (temp !== "") {
                        formattedMdmInfoArray.push({"value": temp});
                    }
                });
                return formattedMdmInfoArray;
            }
            return [];
        }
    },
    "computed": {
        mdmInfo () {
            return this.$store.state.applicationFormValues.values.mdm_info;
        }
    },
    "data" () {
        return {
            "dialog": false,
            "headers": [
                {
                    "text": "Entries",
                    "value": "value",
                    "sortable": false
                }
            ]
        };
    }
};

</script>
