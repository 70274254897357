<template>
  <v-text-field
    v-bind="field"
    @input="updateValue($event)"
    :value="formValue ? formValue : this.cocNr"
    :name="this.name"
    :rules="setRules(field.rules)"
    :label="field.label"
    :persistent-hint="true"
    :hint="getHint()"
    :ref="this.name"
    :data-nw="this.name"
    type="text"
    :readonly="field.permissioned ? !$can(field.ability.action, field.ability.object) : false"
    :disabled="unableToEdit()"
    ></v-text-field>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import gql from "graphql-tag";

export default {
    "extends": BaseElement,
    "data" () {
        return {
            "cocNr": ""
        };
    },
    "watch": {
        values () {
            if (!this.formValue) {
                this.$apollo.queries.getRegistrationNumber.skip = false;
            }
        }
    },
    "computed": {
        values () {
            return this.$store.state.applicationFormValues.values;
        }
    },
    "methods": {
        unableToEdit () {
            if (this.field.createOnly && this.pageType !== "create") {
                return true;
            }
            return !this.canEdit;
        }
    },
    "apollo": {
        "getRegistrationNumber": {
            query () {
                const formId = this.$route.params.id || 0;
                return gql`query {solarisBankBusiness: solaris_bank_business(form_id: ${formId}) {
                            data {registrationNumber }
                        }}`;
            },
            update (data) {
                if (!this.$apollo.queries.getRegistrationNumber.loading) {
                    if (data.solarisBankBusiness.data.length !== 0) {
                        this.cocNr = data.solarisBankBusiness.data[0].registrationNumber;
                    }
                }
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    }
};

</script>
