<template>
  <v-container fluid v-if="$can('read', 'export')">
      <HeaderBar
          :breadcrumbs="breadcrumbs"
          title="Exports"
          data-nw="listExportsHeader"
      />
      <v-col>
          <v-row>
              <v-card flat class="background-grey" width="100%">
                  <v-card-text>
                      <v-row>
                          <v-col>
                              <v-data-table :must-sort="true"
                                            sort-by="exportTime"
                                            :sort-desc="true"
                                            :loading="loading"
                                            :headers="tableHeaders"
                                            :items="items"
                                            :server-items-length="itemsPerPage"
                                            :disable-pagination="true"
                                            :hide-default-footer="true"
                                            :options-sync="options"
                                            item-key="filename"
                                            v-on:update:options="setSortData"
                                            data-nw="exports-data-table"
                              >
                                  <template v-slot:item.filename="{ item }">
                                      <a href="#" @click.stop="download(item)">{{item.filename}}</a>
                                  </template>
                                  <template v-slot:item.id="{ item }">
                                      <a href="#" @click.stop="deleteItem(item)">Delete {{item.id}}</a>
                                  </template>
                              </v-data-table>
                          </v-col>
                      </v-row>
                  </v-card-text>
              </v-card>
          </v-row>
          <v-row>
              <v-col class="total_Count_Records"># of records: {{totalCount}}</v-col>
              <v-col>
                  <v-pagination
                      circle
                      v-model="filtersCurrentPage"
                      :length="numberOfPages"
                      total-visible="5"
                      data-nw="exports-pagination"
                  />
              </v-col>
          </v-row>
      </v-col>
  </v-container>
</template>

<script>
import BaseList from "../../../baseComponents/BaseList";
import HeaderBar from "../../../uiElements/HeaderBar";
import apolloClient from "@/ApolloClient";
import gql from "graphql-tag";

export default {
    "extends": BaseList,
    "name": "ListExports",
    "components": {
        HeaderBar
    },
    "methods": {
        refreshList () {
            this.loading = true;
            this.$apollo.queries.items.refetch();
        },
        async deleteItem (item) {
            if (!confirm("Do you want to permanently delete this item?")) {
                return;
            }
            const mutation = gql`mutation {
                            delete_export(filename: "${item.filename}") {
                                responseString
                              }
                        }`;
            const result = await apolloClient.mutate({mutation});
            this.refreshList();
            this.$toast(
                result.data.delete_export.responseString,
                {"color": "success", "queueable": false}
            );
        },
        download (item) {
            this.downloadS3File(item.filename);
        }
    },
    // eslint-disable-next-line max-lines-per-function
    data () {
        return {
            "tableName": "exports",
            "searchFields": ["filename", "size"],
            "editURL": "EditExport",
            "gqlModel": "exportFileList",
            "tableHeaders": [
                {
                    "value": "filename",
                    "text": "File Name",
                    "sortable": false,
                    "width": "40%"
                },
                {
                    "value": "campaign",
                    "text": "Filter",
                    "sortable": true,
                    "width": "20%"
                },
                {
                    "value": "exportTime",
                    "text": "Export Time",
                    "sortable": true,
                    "width": "20%"
                },
                {
                    "value": "size",
                    "text": "File Size",
                    "sortable": false,
                    "width": "10%"
                },
                {
                    "value": "id",
                    "text": "Actions",
                    "sortable": false,
                    "width": "10%"
                }
            ],
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "Exports",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListExports"}
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
