<template>
    <v-btn v-if="showButton"
        :loading="loading"
        id="bank_security_preview_button"
        rounded color="primary"
        class="text-capitalize btn-block"
        @click="previewBankSecurityDocument"
    >Preview bank security</v-btn>
</template>

<script>
import apolloClient from "../../../ApolloClient";
import gql from "graphql-tag";

export default {
    "components": {},
    "data" () {
        return {
            "showButton": false,
            "bankSecurityDocument": null,
            "loading": false
        };
    },
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        }
    },
    "methods": {
        async previewBankSecurityDocument () {
            this.loading = true;
            const response = await this.getDocumentContent();
            this.bankSecurityDocument = response.result.data.previewBankSecurityDocument.document.content;
            this.loading = false;
            const pdfWindow = window.open("");
            pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,
                ${encodeURI(this.bankSecurityDocument)}'></iframe>`);
            pdfWindow.document.close();
        },
        async getDocumentContent () {
            let error = false;
            const query = gql` {
            previewBankSecurityDocument(formId: "${this.$route.params.id}"){
                document
                    { content }
                responseString
        }}`;
            const result = await apolloClient.query({query}).catch(() => {
                error = true;
            });
            return {error, result};
        }
    },
    async "created" () {
        let showButton = false;
        if (typeof this.$route.params.id !== "undefined") {
            const response = await this.getDocumentContent();
            if (typeof response.result !== "undefined" && response.error === false) {
                showButton = true;
                this.bankSecurityDocument = response.result.data.previewBankSecurityDocument.document.content;
                this.showButton = showButton;
            }
        }
    }
};

</script>
