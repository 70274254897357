<template>
    <v-container class="pt-5 mt-5" fluid>
        <v-col>
            <v-card>
                {"status": "healthy"}
            </v-card>
        </v-col>
    </v-container>
</template>

<script>

export default {
    "name": "MetaHealth"
};
</script>
