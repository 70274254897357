<template>
    <TreeView
        :itemArray="tree"
        :selectedItems="toArray(items)"
        v-on:setSelectedItems="setSelectedItems">
    </TreeView>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import TreeView from "./TreeView";
import {buildCampaignApplicationTemplateTree} from "@/service/treeBuilder.services";
import gql from "graphql-tag";
import {toArray} from "lodash";

export default {
    "extends": BaseElement,
    "components": {TreeView},
    data () {
        return {
            "items": []
        };
    },
    "methods": {
        toArray,
        setSelectedItems (data) {
            const applicationTemplateIds = data.filter((s) => s.startsWith("template"));
            const campaignIds = data.filter((s) => s.startsWith("campaign"));

            this.$store.dispatch("groupFormValues/updateFormValue", {
                "name": "campaign_ids",
                "value": campaignIds
            });
            this.$store.dispatch("groupFormValues/updateFormValue", {
                "name": "template_ids",
                "value": applicationTemplateIds
            });
        }
    },
    "apollo": {
        "treeItems": {
            query () {
                return gql`query {
                            country (first: 10000) { data { id name code iso_country_code }}
                            category (first: 10000) { data { id name country_id label }}
                            campaign (first: 10000, overrideAclCheck: true) { data { id category_id url_prefix }}
                            application_template (first: 10000, overrideAclCheck: true) { data { id category_id name }}
                            }`;
            },
            update (data) {
                const campaignTree = buildCampaignApplicationTemplateTree(data);

                this.$store.dispatch(
                    "listFilters/setCampaignTree",
                    campaignTree
                );
            },
            "fetchPolicy": "no-cache"
        },
        "selectedItems": {
            query () {
                return gql`query {
                            acl_group_campaign( first: 10000,aclgroup_id: ${this.$route.params.id})
                                { data { campaign_id }}
                            acl_group_application_template( first: 10000,aclgroup_id: ${this.$route.params.id})
                                { data { applicationtemplate_id }}
                            }`;
            },
            update (data) {
                const campaignIds = data.acl_group_campaign.data.map((e) => `campaign${e.campaign_id}`);
                // eslint-disable-next-line max-len
                const applicationTemplatesIds = data.acl_group_application_template.data.map((e) => `template${e.applicationtemplate_id}`);

                this.items = [...campaignIds, ...applicationTemplatesIds];

                this.$store.dispatch("groupFormValues/updateFormValue", {
                    "name": "campaign_ids",
                    "value": campaignIds
                });

                this.$store.dispatch("groupFormValues/updateFormValue", {
                    "name": "template_ids",
                    "value": applicationTemplatesIds
                });
            },
            skip () {
                return this.$route.meta.ability.action !== "update";
            }
        }
    },
    "computed": {
        tree () {
            return this.$store.state.listFilters.aclGroups.campaignTree ?? [];
        }
    }
};
</script>
