<template>
        <v-row justify="center">
            <v-dialog :value="show" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">{{headline}}</v-card-title>
                    <v-card-text>{{text}}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeDialog">{{cancelText}}</v-btn>
                        <v-btn color="green darken-1" text @click="applyTransition">{{confirmText}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
</template>

<script>
export default {
    "name": "BaseDialog",
    "props": ["headline", "text", "show", "confirmText", "cancelText"],

    "methods": {
        applyTransition () {
            this.$emit("dialog:confirm");
        },
        "closeDialog" () {
            this.$emit("dialog:close");
        }
    }

};

</script>

<style scoped>

</style>
