<template>
  <v-row>
    <v-btn rounded color="primary"
           class="text-capitalize"
           @click="openDialog">
      <slot/>
    </v-btn>
    <v-dialog :value="dialog" persistent>
      <v-card height="80vh">
        <v-row height="10vh" class="mx-3 pt-3">
          <v-col md="11=0" class="py-0">
            <slot name="dialogHeader"/>
          </v-col>
          <v-col md="1" class="pb-0 pt-2 pr-2">
            <v-btn class="primary--text float-right"
                   text
                   @click.stop="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row height="10vh" class="mx-3">
            <v-col md="11=0" class="py-0">
            <v-text-field
              v-model="searchText"
              @keyup.enter="searchData($event)"
              :placeholder=placeholder
              prepend-inner-icon="mdi-magnify">
            </v-text-field>
          </v-col>
          </v-row>
        <v-data-table
          height="60vh"
          id="base-search-data-table"
          selectable-key
          :single-select="true"
          dense
          :items="this.items"
          :headers="tableHeaders"
          item-key="id"
          disable-filtering
          :items-per-page="-1"
          v-on:click:row="returnValue"
          class="table-cursor"
        >
          <template v-slot:item.active="{ item }">
            {{item.active | formatActiveState}}
          </template>
          <template>
            <div class="float-right border-top"><v-btn
              id="save-choice-button"
              class="text-capitalize float-right my-3 ml-10 mr-3"
              rounded color="primary"
              @click="saveChoice()">
              Save Choice
            </v-btn></div>

          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import apolloClient from "../../ApolloClient";
import {getFieldsFromTableHeaders} from "@/service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "name": "BaseSearchModal",
    "props": ["config", "placeholder"],
    data () {
        return {
            "dialog": false,
            "searchText": "",
            "searchFieldQuery": "",
            "items": [],
            "selected": []
        };
    },
    "methods": {
        async searchData (searchFieldValue) {
            this.searchFieldQuery = searchFieldValue.target.value;
            let searchGQLQuery = "";
            if (this.searchFieldQuery !== null) {
                searchGQLQuery = ` search: {fieldName:${JSON.stringify(this.searchFields)},
                        searchQuery:"${this.searchFieldQuery}"} `;
            }
            const fields = getFieldsFromTableHeaders(this.tableHeaders);
            const query = gql`{${this.tableName}: ${this.gqlModel}(
                                ${searchGQLQuery}
                                ) {totalCount, data{id ${fields}}}}`;
            const results = await apolloClient.query({query});
            this.items = results.data[Object.keys(results.data)[0]].data;
        },
        openDialog () {
            this.searchText = "";
            this.searchFieldQuery = "";
            this.dialog = true;
        },
        closeDialog () {
            this.dialog = false;
        },
        returnValue (item) {
            this.selected = [item];

            this.$emit(
                "newItemSelected",
                this.selected[0]
            );
            this.closeDialog();
        },
        saveChoice () {
            this.$emit(
                "newItemSelected",
                this.selected[0]
            );
            this.closeDialog();
        }
    },
    "computed": {
        tableHeaders () {
            return this.config.tableHeaders;
        },
        gqlModel () {
            return this.config.gqlModel;
        },
        searchFields () {
            return this.config.searchFields;
        },
        tableName () {
            return this.config.tableName;
        }
    }
};
</script>

<style scoped>

</style>


