<template>
  <v-container fluid v-if="$can('read', 'user')">
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="Users"
      data-nw="listUsersHeader"
    >
      <template v-slot:button>
        <v-btn
            v-if="$can('create', 'user')"
            id="new-user-button"
            class="text-capitalize btn-block"
            rounded color="primary"
            @click.stop="CreateUser"
            data-nw="createUserBtn"
        >
          New User
        </v-btn>
      </template>
    </HeaderBar>
    <v-col>
      <v-row align="center">
        <v-col cols="3">
          <v-text-field
            id="search-bar-text-field"
            v-if="showTextFilter"
            v-model="filterText"
            @keyup.enter="searchData($event)"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-card flat class="background-grey" width="100%">
          <v-card-text>
            <v-row>
              <v-col>
                <v-data-table :must-sort="true"
                              :loading="loading"
                              :headers="tableHeaders"
                              :items="items"
                              :server-items-length="itemsPerPage"
                              :disable-pagination="true"
                              :hide-default-footer="true"
                              :options.sync="options"
                              item-key="id"
                              v-on="{ 'click:row': $can('update', 'user') ? goToEdit : null}"
                              :class="$can('update', 'user') ? 'table-cursor' : ''"
                              v-on:update:options="setSortData"
                              data-nw="usersList"
                >
                  <template v-slot:item.role_v2="{ item }">
                    {{item.role_v2|formatRole}}
                  </template>
                  <template v-slot:item.last_login="{ item }">
                    {{item.last_login|formatDateTime}}
                  </template>
                  <template v-slot:item.active="{ item }">
                    {{item.active|formatActiveState}}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="total_Count_Records"># of records: {{totalCount}}</v-col>
              <v-col>
                <v-pagination
                  circle
                  v-model="filtersCurrentPage"
                  :length="numberOfPages"
                  total-visible="5"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import BaseList from "../../baseComponents/BaseList";
import HeaderBar from "../../uiElements/HeaderBar";

export default {
    "extends": BaseList,
    "name": "ListUsers",
    "components": {
        HeaderBar
    },
    "computed": {
        filters () {
            return this.$store.state.listFilters.users;
        }
    },
    "methods": {
        CreateUser () {
            this.$router.push({"name": "CreateUser"});
        }
    },
    // eslint-disable-next-line max-lines-per-function
    "data" () {
        return {
            "tableName": "users",
            "searchFields": ["first_name", "last_name", "username", "emailaddress", "role"],
            "editURL": "EditUser",
            "gqlModel": "user",
            "tableHeaders": [
                {
                    "value": "first_name",
                    "text": "First name",
                    "sortable": true,
                    "width": "10%"
                },
                {
                    "value": "last_name",
                    "text": "Last name",
                    "sortable": true,
                    "width": "10%"
                },
                {
                    "value": "username",
                    "text": "Username",
                    "sortable": true,
                    "width": "20%"
                },
                {
                    "value": "emailaddress",
                    "text": "Email address",
                    "sortable": true,
                    "width": "20%"
                },
                {
                    "value": "role_v2",
                    "text": "Role",
                    "sortable": true,
                    "width": "15%"
                },
                {
                    "value": "last_login",
                    "text": "Last login",
                    "sortable": true,
                    "width": "15%"
                },
                {
                    "value": "active",
                    "text": "Active",
                    "sortable": true,
                    "width": "7%"
                }
            ],
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Users",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListUsers"}
                }
            ]
        };
    }
};
</script>
