import Vue from "vue";
import apolloClient from "@/ApolloClient";
import gql from "graphql-tag";

const state = {
    "onefleetOnboarding": null
};

const fields = ["id", "success", "error_message", "customer_id", "fees_id", "issuing_id", "invoicing_id", "pricing_id", "user_account_id"];

const mutations = {
    SET_ONEFLEET_ONBOARDING (currentState, data) {
        Vue.set(currentState, "onefleetOnboarding", data);
    },
    "CLEAR_OLD_VALUES" (currentState) {
        Vue.set(currentState, "onefleetOnboarding", null);
    }
};

const getters = {
    "getOnboardingField": (currentState) => (field) => ({"name": currentState.onefleetOnboarding?.[field]})
};

const actions = {
    async fetchOnboarding ({ commit }, formId) {
        const query = gql`query {onefleet_onboarding(form_id: ${formId}) {data {${fields.join(" ")}}}}`;
        const response = await apolloClient.query({
            query
        });
        if (response.data.onefleet_onboarding.data?.length) {
            await commit("SET_ONEFLEET_ONBOARDING", response.data.onefleet_onboarding.data[0]);
        }
    },
    async clearOldValues ({commit}) {
        await commit("CLEAR_OLD_VALUES");
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
