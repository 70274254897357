<template>
  <v-container fluid v-if="$can('create', 'user')">
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="New user"
    >
      <template v-slot:button>
        <v-btn
          id="submit-user-button"
          class="text-capitalize btn-block"
          rounded color="primary"
          @click="submitUserForm()">
          Save User
        </v-btn>
      </template>
    </HeaderBar>
    <BaseForm
      type="create"
      store-module="userFormValues"
      :form-segments=formSegments
      :editable="true"
    />
  </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";

export default {
    "name": "NewUser",
    "components": {
        BaseForm,
        HeaderBar
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Users",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListUsers"}
                },
                {
                    "text": "New user",
                    "disabled": true
                }
            ]
        };
    },
    "methods": {
        submitUserForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.userFormSegments;
        }
    },
    created () {
        this.$store.dispatch("userFormValues/deleteFormValues");
    }
};
</script>
