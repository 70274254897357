import Vue from "vue";

export const activeStateFormat = (value) => {
    let activeValue = value;
    if (value === 0) {
        activeValue = "no";
    }
    if (value === 1) {
        activeValue = "yes";
    }
    return activeValue;
};

Vue.filter(
    "formatActiveState",
    activeStateFormat
);
