<template>
    <div class="layout wrap">
        <v-flex
            v-for="fieldname in this.fields"
            :key="fieldname"
            :id="'element_salesforce_'+fieldname"
            :class="'form_element xs4 pr-5'"
        >
            <div class="read-only-label-size">
                <v-label>{{getTitle(fieldname)}}</v-label>
                <p :data-nw="fieldname">{{getSalesforceField(fieldname)}}</p>
            </div>
        </v-flex>
    </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import _ from "lodash";
import gql from "graphql-tag";

export default {
    "extends": BaseElement,
    "props": ["fields"],
    "apollo": {
        "getSalesforceFields": {
            query () {
                return gql` {salesforceFields: salesforce_fields(form_id: ${this.formId ?? 0}) {data{id ${this.fields.join(" ")}}}}`;
            },
            update (data) {
                this.loading = false;
                if (data.salesforceFields.data.length > 0) {
                    this.$store.dispatch("salesforceFields/setSalesforceFields", data.salesforceFields.data[0]);
                }
            }
        }
    },
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        }
    },
    "methods": {
        getTitle (field) {
            return _.capitalize(field).replace(/_/gu, " ");
        },
        getData (field) {
            return this.data && this.data[field] ? this.data[field] : "No data available";
        },
        getSalesforceField (field) {
            const fields = this.$store.state.salesforceFields.salesforceFields;
            return fields && fields[field] ? fields[field] : "No data available";
        }
    },
    data () {
        return {"data": []};
    }
};
</script>

<style>
</style>
