<template>
  <v-container fluid v-if="$can('read', 'option_partner_zf')">
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="ZF Payer Partners"
      data-nw="listZFPayersHeader"
      >

      <template v-slot:button>
        <v-btn
          data-nw="new-zf-payer-button"
          v-if="$can('create', 'option_partner_zf')"
          class="text-capitalize float-right"
          rounded color="primary"
          @click.stop="createZFPayer">
          New ZF Payer Partner
        </v-btn>
      </template>
    </HeaderBar>

    <v-col>
      <v-row align="center">
        <v-col cols="3">
          <v-text-field
            data-nw="zf-payers-search-bar"
            v-if="showTextFilter"
            v-model="filterText"
            @keyup.enter="searchData($event)"
            placeholder="search"
            prepend-inner-icon="mdi-magnify"
            dense
            />
        </v-col>
      </v-row>
      <v-row>
        <v-card flat class="background-grey" width="100%">
          <v-card-text>
            <v-row>
              <v-col>
                <v-data-table :must-sort="true"
                              :loading="loading"
                              :headers="tableHeaders"
                              :items="items"
                              :server-items-length="itemsPerPage"
                              :disable-pagination="true"
                              :hide-default-footer="true"
                              :options-sync="options"
                              item-key="id"
                              v-on="{ 'click:row': $can('update', 'option_partner_zf') ? goToEdit : null}"
                              :class="$can('update', 'option_partner_zf') ? 'table-cursor' : ''"
                              v-on:update:options="setSortData"
                              data-nw="zf-payers-data-table"
                              >
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="total_Count_Records"># of records: {{totalCount}}</v-col>
              <v-col>
                <v-pagination
                  circle
                  v-model="filtersCurrentPage"
                  :length="numberOfPages"
                  total-visible="5"
                  data-nw="zf-payers-pagination"
                  />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-col>
  </v-container>

</template>

<script>
import BaseList from "../../../baseComponents/BaseList";
import HeaderBar from "../../../uiElements/HeaderBar";

export default {
    "extends": BaseList,
    "name": "ListZFPayers",
    "components": {
        HeaderBar
    },
    "methods": {
        createZFPayer () {
            this.$router.push({"name": "CreateZFPayer"});
        }
    },
    data () {
        return {
            "tableName": "zfPayers",
            "searchFields": ["sales_organization_siebel", "value"],
            "editURL": "EditZFPayer",
            "gqlModel": "option_partner_zf",
            "tableHeaders": [
                {
                    "value": "sales_organization_siebel",
                    "text": "Sales Organization Siebel",
                    "sortable": true,
                    "width": "20%"
                },
                {
                    "value": "value",
                    "text": "Value",
                    "sortable": true,
                    "width": "80%"
                }
            ],
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "ZF Payers",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListZFPayers"}
                }
            ]
        };

    }
};
</script>

<style scoped>

</style>
