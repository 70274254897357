<template>
  <div class="read-only-label-size" v-if="!field.copyBtn">
    <v-label>{{field.label}}</v-label>
      <v-simple-table v-if="field.newlineSeparated">
          <template v-slot:default>
              <tbody>
              <tr v-for="(value, index) in getTableData" :key="index">
                  <td>{{ value }}</td>
              </tr>
              </tbody>
          </template>
      </v-simple-table>
      <p :data-nw="this.name">{{getValue}}</p>
  </div>
  <div class="read-only-label-size d-flex align-center" v-else>
      <div class="overflow-auto">
          <v-label>{{field.label}}</v-label>
          <v-simple-table v-if="field.newlineSeparated">
              <template v-slot:default>
                  <tbody>
                  <tr v-for="(value, index) in getTableData" :key="index">
                      <td>{{ value }}</td>
                  </tr>
                  </tbody>
              </template>
          </v-simple-table>
          <p v-else :data-nw="this.name">{{getValue}}</p>
      </div>
      <v-icon class="primary--text mx-2 mb-4" @click.stop="copyToClipboard">
          mdi-content-copy
      </v-icon>
  </div>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import moment from "moment";

export default {
    "extends": BaseElement,
    "components": {},
    "data" () {
        return {
            "parseOptions": {
                "boolToYesNo": (v) => {
                    if (typeof v === "undefined" || v === null) {
                        return "No data available";
                    }
                    return v ? "Yes" : "No";
                }
            }
        };
    },
    "computed": {
        getValue () {
            let value = this.formValue;

            if (this.field.storeAction) {
                this.$store.dispatch(this.field.storeAction);
            }

            if (this.field.getter) {
                const currentField = this.$store
                    .getters[this.field.getter](this.formValue ?? this.field.getterParameter);
                if (currentField) {
                    value = currentField.name;
                }
            }
            if (this.field.dateFormat) {
                value = moment(this.formValue).format("DD-MM-YYYY HH:mm");
            }
            if (this.field.parseTo) {
                value = this.parseOptions[this.field.parseTo[0]](value);
            }

            if (!value) {
                return "No data available";
            }
            return value;
        },
        getTableData () {
            return this.getValue.split("\n");
        }
    },
    "methods": {
        copyToClipboard () {
            navigator.clipboard.writeText(this.getValue);
            this.$toast(`Copied ${this.field.label}`, {"color": "success"});
        }
    }
};

</script>
<style>
  .read-only-label-size label {
    font-size: 12px;
  }
</style>
