<template>
  <v-container fluid v-if="$can('create', 'telesales_agency')">
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="New agency"
    >
      <template v-slot:button>
        <v-btn
          id="submit-telesales-button"
          class="text-capitalize btn-block"
          rounded color="primary"
          @click="submitTelesalesForm()">
          Save Telesales Agency
        </v-btn>
      </template>
    </HeaderBar>
    <BaseForm
      type="create"
      store-module="telesalesFormValues"
      :form-segments=formSegments
      :editable="true"
    />
  </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";

export default {
    "name": "NewTelesales",
    "components": {
        BaseForm,
        HeaderBar
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Telesales",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListTelesales"}
                },
                {
                    "text": "New telesales agency",
                    "disabled": true
                }
            ]
        };
    },
    "methods": {
        submitTelesalesForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            // Create new object to avoid mutating the store
            const formSegments = JSON.parse(JSON.stringify(this.$store.state.formSegments.telesalesFormSegments));
            // Remove the users object from the segments
            return formSegments.splice(
                0,
                1
            );
        }
    },
    "created" () {
        const defaultValues = {"active": 1};
        this.$store.dispatch(
            "telesalesFormValues/setFormValues",
            defaultValues
        );
    }
};
</script>
