<template>
    <v-col cols="3" id="application-list-filter">
      <v-select
        id="application-list-filter-select"
        v-model="selectedFilter"
        :items="allFilters"
        item-text="name"
        label="Filter"
        clearable
        return-object
      ></v-select>
    </v-col>
</template>

<script>

import {buildFilterQuery} from "../../service/filterQuery.services.js";

export default {
    "name": "FilterBar",
    "computed": {
        "statuses" () {
            return this.$store.state.statusses.statusses;
        },
        "selectedFilter": {
            "get" () {
                return this.$store.state.listFilters.applications.selectedFilter;
            },
            "set" (selectedFilter) {
                if (selectedFilter) {
                    const filter = {"filterQuery": buildFilterQuery(selectedFilter),
                        ...selectedFilter};
                    this.$store.dispatch(
                        "listFilters/setFilters",
                        {
                            "tableName": "applications",
                            "selectedFilter": filter,
                            "currentPage": 1,
                            "offset": 0
                        }
                    );
                } else {
                    this.$store.dispatch(
                        "listFilters/setFilters",
                        {
                            "tableName": "applications",
                            "selectedFilter": null,
                            "currentPage": 1,
                            "offset": 0
                        }
                    );
                }
            }
        },
        allFilters () {
            return this.$store.getters["listFilters/getAllFilters"];
        }
    }
};
</script>

