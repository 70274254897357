<template>
    <v-tooltip bottom :disabled="!isCurrentStatusDirty">
        <template v-slot:activator="{ on }">
            <div v-on="on" class="d-inline-block">
            <v-btn
                rounded color="primary"
                id="send_to_solaris_button"
                class="text-capitalize"
                @click.stop="sendToSolaris"
                data-nw="sendToSolarisButton"
                :disabled="isCurrentStatusDirty"
            > Send to Solaris
                <v-icon v-if="isCurrentStatusDirty">mdi-information</v-icon>
            </v-btn>
            </div>
        </template>
        <span>Unavailable due to an unsaved status change, please save the application first.</span>
    </v-tooltip>
</template>

<script>

import gql from "graphql-tag";

export default {
    "inject": ["saveFormBase"],
    "computed": {
        isCurrentStatusDirty () {
            return this.$store.state.statusses.dirty;
        }
    },
    "methods": {
        async sendToSolaris () {
            await this.$store.dispatch("loader/setLoading", true);
            try {
                await this.saveFormBase(false, false);
            } catch (error) {
                return;
            }
            const mutation = gql`mutation{send_to_solaris_bank
            (form_id: ${this.$route.params.id}){responseString error}}`;
            const result = await this.$apollo.mutate({mutation});
            if (!result.data.send_to_solaris_bank.error) {
                this.$toast("Successfully sent application to Solaris", {"color": "success", "queueable": false});
            } else {
                this.$toast("Failed to send application to Solaris", {"color": "error", "queueable": false});
            }
            await this.$root.$emit("refreshForm");
            await this.$store.dispatch("loader/setLoading", false);
        }
    }
};
</script>
