<template>
<v-tooltip bottom :disabled="!shouldBeDisabled">
    <template v-slot:activator="{ on }">
        <div v-on="on" class="d-inline-block">
        <v-btn
            id="send_guarantee_mail_button"
            :disabled="shouldBeDisabled" class="text-capitalize"
            color="primary"
            data-nw="sendGuaranteeMailButton"
            rounded
            v-on:click="sendGuaranteeMail">
        Send Deposit Mail
            <v-icon v-if="shouldBeDisabled">mdi-information</v-icon>
        </v-btn>
        </div>
    </template>
    <span>Unavailable because of invalid deposit status, needs to be one of: {{ allowedStatusLabels }}</span>
</v-tooltip>
</template>

<script>
import apolloClient from "../../../ApolloClient";
import gql from "graphql-tag";

export default {
    "computed": {
        shouldBeDisabled () {
            const status = this.$store.state.applicationFormValues.values.guarantee_status;
            return !this.allowedStatuses.includes(status);
        }
    },
    "methods": {
        async sendGuaranteeMail () {
            await this.$store.dispatch("loader/setLoading", true);
            const mutation = gql`mutation {
                            sendGuaranteeMail(formId: ${this.$route.params.id}) {
                                responseString
                                error
                            }
                        }`;
            const result = await apolloClient.mutate({mutation});
            if (!result.data.sendGuaranteeMail.error) {
                await this.$store.dispatch("loader/setLoading", false);
                this.$toast("The deposit mail was successfully sent", {"color": "success"});
            }
        }
    },
    data () {
        return {
            "allowedStatuses": [
                "10 borg vereist",
                "20 borgstelling verstuurd",
                "30 borgstellingherinnering verstuurd"
            ],
            "allowedStatusLabels": [
                "10 security required",
                "20 security sent",
                "30 Reminder security"

            ]
        };
    }

};
</script>
