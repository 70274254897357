<template>
    <div class="container pl-0">
        <v-btn :id="`salesforce-${field.dialog}-button`"
               rounded color="primary"
               class="text-capitalize"
               @click.stop="openSalesforceAccountSearchDialog"
        >
            {{ field.label }}
        </v-btn>
        <SalesforceAccountSearchDialog v-if="isSearchDialog" :dialog="isSalesforceAccountSearchDialogOpen"/>
    </div>
</template>

<script>
import SalesforceAccountSearchDialog from "@/components/views/dialogs/SalesforceAccountSearchDialog";

export default {
    "props": ["field"],
    "components": {
        SalesforceAccountSearchDialog
    },
    "methods": {
        openSalesforceAccountSearchDialog () {
            this.$store.dispatch("salesforceFields/openDialog", {"open": true, "dialog": this.field.dialog});
        }
    },
    "computed": {
        isSalesforceAccountSearchDialogOpen () {
            return this.$store.state.mdg.salesforceAccountSearchDialogOpen;
        },
        isSearchDialog () {
            return this.field.dialog === "search";
        }
    }
};

</script>
