import {toNumber} from "lodash";

function validateShareCapital (value) {
    return value <= 100;
}

function calculateTotalShareCapital (individuals, shareCapital = 0) {
    const shareCapitalValues = individuals.map((individual) => toNumber(individual.share_capital));
    shareCapitalValues.push(shareCapital);

    return shareCapitalValues.reduce((total, value) => total + value, 0);
}

function getUboIndividuals (individuals, id) {
    return individuals.filter((individual) => individual.isUbo && individual.id !== id);
}

function isUboShareCapitalRequired (data) {
    return !(data.isUbo && !data.share_capital);
}

export function validateUboShareCapital (data) {
    const individuals = data.$store.state.applicationFormValues.individuals;
    // eslint-disable-next-line camelcase
    const { isUbo, id, share_capital } = data.tableData;

    if (isUbo) {
        const totalShareCapital =
            calculateTotalShareCapital(getUboIndividuals(individuals, id), toNumber(share_capital));

        return validateShareCapital(totalShareCapital);
    }

    return true;
}

export function isUboShareCapitalFiledGiven (field, data, rules) {
    if (field === "share_capital" &&
        !isUboShareCapitalRequired(data.tableData) &&
        data.validationHardRules.requiredShareCapital) {
        rules.push(...data.validationHardRules.requiredShareCapital);
    }
}

export function isCombinedShareCapitalValid (field, data, rules) {
    if (field === "share_capital") {
        rules.push(() => validateUboShareCapital(data) || "The combined percentages of all the Ubo's should be between 25.1% to a 100%");
    }
}
