<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="read-only-label-size">
    <v-menu max-width="290" :close-on-content-click="false" v-model="showDatePicker">
      <template v-slot:activator="{ on }">
          <v-text-field
              :value="date(formValue)"
              v-on="on"
              readonly
              :label="field.label"
              :rules="setRules(field.rules)"
           ></v-text-field>
      </template>
      <v-date-picker
          :value="date(formValue)"
          :rules="setRules(field.rules)"
          color="primary"
          @click:date="closeDatePicker($event)"
          :disabled="!canEdit"
          :max="setMaxDateRule(field.maxDate)"
      />
    </v-menu>
  </div>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import moment from "moment";

export default {
    "extends": BaseElement,
    "components": {},
    "methods": {
        closeDatePicker (event) {
            this.showDatePicker = false;
            this.updateValue(moment(event).utc().add(
                12,
                "hours"
            ).toISOString());
        },
        date (formValue = null) {
            const formattedDate = moment(formValue).format("YYYY-MM-DD");
            return formattedDate === "Invalid date" ? null : formattedDate;
        },
        setMaxDateRule (maxDate) {
            if (maxDate === "currentDay") {
                return moment().format("YYYY-MM-DD");
            }

            return null;
        }
    },
    data () {
        return {"showDatePicker": false};
    }
};

</script>
