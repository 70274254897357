import Vue from "vue";
const state = {"statusses": null, "dirty": false};

const mutations = {
    SET_STATUSSES (currentState, statusses) {
        Vue.set(currentState, "statusses", statusses);
    },
    SET_CURRENT_STATUS_DIRTY (currentState) {
        Vue.set(currentState, "dirty", true);
    },
    SET_CURRENT_STATUS_CLEAN (currentState) {
        Vue.set(currentState, "dirty", false);
    }
};

const getters = {
    "getStatusses": (currentState) => currentState.statusses,
    "getStatusByName": (currentState) => (statusName) => {
        if (statusName && Array.isArray(currentState.statusses)) {
            let foundStatus = currentState.statusses.find((status) => status.status === statusName);

            if (typeof foundStatus === "undefined") {
                const statusNameCapitalized = statusName.charAt(0).toUpperCase() + statusName.slice(1);
                foundStatus = currentState.statusses.find((status) => status.status === statusNameCapitalized);
            }
            return foundStatus;
        }

        return typeof "undefined";
    }
};

const actions = {
    async setStatusses ({commit}, statusses) {
        await commit(
            "SET_STATUSSES",
            statusses
        );
    },
    async setCurrentStatusDirty ({commit}) {
        await commit("SET_CURRENT_STATUS_DIRTY");
    },
    async setCurrentStatusClean ({commit}) {
        await commit("SET_CURRENT_STATUS_CLEAN");
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
