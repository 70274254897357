/* eslint-disable no-console */
import _ from "lodash";
import groupEntriesByDigit from "@/utils/groupEntriesByDigit";
import replaceStringInKeys from "@/utils/replaceStringInKeys";

const feeTranslation = {
    "feeCategory": "category",
    "feeOption": "fee_option"
};

const feeFields = [
    "category",
    "amount",
    "frequency",
    "fee_option",
    "activation_delay",
    "duration",
    "type",
    "action"
];

export function parseToFormType (data, formTypes) {
    const returnData = {...data};
    Object.keys(returnData).forEach((key) => {
        const type = formTypes[key];

        switch (type) {
        case "String":
            returnData[key] = String(returnData[key]);
            break;
        case "Int":
            if (typeof returnData[key] === "boolean") {
                returnData[key] = returnData[key] ? 1 : 0;
            } else {
                returnData[key] = parseInt(returnData[key], 10);
                returnData[key] = isNaN(returnData[key]) ? null : returnData[key];
            }
            break;
        case "Date":
            returnData[key] = new Date(returnData[key]);
            break;
        case "Float":
            returnData[key] = parseFloat(returnData[key]);
            returnData[key] = isNaN(returnData[key]) ? null : returnData[key];
            break;
        default:
            break;
        }
    });
    return returnData;
}

function correctValues (values) {
    const correctMap = {
        "max_number_of_fuel_cards": "max_number_of_fuelcards",
        "min_number_of_fuel_cards": "min_number_of_fuelcards",
        "solaris_bank_enabled": "is_solarisbank_enabled",
        "slim_pay_enabled": "is_slimpay_enabled",
        "contact_person_table_enabled": "is_contact_person_table_enabled"
    };
    Object.keys(correctMap).forEach((field) => {
        if (!_.isNil(values[field])) {
            values[correctMap[field]] = values[field];
            delete values[field];
        }
    });
    return values;
}

/**
 * The templates in the database are not compatible with our system and the db itself.
 * Adjustments to key names are made here as well as adjustments to value types
 * @param templateData Template from the database that needs to be applied
 * @param formTypes Actual types that graphql expects the fields to be
 */
export function convertTemplate (templateData, formTypes) {
    const snakeCasedData = _.mapKeys(templateData, (value, key) => _.snakeCase(key));
    const snakeCasedFormTypes = _.mapKeys(formTypes, (value, key) => _.snakeCase(key));

    const correctedData = correctValues(snakeCasedData);

    const dataKeys = Object.keys(correctedData);
    const returnData = {};
    Object.keys(snakeCasedFormTypes).forEach((key) => {
        if (dataKeys.includes(key)) {
            returnData[key] = correctedData[key];
        }
    });

    return parseToFormType(returnData, snakeCasedFormTypes);
}

export function extractFees (templateData) {
    const translatedData = replaceStringInKeys(templateData, feeTranslation);
    return groupEntriesByDigit(translatedData, feeFields, true);
}
