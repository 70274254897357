<template>
    <v-dialog v-if="dialog" :value="dialog" scrollable persistent max-width="1300">
        <v-card height="80vh">
            <v-card-title class="py-3 border-bottom">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-text-field v-if="editable"
                                      hide-details
                                      maxlength="40"
                                      single-line
                                      dense
                                      @blur="saveTitleHandler"
                                      ref="title"
                                      v-model="currentFilter.name"
                        ></v-text-field>
                        <div class="text-no-wrap" v-if="!editable" @click.stop="focusTitle">
                            {{ currentFilter.name }}
                            <v-btn text icon id="editPencilIcon" class="primary--text" @click.stop="focusTitle">
                                <v-icon>mdi-pencil-outline</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn class="text-capitalize px-12 mr-10 align-self-center"
                           rounded
                           color="primary"
                           @click.stop="saveFilter">Save
                    </v-btn>
                    <v-btn class="primary--text float-right"
                           text icon
                           @click.stop="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-col lg="12" class="p-0">
                    <v-tabs v-model="tab" vertical>
                        <v-tab class="border-bottom" id="status-filter-tab">status</v-tab>
                        <v-tab class="border-bottom" id="campaign-filter-tab">Campaign</v-tab>
                        <v-tab class="border-bottom" id="created-filter-tab">created</v-tab>
                        <v-tab class="border-bottom" id="modified-filter-tab">modified</v-tab>
                        <v-tab class="border-bottom" id="last-edited-by-filter-tab">Last edited by</v-tab>
                        <v-tab class="border-bottom" id="sla-filter-tab">Sla</v-tab>
                        <v-tab class="border-bottom" id="sales-rep-filter-tab">Sales rep</v-tab>
                        <v-tab class="border-bottom" id="columns-filter-tab">Columns</v-tab>
                        <v-tab-item>
                            <v-col cols="12">
                                <h4>Status</h4>
                                <v-list dense>
                                    <v-list-group
                                        v-for="statusGroup in statusGroups"
                                        :key="statusGroup.id"
                                        no-action
                                        sub-group
                                        value="true">
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ statusGroup.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item v-for="status in statusForGroup(statusGroup.id)" :key="status.id">
                                            <v-checkbox dense multiple v-model="currentFilter.status" hide-details
                                                        :label="status.translation" :value="status.status"
                                                        class="statusFilter_item"
                                                        color="primary"
                                                        :id="'status_filter_'+ status.id"
                                            ></v-checkbox>
                                        </v-list-item>
                                    </v-list-group>
                                </v-list>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item>
                            <v-col cols="12">
                                <h4>Campaign</h4>
                                <TreeView id="campaign_tree"
                                          :selectedItems="currentFilter.treeIds"
                                          @setSelectedItems="setSelectedTreeIds"
                                          :itemArray="tree">
                                </TreeView>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item>
                            <v-col cols="12">
                                <div style="width: 350px">
                                    <v-btn class="primary--text float-right"
                                           id="clearCreatedDateRangeButton"
                                           icon @click="clearCreatedDate">
                                        <v-icon>mdi-delete-forever-outline</v-icon>
                                    </v-btn>
                                    <h4>Created</h4>
                                    <v-date-picker
                                        :selected-items-text="createdDateText"
                                        width="350"
                                        color="primary"
                                        v-model="currentFilterCreated"
                                        range>
                                    </v-date-picker>
                                </div>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item>
                            <v-col cols="12">
                                <div style="width: 350px">
                                    <v-btn class="primary--text float-right"
                                           id="clearModifiedDateRangeButton"
                                           icon @click="clearModifiedDate">
                                        <v-icon>mdi-delete-forever-outline</v-icon>
                                    </v-btn>
                                    <h4>Modified</h4>
                                    <v-date-picker
                                        :selected-items-text="modifiedDateText"
                                        width="350"
                                        color="primary"
                                        v-model="currentFilterModified"
                                        multiple
                                        range>
                                    </v-date-picker>
                                </div>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item>
                            <v-col cols="12">
                                <h4>Last edited by</h4>
                                <v-col>
                                    <v-combobox
                                        id="last-edited-by-text-field"
                                        v-model="currentFilter.lastEditedBy"
                                        label="Name:"
                                        multiple
                                        chips
                                        closable
                                        hint="Press enter to insert multiple search criteria"
                                        persistent-hint
                                        append-icon=""
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                close
                                                color="primary"
                                                outlined
                                                @click:close="removeLastEditedByChip(data)">
                                                <strong>{{ data.item }}</strong>&nbsp;
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item>
                            <v-col cols="12">
                                <h4>SLA</h4>
                                <v-col cols="4">
                                    <v-list-item>
                                        <v-checkbox dense multiple v-model="currentFilter.sla_warning" hide-details
                                                    label="SLA Warning" value="sla_warning"
                                                    class="statusFilter_item"
                                                    color="primary"
                                        ></v-checkbox>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-checkbox dense multiple v-model="currentFilter.sla_escalation" hide-details
                                                    label="SLA Escalation" value="sla_escalation"
                                                    class="statusFilter_item"
                                                    color="primary"
                                        ></v-checkbox>
                                    </v-list-item>
                                </v-col>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item>
                            <v-col cols="12">
                                <h4>Sales rep</h4>
                                <v-col>
                                    <v-combobox
                                        id="last-edited-by-text-field"
                                        v-model="currentFilter.salesRep"
                                        label="NTID:"
                                        multiple
                                        chips
                                        closable
                                        hint="Press enter to insert multiple search criteria"
                                        persistent-hint
                                        append-icon=""
                                    >
                                        <template v-slot:selection="data">
                                            <v-chip
                                                close
                                                color="primary"
                                                outlined
                                                @click:close="removeSalesRepChip(data)">
                                                <strong>{{ data.item }}</strong>&nbsp;
                                            </v-chip>
                                        </template>
                                    </v-combobox>
                                </v-col>
                            </v-col>
                        </v-tab-item>
                        <v-tab-item>
                            <v-col cols="12">
                                <p class="v-size--x-small">Selected items:</p>
                                <draggable class="d-flex flex-wrap align-center"
                                           v-model="currentFilter.columnIds"
                                           :options="{ animation: 250 }"
                                           draggable=".item"
                                >
                                    <v-chip
                                        v-for="(item, index) in currentFilter.columnIds"
                                        :key="index"
                                        class="item ma-2"
                                        color="rgba(178,213,195,0.3)"
                                        text-color="#4c4d4c"
                                        label
                                        draggable
                                        small
                                        close
                                        close-icon="mdi mdi-close"
                                        @click:close="removeSetColumn(item)"
                                    >
                                        {{ item }}
                                    </v-chip>
                                    <div slot="footer">
                                        <v-chip
                                            v-if="!isAddingNew"
                                            color="rgba(178,213,195,0.3)"
                                            label
                                            class="ma-2"
                                            small
                                            @click="isAddingNew = true"
                                        >
                                            +
                                        </v-chip>

                                        <div style="width: 9rem; height: 24px" v-else>
                                            <v-autocomplete
                                                :items="allColumns()"
                                                item-text="translation"
                                                item-value="id"
                                                return-object
                                                background-color="rgba(178,213,195,0.3)"
                                                autofocus
                                                height="24px"
                                                class="pa-0 ma-0"
                                                style="border-radius: 5px"
                                                v-model="newColumn"
                                                @change="isAddingNew = false"
                                                @blur="isAddingNew = false"
                                                @input="addNewColumn(newColumn)"
                                            ></v-autocomplete>
                                        </div>
                                    </div>
                                </draggable>
                                <h4>Columns</h4>
                                <v-list dense>
                                    <v-list-group
                                        v-for="segment in selectedSegments"
                                        :key="segment.id"
                                        no-action
                                        sub-group
                                        value="true">
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>{{ segment.title }}</v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item v-for="column in segment.fields" :key="column.id">
                                            <v-checkbox dense multiple hide-details v-model="currentFilter.columnIds"
                                                        :label="column.translation"
                                                        :value="column.id"
                                                        color="primary"
                                                        :id="'filter_'+ column.id"
                                            ></v-checkbox>
                                        </v-list-item>
                                    </v-list-group>
                                </v-list>
                            </v-col>
                        </v-tab-item>
                    </v-tabs>
                </v-col>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
/* eslint max-lines: off */
import TreeView from "../../form/elements/TreeView";
import draggable from "vuedraggable";
import extraSegments from "./extraSegments.json";
import lodash from "lodash";
import moment from "moment";

export default {
    "name": "EditFilterDialog",
    "props": {"filter": {"default": null}},
    "components": {TreeView, draggable},
    "computed": {
        formLabels () {
            return this.$store.state.application.formLabels;
        },
        "dialog" () {
            return this.filter !== null;
        },
        tree () {
            return this.$store.state.listFilters.applications.campaignFilterTree;
        },
        statusGroups () {
            return this.$store.state.statusGroups.statusGroups;
        },
        createdDateText () {
            return this.composeDateText("created");
        },
        modifiedDateText () {
            return this.composeDateText("modified");
        },
        statuses () {
            return this.$store.state.statusses.statusses;
        },
        // eslint-disable-next-line complexity,max-statements
        selectedSegments () {
            const forbiddenFields = ["registrationNumber", "taxCountry", "registrationIssuer", "businessPurpose", "foundationDate", "region", "province", "county", "bank_account", "bic", "legal_form", "legal_type"];
            const segments = [];
            for (const segment of this.$store.state.formSegments.applicationFormSegments) {
                const elements = [];
                for (const key of Object.keys(segment.fields).filter((item) => !forbiddenFields.includes(item))) {
                    const element = segment.fields[key];
                    if (["text", "radio", "select", "checkbox", "date"].includes(element.type) ||
                        ["salesforce_account_id", "salesforce_opportunity_id", "ready_for_review_date", "oaf_created_by"].includes(key)
                    ) {
                        elements.push({
                            "id": element.queryTranslation ? element.queryTranslation : key,
                            "translation": this.getLabel(key) ?? element.label
                        });
                    }
                }
                if (elements.length > 0) {
                    segments.push({...segment, "fields": elements});
                }
            }
            return this.mergeSegments(segments, this.getExtraSegments());
        }
    },
    "methods": {
        getExtraSegments () {
            const extraSegmentsWithLabels = [];
            for (const segment of extraSegments) {
                const elements = [];
                for (const value of segment.fields) {
                    const label = this.getLabel(value.id);
                    elements.push({
                        "id": value.id,
                        "translation": label ?? value.translation
                    });
                }
                if (elements.length > 0) {
                    extraSegmentsWithLabels.push({...segment, "fields": elements});
                }
            }
            return extraSegmentsWithLabels;
        },
        addNewColumn (column) {
            if (!this.currentFilter.columnIds) {
                this.$set(this.currentFilter, "columnIds", []);
            }

            if (!this.currentFilter.columnIds.includes(column.id)) {
                this.currentFilter.columnIds.push(column.id);
            }

            this.newColumn = null;
        },
        allColumns () {
            return this.selectedSegments.reduce((mergedFields, segment) => {
                segment.fields.forEach((column) => {
                    mergedFields.push(column);
                });
                return mergedFields;
            }, []);
        },
        removeSetColumn (column) {
            this.currentFilter.columnIds = this.currentFilter.columnIds.filter((item) => item !== column);
        },
        setSelectedTreeIds (selectedTreeIds) {
            this.currentFilter.treeIds = selectedTreeIds;
        },
        composeDateText (type) {
            const [from, to] = type === "created"
                ? this.currentFilterCreated
                : this.currentFilterModified;
            const [start, end] = [
                moment(from).format("DD-MM-YY"),
                moment(to).format("DD-MM-YY")
            ].sort();
            return `From ${start} to ${end}`;
        },
        getLabel (key) {
            return this.formLabels?.find((label) => label.keyword === key)?.label;
        },
        closeDialog () {
            this.$emit(
                "update:filter",
                null
            );
        },
        saveFilter () {
            this.$store.dispatch(
                "listFilters/addEditPersonalFilter",
                this.currentFilter
            );
            this.$emit(
                "update:filter",
                null
            );
        },
        focusTitle () {
            this.editable = true;
            this.$nextTick(() => this.$refs.title.focus());
        },
        saveTitleHandler () {
            this.editable = false;
        },
        statusForGroup (groupId) {
            const statusses = this.$store.state.statusses.statusses;
            return lodash.filter(
                statusses,
                (status) => status.group_id === groupId
            );
        },
        clearModifiedDate () {
            this.currentFilterModified = [];
        },
        clearCreatedDate () {
            this.currentFilterCreated = [];
        },
        removeLastEditedByChip (item) {
            this.currentFilter.lastEditedBy.splice(item.index, 1);
        },
        removeSalesRepChip (item) {
            this.currentFilter.salesRep.splice(item.index, 1);
        },
        mergeSegments (segmentsA, segmentsB) {
            const mergedDict = {};
            [segmentsA, segmentsB].flat().forEach((item) => {
                mergedDict[item.id] = mergedDict[item.id] || {"id": item.id, "title": item.title, "fields": []};
                mergedDict[item.id].fields = mergedDict[item.id].fields.concat(item.fields);
            });
            return Object.values(mergedDict);
        }
    },
    data () {
        return {
            "currentDate": [],
            "currentFilter": null,
            "tab": null,
            "editable": false,
            "currentFilterModified": [],
            "currentFilterCreated": [],
            "isAddingNew": false,
            "newColumn": null
        };
    },
    "watch": {
        currentFilterModified (dateRange) {
            if (this.currentFilter) {
                this.currentFilter.modified = dateRange;
            }
        },
        currentFilterCreated (dateRange) {
            if (this.currentFilter) {
                this.currentFilter.created = dateRange;
            }
        },
        filter (filter) {
            this.currentFilter = JSON.parse(JSON.stringify(filter));
            if (this.currentFilter && this.currentFilter.created) {
                this.currentFilterCreated = this.currentFilter.created;
            } else {
                this.currentFilterCreated = [];
            }
            if (this.currentFilter && this.currentFilter.modified) {
                this.currentFilterModified = this.currentFilter.modified;
            } else {
                this.currentFilterModified = [];
            }
        }
    }
};
</script>
<style>
  .v-date-picker-title__date > div {
    font-size: 20px;
  }
</style>
