import Vue from "vue";
const state = {"sessionLoaded": false};
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const mutations = {
    SET_SESSION_LOADED (currentState, sessionLoaded) {
        Vue.set(currentState, "sessionLoaded", sessionLoaded);
    },

    SET_FORM_LABELS (currentState, data) {
        Vue.set(currentState, "formLabels", data);
    }
};

const getters = {};

const actions = {

    async setSessionLoaded ({commit}) {
        await commit(
            "SET_SESSION_LOADED",
            true
        );
    },

    async getFormLabels ({commit}) {
        const query = gql` query { form_label(first:10000) { data {keyword label} } } `;
        const data = await apolloClient.query({query});
        commit("SET_FORM_LABELS", data.data.form_label.data);
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};

