<template>
    <v-container class="pl-0">
        <v-btn rounded color="primary"
               id="dnb_lookup_button"
               class="text-capitalize"
               @click.stop="dialog = true"
               data-nw="dunsBtn"
        >DnB lookup
        </v-btn>
        <DnbDialog :dialog.sync="dialog"></DnbDialog>
    </v-container>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import DnbDialog from "../../views/dialogs/DnbDialog";

export default {
    "extends": BaseElement,
    "components": {
        DnbDialog
    },
    data () {
        return {"dialog": false};
    }
};

</script>
