import Vue from "vue";

const state = {
    "mdgSearchDialogOpen": false,
    "mdgValidateDialogOpen": false
};

const mutations = {
    OPEN_DIALOG (currentState, data) {
        if (data.dialog === "search") {
            Vue.set(currentState, "mdgSearchDialogOpen", data.open);
        } else {
            Vue.set(currentState, "mdgValidateDialogOpen", data.open);
        }
    }
};

const getters = {};

const actions = {
    async openDialog ({ commit }, data) {
        await commit("OPEN_DIALOG", data);
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
