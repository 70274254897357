import Vue from "vue";
const state = {"statusGroups": null};

const mutations = {
    SET_STATUS_GROUPS (currentState, statusGroups) {
        Vue.set(currentState, "statusGroups", statusGroups);
    }
};

const getters = {};

const actions = {
    async setStatusGroups ({commit}, statusGroups) {
        await commit(
            "SET_STATUS_GROUPS",
            statusGroups
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
