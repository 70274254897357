<template>
    <div>
        <v-row>
            <v-col>
        <v-data-table dense
                      :loading="loading"
                      :headers="headersWithActions"
                      :items="legalRepresentatives"
                      :disable-pagination="true"
                      :hide-default-footer="true"
                      item-key="id"
                      id="legal_representative_data_table">
            <template v-slot:item.first_name="{item}">
                {{ item.first_name }}
            </template>

            <template v-slot:item.last_name="{item}">
                {{ item.last_name }}
            </template>

            <template v-slot:item.email="{item}">
                {{ item.email }}
            </template>

            <template v-slot:item.addres_line_1="{item}">
                {{ item.addres_line_1 }}
            </template>

            <template v-slot:item.address_city="{item}">
                {{ item.address_city }}
            </template>

            <template v-slot:item.address_postal_code="{item}">
                {{ item.address_postal_code }}
            </template>

            <template v-slot:item.address_country="{item}">
                {{ item.address_country }}
            </template>

            <template v-slot:item.is_beneficial_owner="{item}">
                <v-icon v-if="item.is_beneficial_owner === 1" color="green" dense>mdi-check-circle</v-icon>
                <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
            </template>

            <template v-slot:item.is_signee="{item}">
                <v-icon v-if="item.is_signee === 1" color="green" dense>mdi-check-circle</v-icon>
                <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
            </template>

            <template v-slot:item.is_legal_representative="{item}">
                <v-icon v-if="item.is_legal_representative === 1" color="green" dense>mdi-check-circle</v-icon>
                <v-icon v-else color="red" dense>mdi-close-circle</v-icon>
            </template>

            <template v-slot:item.action="{item}">
                <v-btn class="mx-2"
                       :id="`open_document_person_btn${item.id}`"
                       text icon
                       fab
                       small
                       color="primary"
                       @click="openDocumentPersonDialog(item, false)"
                       :disabled="!canEdit">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </template>

        </v-data-table>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-tooltip bottom :disabled="hasBusiness">
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="d-inline-block">
                        <v-btn id="add_document_person_btn"
                               :disabled="!hasBusiness"
                               class="text-capitalize mr-5"
                               right rounded color="primary"
                               @click.stop="openDocumentPersonDialog({'business_id': businessId}, true)">Add
                            <v-icon v-if="!hasBusiness">mdi-information</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>Unavailable due to absence of a solaris bank business. Save the application to create one.</span>
            </v-tooltip>
        </v-row>
        <DocumentPersonDialog
            :EditDocuPersonDialog.sync="dialogOpen"
            :items="item"
            :isNew="isNew"
            :tableHeaders="this.field.tableHeaders"
        >
        </DocumentPersonDialog>
    </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import DocumentPersonDialog from "../../views/dialogs/DocumentPersonDialog";
import {getFieldsFromTableHeaders} from "@/service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "components": {DocumentPersonDialog},
    "extends": BaseElement,
    data () {
        return {
            "extraQueryFields": [
                "screening_progress",
                "customer_vetting_status",
                "risk_classification_status",
                "cdd_color"
            ],
            "dialogOpen": false,
            "error": null,
            "item": {},
            "loading": true,
            "skipQuery": true,
            "businessId": 0,
            "isNew": null,
            "hasBusiness": false
        };
    },
    "apollo": {
        "getDocumentId": {
            query () {
                if (this.$route.params.id) {
                    return gql`query {getDocumentId:
                                solaris_bank_business(form_id: ${this.$route.params.id}) {data {id}}}`;
                }
                return gql`query {getDocumentId:
                                solaris_bank_business(form_id: 0) {data {id}}}`;
            },
            update (data) {
                if (data.getDocumentId.data.length !== 0) {
                    this.businessId = data.getDocumentId.data[0].id;
                    this.skipQuery = false;
                    this.$apollo.queries.getDocumentPerson.skip = false;
                    this.hasBusiness = true;
                } else {
                    this.$store.dispatch(
                        "applicationFormValues/setDocumentsPerson",
                        []
                    );
                    this.hasBusiness = false;
                    this.loading = false;
                }
            },
            "fetchPolicy": "no-cache"
        },
        "getDocumentPerson": {
            query () {
                const fields = this.combineFieldsInString();
                // eslint-disable-next-line max-len
                return gql` {getDocumentPerson: solaris_bank_person(business_id: ${this.businessId}) {data{${fields} business_id}}}`;
            },
            update (data) {
                this.loading = false;
                this.$store.dispatch(
                    "applicationFormValues/setDocumentsPerson",
                    data.getDocumentPerson.data
                );
                this.$root.$emit("submitAssignedPersons");
                this.$root.$emit("submitAssignedCompany");
            },
            skip () {
                return this.skipQuery;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        },
        legalRepresentatives () {
            return this.$store.state.applicationFormValues.documentsPerson;
        },
        headersWithActions () {
            const newHeaders = JSON.parse(JSON.stringify(this.tableHeaders));
            newHeaders.forEach((header) => {
                header.sortable = false;
            });
            newHeaders.push({
                "value": "action",
                "text": "",
                "sortable": false
            });
            return newHeaders;
        },
        fields () {
            return this.field.tableHeaders.filter((field) => !field.exclude);
        },
        tableHeaders () {
            return this.field.tableHeaders.filter(({displayTable}) => displayTable);
        }
    },
    "methods": {
        openDocumentPersonDialog (item, isNew) {
            this.item = item;
            this.dialogOpen = true;
            this.isNew = isNew;
        },
        combineFieldsInString () {
            return `${getFieldsFromTableHeaders(this.fields)} ${this.extraQueryFields.join(" ")}`;
        }
    }
};
</script>
