import Vue from "vue";

const defaultState = {
    "documents": [],
    "deletedDocuments": [],
    "documentTypes": [],
    "documentDialog": false,
    "warningDialog": false,
    "addDocumentDialog": false,
    "loadingTypes": true
};

const mutations = {
    SET_DOCUMENT_DIALOG (currentState, data) {
        Vue.set(currentState, "documentDialog", data);
    },
    SET_WARNING_DIALOG (currentState, data) {
        Vue.set(currentState, "warningDialog", data);
    },
    SET_ADD_DOCUMENT_DIALOG (currentState, data) {
        Vue.set(currentState, "addDocumentDialog", data);
    },
    SET_DOCUMENTS (currentState, data) {
        Vue.set(currentState, "documents", data);
    },
    SET_DOCUMENT_BY_ID (currentState, data) {
        const file = currentState.documents.find((doc) => doc.id === data.id);
        // eslint-disable-next-line camelcase
        file.file_content = data.file_content;
        file.name = data.name;
        file.mimetype = data.mimetype;
        file.size = data.size;
        if (data.verified_by) {
            // eslint-disable-next-line camelcase
            file.verified_by = data.verified_by;
        }
        Vue.set(currentState, "documents", currentState.documents);
    },
    SET_DOCUMENT_TYPES (currentState, data) {
        const documentTypes = [];
        data.map((docType) => documentTypes.push({
            "value": docType.document_type_id,
            "text": docType.document_translation,
            "is_sensitive": docType.is_sensitive,
            "is_required": docType.is_required,
            "document_solaris_name": docType.document_solaris_name
        }));

        Vue.set(currentState, "documentTypes", documentTypes);
        Vue.set(currentState, "loadingTypes", false);
    },
    ADD_DOCUMENT (currentState, data) {
        const missingFile = currentState.documents.find((doc) => doc.name === "Missing file" && doc.business_document_type === data.business_document_type);
        if (missingFile) {
            // eslint-disable-next-line camelcase
            missingFile.file_content = data.file_content;
            missingFile.name = data.name;
            missingFile.mimetype = data.mimetype;
            missingFile.size = data.size;
            if (data.verified_by) {
                // eslint-disable-next-line camelcase
                missingFile.verified_by = data.verified_by;
            }
        } else {
            currentState.documents.push(data);
        }
        Vue.set(currentState, "documents", currentState.documents);
    },
    DELETE_DOCUMENT_BY_INDEX (currentState, data) {
        currentState.documents.splice(data, 1);
        Vue.set(currentState, "documents", currentState.documents);
    },
    CLEAR_DOCUMENTS (currentState) {
        currentState.documents = [];
        Vue.set(currentState, "documents", currentState.documents);
    }
};

const actions = {
    async setDocumentDialog ({ commit }, data) {
        await commit("SET_DOCUMENT_DIALOG", data);
    },
    async setWarningDialog ({ commit }, data) {
        await commit("SET_WARNING_DIALOG", data);
    },
    async setAddDocumentDialog ({ commit }, data) {
        await commit("SET_ADD_DOCUMENT_DIALOG", data);
    },
    async setDocuments ({ commit }, data) {
        await commit("SET_DOCUMENTS", data);
    },
    async setDocumentById ({commit}, data) {
        await commit("SET_DOCUMENT_BY_ID", data);
    },
    async setDocumentTypes ({ commit }, data) {
        await commit("SET_DOCUMENT_TYPES", data);
    },
    async addDocument ({ commit }, data) {
        await commit("ADD_DOCUMENT", data);
    },
    async deleteDocument ({ commit }, data) {
        await commit("DELETE_DOCUMENT", data);
    },
    async deleteDocumentByIndex ({ commit }, data) {
        await commit("DELETE_DOCUMENT_BY_INDEX", data);
    },
    async clearDocuments ({ commit }) {
        await commit("CLEAR_DOCUMENTS");
    },
    async changeDocumentValues ({ commit }, data) {
        await commit("CHANGE_DOCUMENT_VALUES", data);
    }
};

const getters = {
    "getDocumentById": (currentState) => (id) => currentState.documents.find((document) => document.id === id),
    "getDocuments": (currentState) => currentState.documents
};

export default {
    "namespaced": true,
    "state": defaultState,
    getters,
    actions,
    mutations
};
