var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headersWithActions,"items":_vm.filterDeleted(_vm.fees),"disable-pagination":true,"hide-default-footer":true,"item-key":"id","id":"fees_data_table"},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{staticClass:"card-select-style-wide card-select-font-size",on:{"open":function($event){return _vm.open(item.category)},"close":function($event){return _vm.save({id: item.id, category: _vm.currentEdit})}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"maxlength":100,"value":item.category,"placeholder":"Category","single-line":"","autofocus":"","disabled":!_vm.canEdit},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.category))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"open":function($event){return _vm.open(item.amount)},"close":function($event){_vm.save({id: item.id, amount: parseFloat(_vm.currentEdit)})}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"value":item.amount,"placeholder":"Amount","single-line":"","type":"number","autofocus":"","disabled":!_vm.canEdit},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.amount))+" ")])]}},{key:"item.frequency",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"card-select-style-wide card-select-font-size",attrs:{"value":item.frequency,"items":_vm.frequencies,"disabled":!_vm.canEdit || item.fee_option === 'L',"item-text":"label"},on:{"change":function($event){return _vm.save({id: item.id, frequency: $event})}}})]}},{key:"item.fee_option",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"card-select-style-wide card-select-font-size",attrs:{"value":item.fee_option,"items":_vm.feeOptions,"disabled":!_vm.canEdit,"item-text":"label"},on:{"change":function($event){return _vm.saveFeeOption({id: item.id, fee_option: $event})}}})]}},{key:"item.activation_delay",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"open":function($event){return _vm.open(item.activation_delay)},"close":function($event){return _vm.save({id: item.id, activation_delay: _vm.currentEdit})}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"maxlength":14,"value":item.activation_delay,"placeholder":"Activation delay","single-line":"","autofocus":"","disabled":!_vm.canEdit},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.activation_delay))+" ")])]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{on:{"open":function($event){return _vm.open(item.duration)},"close":function($event){return _vm.save({id: item.id, duration: _vm.currentEdit})}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"maxlength":14,"value":item.duration,"placeholder":"Duration","single-line":"","autofocus":"","disabled":!_vm.canEdit},model:{value:(_vm.currentEdit),callback:function ($$v) {_vm.currentEdit=$$v},expression:"currentEdit"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm._f("formatNoValue")(item.duration))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"card-select-style-wide card-select-font-size",attrs:{"value":item.type,"items":_vm.feeTypes,"disabled":!_vm.canEdit,"item-text":"label"},on:{"change":function($event){_vm.save({id: item.id, type: parseInt($event)})}}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"error--text mx-2",attrs:{"id":("remove_fee_btn" + (item.id)),"text":"","icon":"","disabled":!_vm.canEdit},on:{"click":function($event){return _vm.removeFee(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"text-capitalize mr-5",attrs:{"id":"add_fee_btn","right":"","rounded":"","color":"primary","disabled":!_vm.canEdit},on:{"click":function($event){$event.stopPropagation();return _vm.addFee.apply(null, arguments)}}},[_vm._v("Add ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }