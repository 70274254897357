<template>
  <v-card flat tile class="background-grey">
    <div class="bd-callout"
        v-bind:class="borderColor">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
export default {
    "props": ["segment"],
    "computed": {
        globalWarnings () {
            return this.$store.state.formSegments.globalWarnings;
        }
    },
    "watch": {
        globalWarnings () {
            if (this.segment.alert.length > 0) {
                this.borderColor = "bd-callout-red";
            } else {
                this.borderColor = "bd-callout-green";
            }
        }
    },
    data () {
        return {
            "borderColor": "bd-callout-green"
        };
    }
};
</script>

<style>
  .bd-callout {
    padding: 1.25rem;
    margin: 1.25rem 0 1.25rem 0;
    border-left: 0.5rem solid #eee;
  }

  .bd-callout-green {
    border-left-color: #99CC00;
  }

  .bd-callout-red {
      border-left-color: red;
  }
</style>
