import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";
const defaultState = {
    "options": {}
};

const mutations = {
    SET_OPTIONS (currentState, data) {
        Vue.set(currentState, "options", data);
    }
};

/**
 * Get the selected country data using the rootGetters
 * @param rootGetters
 * @returns {*}
 */
function getCurrentCountry (rootGetters) {
    const countries = rootGetters["countries/getCountryByIsoCode"];
    const currentCountry = rootGetters["applicationFormValues/country"];
    if (currentCountry) {
        return countries(currentCountry);
    }
    return null;
}

function getOptionsDependantOnSalesOrganizationSiebel (rootState, dropdownValues) {
    const salesOrganizationSiebelFormValue = rootState.applicationFormValues.values.sales_organization_siebel;
    if (typeof salesOrganizationSiebelFormValue === "undefined") {
        return [];
    }
    const filteredDropdownValues = [];
    dropdownValues.forEach((value) => {
        // If sales_organization_siebel in options table is null: add to dropdown
        if (value.sales_organization_siebel === null) {
            filteredDropdownValues.push(value.value);
        }
        // If sales_organization_siebel in options table matches sales_organization_siebel in form: add to dropdown
        if (value.sales_organization_siebel === salesOrganizationSiebelFormValue) {
            filteredDropdownValues.push(value.value);
        }
    });
    return filteredDropdownValues;
}

function getOptionsDependantOnCountry (rootGetters, dropdownValues) {
    // Get current Country data
    const countryData = getCurrentCountry(rootGetters);
    if (countryData && countryData.id) {
        // Filter dropdown options that belong to the currentCountry
        const filteredDropdownValues = dropdownValues.filter((value) => value.country_id === countryData.id);
        // v-select use text instead of label
        return filteredDropdownValues.map((option) => {
            option.text = option.label;
            return option;
        });
    }
    return [];
}

// eslint-disable-next-line max-params
const getters = {"getDropdownOptionsByName": (currentState, currentGetters, rootState, rootGetters) => (dropdownName) => {
    const dropdownValues = currentState.options[dropdownName];
    if (typeof dropdownValues !== "undefined") {
        if (dropdownValues.length === 0) {
            return [];
        }
        if ("sales_organization_siebel" in dropdownValues[0]) {
            return getOptionsDependantOnSalesOrganizationSiebel(rootState, dropdownValues);
        } else if ("country_id" in dropdownValues[0]) {
            return getOptionsDependantOnCountry(rootGetters, dropdownValues);
        } else if ("label" in dropdownValues[0]) {
            // v-select use text instead of label
            return dropdownValues.map((option) => {
                option.text = option.label;
                return option;
            });
        }
    }
    return [];
}};

const actions = {
    async getDropdownOptions ({commit}) {
        const parameters = "value";
        const parametersWithSos = parameters.concat(" sales_organization_siebel");
        const parametersWithLabel = parameters.concat(" label");
        const optionsGql = gql`query{getOptions:
        dropdownOptions {
            cardOffer {${parametersWithSos}}
            accountingClerk {${parametersWithSos}}
            customerType {${parametersWithSos}}
            dunningClerk {${parametersWithSos}}
            dunningProcedure {${parametersWithSos}}
            invoiceCycle {${parametersWithSos}}
            invoiceFrequency {${parametersWithSos}}
            nextPayee {${parametersWithSos}}
            partnerZF {${parametersWithSos}}
            partnerZL {${parametersWithSos}}
            partnerZR {${parametersWithSos}}
            paymentMethod {${parametersWithSos}}
            paymentTerms {${parametersWithSos}}
            requestType {${parametersWithSos}}
            guaranteeStatus {${parametersWithLabel}}
            siebelStatus {${parametersWithLabel}}
            leadSource {${parametersWithLabel}}
            feeType {${parametersWithLabel}}
            addressType {${parametersWithLabel}}
            salesOffice {${parametersWithSos}}
            alreadyHasFuelCards {${parametersWithLabel} country_id}
            pullCode {${parametersWithSos} text}
        }}`;
        const dropdownOptions = await apolloClient.query({"query": optionsGql});
        await commit("SET_OPTIONS", dropdownOptions.data.getOptions);
    }
};

export default {
    "namespaced": true,
    "state": defaultState,
    getters,
    actions,
    mutations
};
