<template>
    <v-container fluid v-if="$can('resignQueue', 'systemtools')">
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="Resign Quotation Queue"
            data-nw="resignQueue"
        />
        <v-layout align-center class="background-grey mt-12">
            <v-container>
                <v-textarea
                    id="formIds"
                    label="Form IDs to enter into the Resign Queue (values need to be comma separated, E.G. '1,2').
                     OAF_ prefixes will automatically be removed upon submitting."
                    name="form_ids"
                    v-model="formIds"
                    return-object
                    :data-nw="'resignQueueInput'"
                ></v-textarea>
            </v-container>
        </v-layout>
        <v-layout justify-end>
            <v-container>
                <v-layout justify-center>
                    <v-btn
                        id="submitResignQueue"
                        rounded
                        color="primary"
                        :disabled="this.formIds.length === 0"
                        @click="submitFormIds">
                        Update Resign Quotation Queue
                    </v-btn>
                </v-layout>
            </v-container>
        </v-layout>
    </v-container>
</template>
<script>

import HeaderBar from "../../uiElements/HeaderBar";
import gql from "graphql-tag";

export default {
    "name": "ResignQuotationQueue",
    "components": {HeaderBar},
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "Resign Quotation Queue",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ResignQueue"}
                }
            ],
            "formIds": []
        };
    },
    "methods": {
        async submitFormIds () {
            this.formIds = this.formIds.replace(/[\r\n]+/gmu, "");
            this.formIds = this.formIds.replace(/,\s*$/u, "");
            this.formIds = this.formIds.replace(/OAF_/gu, "");
            if (!(/^\d+(,\d+)*$/u).test(this.formIds)) {
                this.$toast("Input is not CSV formatted.", {"color": "error", "timeout": 1000});
                return;
            }
            await this.$store.dispatch(
                "loader/setLoading",
                true
            );
            const mutation = gql`mutation{ addResignQueueEntries (form_ids: [${this.formIds}]){responseString}}`;
            await this.$apollo.mutate({mutation})
                .then((data) => {
                    this.$store.dispatch(
                        "loader/setLoading",
                        false
                    );
                    const response = data.data.addResignQueueEntries.responseString;
                    switch (response) {
                    case "Successfully inserted data":
                        this.$toast("Successfully updated the queue", {"color": "success"});
                        break;
                    case "Failed to insert data":
                        this.$toast("Failed to update the queue, try again later", {"color": "error"});
                        break;
                    default:
                        this.$toast("An unknown error occurred while attempting to update the queue", {"color": "error"});
                        break;
                    }
                }).catch(() => {
                    this.$store.dispatch(
                        "loader/setLoading",
                        false
                    );
                    this.$toast(
                        // eslint-disable-next-line line-comment-position
                        "Something went wrong",
                        {"color": "error"}
                    );
                });
        }
    }
};
</script>
