<template>
  <v-btn
    rounded
    color="primary"
    v-bind="field"
    class="text-capitalize mt-3"
    :data-nw="this.name"
    @click="buttonClicked()">
    <v-icon v-if="field.iconLeft" left :dark="field.dark">{{field.iconLeft}}</v-icon>
    <v-icon v-if="field.iconCenter" :dark="field.dark">{{field.iconCenter}}</v-icon>
    {{field.label}}
    <v-icon v-if="field.iconRight" right :dark="field.dark">{{field.iconRight}}</v-icon>
  </v-btn>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";

export default {
    "extends": BaseElement,
    "props": ["field"],
    "components": {},
    "methods": {
        buttonClicked () {
            if (this.field.action) {
                this.$store.dispatch(this.field.action);
            }
        }
    }
};

</script>
