import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {"values": {}};


const mutations = {
    SET_FORM_VALUES (currentState, data) {
        Vue.set(currentState, "values", data);
    },
    UPDATE_FORM_VALUE (currentState, data) {
        Vue.set(currentState.values, data.name, data.value);
    }
};

const getters = {};

const actions = {
    async setFormValues ({commit}, data) {
        await commit(
            "SET_FORM_VALUES",
            data
        );
    },
    async updateFormValue ({commit}, data) {
        await commit(
            "UPDATE_FORM_VALUE",
            data
        );
    },
    saveForm (context, type) {
        let formMutationQuery = "";
        const data = context.state.values;
        for (const key of Object.keys(data)) {
            const fieldValue = data[key];
            formMutationQuery += typeof fieldValue === "string" ? `${key}: "${fieldValue}" ` : `${key}: ${fieldValue} `;
        }
        // eslint-disable-next-line max-len
        const mutation = gql`mutation{ ${type}_telesales_agency (${formMutationQuery}){responseString}}`;
        return apolloClient.mutate({mutation});
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
