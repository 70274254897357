<template>
  <v-dialog :value="googleMapsDialog" max-width="1300" persistent>
    <v-card id="google_maps_dialog">
      <v-card-title>
        <div id="current_address_field">{{address}}</div>
        <v-row justify="end">
          <v-btn id="close_google_maps_dialog_button"
                 class="primary--text"
                 text
                 @click.stop="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <iframe v-if="url"
                width="100%"
                height="425"
                style="border:0"
                :src="url"
        ></iframe>
        <v-row justify="end">
          <a class="mr-3 mt-3" href="http://maps.google.com/">Go to Google Maps</a>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
    "props": {
        "googleMapsDialog": {"default": false},
        "address": {"default": null},
        "url": {"default": null}
    },
    "methods": {
        closeDialog () {
            this.$emit(
                "update:googleMapsDialog",
                false
            );
        }
    }
};
</script>
