<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table dense
                      :loading="loading"
                      :headers="headersWithActions"
                      :items="filterDeleted(shareHolders)"
                      :disable-pagination="true"
                      :hide-default-footer="true"
                      item-key="id"
                      id="shareholders_data_table">
          <template v-slot:item.name="{item}">
            <v-edit-dialog
              @open="open(item.name)"
              @close="save({id: item.id, name: currentEdit})"
            > {{item.name|formatNoValue}}
              <template v-slot:input>
                <v-text-field
                  :maxlength="maxInputLength"
                  v-model="currentEdit"
                  :value="item.name"
                  placeholder="Name"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.birthday="{item}">
            <v-menu offset-y :close-on-content-click="false" v-model="showDatePicker[item.id]">
              <template v-slot:activator="{ on }">
                <div @open="open(item.birthday)" v-on="on">{{item.birthday|formatDate}}</div>
              </template>
              <v-date-picker v-if="showDatePicker[item.id]"
                             v-model="currentEdit"
                             :value="formatDatePickerValue(item.birthday)"
                             color="primary"
                             @click:date="saveAndCloseDate($event, item.id)"
                             @open="open(item.birthday)"
                             :disabled="!canEdit">
              </v-date-picker>
            </v-menu>
          </template>

          <template v-slot:item.address="{item}">
            <v-edit-dialog
              @open="open(item.address)"
              @close="save({id: item.id, address: currentEdit})"
            > {{ item.address|formatNoValue }}
              <template v-slot:input>
                <v-text-field
                  :maxlength="maxInputLength"
                  v-model="currentEdit"
                  :value="item.address"
                  placeholder="Address"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.zipcode="{item}">
            <v-edit-dialog
              @open="open(item.zipcode)"
              @close="save({id: item.id, zipcode: currentEdit})"
            > {{ item.zipcode|formatNoValue }}
              <template v-slot:input>
                <v-text-field
                  :maxlength="maxInputLength"
                  v-model="currentEdit"
                  :value="item.zipcode"
                  placeholder="Zipcode"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.city="{item}">
            <v-edit-dialog
              @open="open(item.city)"
              @close="save({id: item.id, city: currentEdit})"
            > {{ item.city|formatNoValue }}
              <template v-slot:input>
                <v-text-field
                  :maxlength="maxInputLength"
                  v-model="currentEdit"
                  :value="item.city"
                  placeholder="City"
                  single-line
                  autofocus
                  :disabled="!canEdit"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>

          <template v-slot:item.action="{item}">
            <v-btn class="error--text mx-2"
                   :id="`remove_share_holder_btn${item.id}`"
                   text icon
                   @click="removeShareholder(item)"
                   :disabled="!canEdit">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-btn id="add_share_holder_btn"
             :disabled="(shareHolders && shareHolders.length >= 6) || !canEdit"
             class="text-capitalize mr-5"
             right rounded color="primary"
             @click.stop="addShareholder">Add
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import {getFieldsFromTableHeaders} from "../../../service/queryBuilder.service";
import gql from "graphql-tag";
import moment from "moment";

export default {
    "extends": BaseElement,
    "apollo": {
        "getShareHolders": {
            // eslint-disable-next-line consistent-return
            query () {
                if (this.formId) {
                    const fields = getFieldsFromTableHeaders(this.tableHeaders);
                    return gql`
                        query GetShareHolders($formId: Int!) {
                            getShareHolders: shareholder(form_id: $formId) {
                                data {
                                    ${fields}
                                }
                            }
                        }
                    `;
                }
            },
            variables () {
                return {
                    "formId": this.formId
                };
            },
            update (data) {
                this.loading = false;
                this.$store.dispatch(
                    "applicationFormValues/setShareholders",
                    data?.getShareHolders?.data
                );
            },
            skip () {
                return !this.formId;
            }
        }
    },
    "computed": {
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        },
        shareHolders () {
            return this.$store.state.applicationFormValues.shareholders;
        },
        headersWithActions () {
            const newHeaders = JSON.parse(JSON.stringify(this.tableHeaders));
            newHeaders.shift();
            newHeaders.push({
                "value": "action",
                "text": "",
                "sortable": false
            });
            return newHeaders;
        }
    },
    "methods": {
        filterDeleted (shareholders) {
            return shareholders.filter((shareholder) => !shareholder.deleted);
        },
        addShareholder () {
            if (this.$store.state.applicationFormValues?.shareholders?.length < 6) {
                this.$store.dispatch("applicationFormValues/addShareholders");
            }
        },
        removeShareholder (item) {
            this.$store.dispatch(
                "applicationFormValues/deleteShareholders",
                item.id
            );
        },
        open (value) {
            this.currentEdit = value;
        },
        save (item) {
            this.$store.dispatch(
                "applicationFormValues/updateShareholders",
                item
            );
            this.currentEdit = null;
        },
        saveAndCloseDate (value, id) {
            this.showDatePicker[id] = false;
            this.save({
                id,
                "birthday": `${moment(value).utc().format("YYYY-MM-DDTHH:mm:ss")}.000Z`
            });
        },
        formatDatePickerValue (date) {
            return moment(date).format("YYYY-MM-DD");
        }
    },
    data () {
        return {
            "dialog": false,
            "error": null,
            "counter": 1,
            "currentEdit": null,
            "filterText": "",
            "loading": true,
            "tableHeaders": this.field.tableHeaders,
            "showDatePicker": {},
            "maxInputLength": 30
        };
    }
};
</script>
