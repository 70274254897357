<template>
    <v-container fluid >
        <HeaderBar
                :breadcrumbs="breadcrumbs"
                title="Dunning Clerk"
                data-nw="editDunningClerk"
        >
            <template v-slot:button>
                <LogDialog
                        data-nw="edit-dunning-clerk-data-logs"
                        label="Dunning Clerk Data Logs"
                        :id="dunningClerkId"
                        table="option_dunning_clerk"
                        v-if="$can('log', 'option_dunning_clerk')"
                >
                </LogDialog>
                <v-btn
                        data-nw="edit-dunning-clerk-submit-button"
                        v-if="$can('update', 'option_dunning_clerk')"
                        class="text-capitalize btn-block"
                        rounded color="primary"
                        @click="submitDunningClerkForm()">
                    Save Dunning Clerk
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
                type="update"
                store-module="dunningClerkFormValues"
                :form-segments="formSegments"
                :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../../baseComponents/BaseForm";
import HeaderBar from "../../../uiElements/HeaderBar";
import LogDialog from "../../dialogs/LogDialog";
import editDunningClerkForm from "../../../../formConfig/editDunningClerkForm.json";
import gql from "graphql-tag";

export default {
    "name": "EditDunningClerk",
    "components": {
        BaseForm,
        HeaderBar,
        LogDialog
    },
    "methods": {
        submitDunningClerkForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.getters.getFormSegments("dunningClerkForm");
        },
        dunningClerkId () {
            return this.$store.state.dunningClerkFormValues.values.id;
        }
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "Dunning Clerks",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListDunningClerks"}
                },
                {
                    "text": "Edit Dunning Clerk",
                    "disabled": true
                }
            ]
        };
    },
    "apollo": {
        "dunningClerkFormValues": {
            query () {
                let queryParameters = "";
                const segment = editDunningClerkForm.formSegments[0];
                Object.keys(segment.fields).forEach((field) => {
                    queryParameters = queryParameters.concat(
                        " ",
                        field
                    );
                });
                const visibilityFields = this.$store.getters["formSegments/getVisibilityFields"](segment);
                if (visibilityFields.length) {
                    queryParameters = queryParameters.concat(
                        " ",
                        visibilityFields.join(" ")
                    );
                }
                // eslint-disable-next-line max-len
                return gql`query {dunningClerkFormValues: option_dunning_clerk (id: ${this.$route.params.id}) {data{id ${queryParameters}}}}`;
            },
            update (data) {
                this.$store.dispatch("dunningClerkFormValues/deleteFormValues");
                this.$store.dispatch(
                    "dunningClerkFormValues/setFormValues",
                    data.dunningClerkFormValues.data[0]
                );
                this.$store.dispatch(
                    "dunningClerkFormValues/setDunningClerkIdValue",
                    data.dunningClerkFormValues.data[0].id
                );
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
