<script>
import _, {toNumber} from "lodash";

export default {
    "props": ["name", "field", "value", "rules", "storeModule", "type", "segment", "editable", "pageType"],
    "computed": {
        formValue () {
            let value = this.$store.state[this.storeModule].values[this.name];
            if (_.isNil(value) && !_.isNil(this.field.default)) {
                this.updateValue(this.field.default);
                value = this.field.default;
            }
            if (typeof this.field.rules !== "undefined" || typeof this.softRules !== "undefined") {
                this.validateByGroup();
            }
            return value;
        },
        canEdit () {
            if (this.$store.state.userRole.userRole === "ROLE_READONLY") {
                return false;
            }
            if (typeof this.editable !== "undefined" && !this.editable) {
                return false;
            }
            if (this.field.disabled) {
                return false;
            }
            if (this.isDisabled) {
                return false;
            }
            if (this.field.permissioned) {
                return this.$can(this.field.ability.action, this.field.ability.object);
            }
            return true;
        },
        isDisabled () {
            if (this.field.depends_true) {
                for (const name of this.field.depends_true) {
                    const value = this.$store.state[this.storeModule].values[name];
                    if (!value) {
                        return true;
                    }
                }
            }
            if (this.field.depends_false) {
                for (const name of this.field.depends_false) {
                    const value = this.$store.state[this.storeModule].values[name];
                    if (value) {
                        return true;
                    }
                }
            }
            return false;
        }
    },
    created () {
        this.softRules = this.field?.softRules;
    },
    // eslint-disable-next-line max-lines-per-function
    data () {
        return {
            "hint": "",
            "validationHardRules": {
                "required": [(v) => Boolean(v) || "The field is required"],
                // eslint-disable-next-line no-useless-escape
                "email": [(v) => this.validateRegex(/(.*)@(.*)\.(.*)/u, "E-mail must be valid", v)],
                "phoneNumberLength": [(v) => this.validatePhoneNumberLength("Phone number country code and this field combined can be max 15 characters", v)],
                "phoneNumber": [(v) => this.validateRegex(/^[+]?[0-9]{0,4}[-\s]?([0-9]\s)?[0-9]{0,11}$/u, "Phone number must be valid (only numbers and '-' are allowed)", v)],
                "phoneNumberCountryCombined": [(v) => this.validateRegex(/^[0-9]{6}[0-9]{0,9}$/u, "Phone number must be valid and at least 6 digits (only numbers are allowed)", v)],
                "countryCode": [(v) => this.validateRegex(/^(\+\d{1,4})$/u, "Needs a format of +0 (maximum of 4 digits)", v)],
                "faxNumber": [(v) => this.validateRegex(/[+!@#$%^&*(),.?":{}|<>\s]/u, "Fax number must be valid (only numbers and '-' are allowed)", v, false)],
                "lengthOfTen": [(v) => this.validateRegex(/^[^\n\r]{10}$/u, "This field needs a length of 10", v)],
                "shareCapital": [
                    (v) => {
                        if (toNumber(v) === 0) {
                            return null;
                        }
                        if (v < 25.1 || v > 100) {
                            return "The percentage should be between 25.1% to 100%";
                        }
                        return null;
                    }
                ],
                "requiredShareCapital": [(v) => Boolean(v) || "the share capital field is a required field for the Ubo"],
                "number": [(v) => this.validateRegex(/^(\d+)$/u, "Field needs to be a number", v)],
                "float": [(v) => this.validateRegex(/^[-+]?[0-9]*\.?[0-9]{0,2}$/u, "Field needs to be a decimal number", v)],
                "uploadFileFormat": [
                    (v) => {
                        if (!v) {
                            return true;
                        }
                        return v.name.endsWith(".pdf") || v.name.endsWith(".doc") || v.name.endsWith(".docx") || v.name.endsWith(".xls") || v.name.endsWith(".xlsx") || "Incorrect fileType is used";
                    }
                ]
            },
            "validationSoftRules": {
                // eslint-disable-next-line no-useless-escape
                "email": [(v) => this.validateRegex(/(.*)@(.*)\.(.*)/u, "E-mail is incorrect", v)],
                "phoneNumber": [(v) => this.validateRegex(/^[+]?[0-9]{0,4}[-\s]?[0-9]{0,11}$/u, "Phone number is incorrect (only numbers, '-' and '+' are allowed)", v)],
                "iban": [(v) => this.validateIBAN("Not valid IBAN format", v)]
            },
            "parseOptions": {
                "number": [(v) => (/^(\d+)$/u).test(v) ? parseInt(v, 10) : v],
                "float": [(v) => (/^[-+]?[0-9]*\.?[0-9]{0,2}$/u).test(v) ? parseFloat(v) : v],
                "string": [(v) => String(v)],
                "boolToYes": [(v) => v ? "yes" : "no"]
            }
        };
    },
    updated () {
        this.$nextTick().then(() => {
            if (typeof this.$refs[this.name] !== "undefined" && this.$refs[this.name].validate() && !this.softValidate(this.formValueByFieldName(this.name))) {
                document.querySelector(`#element_${this.name} .v-messages`).classList.add("softWarning--text");
            }
        });
        if (this.field?.updateFunction) {
            this.$store.dispatch(this.field.updateFunction, this.formValue);
        }
    },
    "methods": {
        validatePhoneNumberLength (errorMessage, value) {
            const countrycode = this.formValueByFieldName("phone_number_country_code");
            if (!value || !countrycode || countrycode?.length < 1) {
                return true;
            }
            return value.length <= 15 - countrycode.length || errorMessage;
        },
        validateRegex (regex, errorMessage, value, condition = true) {
            if (!value) {
                return true;
            }
            return regex.test(value) === condition || errorMessage;
        },
        validateIBAN (errorMessage, value) {
            // eslint-disable-next-line max-len
            const validSepaIbanRegex = /^(?:((?:IT|SM)\d{2}[A-Z]{1}\d{22})|(NL\d{2}[A-Z]{4}\d{10})|(LV\d{2}[A-Z]{4}\d{13})|((?:BG|GB|IE)\d{2}[A-Z]{4}\d{14})|(GI\d{2}[A-Z]{4}\d{15})|(RO\d{2}[A-Z]{4}\d{16})|(MT\d{2}[A-Z]{4}\d{23})|(NO\d{13})|((?:DK|FI)\d{16})|((?:SI)\d{17})|((?:AT|EE|LU|LT)\d{18})|((?:HR|LI|CH)\d{19})|((?:DE|VA)\d{20})|((?:AD|CZ|ES|MD|SK|SE)\d{22})|(PT\d{23})|((?:IS)\d{24})|((?:BE)\d{14})|((?:FR|MC|GR)\d{25})|((?:PL|HU|CY)\d{26}))$/u;

            return !value ? true : validSepaIbanRegex.test(value) === true || errorMessage;
        },
        updateValue (updateValue, fieldName) {
            let value = this.parser(updateValue);
            let name = this.name;
            if (fieldName) {
                name = fieldName;
            }

            if (name === "max_cards" && typeof value === "string") {
                value = parseFloat(value) || null;
            }

            this.$store.dispatch(
                `${this.storeModule}/updateFormValue`,
                {
                    name,
                    value
                }
            );

            if (name === "self_payers" && value === "yes") {
                this.setPaymentMethodToManualCollection();
            }

            if (typeof this.field.rules !== "undefined" || typeof this.softRules !== "undefined") {
                this.validateByGroup();
            }
        },
        // eslint-disable-next-line complexity
        softValidate (value) {
            if (typeof this.softRules !== "undefined" && Array.isArray(this.softRules) && this.softRules.length > 0 && value !== null) {
                for (const softRule of this.softRules) {
                    const rules = this.validationSoftRules[softRule];
                    if (typeof rules === "undefined" || !Array.isArray(rules) || rules.length === 0) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                    let hintText = "";
                    for (const rule of rules) {
                        const ruleResult = rule(value);
                        if (ruleResult !== true) {
                            hintText = ruleResult;
                            break;
                        }
                    }

                    this.setHint(hintText);
                    if (hintText.length > 0) {
                        return false;
                    }
                }
            }
            return true;
        },
        hardValidate (value) {
            if (typeof this.field.rules !== "undefined" && Array.isArray(this.field.rules) && this.field.rules.length > 0) {
                for (const softRule of this.field.rules) {
                    const rules = this.validationHardRules[softRule];
                    if (typeof rules === "undefined" || !Array.isArray(rules) || rules.length === 0) {
                        // eslint-disable-next-line no-continue
                        continue;
                    }
                    for (const rule of rules) {
                        const ruleResult = rule(value);
                        if (ruleResult !== true) {
                            return false;
                        }
                    }

                }
            }
            return true;
        },
        parser (value) {
            let newValue = value;
            if (value !== "" && this.field.parseTo && this.field.parseTo.length > 0) {
                for (const parseOption of this.field.parseTo) {
                    newValue = this.parseValue(parseOption, newValue);
                }
            }
            if (value === "" && this.field.parseTo?.includes("number")) {
                return null;
            }
            return typeof newValue !== "undefined" ? newValue : null;
        },
        parseValue (parseOption, value) {
            let newValue = value;
            const options = this.parseOptions[parseOption];
            if (typeof options === "undefined" || !Array.isArray(options) || options.length === 0) {
                // eslint-disable-next-line no-continue
                return value;
            }
            for (const option of options) {
                newValue = option(newValue);
            }
            return newValue;
        },
        setHint (hint) {
            this.hint = hint;
        },
        getHint () {
            return this.hint;
        },
        setRules (ruleSet) {
            if (!ruleSet) {
                return [true];
            }

            if (Array.isArray(ruleSet)) {
                const rules = [];
                for (const rule of ruleSet) {
                    rules.push(...this.validationHardRules[rule]);
                }
                return rules;
            }
            return this.validationHardRules[ruleSet];
        },
        formValueByFieldName (fieldName) {
            return this.$store.state[this.storeModule].values[fieldName];
        },
        validateByGroup () {
            const el = this.$refs[this.name];
            if (typeof el === "undefined") {
                return;
            }
            if (!this.hardValidate(this.formValueByFieldName(this.name)) ||
                !this.softValidate(this.formValueByFieldName(this.name))) {
                this.$store.dispatch(
                    "formSegments/setSegmentAlerts",
                    { "segmentName": this.segment.id, "fieldName": this.name}
                );
            } else {
                this.$store.dispatch(
                    "formSegments/removeSegmentAlerts",
                    { "segmentName": this.segment.id, "fieldName": this.name}
                );
            }
        },
        setPaymentMethodToManualCollection () {
            try {
                const paymentMethods = this.$store.getters["dropdownOptions/getDropdownOptionsByName"]("paymentMethod");
                const value = paymentMethods.filter((method) => method.startsWith("Q"))[0];
                const name = "payment_method";
                this.$store.dispatch(
                    `${this.storeModule}/updateFormValue`,
                    {
                        name,
                        value
                    }
                );

            } catch (exception) {
                throw Error("Failed to set payment method to manual collection");
            }
        }
    }
};
</script>
