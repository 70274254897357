<template>
  <div>
    <div class="v-label theme--light">{{field.label}}</div>
  <v-radio-group
    class="mt-1"
    v-bind="field.value"
    @change="updateValue($event)"
    :name="name"
    :value="formValue"
    :rules="setRules(field.rules)"
    :persistent-hint="true"
    :hint="getHint()"
    :ref="this.name"
    :disabled="!canEdit"
    row>
    <v-radio
      v-for="(o,ix) in field.options"
      :key="ix"
      :label="sanitizeOptions(o).label"
      :value="sanitizeOptions(o).value"
      color="primary"
    ></v-radio>
  </v-radio-group>
  </div>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";

import lodash from "lodash";

export default {
    "extends": BaseElement,
    "components": {},
    "methods": {
        sanitizeOptions (b) {
            return lodash.isString(b) ? {
                "value": b,
                "label": b
            } : b;
        }
    }
};

</script>
<style>
  .v-label.theme--light {
    margin-bottom: 0;
  }
</style>
