<template>
    <v-container class="pl-0">
        <v-btn v-if="$can('changeApplicationTemplate', 'applications')"
               rounded color="primary"
               id="change-template-button"
               class="text-capitalize"
               :disabled="!canEdit"
               @click.stop="dialog = true"
               data-nw="changeTemplateBtn"
        >Change template
        </v-btn>
        <ChangeTemplateDialog :dialog.sync="dialog"></ChangeTemplateDialog>
    </v-container>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import ChangeTemplateDialog from "../../views/dialogs/ChangeTemplateDialog";

export default {
    "extends": BaseElement,
    "components": {
        ChangeTemplateDialog
    },
    data () {
        return {"dialog": false};
    }
};

</script>
