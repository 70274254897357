<template>
  <v-container fluid v-if="$can('read', 'telesales_agency')">
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="Telesales"
      data-nw="telesalesHeader"
    >
      <template v-slot:button>
        <v-btn
            v-if="$can('create', 'telesales_agency')"
            id="new-telesales-button"
            class="text-capitalize btn-block"
            rounded color="primary"
            @click.stop="CreateTelesales"
            data-nw="newTelesalesAgencyBtn"
        >
          New Telesales Agency
        </v-btn>
      </template>
    </HeaderBar>

    <v-col>
      <v-row align="center">
        <v-col col="3">
          <v-text-field
            id="search-bar-text-field"
            v-if="showTextFilter"
            v-model="filterText"
            @keyup.enter="searchData($event)"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-card flat class="background-grey" width="100%" >
          <v-card-text>
            <v-row>
              <v-col>
                <v-data-table :must-sort="true"
                              :loading="loading"
                              :headers="tableHeaders"
                              :items="items"
                              :server-items-length="itemsPerPage"
                              :disable-pagination="true"
                              :hide-default-footer="true"
                              :options.sync="options"
                              item-key="id"
                              v-on="{ 'click:row': $can('read', 'telesales_agency') ? goToEdit : null}"
                              :class="$can('read', 'telesales_agency') ? 'table-cursor' : ''"
                              v-on:update:options="setSortData"
                              data-nw="telesalesList"
                >
                  <template v-slot:item.active="{ item }">
                    {{item.active|formatActiveState}}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="total_Count_Records"># of records: {{totalCount}}</v-col>
              <v-col>
                <v-pagination
                  circle
                  v-model="filtersCurrentPage"
                  :length="numberOfPages"
                  total-visible="5"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import BaseList from "../../baseComponents/BaseList";
import HeaderBar from "../../uiElements/HeaderBar";
import JsonWebtoken from "jsonwebtoken";

export default {
    "extends": BaseList,
    "name": "ListTelesales",
    "components": {
        HeaderBar
    },
    "computed": {
        filters () {
            return this.$store.state.listFilters.telesales;
        }
    },
    "methods": {
        CreateTelesales () {
            this.$router.push({"name": "CreateTelesales"});
        }
    },
    data () {
        return {
            "tableName": "telesales",
            "searchFields": [
                "name",
                "email"
            ],
            "editURL": "EditTelesales",
            "gqlModel": "telesales_agency",
            "tableHeaders": [
                {
                    "value": "name",
                    "text": "Name",
                    "sortable": true,
                    "width": "40%"

                },
                {
                    "value": "email",
                    "text": "email",
                    "sortable": true,
                    "width": "40%"
                },
                {
                    "value": "active",
                    "text": "active",
                    "sortable": false,
                    "width": "20%"
                }
            ],
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Telesales",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListTelesales"}
                }
            ]
        };
    },
    "mounted" () {
        if (this.$store.state.userRole.userRole === "ROLE_TELESALES_USER") {
            const token = window.localStorage.getItem("jwt");
            const decoded = JsonWebtoken.decode(token);
            this.$router.push(`/telesales/edit/${decoded.telesales_agency_id}`);
        }
    }
};
</script>
