import Vue from "vue";

export const convertOafId = (value) => String(value).replace(
    /^OAF_|OAF/u,
    ""
);

Vue.filter(
    "oafID",
    convertOafId
);
