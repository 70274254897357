import Vue from "vue";
import Vuex from "vuex";
import accountingClerkFormValues from "./modules/accountingClerkFormValues";
import appianhr from "./modules/appian";
import application from "./modules/application";
import applicationFormValues from "./modules/applicationFormValues";
import auditLogs from "./modules/auditLogs";
import campaigns from "./modules/campaigns";
import categories from "./modules/categories";
import countries from "./modules/countries";
import dropdownOptions from "./modules/dropdownOptions";
import dunningClerkFormValues from "./modules/dunningClerkFormValues";
import errorHandling from "./modules/errorHandling";
import exports from "./modules/exports";
import fleetField from "@/store/modules/fleetField";
import formSegments from "./modules/formSegments";
import formTypes from "./modules/formTypes";
import groupFormValues from "./modules/groupFormValues";
import legalForms from "@/store/modules/legalForms";
import legalTypes from "@/store/modules/legalTypes";
import listFilters from "./modules/listFilters";
import loader from "./modules/loader";
import mdg from "./modules/mdg";
import netPromoterScore from "./modules/netPromoterScore";
import onefleet from "./modules/onefleet";
import opal from "./modules/opal";
import rebateFormValues from "./modules/rebateFormValues";
import salesforceFields from "./modules/salesforceFields";
import sap from "./modules/sap";
import session from "./modules/session";
import siteFormValues from "./modules/siteFormValues";
import solarisBank from "./modules/solarisBank";
import solarisDocuments from "@/store/modules/solarisDocuments";
import statusGroups from "./modules/statusGroups";
import statusses from "./modules/statusses";
import telesalesAgencies from "./modules/telesalesAgencies";
import telesalesAgencyId from "./modules/telesalesAgencyId";
import telesalesFormValues from "./modules/telesalesFormValues";
import templates from "./modules/templates";
import tollBox from "./modules/tollBox";
import userFormValues from "./modules/userFormValues";
import userRole from "./modules/userRole";
import zfPayerFormValues from "./modules/zfPayerFormValues";
import zipcode from "./modules/zipcode";
import zlPayerFormValues from "./modules/zlPayerFormValues";
import zrPayerFormValues from "./modules/zrPayerFormValues";

Vue.use(Vuex);

export default new Vuex.Store({
    "modules": {
        accountingClerkFormValues,
        appianhr,
        application,
        categories,
        countries,
        campaigns,
        applicationFormValues,
        legalTypes,
        listFilters,
        loader,
        statusGroups,
        statusses,
        templates,
        netPromoterScore,
        formSegments,
        formTypes,
        groupFormValues,
        rebateFormValues,
        telesalesAgencies,
        telesalesAgencyId,
        telesalesFormValues,
        userFormValues,
        auditLogs,
        zipcode,
        session,
        userRole,
        siteFormValues,
        errorHandling,
        dropdownOptions,
        solarisDocuments,
        fleetField,
        mdg,
        solarisBank,
        legalForms,
        tollBox,
        salesforceFields,
        opal,
        sap,
        zlPayerFormValues,
        zrPayerFormValues,
        zfPayerFormValues,
        dunningClerkFormValues,
        exports,
        onefleet
    },
    "getters": {

        /**
         * Evaluates the visibility condition by retrieving the value from the formValues or currentState
         * and comparing it to the condition value using the operator.
         * The stateValue can be specified as a dot-separated string to access nested objects.
         * @param {object} currentState The current state of the store
         * @param {object} condition The visibilityCondition object as defined in tests/unit/specs/json.spec.js
         * @param {object} formValues The formValues object retrieved from the store, e.g. applicationFormValues
         * @returns {boolean} true if the condition is met, false otherwise
         */
        "getVisibilityConditionResult": (currentState) => (condition, formValues) => {
            let value = null;
            if (typeof condition.field !== "undefined") {
                value = formValues?.values?.[condition.field];
            } else if (typeof condition.stateValue !== "undefined") {
                value = condition.stateValue.split(".").reduce((o, i) => o?.[i], currentState);
            } else {
                throw Error("Invalid visibilityCondition, field or stateValue is required", condition);
            }
            const operators = {
                "===": () => value === condition.value,
                "!==": () => value !== condition.value,
                "<": () => value < condition.value,
                "<=": () => value <= condition.value,
                ">": () => value > condition.value,
                ">=": () => value >= condition.value,
                "length_lt": () => value?.length < condition.value,
                "length_lte": () => value?.length <= condition.value,
                "length_eq": () => value?.length === condition.value,
                "length_gte": () => value?.length >= condition.value,
                "length_gt": () => value?.length > condition.value
            };
            if (condition.operator in operators) {
                return operators[condition.operator]();
            }
            throw Error("Invalid operator for visibilityCondition", condition?.operator);
        },

        /**
         * Retrieves the form segments from the store and evaluates the visibility conditions.
         * The visibility result is stored in the segment.visible property.
         * @param {object} currentState The current state of the store
         * @param {object} storeGetters The store getters
         * @param {string} form The form name
         * @returns {array} The form segments
         */
        "getFormSegments": (currentState, storeGetters) => (form) => {
            const segments = currentState.formSegments[`${form}Segments`];
            const formValues = currentState?.[`${form}Values`];
            if (!formValues) {
                return segments;
            }
            return segments.map((segment) => {
                let visibility = true;
                if (segment.visibilityCondition) {
                    if (segment.visibilityCondition.all) {
                        visibility = segment.visibilityCondition.all
                            .every((condition) => storeGetters.getVisibilityConditionResult(condition, formValues));
                    } else if (segment.visibilityCondition.any) {
                        visibility = segment.visibilityCondition.any
                            .some((condition) => storeGetters.getVisibilityConditionResult(condition, formValues));
                    } else {
                        throw Error("Invalid visibilityCondition for segment");
                    }
                }
                segment.visible = visibility;
                return segment;
            });
        }
    },
    "actions": {
        // eslint-disable-next-line max-statements,max-lines-per-function,complexity
        async initialiseStore () {
            // Check if the ID exists
            const localListFilters = JSON.parse(window.localStorage.getItem("listFilters"));
            const user = JSON.parse(window.localStorage.getItem("user"));
            if (localListFilters) {
                if (localListFilters.applications) {
                    // Replace the state object with the stored item
                    delete localListFilters.applications.predefinedFilters;
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.applications
                    );
                }
                if (localListFilters.rebates) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.rebates
                    );
                }
                if (localListFilters.site) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.site
                    );
                }
                if (localListFilters.telesales) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.telesales
                    );
                }
                if (localListFilters.users) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.users
                    );
                }
                if (localListFilters.accountingClerks) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.accountingClerks
                    );
                }
                if (localListFilters.dunningClerks) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.dunningClerks
                    );
                }
                if (localListFilters.zfPayers) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.zfPayers
                    );
                }
                if (localListFilters.zlPayers) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.zlPayers
                    );
                }
                if (localListFilters.zrPayers) {
                    this.dispatch(
                        "listFilters/setFilters",
                        localListFilters.zrPayers
                    );
                }

            }
            this.dispatch("session/sessionLoaded", false);
            this.dispatch("formSegments/loadApplicationFormConfig");
            this.dispatch("formSegments/loadRebateFormConfig");
            this.dispatch("formSegments/loadTelesalesConfig");
            this.dispatch("formSegments/loadUserFormConfig");
            this.dispatch("formSegments/loadGroupFormConfig");
            this.dispatch("formSegments/loadSiteFormConfig");
            this.dispatch("formSegments/loadAccountingClerkFormConfig");
            this.dispatch("formSegments/loadDunningClerkFormConfig");
            this.dispatch("formSegments/loadZFPayerFormConfig");
            this.dispatch("formSegments/loadZLPayerFormConfig");
            this.dispatch("formSegments/loadZRPayerFormConfig");
            if (user) {
                await this.dispatch("session/buildSession", user);
            }
        }
    },
    "strict": process.env.NODE_ENV !== "production"
});
