<template>
  <v-select
    id="slimpay_preview_select"
    v-bind="field"
    :value="formValue"
    :name="name"
    :label="field.label"
    :rules="setRules(field.rules)"
    :items="documents"
    :persistent-hint="true"
    :ref="this.name"
    :hint="getHint()"
    :append-outer-icon="getAppendOuterIcon"
    @click:append-outer="getClickFunction()"
    v-model="selected"
    item-text="label"
    item-value="id"
    />
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import apolloClient from "../../../ApolloClient";
import gql from "graphql-tag";


export default {
    "extends": BaseElement,
    "components": {},
    data () {
        return {
            "selected": null,
            "documents": []
        };
    },
    "computed": {
        getAppendOuterIcon () {
            if (typeof this.field.appendOuterIcon !== "undefined" && this.selected !== null) {
                return this.field.appendOuterIcon;
            }
            return "";
        },
        formId () {
            return this.$store.state.applicationFormValues.values.id;
        }
    },
    "methods": {
        isFunction (functionToCheck) {
            return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]";
        },
        async getClickFunction () {
            const formId = await this.$store.state.applicationFormValues.values.id;
            // eslint-disable-next-line max-len
            const query = gql` {previewSlimPayDocument(documentId: "${this.selected}", formId: "${formId}"){
              document{contentType content}
            }}`;
            const result = await apolloClient.query({query});
            const pdfWindow = window.open("");
            pdfWindow.document.write(`<iframe width='100%' height='100%' src='data:application/pdf;base64,
            ${encodeURI(result.data.previewSlimPayDocument.document.content)}'></iframe>`);
            pdfWindow.document.close();
        }
    },
    "watch": {
        async formId () {
            const query = gql`{getSlimPayDocuments(formId: "${this.formId}"){responseString
                documents{label id paymentScheme reference dateSigned}
            }}`;

            const result = await apolloClient.query({query});

            if (result.data.getSlimPayDocuments.documents) {
                this.documents = result.data.getSlimPayDocuments.documents;
            }
        }
    }
};

</script>
