<template>
    <div>
        <div class="read-only-label-size">
            <v-label>{{field.label}}</v-label>
            <br>
        <a v-if="getValue" :href="getValue" target="_blank">{{field.linkName}}</a>
            <p v-else>No data available</p>
        </div>
    </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";

export default {
    "extends": BaseElement,
    "components": {},
    "computed": {
        getValue () {
            const currentField = this.$store.getters[this.field.getter](this.formValue);
            return currentField?.name;
        }
    }
};
</script>
