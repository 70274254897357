<template>
    <v-dialog :value="dialog" max-width="400" :persistent="true">
        <v-card id="change-template-dialog" color="primary">
            <v-card-title class="white--text">Change template</v-card-title>
            <v-card-text>
                <v-select
                        id="change-template-select-country"
                        v-model="selectedCountry"
                        dark
                        return-object
                        :items=linkedCountries
                        item-text="name"
                        item-value="id"
                        label="Choose country">
                </v-select>
                <v-select
                        v-if="selectedCountry"
                        id="change-template-select-template"
                        v-model="selectedTemplate"
                        dark
                        :items=templatesForCountry
                        item-text="name"
                        item-value="id"
                        :label=templateSelectLabel
                        :disabled=disabledTemplateSelect>
                </v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id="change-template-cancel-button"
                       class="text-capitalize" color="white" text @click.stop="closeDialog"
                       data-nw="closeChangeTemplate"
                >Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn id="change-template-apply-button"
                       class="text-capitalize" color="white" text @click.stop="changeTemplate"
                       :disabled="disabledCreateButton">Apply
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    "props": {"dialog": {"default": false}},
    "methods": {
        closeDialog () {
            this.$emit(
                "update:dialog",
                false
            );
        },
        async changeTemplate () {
            await this.$store.dispatch("loader/setLoading", true);
            await this.$store.dispatch("applicationFormValues/deleteFees");
            await this.$store.dispatch(
                "applicationFormValues/getTemplateValues",
                {
                    "templateId": this.selectedTemplate,
                    "country": this.selectedCountry.iso_country_code
                }
            );
            await this.$store.dispatch(
                "applicationFormValues/updateFormValue",
                {
                    "name": "application_template_id",
                    "value": this.selectedTemplate
                }
            );
            this.$emit(
                "update:dialog",
                false
            );
            await this.$store.dispatch("loader/setLoading", false);
        },
        setTemplateOptions (templates) {
            this.selectedTemplate = null;

            if (templates.length === 0) {
                this.templateSelectLabel = "No template available";
            } else {
                this.templateSelectLabel = "Choose template";
            }
            this.disabledTemplateSelect = templates.length === 0;
        }
    },
    "data" () {
        return {
            "disabledTemplateSelect": true,
            "disabledCreateButton": true,
            "selectedCountry": null,
            "selectedTemplate": null,
            "templateSelectLabel": "Choose template"
        };
    },
    "computed": {
        "user" () {
            return this.$store.state.session.user;
        },
        "countries" () {
            return this.$store.state.countries.countries;
        },
        "categories" () {
            return this.$store.state.categories.categories;
        },
        "templates" () {
            return this.$store.state.templates.templates;
        },
        "linkedCountries" () {

            /**
             * Retrieve the necessary category objects to see what country they are linked to
             * and filter countries based on that information
             */
            return this.$store.getters["countries/getLinkedCountries"];
        },
        "templatesForCountry" () {
            const templates = this.$store.getters["templates/templateForCountry"](this.selectedCountry.id);
            this.setTemplateOptions(templates);
            return templates;
        }
    },
    "watch": {
        "selectedTemplate" () {
            this.disabledCreateButton = !this.selectedTemplate;
        }
    }
};
</script>
