<template>
    <v-container class="pl-0">
        <v-btn v-if="$can('changeApplicationTemplate', 'applications')"
               rounded color="primary"
               id="change-template-button"
               class="text-capitalize"
               :disabled="!canEdit"
               @click.stop="dialog = true"
               data-nw="changeTemplateBtn"
        >Change campaign
        </v-btn>
        <ChangeCampaignDialog :dialog.sync="dialog"></ChangeCampaignDialog>
    </v-container>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import ChangeCampaignDialog from "@/components/views/dialogs/ChangeCampaignDialog.vue";

export default {
    "extends": BaseElement,
    "components": {
        ChangeCampaignDialog
    },
    data () {
        return {"dialog": false};
    }
};

</script>
