import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {
    "values": {}
};

const mutations = {
    SET_GROUP_NAME (currentState, data) {
        Vue.set(currentState.values, "name", data.name);
        Vue.set(currentState.values, "id", data.id);
    },
    SET_USER_IN_GROUP (currentState, data) {
        Vue.set(currentState.values, "users", data);
    },
    SET_GROUP_CATEGORY_IDS (currentState, data) {
        Vue.set(currentState.values, "category_ids", data.category_ids);
    },
    SET_GROUP_CAMPAIGN_IDS (currentState, data) {
        const campaignIds = data.campaign_ids.map((id) => `campaign${id}`);
        Vue.set(currentState.values, "campaign_ids", campaignIds);
    },
    SET_GROUP_APPLICATION_TEMPLATE_IDS (currentState, data) {
        const templateIds = data.applicationtemplate_ids.map((id) => `template${id}`);
        Vue.set(currentState.values, "template_ids", templateIds);
    },
    DELETE_FORM_VALUES (currentState) {
        const clearedForm = {
            "category_ids": [],
            "campaign_ids": [],
            "template_ids": []
        };
        Vue.set(currentState, "values", clearedForm);
    },
    UPDATE_FORM_VALUE (currentState, data) {
        Vue.set(currentState.values, data.name, data.value);
    }
};

/**
 * @param {Promise} mutation The mutation that needs to be executed
 * @returns {Promise<T>} Result of the mutation
 */
function executeMutation (mutation) {
    return apolloClient.mutate({mutation}).then((response) => {
        if (response.data[Object.keys(response.data)[0]].errors) {
            throw Error(response.data[Object.keys(response.data)[0]].errors);
        }
        return response;
    }).catch((error) => {
        throw Error(error);
    });
}

const getters = {
    "categorySubHeader": () => () => ({"name": "Categories"}),
    "campaignTemplateSubHeader": () => () => ({"name": "Campaigns / Paper Templates"})
};

const actions = {
    async setGroupName ({commit}, data) {
        await commit(
            "SET_GROUP_NAME",
            data
        );
    },

    async setUsersInGroup ({commit}, data) {
        await commit(
            "SET_USER_IN_GROUP",
            data
        );
    },

    async setGroupCategoryIds ({commit}, data) {
        await commit(
            "SET_GROUP_CATEGORY_IDS",
            data
        );
    },

    async setGroupCampaignIds ({commit}, data) {
        await commit(
            "SET_GROUP_CAMPAIGN_IDS",
            data
        );
    },

    async setGroupApplicationTemplateIds ({commit}, data) {
        await commit(
            "SET_GROUP_APPLICATION_TEMPLATE_IDS",
            data
        );
    },

    async updateFormValue ({commit}, data) {
        await commit(
            "UPDATE_FORM_VALUE",
            data
        );
    },

    async deleteFormValues ({commit}) {
        await commit("DELETE_FORM_VALUES");
    },

    // eslint-disable-next-line complexity,max-statements
    async saveForm (context, type) {
        const parseId = (id) => parseInt(id.replace(/[a-zA-Z]+/u, ""), 10);
        const campaignIds = state.values.campaign_ids ? state.values.campaign_ids.map(parseId) : [];
        const applicationTemplateIds = state.values.template_ids ? state.values.template_ids.map(parseId) : [];

        const createGroupQuery =
            `name: "${state.values.name}",
             categoryIds: [${state.values.category_ids}],
             campaignIds: [${campaignIds}],
             applicationTemplateIds: [${applicationTemplateIds}]`;
        const formMutationQuery = type === "create" ? createGroupQuery : `${createGroupQuery}, id: ${state.values.id}`;
        const queryType = "create_acl_group";
        const mutation = gql`mutation {${queryType} (${formMutationQuery}) { responseString insertedData { id }}}`;
        const mutationResult = await executeMutation(mutation);

        if (!mutationResult.data[queryType].insertedData) {
            throw new Error(mutationResult.data[queryType].responseString);
        }

        return mutationResult;
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
