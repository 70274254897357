<template>
  <v-container fluid v-if="$can('create', 'applications')">
    <HeaderBar
        class="pt-3"
        :breadcrumbs="breadcrumbs"
        title="New application"
    >
      <template v-slot:button>
        <v-btn
            id="submit-application-button"
            class="text-capitalize btn-block"
            rounded color="primary"
            @click="submitApplicationForm()">
          Save Application
        </v-btn>
      </template>
    </HeaderBar>
    <BaseForm
        class="mt-5"
        store-module="applicationFormValues"
        :form-segments=formSegments
        :editable="true"
    ></BaseForm>
  </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import gql from "graphql-tag";

export default {
    "name": "NewCampaignApplication",
    "components": {
        BaseForm,
        HeaderBar
    },
    "mounted" () {
        this.$store.dispatch("applicationFormValues/clearOldValues");
        this.$store.dispatch("opal/clearOldValues");
        this.$store.dispatch("sap/clearOldValues");
        this.$store.dispatch("solarisBank/clearOldValues");
        this.$store.dispatch("applicationFormValues/clearFees");
        this.$store.dispatch("fleetField/clearFleetField");
        this.$store.dispatch("onefleet/clearOldValues");
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Applications",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListApplication"}
                },
                {
                    "text": "New application",
                    "disabled": true
                }
            ],
            "defaultValuesResponse": null,
            "defaultValues": {}
        };
    },
    "methods": {
        submitApplicationForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.applicationFormSegments;
        },
        applicationStatus () {
            return this.$store.state.applicationFormValues.values.status;
        },
        formTypes () {
            return this.$store.state.formTypes.formTypes;
        }
    },
    "watch": {
        "formTypes" () {
            this.$apollo.queries.defaultValues.skip = false;
        },
        "formValues" () {
            return this.$store.state.applicationFormValues.values;
        }
    },
    "apollo": {
        "defaultValues": {
            query () {
                return gql`query {default_values_for_campaign (campaign_id:${this.$route.params.campaign_id})
                                    {defaultValues responseString}}`;
            },
            update (data) {
                const response = data.default_values_for_campaign;
                if (response.responseString) {
                    this.$toast(response.responseString, {"color": "error"});
                } else {
                    response.defaultValues.Form[0].properties.campaign = this.$route.params.campaign_id;
                    this.$store.dispatch("applicationFormValues/setDefaultValues", response.defaultValues.Form[0].properties);
                    response.defaultValues.Fee.forEach((fee) => this.$store.dispatch("applicationFormValues/addFees", fee.properties));
                    if (response.defaultValues.FleetField) {
                        response.defaultValues.FleetField.forEach((fleetField) => this.$store.dispatch("fleetField/addFleetfield", fleetField.properties));
                    }
                    if (response.defaultValues.SalesforceFormFields &&
                        response.defaultValues.SalesforceFormFields[0].properties) {
                        this.$store.dispatch(
                            "salesforceFields/setSalesforceFields",
                            response.defaultValues.SalesforceFormFields[0].properties
                        );
                    }
                    this.$store.dispatch("applicationFormValues/fetchPhoneNumberCountryCodes");
                }
            },
            skip () {
                return false;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "created" () {
    // Clear any dnb search params still in the store
        this.$store.dispatch(
            "applicationFormValues/setDnbSearchParams",
            {
                "formValues":
              {
                  "data":
                    [{}]
              }
            }
        );
    }
};
</script>
