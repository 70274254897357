<template>
  <v-checkbox
    v-bind="field"
    @change="changeValue($event)"
    :name="name"
    :input-value="parsedFormValue"
    :label="field.label"
    :rules="setRules(field.rules)"
    :items="field.items"
    :persistent-hint="true"
    :hint="getHint()"
    :ref="this.name"
    :readonly="field.permissioned ? !$can(field.ability.action, field.ability.object) : false"
    :disabled="!canEdit"
    :data-nw="this.name"
  ></v-checkbox>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";

export default {
    "extends": BaseElement,
    "components": {},
    "data" () {
        return {
            "parseOptionsCheckbox": {
                "boolToYes": (v) => v === "yes"
            }
        };
    },
    "computed": {
        parsedFormValue () {
            if (this.field.parseTo) {
                return this.parseOptionsCheckbox[this.field.parseTo[0]](this.formValue);
            }
            return this.formValue;
        }
    },
    "methods": {
        changeValue (event) {
            this.updateValue(event === true ? 1 : 0);
        }
    }
};

</script>
