<template>
    <v-container class="pl-0">
        <v-btn rounded color="primary"
               class="text-capitalize"
               id="fraud_check_button"
               data-nw="senseCheckBtn"
               @click.stop="fraudCheckDialog = true">Sense Check
        </v-btn>
        <FraudCheckDialog :dialog.sync="fraudCheckDialog"></FraudCheckDialog>
    </v-container>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import FraudCheckDialog from "../../views/dialogs/FraudCheckDialog";

export default {
    "extends": BaseElement,
    "components": {FraudCheckDialog},
    data () {
        return {"fraudCheckDialog": false};
    }
};

</script>
