import Vue from "vue";
import _ from "lodash";
import apolloClient from "@/ApolloClient";
import gql from "graphql-tag";


const defaultState = {
    "appianHrId": null
};

const mutations = {
    SAVE_APPIAN_HR_ID (currentState, data) {
        Vue.set(currentState, "appianHrId", data);
    },
    SAVE_APPIAN_HR_URL (currentState, data) {
        Vue.set(currentState, "appianHrUrl", data);
    },
    CLEAR_OLD_VALUES (currentState) {
        Vue.set(currentState, "appianHrId", "");
        Vue.set(currentState, "appianHrUrl", "");
    }
};

const getters = {
    "getAppianHrId": (currentState) => () => ({ "name": currentState.appianHrId}),
    "getAppianHrUrl": (currentState) => () => ({ "name": currentState.appianHrUrl})
};

const actions = {
    async appianHrData ({commit}, formId) {
        if (formId) {
            const idQuery = gql`query {form_export( form_id: ${formId},
                key: "appian_hr_case_id"){data{external_id}}}`;
            const urlQuery = gql`query {form_export( form_id: ${formId},
                key: "appian_hr_case_url"){data{external_id}}}`;
            const idResult = await apolloClient.query({"query": idQuery});
            const urlResult = await apolloClient.query({"query": urlQuery});
            if (!_.isNil(idResult.data.form_export.data[0])) {
                commit("SAVE_APPIAN_HR_ID", idResult.data.form_export.data[0].external_id);
            }
            if (!_.isNil(urlResult.data.form_export.data[0])) {
                commit("SAVE_APPIAN_HR_URL", urlResult.data.form_export.data[0].external_id);
            }
        }
    },
    async clearOldValues ({commit}) {
        await commit("CLEAR_OLD_VALUES");
    }
};

export default {
    "namespaced": true,
    "state": defaultState,
    getters,
    actions,
    mutations
};
