import Vue from "vue";

const countries = {
    "1": "NL",
    "2": "DE",
    "3": "AT",
    "4": "BE",
    "5": "ES",
    "6": "PL",
    "7": "PT",
    "8": "GB",
    "9": "LU"
};

/**
 * Convert value to shortcode or return value if not found in countriesArray
 *
 * @param {string} value The string value of a country
 * @returns {string} the shortcode
 */
export const formatCountry = (value) => {
    let shortcode = countries[value] ? countries[value] : value;
    if (!shortcode || shortcode.length !== 2) {
        shortcode = "unknown";
    }
    shortcode = shortcode.toLowerCase();
    return `countries/${shortcode}.png`;
};

Vue.filter(
    "formatCountry",
    formatCountry
);
