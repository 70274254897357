var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pl-0 pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"pl-3 mt-10",attrs:{"sort-by":"modified_at","sort-desc":true,"disable-pagination":true,"hide-default-footer":true,"headers":_vm.headers,"items":_vm.auditLogs,"dense":""},scopedSlots:_vm._u([{key:"item.modified_by_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"audit-log-row"},[_vm._v(" "+_vm._s(item.modified_by_type)+" ")])]}},{key:"item.modified_by",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate audit-log-row",staticStyle:{"max-width":"25vw"}},[_vm._v(" "+_vm._s(item.modified_by)+" ")])]}},{key:"item.modified_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"audit-log-row"},[_vm._v(" "+_vm._s(_vm._f("formatDateTimeSecond")(item.modified_at))+" ")])]}},{key:"item.field",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"audit-log-row"},[_vm._v(" "+_vm._s(item.field)+" ")])]}},{key:"item.new_value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"audit-log-row"},[_vm._v(" "+_vm._s(_vm.cutJsonStrings(item.new_value))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }