<template>
    <v-container fluid >
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="Site"
            data-nw="editSite"
        >
            <template v-slot:button>
                <LogDialog
                    label="Site Data Logs"
                    :id="siteId"
                    table="site"
                    v-if="$can('log', 'sites')"
                >
                </LogDialog>
                <v-btn
                    data-nw="edit-site-submit-button"
                    v-if="$can('update', 'sites')"
                    class="text-capitalize btn-block"
                    rounded color="primary"
                    @click="submitSiteForm()">
                    Save Site
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
            type="update"
            store-module="siteFormValues"
            :form-segments="formSegments"
            :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import LogDialog from "../dialogs/LogDialog";
import editSiteForm from "../../../formConfig/editSiteForm";
import gql from "graphql-tag";

export default {
    "name": "EditSite",
    "components": {
        BaseForm,
        HeaderBar,
        LogDialog
    },
    "methods": {
        submitSiteForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.getters.getFormSegments("siteForm");
        },
        siteId () {
            return this.$store.state.siteFormValues.values.id;
        }
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Sites",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListSites"}
                },
                {
                    "text": "Edit site",
                    "disabled": true
                }
            ]
        };
    },
    "apollo": {
        "siteFormValues": {
            query () {
                let queryParameters = "";
                const segment = editSiteForm.formSegments[0];
                Object.keys(segment.fields).forEach((field) => {
                    queryParameters = queryParameters.concat(
                        " ",
                        field
                    );
                });
                const visibilityFields = this.$store.getters["formSegments/getVisibilityFields"](segment);
                if (visibilityFields.length) {
                    queryParameters = queryParameters.concat(
                        " ",
                        visibilityFields.join(" ")
                    );
                }
                // eslint-disable-next-line max-len
                return gql`query {siteFormValues: site (id: ${this.$route.params.id}) {data{id ${queryParameters}}}}`;
            },
            update (data) {
                this.$store.dispatch("siteFormValues/deleteFormValues");
                this.$store.dispatch(
                    "siteFormValues/setFormValues",
                    data.siteFormValues.data[0]
                );
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
