<template>
    <v-dialog
        width="600"
        v-model="getWarningDialogOpen"
        persistent>
        <v-card>
            <v-card-title>
                This document contains sensitive information
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    @click="closeWarningDialog"
                    elevation="2"
                    style="margin-right: 16px">
                    Close
                </v-btn>
                <v-btn
                    color="primary"
                    elevation="2"
                    @click="openDocumentDialog">
                    Open
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    "methods": {
        closeWarningDialog () {
            this.$store.dispatch("solarisDocuments/setWarningDialog", false);
        },
        openDocumentDialog () {
            this.closeWarningDialog();
            this.$store.dispatch("solarisDocuments/setDocumentDialog", true);
        }
    },
    "computed": {
        getWarningDialogOpen () {
            return this.$store.state.solarisDocuments.warningDialog;
        }
    }
};
</script>
