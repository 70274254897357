<template>
    <v-dialog scrollable :value="openDialog" persistent max-width="600">
        <v-card data-nw="error-modal">
            <v-card-title>
                <v-col lg="10">
                    An error has occurred
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text class="pl-9">
                {{getErrorMessage()}}
                <div class="read-only-label-size technical-error-message pt-10" v-if="getError()">
                    <label class="technical-error-message">Response from server:</label>
                    <p>{{getError()}}</p>
                </div>
            </v-card-text>
            <v-card-actions class="text-center">
                <v-spacer></v-spacer>
                <v-btn @click.stop="closeDialog" class="primary" data-nw="btn-dismiss-error">
                    OK
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    "name": "ErrorDialog",
    data () {
        return {
            "openDialog": false
        };
    },
    "methods": {
        closeDialog () {
            this.$store.dispatch("errorHandling/setOriginalState");
            this.$store.dispatch(
                "loader/setLoading",
                false
            );
        },
        getErrorMessage () {
            return this.$store.state.errorHandling.message;
        },
        getError () {
            if (this.$store.state.errorHandling.originalError) {
                return this.$store.state.errorHandling.originalError.message;
            }
            return null;
        }
    },
    "watch": {
        "$store.state.errorHandling.message" (newValue) {
            this.openDialog = newValue !== "";
        }
    }
};
</script>

<style>
    .technical-error-message {
        color: #BBBBBB;
    }
</style>
