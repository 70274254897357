<template>
    <div>
        <v-file-input
            :id="this.name"
            @change="fileChosen"
            v-model="file"
            :name="this.name"
            :rules="setRules(field.rules)"
            :label="field.label"
            :persistent-hint="true"
            :hint="uploadedDocumentHint"
            :ref="this.name"
            accept=".PDF,.DOC,.DOCX,.XLS,.XLSX"
            show-size
            :clearable="false"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
            v-bind:class="{ smaller: uploadedDocumentHint }"
            :disabled="!canEdit"
        ></v-file-input>
        <PreviewUploadButton
            v-if="field.preview"
            :name="'preview-${this.name}'"
            :field="field"
            :store-module="storeModule"
            class="float-left"
        />
    </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import PreviewUploadButton from "./PreviewUploadButton";
import moment from "moment";

export default {
    "components": {
        PreviewUploadButton
    },
    "extends": BaseElement,
    "data" () {
        return {"file": null};
    },
    "computed": {
        document () {
            return this.$store.getters[this.field.getter](this.name);
        },
        uploadedDocumentHint () {
            return this.document ? this.document.filename : "";
        }
    },
    "watch": {
        document (newValue) {
            if (!newValue.content) {
                this.file = null;
            }
        }
    },
    "methods": {
        fileChosen () {
            if (this.file) {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(this.file);
                fileReader.onload = () => {

                    const fileData = {
                        "filename": this.file.name,
                        "modified_at": `${moment().utc().format("YYYY-MM-DDTHH:mm:ss")}.000Z`,
                        "type": this.name,
                        "mimetype": this.file.type,
                        "content": fileReader.result.split(",").pop()
                    };

                    this.$store.dispatch(
                        this.field.dispatch,
                        {
                            "type": this.name,
                            ...fileData
                        }
                    );
                };

            } else {
                this.$store.dispatch(
                    this.field.dispatch,
                    {
                        "type": this.name,
                        "content": null
                    }
                );
            }
        }
    }
};

</script>

<style>
.v-file-input {
    padding-right: 18px;
}
.smaller {
    width: 70%;
    position: relative;
    float: left;
    margin-right: 20px;
}
</style>
