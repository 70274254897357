<template>
    <v-container class="pl-0">
        <v-tooltip bottom :disabled="hasBusinessReference">
            <template v-slot:activator="{ on }">
                <div v-on="on" class="d-inline-block">
                    <v-btn id="add-document-button"
                           rounded color="primary"
                           class="text-capitalize"
                           @click.stop="openAddDocumentDialog"
                           :disabled="!hasBusinessReference"
                    >Add Document
                        <v-icon v-if="!hasBusinessReference">mdi-information</v-icon>
                    </v-btn>
                </div>
            </template>
            <span>Unavailable due to absence of a solaris bank business reference. Please send to Solaris first</span>
        </v-tooltip>
        <AddDocumentDialog :dialog="addDocumentDialog"></AddDocumentDialog>
    </v-container>
</template>

<script>
import AddDocumentDialog from "@/components/views/dialogs/AddDocumentDialog";
import BaseElement from "@/components/baseComponents/BaseElement";
import gql from "graphql-tag";

export default {
    "components": {AddDocumentDialog},
    "data": () => ({
        "hasBusinessReference": false
    }),
    "extends": BaseElement,
    "methods": {
        openAddDocumentDialog () {
            this.$store.dispatch("solarisDocuments/setAddDocumentDialog", true);
        }
    },
    "computed": {
        addDocumentDialog () {
            return this.$store.state.solarisDocuments.addDocumentDialog;
        }
    },
    "apollo": {
        "solarisBankReference": {
            "query": gql`query solarisBankBusiness($form_id: Int){solaris_bank_business(form_id: $form_id) {data {reference}}}`,
            variables () {
                return {
                    "form_id": Number(this.$route.params.id)
                };
            },
            update (data) {
                this.hasBusinessReference = !!data?.solaris_bank_business?.data[0]?.reference;
            }
        }
    }
};
</script>
