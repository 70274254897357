<template>
    <v-data-table :loading="loading"
                  :headers="headers"
                  :items="items"
                  :disable-pagination="true"
                  :hide-default-footer="true"
                  item-key="id">
        <template v-slot:item.toll_box_id="{item}">
            <v-select
                class="card-select-style-wide card-select-font-size"
                :value="item.toll_box_id"
                @change="save({id: item.id, toll_box_id: $event})"
                :items="tollBoxItems"
                :disabled="disabled"
            />
        </template>
        <template v-slot:item.toll_box_license_plate="{item}">
            <v-edit-dialog
                @open="open(item.toll_box_license_plate)"
                @close="save({id: item.id, toll_box_license_plate: currentEdit})">
                {{item.toll_box_license_plate|formatNoValue}}
                <template v-slot:input>
                    <v-text-field
                        :maxLength="maxInputLength"
                        v-model="currentEdit"
                        :value="item.toll_box_license_plate"
                        placeholder="Toll box license plate"
                        single-line
                        autofocus
                        :disabled="disabled"
                    />
                </template>
            </v-edit-dialog>
        </template>
    </v-data-table>
</template>

<script>

export default {
    "name": "TollBoxRowField",
    "props": ["headers", "items", "loading", "maxInputLength", "disabled"],
    async "mounted" () {
        await this.$store.dispatch("tollBox/setTollBoxes");
    },
    "computed": {
        tollBoxItems () {
            return this.$store.getters["tollBox/getTollBoxOptions"](this.salesOrganizationWinshuttle);
        },
        salesOrganizationWinshuttle () {
            return this.$store.state.applicationFormValues.values.sales_organization_winshuttle;
        }
    },
    data () {
        return {
            "currentEdit": null
        };
    },
    "methods": {
        open (value) {
            this.currentEdit = value;
        },
        save (item) {
            this.$store.dispatch(
                "applicationFormValues/updateCards",
                item
            );
            this.currentEdit = null;
        }
    }
};
</script>

<style scoped>

</style>
