/**
 * Groups entries from an object whose keys contain a digit that matches a value in an array,
 * grouping them by the digit in the key name.
 *
 * @param {Object} obj - The object whose entries to group.
 * @param {Array} arr - The array of values to match against key names.
 * @param {boolean} startAtZero - Optional flag to start the index at zero, means that all digits will be subtracted by 1.
 * @returns {(Object)|(boolean)} An object with keys representing the digits found in the matching entries' key names,
 *  and values representing an object containing the matching entries grouped by digit.
 *
 * @example
 * const obj = {
 *   amount: 1,
 *   amount1: 2,
 *   frequency1: 3,
 *   notpresent: 4,
 * };
 * const arr = ['amount', 'frequency'];
 * const groupedEntries = groupEntriesByDigit(obj, arr);
 * console.log(groupedEntries);
 * // Output:
 * // {
 * //   1: { amount: 2, frequency: 3 }
 * // }
 * // Output (with startAtZero):
 * // {
 * //   0: { amount: 2, frequency: 3 }
 * // }
 */
export default function groupEntriesByDigit (obj, arr, startAtZero = false) {
    const result = {};
    const keys = Object.keys(obj);
    const arrSet = new Set(arr);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const match = key.match(/^(\D+)(\d+)$/u);
        if (match && arrSet.has(match[1])) {
            const digit = startAtZero ? match[2] - 1 : match[2];
            const group = result[digit] || (result[digit] = {});
            group[match[1]] = obj[key];
        }
    }
    return Object.keys(result).length ? result : false;
}
