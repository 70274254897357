import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {
    "templates": [],
    "templateNames": []
};

const mutations = {
    SET_TEMPLATES (currentState, templates) {
        Vue.set(currentState, "templates", templates);
    },
    SET_TEMPLATENAMES (currentState, templateNames) {
        Vue.set(currentState, "templateNames", templateNames);
    }
};

const getters = {
    "applicationTemplateName": (currentState) => (templateId) => {
        if (typeof templateId === "undefined") {
            return null;
        }
        const tpl = currentState.templates.find((template) => template.id === templateId);
        if (!tpl) {
            return null;
        }
        return { "name": tpl.name};
    },
    "categoryIdsFromTemplates": (currentState) => currentState.templates.map((temp) => temp.category_id),
    "templateForCountry": (currentState, currentGetters, rootState, rootGetters) => (selectedCountry) => currentState.templates
        .filter((template) => rootGetters["categories/forCountry"](selectedCountry).includes(template.category_id) && template.active)
};

const actions = {
    async fetchTemplates ({commit}) {
        const query = gql`query{application_template(first: -1){data{id category_id name telesalesAgency_id active}}}`;
        const templates = await apolloClient.query({query});

        await commit(
            "SET_TEMPLATES",
            templates.data.application_template.data
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
