import Vue from "vue";
const state = {"countries": []};

const mutations = {
    SET_COUNTRIES (currentState, countries) {
        Vue.set(currentState, "countries", countries);
    }
};

const getters = {
    "getCountry": (currentState) => (countryId) => currentState.countries.find((country) => country.id === countryId),
    "getCountryByIsoCode": (currentState) => (countryIsoCountryCode) => currentState.countries.find((country) => country.iso_country_code === countryIsoCountryCode),
    "getCountriesSelectOptions": (currentState) => currentState.countries.map((country) => ({
        "value": country.id,
        "text": country.name
    })),
    "getCountriesSelectOptionsString": (currentState) => currentState.countries.map((country) => ({
        "value": country.iso_country_code,
        "text": country.name
    })),
    "getLinkedCountries": (currentState, currentGetters, rootState, rootGetters) => currentState.countries
        .filter((country) => rootGetters["categories/getCountriesFromCategoryFilter"].includes(country.id))
};

const actions = {
    async setCountries ({commit}, countries) {
        await commit(
            "SET_COUNTRIES",
            countries
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
