var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","value":_vm.dialog,"persistent":"","max-width":"900"}},[_c('v-card',{staticClass:"involved-page",attrs:{"height":"40vh"}},[_c('v-card-title',{staticClass:"pa-3"},[_c('v-col',{attrs:{"lg":"10"}},[_vm._v(" KYC Screening status ")]),_c('v-col',{attrs:{"lg":"2"}},[_c('v-btn',{staticClass:"primary--text float-right",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeDialog.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pa-0 involved-page"},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.allHits,"disable-pagination":true,"hide-default-footer":true,"item-key":"id","id":"hits_data_table"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"color-screening-risk-columns name-column"},[_c('div',[_vm._v(_vm._s(item.name))])])]}},{key:"item.cdd_color",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"color-screening-risk-columns"},[_c('div',{class:_vm.getCddColor(item) + ' color-column'},[_vm._v("  ")])])]}},{key:"item.screening_progress",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"color-screening-risk-columns"},[_c('div',[_vm._v(_vm._s(item.screening_progress))])])]}},{key:"item.risk_classification_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"color-screening-risk-columns"},[_c('div',[_vm._v(_vm._s(item.risk_classification_status))])])]}},{key:"item.customer_vetting_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"color-screening-risk-columns vetting-column"},[_c('div',[_vm._v(_vm._s(item.customer_vetting_status))])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }