<template>
    <v-dialog :value="EditDocuPersonDialog">
        <v-card height="100%">
            <v-card-title>
                <v-col lg="10">
                    {{ dialogText() }}
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-form id="legal_rep_form" ref="editLegalRepDialog">
                    <v-list>
                        <v-list-item>
                            <v-row>
                                <v-col>
                                    <v-layout wrap>
                                        <v-flex v-for="(field, key) in tableHeaders"
                                            :key="key"
                                            :id="field.value+'_'+key"
                                            :class="'form_element field_type_'+field.type+' ' +
                                             'xs'+field.flex+' '+field.class">
                                            <v-text-field
                                                v-if="field.type === 'text'"
                                                v-bind="field"
                                                :id="field.id"
                                                :name="field.value"
                                                :value="tableData[field.value]"
                                                :label="field.label"
                                                :ref="field.value"
                                                @input="changeInput($event, field)"
                                                :type="field.type"
                                                :rules="setRules(field.rules)"
                                            />
                                            <template v-else-if="field.type === 'plain_text'">
                                                <div class="read-only-label-size">
                                                    <v-label>{{field.label}}</v-label>
                                                    <p :data-nw="field.value">{{ tableData[field.value] }}</p>
                                                </div>
                                            </template>
                                            <v-menu v-if="field.type === 'date'"
                                                    :id="field.id"
                                                    max-width="290"
                                                    :close-on-content-click="false"
                                                    v-model="showDatePicker">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                        id="date-picker-value"
                                                        :value="formatDatePickerValue(tableData[field.value])"
                                                        v-on="on"
                                                        readonly
                                                        :label="field.label"
                                                        :rules="setRules(field.rules)"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                :value="formatDatePickerValue(tableData[field.value])"
                                                :allowed-dates="disableFutureDates"
                                                color="primary"
                                                :ref="field.value"
                                                @click:date="saveAndCloseDate($event)"
                                            />
                                            </v-menu>
                                            <v-autocomplete
                                                v-if="field.type === 'filterable_select'"
                                                max-width="290"
                                                :id="field.id"
                                                :label="field.label"
                                                :ref="field.value"
                                                :items="countries"
                                                @change="changeInput($event, field)"
                                                :data-nw="field.value"
                                                :data-nw-value="tableData[field.value]"
                                                v-model="tableData[field.value]"
                                            ></v-autocomplete>
                                            <v-checkbox
                                                v-if="field.type === 'checkbox'"
                                                v-bind="field"
                                                :id="field.id"
                                                :ref="field.value"
                                                :label="field.text"
                                                :value="tableData[field.value]"
                                                :input-value="tableData[field.value]"
                                                @change="updateValue($event, field.value)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-col>
                            </v-row>
                        </v-list-item>
                    </v-list>
                </v-form>
            </v-card-text>
            <v-card-text>
                <v-row justify="end">
                    <v-btn id="save_document_person_btn"
                           class="text-capitalize mr-5"
                           right rounded color="primary"
                            @click="save">{{ persistButtonText() }}
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from "moment";

export default {
    "props": {
        "EditDocuPersonDialog": {"default": false},
        "items": {"default": {}},
        "tableHeaders": {"default": {}},
        "isNew": {"default": null}
    },
    "data" () {
        return {
            "maxInputLength": 30,
            "close": false,
            "showDatePicker": false,
            "formRules": {
                "required": [(v) => Boolean(v) || "The field is required"],
                // eslint-disable-next-line no-useless-escape
                "email": [(v) => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/u).test(v) || "E-mail must be valid"],
                "validateAge": [
                    (value) => {
                        if (this.tableData.is_signee) {
                            return true;
                        }
                        const selectedDate = moment(value, "YYYY-MM-DD");
                        return moment().diff(selectedDate, "years") >= 18 || "Person needs to be 18 years or older";
                    }
                ],
                "float": [
                    (v) => {
                        if (this.tableData.is_beneficial_owner && !v) {
                            return "Voting share is required for beneficial owners";
                        }
                        if (!v) {
                            return true;
                        }
                        return (/^[-+]?[0-9]*\.?[0-9]{0,2}$/u).test(v) || "Field needs to be a decimal number";
                    }
                ]
            },
            "parseOptions": {
                "float": [(v) => (/^[-+]?[0-9]*\.?[0-9]{0,2}$/u).test(v) ? parseFloat(v) : v]
            },
            "tableData": { ...this.items}
        };
    },
    "watch": {
        EditDocuPersonDialog (isOpen) {
            if (isOpen) {
                this.tableData = { ...this.items};
            }
        }
    },
    "computed": {
        "countries" () {
            return this.$store.getters["countries/getCountriesSelectOptionsString"];
        }
    },
    "methods": {
        disableFutureDates (val) {
            return val <= new Date().toISOString().split("T")[0];
        },
        dialogText () {
            return this.isNew ? "Add legal representative & beneficial owner" : "Edit legal representative & beneficial owner";
        },
        persistButtonText () {
            return this.isNew ? "Add" : "Update";
        },
        formatDatePickerValue (date) {
            const formattedDate = moment(date).format("YYYY-MM-DD");
            return formattedDate === "Invalid date" ? "" : formattedDate;
        },
        setRules (ruleSet) {
            if (!ruleSet) {
                return [true];
            }

            if (Array.isArray(ruleSet)) {
                const rules = [];
                for (const rule of ruleSet) {
                    rules.push(...this.formRules[rule]);
                }
                return rules;
            }

            return this.formRules[ruleSet];
        },
        parser (field, value) {
            let newValue = value;
            if (value !== "" && field.parseTo && field.parseTo.length > 0) {
                for (const parseOption of field.parseTo) {
                    newValue = this.parseValue(parseOption, newValue);
                }
            }
            return newValue;
        },
        parseValue (parseOption, value) {
            let newValue = value;
            const options = this.parseOptions[parseOption];
            if (typeof options === "undefined" || !Array.isArray(options) || options.length === 0) {
                // eslint-disable-next-line no-continue
                return value;
            }
            for (const option of options) {
                newValue = option(newValue);
            }
            return newValue;
        },
        save () {
            if (this.$refs.editLegalRepDialog.validate()) {
                this.removeLeadingSpace();
                this.tableData.state = this.tableData.state ?? "new";
                const functionName = this.tableData.id ? "applicationFormValues/updateDocumentsPerson" : "applicationFormValues/addDocumentsPerson";
                this.$store.dispatch(
                    functionName,
                    this.tableData
                );
                this.$toast(
                    "The data has been added to the application, don't forget to save the application",
                    {"color": "success"}
                );
                this.closeDialog();
            } else {
                this.$toast(
                    "The data you submitted is invalid. Please check for incomplete fields.",
                    {"color": "error"}
                );
            }
        },
        changeInput (value, field) {
            this.tableData[field.value] = field.parseTo ? this.parser(field, value) : value;
        },
        saveAndCloseDate (value) {
            this.showDatePicker = false;
            // eslint-disable-next-line camelcase
            this.tableData.birth_date = value;
        },
        updateValue (value, name) {
            this.tableData[name] = value ? 1 : 0;
            if (["is_beneficial_owner", "is_signee"].includes(name)) {
                this.$refs.editLegalRepDialog.validate();
            }
        },
        closeDialog () {
            this.$emit(
                "update:EditDocuPersonDialog",
                false
            );
        },
        removeLeadingSpace () {
            const fieldsToTrim = ["address_city", "address_line_1", "address_line_2", "address_postal_code", "email", "first_name", "last_name"];
            fieldsToTrim.forEach((field) => {
                if (this.tableData[field]) {
                    this.tableData[field] = this.tableData[field].trim();
                }
            });
        }
    }
};
</script>

<style>
.read-only-label-size > label {
    font-size: 12px;
}
.read-only-label-size > p {
    font-size: 16px;
}
</style>
