<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          id="telesales_user_list"
          :items="this.users"
          :headers="tableHeaders">
          <template v-slot:item.active="{ item }">
            {{item.active | formatActiveState}}
          </template>
          <template v-slot:item.role="{ item }">
              <div :class="item.role ? 'text-capitalize' :  ''">
                  {{item.role|formatRole}}
              </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getFieldsFromTableHeaders} from "../../../service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "name": "UserTelesalesList",
    data () {
        return {
            "tableHeaders": [
                {
                    "value": "first_name",
                    "text": "First name",
                    "sortable": false
                },
                {
                    "value": "last_name",
                    "text": "Last name",
                    "sortable": false
                },
                {
                    "value": "username",
                    "text": "Username",
                    "sortable": false
                },
                {
                    "value": "emailaddress",
                    "text": "Email address",
                    "sortable": false
                },
                {
                    "value": "role",
                    "text": "Role",
                    "sortable": false
                },
                {
                    "value": "active",
                    "text": "Active",
                    "sortable": false
                }
            ]
        };
    },
    "apollo": {
        "users": {
            query () {
                const fields = getFieldsFromTableHeaders(this.tableHeaders);
                return gql` {users: user(telesale_id: ${this.$route.params.id}) {data{${fields}}}}`;
            },
            update (data) {
                this.loading = false;
                return data.users.data;
            }
        }
    }
};
</script>
