<template>
    <v-container fluid v-if="$can('read', 'rebate')">
        <HeaderBar
                :breadcrumbs="breadcrumbs"
                title="Rebates"
                data-nw="listRebateHeader"
        >
          <template v-slot:button>
            <v-btn
              v-if="$can('create', 'rebate')"
              id="new-rebate-button"
              class="text-capitalize float-right"
              rounded color="primary"
              @click.stop="createRebate"
              data-nw="newRebateBtn"
            >
              New Rebate
            </v-btn>
          </template>
        </HeaderBar>

        <v-col>
            <v-row align="center">
                <v-col cols="3">
                  <v-text-field
                    id="search-bar-text-field"
                    v-if="showTextFilter"
                    v-model="filterText"
                    @keyup.enter="searchData($event)"
                    placeholder="Search"
                    prepend-inner-icon="mdi-magnify"
                    dense
                  />
                </v-col>
            </v-row>
            <v-row>
                <v-card flat class="background-grey" width="100%">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-data-table :must-sort="true"
                                              :loading="loading"
                                              :headers="tableHeaders"
                                              :items="items"
                                              :server-items-length="itemsPerPage"
                                              :disable-pagination="true"
                                              :hide-default-footer="true"
                                              :options.sync="options"
                                              item-key="id"
                                              v-on="{ 'click:row': $can('read', 'rebate') ? goToEdit : null}"
                                              :class="$can('read', 'rebate') ? 'table-cursor' : ''"

                                              v-on:update:options="setSortData"
                                              data-nw="rebateList"
                                >
                                    <template v-slot:item.start_date="{ item }">
                                        {{item.start_date|formatDate}}
                                    </template>
                                    <template v-slot:item.category.country_id="{ item }">
                                        <v-img v-if="item.category"
                                               :src=item.category.country_id|formatCountry width="24">
                                        </v-img>
                                        <v-img v-else :src={}|formatCountry width="24">
                                        </v-img>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="total_Count_Records"># of records: {{totalCount}}</v-col>
                            <v-col>
                              <v-pagination
                                circle
                                v-model="filtersCurrentPage"
                                :length="numberOfPages"
                                total-visible="5"
                              />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import BaseList from "../../baseComponents/BaseList";
import HeaderBar from "../../uiElements/HeaderBar";

export default {
    "extends": BaseList,
    "name": "ListRebates",
    "components": {
        HeaderBar
    },
    "computed": {
        filters () {
            return this.$store.state.listFilters.rebates;
        }
    },
    "methods": {
        createRebate () {
            this.$router.push({"name": "CreateRebate"});
        }
    },
    // eslint-disable-next-line max-lines-per-function
    data () {
        return {
            "tableName": "rebates",
            "searchFields": ["rebate_name", "rebate_code", "agency"],
            "editURL": "EditRebate",
            "gqlModel": "rebate",
            "tableHeaders": [
                {
                    "value": "rebate_code",
                    "text": "Code",
                    "sortable": true,
                    "width": "5%"
                },
                {
                    "value": "rebate_name",
                    "text": "Rebate Name",
                    "sortable": true,
                    "width": "25%"
                },
                {
                    "value": "start_date",
                    "text": "Startdate",
                    "sortable": true,
                    "width": "7%"
                },
                {
                    "value": "category.name",
                    "text": "Category Name",
                    "sortable": false,
                    "width": "10%"
                },
                {
                    "value": "site.name",
                    "text": "Site Name",
                    "sortable": false,
                    "width": "20%"
                },
                {
                    "value": "category.country_id",
                    "text": "Country",
                    "sortable": false,
                    "width": "5%"
                }
            ],
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Rebates",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListRebates"}
                }
            ]
        };
    }
};
</script>
