<template>
    <v-container fluid>
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="Application"
            :application-filter-bar="true"
            v-bind:class="{finishedLoading: !loading}"
            data-nw="listApplicationsHeader"
        >
            <template v-slot:button >
                <v-container>
                    <v-row>
                        <v-btn
                            v-if="$can('read', 'export')"
                            id="export-list-application-button"
                            class="text-capitalize btn-block mx-2"
                            rounded
                            color="secondary"
                            @click.stop="exportList()"
                            data-nw="exportListApplicationBtn"
                        >
                            Export List
                        </v-btn>
                        <v-btn
                            id="refresh-list-application-button"
                            class="text-capitalize btn-block mx-2"
                            rounded
                            color="secondary"
                            @click.stop="refreshList()"
                            data-nw="refreshListApplicationBtn"
                        >
                            Refresh list
                        </v-btn>
                        <v-btn
                            v-if="$can('create', 'applications')"
                            id="new-application-button"
                            class="text-capitalize btn-block mx-2"
                            rounded
                            color="primary"
                            @click.stop="newApplicationDialog = true"
                            data-nw="newApplicationBtn"
                        >
                            New Application
                        </v-btn>
                    </v-row>
                </v-container>
                <NewApplicationDialog :newApplicationDialog.sync="newApplicationDialog"></NewApplicationDialog>
            </template>
        </HeaderBar>
        <v-col>
            <v-row align="center" v-if="hasHiddenColumns">
                <v-col cols="12">
                    <div style="color: red">
                        Note: not all columns are shown, but they will appear in the exports.
                    </div>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="3">
                    <v-text-field
                        id="search-bar-text-field"
                        v-if="showTextFilter"
                        v-model="filterText"
                        @keyup.enter="searchData($event)"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        dense
                    ></v-text-field>
                </v-col>
                <v-col class="text-right">
                    <v-btn class="text-capitalize"
                           id="filter-button"
                           text @click.stop="dialog = true">
                        <v-icon class="primary--text pr-2">mdi-filter-menu-outline</v-icon>
                        Filter
                    </v-btn>
                    <FilterManagementDialog :dialog.sync="dialog"></FilterManagementDialog>
                </v-col>
            </v-row>
            <v-row>
                <v-card flat class="background-grey" width="100%">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-data-table :must-sort="true"
                                              :loading="loading"
                                              :headers="tableHeaders"
                                              :items="items"
                                              :server-items-length="itemsPerPage"
                                              :disable-pagination="true"
                                              :hide-default-footer="true"
                                              :options.sync="options"
                                              item-key="id"
                                              v-on:click:row="goToEdit"
                                              class="table-cursor"
                                              v-on:update:options="setSortData"
                                              style="overflow-x: auto"
                                >
                                    <template v-slot:item.id="{ item }">
                                        {{ item.id }}
                                    </template>
                                    <template v-slot:item.fraud_check_report="{ item }">
                                        <v-icon v-if="fraudCheck(item)" class="px-0 m-0 ml-n4" dense style="color: red">
                                            mdi-shield-alert-outline
                                        </v-icon>
                                    </template>
                                    <template v-slot:item.status_modified="{ item }">
                                        <v-divider vertical class="px-1 m-0 ml-n4"
                                                   v-bind:class="item | formatStatus(statusses)">
                                        </v-divider>
                                    </template>
                                    <template v-slot:item.status="{ item }">
                                        {{ item.status|formatStatusTranslation(statusses) }}
                                    </template>
                                    <template v-slot:item.guarantee_status="{ item }">
                                        {{ formatGuaranteeStatusTranslation(item.guarantee_status) }}
                                    </template>
                                    <template v-slot:item.created="{ item }">
                                        {{ item.created|formatDateTime }}
                                    </template>
                                    <template v-slot:item.modified="{ item }">
                                        {{ item.modified|formatDateTime }}
                                    </template>
                                    <template v-slot:item.category.country_id="{ item }">
                                        <v-img data-nw="country-flag" v-if="item.category"
                                               :src=item.category.country_id|formatCountry width="24">
                                        </v-img>
                                        <v-img v-else :src={}|formatCountry width="24">
                                        </v-img>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="total_Count_Records"># of records: {{ totalCount }}</v-col>
                            <v-col>
                                <v-pagination
                                    circle
                                    v-model="filtersCurrentPage"
                                    :length="numberOfPages"
                                    total-visible="5"
                                    data-nw="applications-pagination"
                                ></v-pagination>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import {lowerCase, upperFirst} from "lodash";
import BaseList from "../../baseComponents/BaseList";
import HeaderBar from "../../uiElements/HeaderBar";
import NewApplicationDialog from "../dialogs/NewApplicationDialog";
import apolloClient from "@/ApolloClient";
import defaultHeaderFile from "./headers/defaultHeaders.json";
import {getFieldsFromTableHeaders} from "@/service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "extends": BaseList,
    "name": "ListApplication",
    "components": {
        HeaderBar,
        NewApplicationDialog
    },
    created () {
        this.$store.dispatch("application/getFormLabels");
    },
    "computed": {
        formLabels () {
            return this.$store.state.application.formLabels;
        },
        filters () {
            return this.$store.state.listFilters.applications;
        },
        tableHeaders () {
            let filterName = "none";
            if (this.filters) {
                filterName = this.filters.selectedFilter ? this.filters.selectedFilter.name : "none";
            }
            if (filterName === "none") {
                return this.defaultHeaders();
            }

            if (typeof this.filters.selectedFilter.columnIds === "undefined") {
                return this.defaultHeaders();
            }

            return this.headersFromColumnIds(this.filters.selectedFilter.columnIds);
        },
        hasHiddenColumns () {
            if (this.filters && this.filters.selectedFilter && this.filters.selectedFilter.columnIds) {
                return this.filters.selectedFilter.columnIds.length > 15;
            }
            return false;
        }
    },
    "methods": {
        headersFromColumnIds (columnIds, limitColumns = true) {
            if (typeof columnIds === "undefined") {
                return [...this.defaultHeaders()];
            }
            const th = [];
            let columnCount = columnIds.length + 1;
            if (columnCount > 14 && limitColumns) {
                columnCount = 15;
            }
            const width = Math.floor(100 / columnCount);
            th.push({
                "value": "id",
                "text": "Id",
                "sortable": true,
                "width": `${width}%`
            });
            for (let n = 0; n < columnCount - 1; n++) {
                const formLabel = this.formLabels?.find((label) => label.keyword === columnIds[n]);
                th.push({
                    "value": columnIds[n],
                    "text": formLabel ? formLabel.label : upperFirst(lowerCase(columnIds[n])),
                    "sortable": columnIds[n].indexOf(".") === -1 && !["solaris_state", "fleet_size"].includes(columnIds[n]),
                    "width": `${width}%`
                });
            }
            return th;
        },
        refreshList () {
            this.loading = true;
            this.$apollo.queries.items.refetch();
        },
        exportList () {
            this.loading = true;
            let searchGQLQuery = "";
            if (this.filters.searchFieldQuery !== null) {
                searchGQLQuery = ` search: {fieldName:${JSON.stringify(this.searchFields)},
                        searchQuery:"${this.filters.searchFieldQuery}"} `;
            }
            let usePredefinedFilter = "";
            if (this.filters.selectedFilter && this.filters.selectedFilter.filterQuery) {
                usePredefinedFilter = this.filters.selectedFilter.filterQuery;
            }
            const columnIds = this.filters.selectedFilter?.columnIds;
            const fields = getFieldsFromTableHeaders(this.headersFromColumnIds(columnIds, false));
            const filterName = this.filters.selectedFilter ? this.filters.selectedFilter.name : "none";
            const query = gql`{${this.tableName}: ${this.gqlModel}(
                                exportResult: true,
                                exportName: "${filterName}",
                                sort: { fieldName: "${this.filters.sortField}", sortOrder: "${this.filters.sortOrder}"},
                                ${searchGQLQuery},
                                ${usePredefinedFilter},
                                ) {totalCount, message, data{id ${fields}}}}`;
            const result = apolloClient.query({query});
            result.then((exportResult) => {
                this.$toast(exportResult.data.applications.message, {"color": "success"});
                this.loading = false;
            });
        },
        fraudCheck (item) {
            const translatedStatus = this.$options.filters.formatStatusTranslation(item.status, this.statusses);
            if (translatedStatus === "Ready for review") {
                const fraudCheckReport = item.fraud_check_report;
                if (typeof fraudCheckReport !== "undefined" && fraudCheckReport !== null) {
                    const fraudCheckReportJSON = JSON.parse(fraudCheckReport);
                    if (this.checkFraudDuplicate(fraudCheckReportJSON) ||
                        this.checkFraudBlacklist(fraudCheckReportJSON)) {
                        return true;
                    }
                }
            }
            return false;
        },
        checkFraudDuplicate (fraudCheckReport) {
            if (fraudCheckReport.duplicate &&
                fraudCheckReport.duplicate.data &&
                fraudCheckReport.duplicate.data.results) {
                if (fraudCheckReport.duplicate.data.results.length > 0) {
                    return true;
                }
            }
            return false;
        },
        checkFraudBlacklist (fraudCheckReport) {
            if (fraudCheckReport.blacklist &&
                fraudCheckReport.blacklist.data &&
                fraudCheckReport.blacklist.data.fieldMatchingBlacklist) {
                if (fraudCheckReport.blacklist.data.fieldMatchingBlacklist.length > 0) {
                    return true;
                }
            }
            return false;
        },
        formatGuaranteeStatusTranslation (guaranteeStatus) {
            const allStatuses = this.$store.state.dropdownOptions.options.guaranteeStatus;
            if (typeof allStatuses !== "undefined") {
                const translatedStatus = allStatuses.find((allStatus) => allStatus.value === guaranteeStatus);
                if (translatedStatus) {
                    return translatedStatus.label;
                }
            }
            return "";
        },
        defaultHeaders () {
            return defaultHeaderFile;
        }
    },
    data () {
        return {
            "tableName": "applications",
            "searchFields": [
                "company_name",
                "id",
                "authorityNumber",
                "zipcode",
                "emailaddress",
                "vat_number",
                "contact_emailaddress",
                "invoicing_emailaddress"
            ],
            "newApplicationDialog": false,
            "editURL": "EditApplication",
            "gqlModel": "form",
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Applications",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "ListApplication"}
                }
            ]
        };
    }
};
</script>
