import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const state = {
    "values": {}
};

const mutations = {
    SET_FORM_VALUES (currentState, data) {
        Vue.set(currentState, "values", data);
    },
    UPDATE_FORM_VALUE (currentState, data) {
        Vue.set(currentState.values, data.name, data.value);
    },
    DELETE_FORM_VALUES (currentState) {
        Vue.set(currentState, "values", {});
    }
};

const getters = {};

const actions = {
    async setFormValues ({commit}, data) {
        await commit(
            "SET_FORM_VALUES",
            data
        );
    },
    async updateFormValue ({commit}, data) {
        await commit(
            "UPDATE_FORM_VALUE",
            data
        );
    },
    async deleteFormValues ({commit}) {
        await commit("DELETE_FORM_VALUES");
    },
    async saveForm (context, type) {
        let formMutationQuery = "";
        const data = context.state.values;
        for (const key of Object.keys(data)) {
            const fieldValue = data[key];
            formMutationQuery += typeof fieldValue === "string" ? `${key}: "${fieldValue}" ` : `${key}: ${fieldValue} `;
        }
        const queryType = `${type}_site`;
        const mutation = gql`mutation{ ${queryType} (${formMutationQuery}){responseString insertedData {id}}}`;
        const mutationResult = await apolloClient.mutate({mutation});

        if (!mutationResult.data[queryType].insertedData) {
            throw new Error(mutationResult.data[queryType].responseString);
        }
        return mutationResult;
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
