import Vue from "vue";
import lodash from "lodash";
import moment from "moment";

export const statusFormat = (item, statusses) => {
    const now = moment.utc().format();
    const matchedStatus = lodash.find(
        statusses.statusses,
        (status) => status.status === item.status
    );

    if (matchedStatus && matchedStatus.sla_escalation > 0) {
        const newDate = moment.utc(item.status_modified).add(
            matchedStatus.sla_escalation,
            "minutes"
        ).format();
        if (newDate < now) {
            return "error";
        }
    }
    if (matchedStatus && matchedStatus.sla_warning > 0) {
        const newDate = moment.utc(item.status_modified).add(
            matchedStatus.sla_warning,
            "minutes"
        ).format();
        return newDate < now ? "warning" : "secondary";
    }

    return "secondary";
};

export const statusTranslation = (value, statusses) => {
    const matchedStatus = lodash.find(
        statusses.statusses,
        (status) => status.status === value
    );
    if (matchedStatus && matchedStatus.translation) {
        return matchedStatus.translation;
    }
    return "";
};


Vue.filter(
    "formatStatus",
    statusFormat
);

Vue.filter(
    "formatStatusTranslation",
    statusTranslation
);
