<template>
  <v-container class="pl-0">
    <v-btn
      class="text-capitalize"
      id="google_maps_button"
      rounded color="primary"
      @click.stop="openGoogleMapsDialog">
      {{field.label}}
    </v-btn>
    <GoogleMapsDialog
      v-if="googleMapsDialog === true"
      :googleMapsDialog.sync="googleMapsDialog"
      :url="googleMapsUrl"
      :address="showAddress()">
    </GoogleMapsDialog>
  </v-container>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import GoogleMapsDialog from "../../views/dialogs/GoogleMapsDialog";
import getEnv from "../../../utils/env";

export default {
    "extends": BaseElement,
    "components": {GoogleMapsDialog},
    data () {
        return {
            "googleMapsDialog": false,
            "googleMapsUrl": null
        };
    },
    "methods": {
        openGoogleMapsDialog () {
            this.composeGoogleMapsUrl();
            this.googleMapsDialog = true;
        },
        getGoogleBaseUrl () {
            const googleMapsEmbedUrl = "https://www.google.com/maps/embed/v1/place?key=";
            const googleApiKey = getEnv("VUE_APP_GOOGLE_API_KEY");

            return googleMapsEmbedUrl.concat(
                googleApiKey,
                "&q="
            );
        },
        getAddressFields () {
            const address = this.$store.state.applicationFormValues.values[this.field.address];
            const city = this.$store.state.applicationFormValues.values[this.field.city];
            const country = this.$store.state.applicationFormValues.values[this.field.country];
            return {
                address,
                city,
                country
            };
        },
        validAddress () {
            const componentFieldsSet = typeof this.field.address !== "undefined" && this.field.address.length > 0 &&
                typeof this.field.city !== "undefined" && this.field.city.length > 0;

            const addressFields = this.getAddressFields();
            const formFieldsSet = addressFields.address && addressFields.city;

            return componentFieldsSet && formFieldsSet;
        },
        composeGoogleMapsUrl () {
            if (!this.validAddress()) {
                this.googleMapsUrl = encodeURI(`${this.getGoogleBaseUrl()}fake_address`);
                return;
            }
            const addressFields = this.getAddressFields();

            let uri = this.getGoogleBaseUrl().concat(
                `${addressFields.address}`,
                `, ${addressFields.city}`
            );
            if (typeof this.field.country !== "undefined" && this.field.country.length > 0 &&
                typeof addressFields.country === "undefined" && addressFields.country.length > 0) {
                uri = uri.concat(`, ${addressFields.country}`);
            }

            this.googleMapsUrl = encodeURI(uri);
        },
        showAddress () {
            if (!this.validAddress()) {
                return "Address not found! Make sure to fill in both address and city.";
            }

            const addressFields = this.getAddressFields();
            let address = `Searching for address: ${addressFields.address}, ${addressFields.city}`;
            if (addressFields.country !== null) {
                address = `${address}, ${addressFields.country}`;
            }
            return address;
        }
    }
};

</script>
