<template>
  <div :id="`editableFilterList_`+ name" class="fill-height">
    <v-row>
      <v-col>
        <v-icon class="primary--text">mdi-settings</v-icon>
        Select the filter you would like to apply.
        <span v-if="!editable">Predefined filters cannot be changed or deleted, but can be copied.</span>
      </v-col>
      <v-card-actions class="justify-content-end">
        <v-btn class="text-capitalize px-12"
               rounded
               color="primary"
               @click.stop="createFilter">New
        </v-btn>
      </v-card-actions>
    </v-row>
    <v-divider v-if="filters && filters.length > 0"></v-divider>
    <v-card-text class="py-0">
      <v-row class="py-0">
        <v-col class="py-0">
          <v-list v-if="filters && filters.length > 0">
            <v-list-item-group v-model="selectedFilter" max-height="100px">
              <v-list-item :class="'filter_listItem'"
                           :id="'filter_'+ item.id"
                           v-for="item in filters"
                           :value="item" :key="item.id">
                <v-list-item-content>
                  <v-list-item-title>
                      {{item.name}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-icon :id="'edit_'+item.id"
                        v-if="editable"
                        class="primary--text mx-2"
                        @click.stop="editFilter(item)">
                  mdi-pencil-outline
                </v-icon>
                <v-icon :id="'copy_'+ item.id"
                        class="primary--text mx-2"
                        @click.stop="copyFilter(item)">
                  mdi-content-copy
                </v-icon>
                <v-icon :id="'delete_'+item.id"
                        v-if="editable"
                        class="error--text mx-2"
                        @click.stop="deleteFilter(item)">
                  mdi-close
                </v-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-row class="justify-content-end border-top py-2">
        <v-card-actions >
          <v-btn id="apply-filter-button"
                 class="text-capitalize px-12 apply_Filter"
                 rounded
                 color="primary"
                 :disabled="disabledApplyButton"
                 @click.stop="applyFilter">Apply
          </v-btn>
        </v-card-actions>
    </v-row>
    <EditFilterDialog :filter.sync="selectedEditItem"></EditFilterDialog>

  </div>
</template>

<script>
import EditFilterDialog from "../views/dialogs/EditFilterDialog";
import {buildFilterQuery} from "../../service/filterQuery.services";

export default {
    "name": "EditablefilterList",
    "components": {EditFilterDialog},
    "props": ["filters", "editable", "tabs", "name"],
    "computed": {
        "statuses" () {
            return this.$store.state.statusses.statusses;
        }
    },
    "methods": {
        applyFilter () {
            if (typeof this.selectedFilter !== "undefined") {
                const filter = {"filterQuery": buildFilterQuery(this.selectedFilter),
                    ...this.selectedFilter};
                this.$store.dispatch(
                    "listFilters/setFilters",
                    {
                        "tableName": "applications",
                        "selectedFilter": filter,
                        "currentPage": 1,
                        "offset": 0
                    }
                );
            } else {
                this.$store.dispatch(
                    "listFilters/setFilters",
                    {
                        "tableName": "applications",
                        "selectedFilter": null,
                        "currentPage": 1,
                        "offset": 0
                    }
                );
            }
            this.$emit("closeDialog");
        },
        copyFilter (item) {
            const newFilter = JSON.parse(JSON.stringify(item));
            newFilter.id = null;
            newFilter.name += " - copy";
            this.selectedEditItem = newFilter;
            this.$emit(
                "update:tabs",
                1
            );
        },
        createFilter () {
            const newFilter = {
                "name": "new Filter",
                "id": null
            };
            this.selectedEditItem = newFilter;
            this.$emit(
                "update:tabs",
                1
            );
        },
        editFilter (item) {
            this.selectedFilter = null;
            this.selectedEditItem = item;
        },
        deleteFilter (item) {
            this.$store.dispatch(
                "listFilters/deletePersonalFilter",
                item
            );
            if (this.$store.state.listFilters.applications.selectedFilter &&
                item.id === this.$store.state.listFilters.applications.selectedFilter.id) {
                this.$store.dispatch(
                    "listFilters/setFilters",
                    {
                        "tableName": "applications",
                        "selectedFilter": null
                    }
                );
            }
        }
    },
    "watch": {
        "selectedFilter" () {
            this.disabledApplyButton = typeof this.selectedFilter === "undefined";
        }
    },
    data () {
        return {
            "selectedFilter": null,
            "selectedEditItem": null,
            "selectedCloneItem": null,
            "disabledApplyButton": true
        };
    }
};
</script>
