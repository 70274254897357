<template>
    <v-container fluid class="pl-0">
        <HeaderBar
            class="pt-3"
            data-nw="editApplicationHeader"
            id="header"
            :breadcrumbs="breadcrumbs"
            title="Application"
        >

            <template v-slot:button>
                <v-btn
                    v-if="$can('read', 'audit_log')"
                    class="text-capitalize px-0"
                    id="audit-log-button"
                    text @click.stop="auditLogDialog = true"
                    data-nw="auditLogBtn"
                >
                    Audit Logs
                    <v-icon class="primary--text pl-2">mdi-message-text-clock-outline</v-icon>
                </v-btn>

                <LogDialog
                    v-if="$can('log', 'applications')"
                    label="Data Logs"
                    :id="formId"
                    columnName="form_id"
                    data-nw="DataLogDialog"
                    table="log"
                />

                <div class="header-bar__application-button">
                    <v-menu offset-y v-if="permissionForStatus">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                :loading="transLoading"
                                color="secondary"
                                dark
                                class="text-capitalize"
                                rounded
                                @click="checkToast"
                                v-on="on"
                            >
                                Transitions
                            </v-btn>
                        </template>
                        <v-list v-if="transitions.length > 0">
                            <v-list-item
                                v-for="(transition, index) in transitions"
                                :key="index"
                                dense
                                @click="doTransition(transition.value)"
                            >
                                <v-list-item-title>{{ transition.text }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        id="refresh-values-button"
                        class="text-capitalize mx-2"
                        rounded color="secondary"
                        @click="refreshFormValues()"
                        data-nw="refreshValues">
                        Refresh
                    </v-btn>
                    <v-btn
                        id="send-status-to-salesforce-button"
                        v-if="canSendStatus"
                        class="text-capitalize mx-2"
                        rounded color="secondary"
                        @click="sendStatus()"
                        data-nw="sendStatusButton">
                        Status -> SF
                    </v-btn>
                    <v-btn
                        id="workflow-graph-button"
                        class="text-capitalize mx-2"
                        rounded color="secondary"
                        @click="openGraph()"
                        data-nw="workflowGraph">
                        Workflow graph
                    </v-btn>
                    <v-btn
                        v-if="canSaveApplication()"
                        id="submit-application-button"
                        class="text-capitalize"
                        rounded color="primary"
                        @click="submitApplicationForm()"
                        data-nw="saveApplication">
                        Save Application
                    </v-btn>
                </div>
            </template>

            <audit-log-dialog
                v-if="$can('read', 'audit_log')"
                :dialog.sync="auditLogDialog"
                data-nw="auditLogDialog"
            >
            </audit-log-dialog>
        </HeaderBar>

        <BaseForm
            class="pl-3 mt-10"
            store-module="applicationFormValues"
            :form-segments="formSegments"
            :editable="editable"
            v-if="sessionLoaded"
        />
        <BaseDialog
            :show="dialogSolaris"
            headline="Confirm changes"
            text="Do you want to overwrite existing company values?"
            confirm-text="Apply changes"
            cancel-text="Cancel"
            v-on:dialog:confirm="changeCompanyValues"
            v-on:dialog:close="closeCompanyValuesDialog"
            v-if="sessionLoaded"
        />
        <BaseDialog
            :show="reconfirmationMailDialog"
            headline="Confirm changes"
            text="Do you want to send the reconfirmation mail?"
            confirm-text="Send"
            cancel-text="Cancel"
            v-on:dialog:confirm="applyReconfirmTransition"
            v-on:dialog:close="reconfirmationMailDialog=false"
            v-if="sessionLoaded"
        />
    </v-container>
</template>
<script>
/* eslint max-lines: off */
import AuditLogDialog from "../dialogs/AuditLogDialog";
import BaseDialog from "../../baseComponents/BaseDialog";
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import LogDialog from "../dialogs/LogDialog";
import apolloClient from "@/ApolloClient";
import axios from "axios";
import editApplicationForm from "@/formConfig/editApplicationForm";
import getEnv from "@/utils/env";
import gql from "graphql-tag";

export default {
    "name": "EditApplication",
    "components": {
        LogDialog,
        AuditLogDialog,
        BaseForm,
        BaseDialog,
        HeaderBar
    },
    "mounted" () {
        this.mountStore();
        if (this.$store.state.formSegments.applicationKeyTranslations.length !== 0) {
            this.skipQuery = false;
        }

        this.mountAppianHr();
        this.$root.$on(
            "refreshForm",
            () => {
                this.refreshFormValues();
            }
        );
    },
    "methods": {
        mountAppianHr () {
            this.$store.dispatch("appianhr/clearOldValues");
            this.$store.dispatch("appianhr/appianHrData", this.$route.params.id);
        },
        mountStore () {
            this.$store.dispatch("applicationFormValues/clearOldValues");
            this.$store.dispatch("opal/clearOldValues");
            this.$store.dispatch("opal/opalId", this.$route.params.id);
            this.$store.dispatch("sap/clearOldValues");
            this.$store.dispatch("sap/sapData", this.$route.params.id);
            this.$store.dispatch("netPromoterScore/clearOldValues");
            this.$store.dispatch("netPromoterScore/fetchNetPromoterScore", this.$route.params.id);
            this.$store.dispatch("solarisBank/clearOldValues");
            this.$store.dispatch("solarisBank/fetchIdNow", this.$route.params.id);
            this.$store.dispatch("solarisBank/fetchSolarisBankBusiness", this.$route.params.id);
            this.$store.dispatch("onefleet/clearOldValues");
            this.$store.dispatch("onefleet/fetchOnboarding", this.$route.params.id);
        },
        checkToast () {
            if (this.transitions.length === 0) {
                this.$toast(
                    "No possible transitions",
                    {
                        "color": "softWarning"
                    }
                );
            }
        },
        // eslint-disable-next-line consistent-return
        async doTransition (item) {
            await this.$store.dispatch("loader/setLoading", true);
            const mutation = gql`mutation {apply_transition( form_id: ${this.$route.params.id},
         transitionType: "${item}"){responseString, error}}`;
            const result =
                await this.$apollo.mutate({mutation});
            await this.$store.dispatch("loader/setLoading", false);
            if (result.data.apply_transition.responseString) {
                this.$toast(
                    "The transition was successfully applied",
                    {"color": "success"}
                );
                this.refreshFormValues();
            }
        },
        async "applyReconfirmTransition" () {
            this.reconfirmationMailDialog = false;
            await this.$store.dispatch("loader/setLoading", true);

            await this.$store.dispatch("applicationFormValues/applyTransition").then((response) => {
                this.$store.dispatch("loader/setLoading", false);
                const data = response.data[Object.keys(response.data)[0]];
                if (data.error) {
                    this.$toast("Something went wrong while sending the reconfirmation email", {"color": "error"});
                } else {
                    this.$toast("The reconfirmation email is send successfully", {"color": "success"});
                }
                this.refreshFormValues();
            }).catch(() => {
                this.$store.dispatch("loader/setLoading", false);
                this.refreshFormValues();
                this.$toast("Something went wrong while sending the reconfirmation email", {"color": "error"});
            });
        },
        submitApplicationForm () {
            this.$root.$emit("submitBaseForm");
        },
        refreshFormValues () {
            this.reconfirmationMailDialog = false;
            this.transLoading = true;
            this.transitions = [];
            apolloClient.cache.reset();
            apolloClient.resetStore();
        },
        closeCompanyValuesDialog () {
            this.$store.dispatch(
                "applicationFormValues/setSolarisDialog",
                {"params": {}, "dialog": false}
            );
        },
        "changeCompanyValues" () {
            this.$store.dispatch("applicationFormValues/solarisCompanyDetails");
        },
        fraudCheck (applicationStatus, fraudCheckReport) {
            const translatedStatus = this.$options.filters.formatStatusTranslation(
                applicationStatus,
                this.storeStatuses
            );
            if (translatedStatus === "Ready for review") {
                if (typeof fraudCheckReport !== "undefined" && fraudCheckReport !== null) {
                    const fraudCheckReportJSON = JSON.parse(fraudCheckReport);
                    const firstCheck = fraudCheckReportJSON.duplicate.data.results;
                    const secondCheck = fraudCheckReportJSON.blacklist.data.fieldMatchingBlacklist;
                    if (firstCheck.length > 0 || secondCheck.length > 0) {
                        this.$toast(
                            "Warning: A match was found for this application. Click the sensecheck button for more details.",
                            {
                                "color": "softWarning",
                                "timeout": 10000
                            }
                        );
                    }
                }
            }
        },
        canSaveApplication () {
            if (this.$can("update", "applications")) {
                return this.permissionForStatus;
            }
            return false;
        },
        checkMdgDialog (form) {
            const mdmNeedValidationStatus = 6;
            if (form.status === "mdm searched error") {
                this.openMdgDialog("search", "MDG found no or multiple customers");
            } else if (form.mdm_search_status === mdmNeedValidationStatus) {
                this.openMdgDialog("validate", "MDG data needs to be validated");
            }
        },
        openMdgDialog (dialog, toastText) {
            this.$store.dispatch("mdg/openDialog", {"open": true, dialog});
            this.$toast(
                toastText,
                {
                    "color": "softWarning",
                    "timeout": 5000
                }
            );
        },
        openGraph () {
            const token = window.localStorage.getItem("jwt");
            const serverUrl = getEnv("VUE_APP_SERVER");
            const requestUrl = `${serverUrl}/workflow?formId=${this.formId}`;

            axios
                .get(requestUrl, {
                    "headers": {
                        "Authorization": `Bearer ${token}`
                    },
                    "responseType": "blob"
                })
                .then((response) => {
                    const blob = new Blob([response.data], { "type": "image/svg+xml"});
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, "_blank").focus();
                });
        },
        async sendStatus () {
            await this.$store.dispatch("loader/setLoading", true);
            const mutation = gql`mutation {
                send_status_to_salesforce(id: ${this.$route.params.id}){responseString, success}
            }`;
            const result = await this.$apollo.mutate({mutation});
            await this.$store.dispatch("loader/setLoading", false);
            if (result.data?.send_status_to_salesforce.success) {
                this.$toast(
                    "The status was successfully updated.",
                    {"color": "success"}
                );
            } else {
                const message = result.data.send_status_to_salesforce.responseString ?? "Unknown error.";
                this.$toast(
                    `Error: ${message}`,
                    {"color": "error"}
                );
            }
        }
    },
    "computed": {
        formId () {
            return parseInt(this.$route.params.id, 10);
        },
        siteId () {
            return this.$store.state.applicationFormValues.values.site_id;
        },
        dialogSolaris () {
            return this.$store.state.applicationFormValues.dialogSolaris;
        },
        formSegments () {
            return this.$store.getters.getFormSegments("applicationForm");
        },
        storeStatuses () {
            return this.$store.state.statusses;
        },
        sessionLoaded () {
            return this.$store.state.session.session_loaded;
        },
        applicationStatus () {
            return this.$store.state.applicationFormValues.values.status;
        },
        userRole () {
            return this.$store.state.userRole.userRole;
        },
        permissionForStatus () {
            if (this.userRole === "ROLE_TELESALES_USER" && this.storeStatuses.statusses) {
                const status = this.storeStatuses.statusses.find((s) => s.status === this.applicationStatus);
                return status && status.permission_level === 0;
            }
            return true;
        },
        canSendStatus () {
            return this.currentStatus.send_to_salesforce &&
                this.$store.state.applicationFormValues.values.salesforce_account_id !== null;
        }
    },
    "watch": {
        applicationStatus (status) {
            // Only show send_quotation_mail transition when current status is quotation
            if (!this.quotationStatusses.includes(status)) {
                this.transitions = this.transitions.filter((transaction) => transaction.value !== "send_quotation_mail");
            }
            document.getElementById("header").classList.add("finishedLoading");
            return status;
        },
        "$store.state.countries.countries" () {
            const {country, zipcode} = this.$store.state.applicationFormValues.values;
            this.$store.dispatch("zipcode/setCountry", country);
            this.$store.dispatch("zipcode/addressLookup", {country, zipcode});
        },
        "$store.state.applicationFormValues.values.country" () {
            this.$store.dispatch("zipcode/setCountry", this.$store.state.applicationFormValues.values.country);
        },
        "$store.state.statusses.statusses" () {
            const status = this.$store.state.applicationFormValues.values.status;
            const fraudCheckReport = this.$store.state.applicationFormValues.values.fraud_check_report;
            this.fraudCheck(status, fraudCheckReport);
        },
        "$store.state.formSegments.applicationKeyTranslations" () {
            this.skipQuery = false;
        }
    },
    data () {
        return {
            "STATUS_BLOCKED_GROUP_ID": 19,
            "transLoading": true,
            "editable": true,
            "auditLogDialog": false,
            "reconfirmationMailDialog": false,
            "logDialog": false,
            "transitions": [],
            "skipQuery": true,
            "quotationStatusses": ["quotation", "Quotation for customer pending"],
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Applications",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListApplication"}
                },
                {
                    "text": "Edit application",
                    "disabled": true
                }
            ],
            "currentStatus": {
                "send_to_salesforce": false
            }
        };
    },
    "apollo": {
        "getCurrentStatus": {
            query () {
                // eslint-disable-next-line max-len
                return gql`query {getCurrentStatus(form_id: ${this.$route.params.id}) {responseString, data { id, group_id, key, status, translation, send_to_salesforce}}}`;
            },
            update (data) {
                if (data.getCurrentStatus !== null) {
                    this.currentStatus = data.getCurrentStatus.data;
                }
                this.transLoading = false;
            },
            "fetchPolicy": "no-cache"
        },
        "get_transitions": {
            query () {
                // eslint-disable-next-line max-len
                return gql`mutation {get_transitions(form_id: ${this.$route.params.id}) {responseString, transitions, error}}`;
            },
            update (data) {
                if (data.get_transitions !== null) {
                    this.transitions = data.get_transitions.transitions.map((transition) => ({
                        "text": transition,
                        "value": transition
                    }));
                }
                this.transLoading = false;
            },
            skip () {
                return this.$store.state.userRole.userRole !== "ROLE_GLOBAL_ADMIN" && this.currentStatus.group_id === this.STATUS_BLOCKED_GROUP_ID;
            },
            "fetchPolicy": "no-cache"
        },
        "reconfirm": {
            query () {
                return gql`query Reconfirm {
                      reconfirmStaged(form_id: ${this.$route.params.id}) {
                        data {
                          form_id
                          all
                          business
                          persons
                          slimpay
                        }
                        responseString
                      }
                    }`;
            },
            update (data) {
                if (data.reconfirmStaged.data.all) {
                    this.reconfirmationMailDialog = true;
                }
            },
            skip () {
                if (typeof this.currentStatus.status === "undefined") {
                    return true;
                }
                return this.currentStatus.status !== "tebehandelenBevestigd";
            }
        },
        "formValues": {
            query () {
                this.$store.dispatch("loader/setLoading", true);
                // NOTE: Add fields here for field visibility
                let queryParameters = "vies_check_date fraud_check_report send_to_salesforce is_contact_person_table_enabled is_solarisbank_enabled site_id " +
                    "cars_amount trucks_amount vans_amount vans_amount trucks_big_amount busses_amount trucks_huge_amount is_bp_employee";
                editApplicationForm.formSegments.forEach((segment) => {
                    Object.keys(segment.fields).forEach((field) => {
                        // Skip if 1 of these types
                        if (!segment.fields[field].exclude) {
                            let parameter = field;
                            // If translation available, translate key to be sent with query as correct parameter
                            const translation = this.$store.getters["formSegments/getTranslationFromKey"](field);
                            if (translation) {
                                parameter = translation;
                            }
                            queryParameters = queryParameters.concat(
                                " ",
                                parameter
                            );
                        }
                    });
                    const visibilityFields = this.$store.getters["formSegments/getVisibilityFields"](segment);
                    if (visibilityFields.length) {
                        queryParameters = queryParameters.concat(
                            " ",
                            visibilityFields.join(" ")
                        );
                    }
                });
                return gql`query {formValues: form (id: ${this.$route.params.id}) {data{id ${queryParameters} }}}`;
            },
            update (data) {
                this.$store.dispatch("loader/setLoading", false);
                if (data.formValues.data.length === 0) {
                    this.$store.dispatch("errorHandling/setErrorMessage", {"originalError": null, "message": "Application not found."});
                }

                // Translate keys to match our editApplicationForm config keys
                const formValues = {};
                for (const [responseKey, responseValue] of Object.entries(data.formValues.data[0])) {
                    const key = this.$store.getters["formSegments/getKeyFromTranslation"](responseKey);
                    if (key) {
                        formValues[key] = responseValue;
                    } else {
                        formValues[responseKey] = responseValue;
                    }
                }

                this.$store.dispatch(
                    "applicationFormValues/setFormValues",
                    formValues
                );
                if (this.storeStatuses.statusses !== null) {
                    this.fraudCheck(data.formValues.data[0].status, data.formValues.data[0].fraud_check_report);
                }

                this.$store.dispatch("applicationFormValues/setDnbSearchParams", data);
                this.$store.dispatch("applicationFormValues/fetchPhoneNumberCountryCodes");

                this.checkMdgDialog(formValues);
            },
            skip () {
                return this.skipQuery;
            },
            "fetchPolicy": "no-cache"
        },
        "siteValues": {
            query () {
                this.$store.dispatch("loader/setLoading", true);

                return gql`query Site($siteId: Int) { site(id: $siteId) { data { id name code address }}}`;
            },
            variables () {
                return {"siteId": this.siteId};
            },
            update (data) {
                this.$store.dispatch("loader/setLoading", false);
                const siteData = data.site.data[0];
                this.$store.dispatch("applicationFormValues/setSiteValuesForForm", siteData);
            },
            skip () {
                return !this.siteId;
            },
            "fetchPolicy": "no-cache"
        },
        "getDocuments": {
            // eslint-disable-next-line consistent-return
            query () {
                const params = "type filename mimetype content id";
                if (this.formId) {
                    return gql` {getDocuments: form_document(form_id: ${this.formId}) {data{document{${params}}}}}`;
                }
            },
            update (data) {
                data.getDocuments.data.forEach((document) => {
                    this.$store.dispatch(
                        "applicationFormValues/setDocuments",
                        document.document
                    );
                });
            }
        }
    }
};
</script>
