/**
 * Get string of fields for gql from tableheaders-array
 *
 * @param {array} tableHeaders tableheaders-array
 * @returns {string} gql-fields to return
 */
export function getFieldsFromTableHeaders (tableHeaders) {
    const fieldsArray = {};
    tableHeaders.forEach((key) => {
        const field = key.value.split(".");
        const objectName = field[0];
        const fieldName = field[1] ? field[1] : objectName;

        if (fieldsArray[objectName]) {
            fieldsArray[objectName].push(fieldName);
        } else {
            fieldsArray[objectName] = [fieldName];
        }
    });

    let queryFields = "";
    Object.keys(fieldsArray).forEach((key) => {
        const value = fieldsArray[key];
        if (value.length > 1 || value[0] !== key) {
            const values = value.join(" ");
            queryFields += ` ${key} {${values}}`;
        } else {
            queryFields += ` ${value}`;
        }
    });
    return queryFields;
}
