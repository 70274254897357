<template>
    <v-container fluid >
        <HeaderBar
                :breadcrumbs="breadcrumbs"
                title="ZR Payer Partner"
                data-nw="editZRPayer"
        >
            <template v-slot:button>
                <LogDialog
                        data-nw="edit-zr-payer-data-logs"
                        label="ZR Payer Partner Data Logs"
                        :id="zrPayerId"
                        table="option_partner_zr"
                        v-if="$can('log', 'option_partner_zr')"
                >
                </LogDialog>
                <v-btn
                        data-nw="edit-zr-payer-submit-button"
                        v-if="$can('update', 'option_partner_zr')"
                        class="text-capitalize btn-block"
                        rounded color="primary"
                        @click="submitZRPayerForm()">
                    Save ZR Payer Partner
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
                type="update"
                store-module="zrPayerFormValues"
                :form-segments="formSegments"
                :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../../baseComponents/BaseForm";
import HeaderBar from "../../../uiElements/HeaderBar";
import LogDialog from "../../dialogs/LogDialog";
import editZRPayerForm from "../../../../formConfig/editZRPayerForm.json";
import gql from "graphql-tag";

export default {
    "name": "EditZRPayer",
    "components": {
        BaseForm,
        HeaderBar,
        LogDialog
    },
    "methods": {
        submitZRPayerForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.getters.getFormSegments("zrPayerForm");
        },
        zrPayerId () {
            return this.$store.state.zrPayerFormValues.values.id;
        }
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "ZR Payers",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListZRPayers"}
                },
                {
                    "text": "Edit ZR Payer",
                    "disabled": true
                }
            ]
        };
    },
    "apollo": {
        "zrPayerFormValues": {
            query () {
                let queryParameters = "";
                const segment = editZRPayerForm.formSegments[0];
                Object.keys(segment.fields).forEach((field) => {
                    queryParameters = queryParameters.concat(
                        " ",
                        field
                    );
                });
                const visibilityFields = this.$store.getters["formSegments/getVisibilityFields"](segment);
                if (visibilityFields.length) {
                    queryParameters = queryParameters.concat(
                        " ",
                        visibilityFields.join(" ")
                    );
                }
                // eslint-disable-next-line max-len
                return gql`query {zrPayerFormValues: option_partner_zr (id: ${this.$route.params.id}) {data{id ${queryParameters}}}}`;
            },
            update (data) {
                this.$store.dispatch("zrPayerFormValues/deleteFormValues");
                this.$store.dispatch(
                    "zrPayerFormValues/setFormValues",
                    data.zrPayerFormValues.data[0]
                );
                this.$store.dispatch(
                    "zrPayerFormValues/setZRPayerIdValue",
                    data.zrPayerFormValues.data[0].id
                );
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
