<template>
    <v-dialog :value="getAddDocumentOpen" max-width="1000" persistent>
        <v-card :loading="loadingAdd">
            <v-card-title>
                <v-col lg="10">
                    Add a document
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                           data-nw="fraudCheckDialogClose"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="formValid"
                    lazy-validation>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="8">
                            <v-select
                                :items="getDocumentTypes"
                                v-model="documentType"
                                label="Document type"
                                :rules="[v => !!v || 'Document type is required']"
                                :loading="getLoadingTypes"
                                :no-data-text="dropdownText"
                                required
                                outlined
                            ></v-select>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="8">
                            <v-file-input
                                v-model="document"
                                accept=".pdf,.doc,.docx"
                                label="Document"
                                :rules="documentRules"
                                required
                                outlined
                            ></v-file-input>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>

                    <v-row v-if="showNTid">
                        <v-spacer></v-spacer>
                        <v-col cols="8">
                            <v-text-field
                                v-model="NTid"
                                label="NTid"
                                :rules="[(v) => !!v || 'Fill in NTid']"
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-btn
                                id="submit-document-button"
                                rounded color="primary"
                                class="text-capitalize"
                                @click.stop="submit"
                            >
                                Add Document
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import gql from "graphql-tag";

export default {
    "props": {
        "dialog": {"default": false}
    },
    "data" () {
        return {
            "formValid": true,
            "documentName": "",
            "documentType": null,
            "loadingAdd": false,
            "legalType": null,
            "document": null,
            "NTid": null,
            "showNTid": false,
            "documentRules": [
                (v) => !v || v.name.endsWith(".docx") || v.name.endsWith(".doc") || v.name.endsWith(".pdf") || "Wrong document type",
                (v) => !v || v.size < 5000000 || "Document should be less than 5 MB"
            ]
        };
    },
    "watch": {
        "documentType": {
            handler () {
                if (this.documentType && this.getDocumentSolarisName === "ID_DOCUMENT") {
                    this.showNTid = true;
                } else {
                    this.showNTid = false;
                    this.NTid = "";
                }
            }
        }
    },
    "methods": {
        closeDialog () {
            this.$refs.form.reset();
            this.$store.dispatch("solarisDocuments/setAddDocumentDialog", false);
            this.loadingAdd = false;
        },
        // eslint-disable-next-line max-lines-per-function
        async submit () {
            if (this.$refs.form.validate()) {
                this.loadingAdd = true;

                const documentTypeInfo = this.getDocumentTypes
                    .find((docType) => docType.value === this.documentType);

                const fileData = {
                    "name": "Missing file",
                    "document_type": documentTypeInfo.text,
                    "business_document_type": documentTypeInfo.value,
                    "is_sensitive": documentTypeInfo.is_sensitive,
                    "is_required": documentTypeInfo.is_required ? "Required" : "",
                    "verified_by": this.NTid
                };

                if (this.$route.params.id) {
                    this.$apollo.queries.getSolarisBankBusinessId.skip = false;
                    const queryBusinessId = await this.$apollo.queries.getSolarisBankBusinessId.refetch();
                    // eslint-disable-next-line camelcase
                    fileData.business_id = queryBusinessId.data.solaris_bank_business.data[0].id;
                }
                if (this.document) {
                    fileData.mimetype = this.document.type;
                    fileData.size = this.document.size;
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(this.document);
                    fileReader.onload = () => {
                        const trimmedFile = fileReader.result.split(",")[1];
                        fileData.name = this.document.name;
                        // eslint-disable-next-line camelcase
                        fileData.file_content = trimmedFile;
                    };
                }

                setTimeout(() => {
                    this.$store.dispatch("solarisDocuments/addDocument", fileData);
                    this.closeDialog();
                }, 1000);
            }
        }
    },
    "apollo": {
        "getSolarisBankBusinessId": {
            query () {
                return gql`query {solaris_bank_business(form_id: ${this.$route.params.id}) {data {id}}}`;
            },
            update (data) {
                if (!this.$apollo.queries.getSolarisBankBusinessId.loading) {
                    return data.solaris_bank_business.data[0].id;
                }
                return null;
            },
            skip () {
                return true;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "computed": {
        dropdownText () {
            return this.loadingTypes ? "Loading..." : "Legal type has no document type";
        },
        getLoadingTypes () {
            return this.$store.state.solarisDocuments.loadingTypes;
        },
        getDocumentTypes () {
            return this.$store.state.solarisDocuments.documentTypes;
        },
        getAddDocumentOpen () {
            return this.$store.state.solarisDocuments.addDocumentDialog;
        },
        getDocumentSolarisName () {
            const docuType = this.getDocumentTypes.find((docType) => docType.value === this.documentType);
            return docuType.document_solaris_name;
        }
    }
};
</script>
