<template>
    <v-app-bar flat app height="200" class="pt-5 header-bar d-print-none">
      <v-container fluid class="bg-white">
            <v-row v-if="applicationFilterBar" justify="space-between">
                <FilterBar></FilterBar>
                <UserRole></UserRole>
            </v-row>
            <v-row v-else>
              <v-spacer class="pt-5"></v-spacer>
                <UserRole></UserRole>
            </v-row>
            <v-row class="background-grey">
                <v-col class="py-0">
                    <v-row class="mb-2">
                        <v-breadcrumbs
                                :items="breadcrumbs"
                                divider=">"
                        ></v-breadcrumbs>
                      <slot></slot>
                    </v-row>
                    <v-row class="mb-2">
                      <v-col cols="12">
                        <h3 v-if="title">{{ title }}</h3>
                      </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto" class="header-bar__buttons">
                  <slot name="button"></slot>
                </v-col>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
import FilterBar from "./FilterBar";
import UserRole from "./UserRole";

export default {
    "name": "HeaderBar",
    "components": {
        UserRole,
        FilterBar
    },
    "props": ["breadcrumbs", "title", "applicationFilterBar"],
    data () {
        return {"dialog": false};
    }
};
</script>
<style lang="scss">
    .header-bar {
        .v-toolbar__content {
            padding: 4px 12px;
        }
        .v-breadcrumbs {
            padding-left: 12px;
        }
        .bg-white {
            background-color: #fff;
        }
        &__buttons.col {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
</style>
