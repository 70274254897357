<template>
    <TreeView
        :itemArray="tree"
        :selectedItems="toArray(items)"
        v-on:setSelectedItems="setSelectedGroupIds">
    </TreeView>
</template>

<script>

import BaseElement from "../../baseComponents/BaseElement";
import TreeView from "./TreeView";
import { buildUserGroupTree} from "@/service/treeBuilder.services";
import {disableItems} from "@/helpers/treeViewItemDisabler";
import gql from "graphql-tag";
import {toArray} from "lodash";

export default {
    "extends": BaseElement,
    "components": {TreeView},
    data () {
        return {
            "items": []
        };
    },
    "methods": {
        toArray,
        setSelectedGroupIds (data) {
            this.$store.dispatch(
                "userFormValues/updateFormValue",
                {
                    "name": "aclgroup_ids",
                    "value": data
                }
            );
        }
    },
    "apollo": {
        "groups": {
            query () {
                return gql`query {
                    groups: acl_group {data {id name}}
                }`;
            },
            update (data) {
                const isGlobalAdmin = this.$store.state.userRole.userRole === "ROLE_GLOBAL_ADMIN";
                const items = isGlobalAdmin ? data.groups.data : disableItems(data.groups.data);
                const userGroupTree = buildUserGroupTree(items);

                this.$store.dispatch(
                    "listFilters/setUserGroupTree",
                    userGroupTree
                );
            },
            "fetchPolicy": "no-cache"
        },
        "selectedItems": {
            query () {
                return gql`query
                    { acl_group_user( first: 10000, user_id: ${this.$route.params.id}) { data { aclgroup_id }}}`;
            },
            update (data) {
                this.items = data.acl_group_user.data.map((e) => e.aclgroup_id);
                this.$store.dispatch(
                    "userFormValues/updateFormValue",
                    {
                        "name": "aclgroup_ids",
                        "value": this.items
                    }
                );
            },
            skip () {
                return this.$route.meta.ability.action !== "update";
            }
        }
    },
    "computed": {
        tree () {
            return this.$store.state.listFilters.users.groupTree ?? [];
        }
    }
};

</script>
