import "cross-fetch/polyfill";
import ApolloClient from "apollo-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import getEnv from "./utils/env";
import { onError } from "apollo-link-error";
import router from "@/router";
import { setContext } from "apollo-link-context";
import store from "./store";


const apolloURL = createUploadLink({"uri": getEnv("VUE_APP_GRAPHQL_SERVER")});

const authLink = setContext((_, { headers }) => {
    const token = window.localStorage.getItem("jwt");
    return {
        "headers": {
            ...headers,
            "authorization": token ? `Bearer ${token}` : ""
        }
    };
});

const logoutLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (operation.operationName === "customErrorHandling") {
        return false;
    }
    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            let errorMessage = "";
            switch (err.extensions.code) {
            case "UNAUTHENTICATED":
                router.push({"name": "Logout"});
                return false;
            case "FORBIDDEN":
                errorMessage = "You don't have sufficient rights.";
                break;
            case "INTERNAL_SERVER_ERROR":
                errorMessage = "Something went wrong server-side. Try again later.";
                break;
            case "WRONG_FILE_FORMAT":
                return false;
            case "NO_FORM_ID":
                errorMessage = "Something went wrong saving the form: form id is undefined.";
                break;
            default:
                errorMessage = "An unknown error has occurred.";
                break;
            }
            store.dispatch("errorHandling/setErrorMessage", {"originalError": err, "message": errorMessage});
        }
    } else if (networkError) {
        store.dispatch("errorHandling/setErrorMessage", {"originalError": networkError, "message": "Something went wrong with the network connection."});
    }
    return false;
});


export default new ApolloClient({
    "link": logoutLink.concat(authLink.concat(apolloURL)),
    "cache": new InMemoryCache({"addTypename": false}),
    "connectToDevTools": true
});
