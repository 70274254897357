/**
 * Translates strings inside keys in an object to a different string based on another object input.
 * @param {Object} obj - The object to be translated.
 * @param {Object} mapping - The object containing the key mapping.
 * @returns {Object} - The translated object.
 *
 * @example
 *
 * const obj = {
 *   foo8: 1,
 *   bar2: 2,
 *   baz: 3,
 *   foo: 4
 * };
 *
 * const mapping = {
 *   foo: 'hello',
 *   bar: 'world'
 * };
 *
 * const translatedObj = translateKeys(obj, mapping);
 * console.log(translatedObj); // { hello8: 1, world2: 2, baz: 3, hello: 4 }
 */
export default function translateKeys (obj, mapping) {
    const translatedObj = {};
    Object.keys(obj).forEach((key) => {
        const mapKey = Object.keys(mapping).find((k) => key.startsWith(k));
        const newKey = mapKey ? mapping[mapKey] + key.slice(mapKey.length) : key;
        translatedObj[newKey] = obj[key];
    });
    return translatedObj;
}

