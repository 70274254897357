import lodash from "lodash";

/**
 * @param {Object} data Object with all necessary information
 * @returns {Array<Object>}
 */
// eslint-disable-next-line max-lines-per-function
export function buildCampaignApplicationTemplateTree (data) {
    const templates = JSON.parse(JSON.stringify(data.application_template.data));
    const campaigns = JSON.parse(JSON.stringify(data.campaign.data));
    const countries = JSON.parse(JSON.stringify(data.country.data));
    const categories = JSON.parse(JSON.stringify(data.category.data));

    templates.forEach((templateElement) => {
        templateElement.treeID = `template${templateElement.id}`;
        templateElement.name = `${templateElement.name} - PT`;

        const category = lodash.find(
            categories,
            (categoryElement) => templateElement.category_id === categoryElement.id
        );

        if (category.children) {
            category.children.push(templateElement);

        } else {
            category.children = [templateElement];
        }
    });

    campaigns.forEach((campaignElement) => {
        campaignElement.treeID = `campaign${campaignElement.id}`;
        campaignElement.name = campaignElement.url_prefix;
        const category = lodash.find(
            categories,
            (categoryElement) => campaignElement.category_id === categoryElement.id
        );

        if (category.children) {
            category.children.push(campaignElement);

        } else {
            category.children = [campaignElement];
        }
    });
    const filteredCategories = categories.filter((categoryElement) => !!categoryElement.children);
    filteredCategories.forEach((categoryElement) => {
        categoryElement.treeID = `category${categoryElement.id}`;
        const country = lodash.find(
            countries,
            (countryElement) => categoryElement.country_id === countryElement.id
        );
        if (categoryElement.label) {
            categoryElement.name = categoryElement.label;
        }
        if (country.children) {
            country.children.push(categoryElement);
        } else {
            country.children = [categoryElement];
        }
    });

    const filteredCountries = countries.filter((countryElement) => !!countryElement.children);
    filteredCountries.forEach((countryElement) => {
        countryElement.treeID = `country${countryElement.id}`;
    });
    return filteredCountries;
}

/**
 * @param {Object} data Object with all necessary information
 * @returns {Array<Object>}
 */
// eslint-disable-next-line max-lines-per-function
export function buildCampaignFilterTree (data) {
    const campaigns = JSON.parse(JSON.stringify(data.campaigns));
    const countries = JSON.parse(JSON.stringify(data.countries));
    const categories = JSON.parse(JSON.stringify(data.categories));

    campaigns.forEach((campaignElement) => {
        campaignElement.treeID = `campaign${campaignElement.id}`;
        campaignElement.name = campaignElement.url_prefix;
        const category = lodash.find(
            categories,
            (categoryElement) => campaignElement.category_id === categoryElement.id
        );

        if (category.children) {
            category.children.push(campaignElement);

        } else {
            category.children = [campaignElement];
        }
    });

    categories.forEach((categoryElement) => {
        categoryElement.treeID = `category${categoryElement.id}`;
        const country = lodash.find(
            countries,
            (countryElement) => categoryElement.country_id === countryElement.id
        );
        if (categoryElement.label) {
            categoryElement.name = categoryElement.label;
        }
        if (country.children) {
            country.children.push(categoryElement);
        } else {
            country.children = [categoryElement];
        }
    });

    const filteredCountries = countries.filter((countryElement) => !!countryElement.children);
    filteredCountries.forEach((countryElement) => {
        countryElement.treeID = `country${countryElement.id}`;
    });
    return filteredCountries;
}

/**
 * @param {Object} data Object with all necessary information
 * @returns {Array<Object>}
 */
export function buildCategoryTree (data) {
    const countries = JSON.parse(JSON.stringify(data.country.data));
    const categories = JSON.parse(JSON.stringify(data.category.data));
    const campaigns = JSON.parse(JSON.stringify(data.campaign.data));
    const templates = JSON.parse(JSON.stringify(data.application_template.data));

    templates.forEach((templateElement) => {
        const category = lodash.find(
            categories,
            (categoryElement) => templateElement.category_id === categoryElement.id
        );
        if (category) {
            category.hasTemplates = true;
        }
    });

    campaigns.forEach((campaignElement) => {
        const category = lodash.find(
            categories,
            (categoryElement) => campaignElement.category_id === categoryElement.id
        );
        if (category) {
            category.hasCampaigns = true;
        }
    });
    const filteredCategories =
        categories.filter((categoryElement) => categoryElement.hasCampaigns || categoryElement.hasTemplates);
    filteredCategories.forEach((categoryElement) => {
        categoryElement.treeID = categoryElement.id;
        categoryElement.name = categoryElement.label || categoryElement.name;
        const country = lodash.find(
            countries,
            (countryElement) => categoryElement.country_id === countryElement.id
        );

        if (country) {
            if (!country.children) {
                country.children = [];
            }
            country.children.push(categoryElement);
        }
    });

    const filteredCountries = countries.filter((country) => country.children && country.children.length);
    filteredCountries.forEach((country) => {
        country.treeID = `country${country.id}`;
    });

    return filteredCountries;
}

/**
 * @param {Object} data Object with all necessary information
 * @returns {Array<Object>}
 */
export function buildUserGroupTree (data) {
    const groups = JSON.parse(JSON.stringify(data));

    groups.forEach((groupElement) => {
        groupElement.treeID = groupElement.id;
    });
    groups.sort((a, b) => a.name.localeCompare(b.name));

    return groups;
}

