<template>
    <div v-if="fileName">
        <v-menu offset-y>
            <template v-slot:activator="{ on }">
                <v-btn
                    color="primary"
                    dark
                    v-on="on"
                    rounded
                >
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                    :href="url"
                    target="_blank"
                    :download="fileName">
                    <v-list-item-title>{{previewLabel}}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    v-on:click="deleteDocument"
                    >
                    <v-list-item-title>{{deleteLabel}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";

export default {
    "extends": BaseElement,
    "props": ["field"],
    "computed": {
        url () {
            if (this.document && this.document.content) {
                const fileObject = this.dataURLtoFile(this.document);
                this.setOldUrl(fileObject);
                return URL.createObjectURL(fileObject);
            }
            return null;
        },
        fileName () {
            return this.document ? this.document.filename : "";
        },
        document () {
            if (this.oldURL) {
                URL.revokeObjectURL(this.oldURL);
            }
            return this.$store.getters[this.field.getter](this.field.fileFieldName);
        },
        previewLabel () {
            return this.field.previewLabel ? this.field.previewLabel : "Preview";
        },
        deleteLabel () {
            return this.field.deleteLabel ? this.field.deleteLabel : "Delete";
        }
    },
    "data" () {
        return {"oldURL": null};
    },
    "methods": {
        deleteDocument () {
            this.$store.dispatch(
                `${this.storeModule}/deleteDocument`,
                this.field.fileFieldName
            ).then((response) => {
                this.$store.dispatch(
                    "loader/setLoading",
                    false
                );
                if (response.data[Object.keys(response.data)[0]].errors) {
                    this.$toast(
                        "Something went wrong while saving the data",
                        {"color": "error"}
                    );
                } else {
                    this.$toast(
                        "The document is successfully deleted",
                        {"color": "success"}
                    );
                }
            }).catch(() => {
                this.$store.dispatch(
                    "loader/setLoading",
                    false
                );
                this.$toast(
                    "Something went wrong while deleting the document",
                    {"color": "error"}
                );
            });
        },
        setOldUrl (url) {
            this.oldURL = url;
        },
        dataURLtoFile (document) {
            const bstr = atob(document.content);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File(
                [u8arr],
                document.filename,
                {"type": document.mimetype}
            );
        }
    }
};

</script>
