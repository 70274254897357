import Vue from "vue";
import apolloClient from "../../ApolloClient";
import gql from "graphql-tag";

const defaultState = {
    "tollBoxes": [],
    "tollBoxOptions": []
};

const getters = {
    "getTollBoxes": (currentState) => currentState.tollBoxes,
    "getTollBoxOptions": (currentState) => (salesOrganizationWinshuttle) => currentState.tollBoxOptions
        .filter((tollBox) => tollBox.sales_organization_winshuttle === salesOrganizationWinshuttle)
};

const mutations = {
    SET_TOLL_BOX_OPTIONS (currentState, tollBoxes) {
        const tollBoxesOptions = [];
        tollBoxes.forEach((tollBox) => {
            tollBoxesOptions.push({
                "text": tollBox.display_label,
                "value": tollBox.id,
                "sales_organization_winshuttle": tollBox.sales_organization_winshuttle
            });
        });
        Vue.set(currentState, "tollBoxOptions", tollBoxesOptions);
    },
    SET_TOLL_BOXES (currentState, tollBoxes) {
        Vue.set(currentState, "tollBoxes", tollBoxes);
    }
};

const actions = {
    async setTollBoxes ({ commit, state}) {
        if (state.tollBoxes.length === 0) {
            const query = await gql`{toll_box {data
            {id toll_box_type display_label sales_organization_winshuttle amount_per_box}
            }}`;
            const result = await apolloClient.query({query});
            const tollBoxes = result.data.toll_box.data;
            await commit("SET_TOLL_BOXES", tollBoxes);
            await commit("SET_TOLL_BOX_OPTIONS", tollBoxes);
        }
    }
};

export default {
    "namespaced": true,
    "state": defaultState,
    getters,
    actions,
    mutations
};
