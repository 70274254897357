<template>
    <v-container fluid v-if="$can('create', 'option_accounting_clerk')">
        <HeaderBar
                :breadcrumbs="breadcrumbs"
                title="New Accounting Clerk"
        >
            <template v-slot:button>
                <v-btn
                        class="text-capitalize btn-block"
                        rounded color="primary"
                        @click="sumbitAccountingClerkForm()">
                    Save Accounting Clerk
                </v-btn>
            </template>
        </HeaderBar>
        <BaseForm
                type="create"
                store-module="accountingClerkFormValues"
                :form-segments=formSegments
                :editable="true"
        />
    </v-container>
</template>
<script>
import BaseForm from "../../../baseComponents/BaseForm";
import HeaderBar from "../../../uiElements/HeaderBar";

export default {
    "name": "NewAccountingClerk",
    "components": {
        BaseForm,
        HeaderBar
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "Accounting Clerks",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListAccountingClerks"}
                },
                {
                    "text": "New Accounting Clerk",
                    "disabled": true
                }
            ]
        };
    },
    "methods": {
        sumbitAccountingClerkForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.state.formSegments.accountingClerkFormSegments;
        }
    },
    created () {
        this.$store.dispatch("accountingClerkFormValues/deleteFormValues");
    }
};
</script>
