<template>
  <v-container fluid v-if="$can('read', 'telesales_agency') || $can('ownAgency', 'telesales_agency')">
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="Telesales"
      data-nw="editTelesalesHeader"
    >
      <template v-slot:button>
        <v-btn
          id="submit-telesales-button"
          class="text-capitalize btn-block"
          rounded color="primary"
          v-if="$can('update', 'telesales_agency')"
          @click="submitTelesalesForm()"
          data-nw="saveTelesales"
        >
          Save Telesales Agency
        </v-btn>
      </template>
    </HeaderBar>
    <BaseForm
      type="update"
      store-module="telesalesFormValues"
      :form-segments="formSegments"
      :editable="true"
    />
  </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import editTelesalesForm from "@/formConfig/editTelesalesForm";
import gql from "graphql-tag";

export default {
    "name": "EditTelesales",
    "components": {
        BaseForm,
        HeaderBar
    },
    "methods": {
        submitTelesalesForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.getters.getFormSegments("telesalesForm");
        }
    },
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Telesales",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListTelesales"}
                },
                {
                    "text": "Edit Telesales",
                    "disabled": true
                }
            ]
        };
    },
    "apollo": {
        "formValues": {
            query () {
                let queryParameters = "";
                editTelesalesForm.formSegments.forEach((segment) => {
                    Object.keys(segment.fields).forEach((field) => {
                        if (field !== "type") {
                            queryParameters = queryParameters.concat(
                                " ",
                                field
                            );
                        }
                    });
                    const visibilityFields = this.$store.getters["formSegments/getVisibilityFields"](segment);
                    if (visibilityFields.length) {
                        queryParameters = queryParameters.concat(
                            " ",
                            visibilityFields.join(" ")
                        );
                    }
                });
                // eslint-disable-next-line max-len
                return gql`query {formValues: telesales_agency (id: ${this.$route.params.id}) {data{id ${queryParameters}}}}`;
            },
            update (data) {
                this.$store.dispatch(
                    "telesalesFormValues/setFormValues",
                    data.formValues.data[0]
                );
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
