import Vue from "vue";

const originalState = {
    "originalError": null,
    "message": ""
};

const state = {...originalState};

const mutations = {
    SET_ERROR_MESSAGE (currentState, payload) {
        Vue.set(currentState, "originalError", payload.originalError);
        Vue.set(currentState, "message", payload.message);
    }
};

const actions = {
    async setErrorMessage ({commit}, payload) {
        await commit("SET_ERROR_MESSAGE", payload);
    },
    async setOriginalState ({commit}) {
        await commit("SET_ERROR_MESSAGE", originalState);
    }

};

export default {
    "namespaced": true,
    state,
    actions,
    mutations
};
