<template>
    <v-container>
        <BaseDialog
            :show="dialog"
            cancel-text="Disagree"
            confirm-text="Agree"
            headline="Confirm changes"
            text="Do you want to send a reconfirmation email"
            v-on:dialog:confirm="applyTransition"
            v-on:dialog:close="closeDialog"
        ></BaseDialog>
        <v-row>
            <v-form id="mainForm" ref="baseFormRef">
                <v-list>
                    <v-list-item v-for="segment in formSegments" :key="segment.title" v-show="segment.visible">
                        <v-row>
                            <BaseFormSegment :editable="editable"
                                             :last-updated="lastUpdated"
                                             :page-type="type"
                                             :segment="segment"
                                             :store-module="storeModule"/>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-form>
        </v-row>
    </v-container>
</template>

<script>
import BaseDialog from "./BaseDialog";
import BaseFormSegment from "./BaseFormSegment";
import editApplicationForm from "@/formConfig/editApplicationForm.json";
import moment from "moment";

export default {
    "components": {
        BaseFormSegment,
        BaseDialog
    },
    "props": ["formSegments", "storeModule", "type", "editable"],
    data () {
        return {
            "dialog": false,
            "lastUpdated": moment(),
            "idConfirmed": null
        };
    },
    provide () {
        return {
            "saveFormBase": this.saveForm
        };
    },
    mounted () {
        this.$root.$on(
            "submitBaseForm",
            (sendToSolaris, forceSave, communicate) => {
                this.submitForm("baseFormRef", sendToSolaris, forceSave, communicate);
            }
        );
    },
    "computed": {
        valuesLoadedInStore () {
            return this.$store.state[this.storeModule].values;
        }
    },
    "watch": {
        valuesLoadedInStore () {
            const formRef = this.$refs.baseFormRef;
            formRef.validate();
        }
    },
    "methods": {
        formTextFieldArray () {
            const allFields = [];
            for (const segment of editApplicationForm.formSegments) {
                for (const field in segment.fields) {
                    if (["text", "coc", "hidden", "vat"].includes(segment.fields[field].type) &&
                        !segment.fields[field].parseTo) {
                        allFields.push(`${field}`);
                    }
                }
            }
            return allFields;
        },
        "closeDialog" () {
            this.dialog = false;
            this.$root.$emit("refreshForm");
        },
        async "applyTransition" () {
            this.closeDialog();
            await this.$store.dispatch("loader/setLoading", true);
            this.lastUpdated = moment();
            this.$toast("The data is successfully saved", {"color": "success"});

            await this.$store.dispatch("applicationFormValues/applyTransition").then((response) => {
                this.$store.dispatch("loader/setLoading", false);
                const data = response.data[Object.keys(response.data)[0]];
                if (data.error) {
                    this.$toast("Something went wrong while sending the reconfirmation email", {"color": "error"});
                } else {
                    this.$toast("The reconfirmation email is send successfully", {"color": "success"});
                }
                this.$root.$emit("refreshForm");
            }).catch(() => {
                this.$store.dispatch("loader/setLoading", false);
                this.$root.$emit("refreshForm");
                this.$toast("Something went wrong while sending the reconfirmation email", {"color": "error"});
            });
        },
        // eslint-disable-next-line max-lines-per-function,max-statements,complexity
        async saveForm (sendToSolaris, communicate = true) {
            this.idConfirmed = null;
            if (communicate) {
                await this.$store.dispatch("loader/setLoading", true);
            }
            await this.$store.dispatch(`${this.storeModule}/trimFormFields`, this.formTextFieldArray());
            // eslint-disable-next-line max-statements
            try {
                const response = await this.$store.dispatch(`${this.storeModule}/saveForm`, this.type);
                if (communicate) {
                    await this.$store.dispatch("loader/setLoading", false);
                }
                const data = response.data[Object.keys(response.data)[0]];
                if (data.errors) {
                    if (communicate) {
                        this.$toast("Something went wrong while saving the data", {"color": "error"});
                    }
                } else if (data.reconfirmChanges) {
                    this.dialog = true;
                } else {
                    this.lastUpdated = moment();
                    if (communicate) {
                        this.$toast(
                            "The data is successfully saved",
                            {"color": "success", "queueable": false}
                        );
                    }

                    if (["CreateApplication", "CreateCampaignApplication"].includes(this.$route.name)) {
                        if (communicate) {
                            await this.$store.dispatch("loader/setLoading", false);
                        }
                        await this.$router.push({"name": "EditApplication", "params": {"id": data.insertedId[0]}});
                    }
                    if (communicate) {
                        await this.$root.$emit("refreshForm");
                    }
                }
            } catch (error) {
                await this.$store.dispatch(
                    "loader/setLoading",
                    false
                );
                let errorMessage = "Something went wrong while saving the data";
                if (error.message.includes("already in use")) {
                    errorMessage = error.message;
                }
                if (error.message.includes("was not given")) {
                    errorMessage = error.message;
                }
                if (error.message.includes("At least one category")) {
                    errorMessage = error.message;
                }
                this.$toast(
                    errorMessage,
                    {"color": "error", "queueable": false}
                );
                if (!communicate) {
                    throw error;
                }
            }
        },
        async submitForm (ref, sendToSolaris = false, forceSave = false, communicate = true) {
            if (forceSave) {
                await this.saveForm(sendToSolaris, communicate);
                return;
            }
            const formRef = this.$refs[ref];
            if (!formRef) {
                return;
            }
            const formValid = formRef.validate();
            if (!formValid && this.storeModule !== "applicationFormValues") {
                this.$toast(
                    "The data you submitted is invalid. Please check for incomplete fields.",
                    {"color": "error"}
                );
            } else {
                this.$toast(
                    this.storeModule === "applicationFormValues" ? "Not all data is valid. Processing..." : "Processing...",
                    {
                        "color": "success"
                    }
                );
                await this.saveForm(sendToSolaris, communicate);
            }
        }
    }
};
</script>

<style>
#mainForm {
    width: 100vw
}
</style>
