import Vue from "vue";
const state = {"categories": []};

const mutations = {
    SET_CATEGORIES (currentState, categories) {
        Vue.set(currentState, "categories", categories);
    }
};

const getters = {
    "forCountry": (currentState) => (countryId) => currentState.categories
        .filter((category) => countryId === category.country_id)
        .map((category) => category.id),
    "getFormCategory": (currentState) => (categoryId) => currentState.categories.find((category) => category.id === categoryId),
    "getFormCategoryName": (currentState) => (categoryId) => {
        const item = currentState.categories.find((category) => category.id === categoryId);
        if (item) {
            const returnItem = JSON.parse(JSON.stringify(item));
            returnItem.name = returnItem.label;
            return returnItem;
        }
        return null;
    },
    "getCategories": (currentState) => currentState.categories.map((item) => ({
        "value": item.id,
        "text": item.name
    })),
    "getCountriesFromCategoryFilter": (currentState, currentGetters, rootState, rootGetters) => {
        const filteredCategoriesFromTemplates = currentState.categories
            .filter((category) => rootGetters["templates/categoryIdsFromTemplates"]
                .includes(category.id));

        const filteredCategoriesFromCampaigns = currentState.categories
            .filter((category) => rootGetters["campaigns/categoryIdsFromCampaigns"]
                .includes(category.id));

        const filteredCategories = [...filteredCategoriesFromTemplates, ...filteredCategoriesFromCampaigns];

        return filteredCategories.map((category) => category.country_id);
    }
};

const actions = {
    async setCategories ({commit}, categories) {
        await commit(
            "SET_CATEGORIES",
            categories
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
