<template>
    <v-dialog scrollable :value="dialog" persistent max-width="900">
        <v-card height="40vh" class="involved-page">
            <v-card-title class="pa-3">
                <v-col lg="10">
                    KYC Screening status
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right"
                           text
                           @click.stop="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text class="pa-0 involved-page">
                <v-row class="pa-0">
                    <v-col class="pa-0">
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            :items="allHits"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            item-key="id"
                            id="hits_data_table">
                            <template v-slot:item.name="{item}">
                                <div class="color-screening-risk-columns name-column">
                                    <div>{{ item.name }}</div>
                                </div>
                            </template>
                            <template v-slot:item.cdd_color="{item}">
                                <div class="color-screening-risk-columns">
                                    <div :class="getCddColor(item) + ' color-column'">&nbsp;&nbsp;</div>
                                </div>
                            </template>
                            <template v-slot:item.screening_progress="{item}">
                                <div class="color-screening-risk-columns">
                                    <div>{{ item.screening_progress }}</div>
                                </div>
                            </template>
                            <template v-slot:item.risk_classification_status="{item}">
                                <div class="color-screening-risk-columns">
                                    <div>{{ item.risk_classification_status }}</div>
                                </div>
                            </template>
                            <template v-slot:item.customer_vetting_status="{item}">
                                <div class="color-screening-risk-columns vetting-column">
                                    <div>{{ item.customer_vetting_status }}</div>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<style>
.screening-status-header {
    font-size: larger !important;
}
.color-screening-risk-columns {
    user-select: none;
}
.name-column {
    width: max(200px);
    padding-left: max(15px);
    font-size: larger;
}
.vetting-column {
    padding-right: max(15px);
}
.color-column {
    width: max(15px);
}
.color-column-red {
    background-color: #b22222;
}
.color-column-green {
    background-color: #008000;
}
.color-column-yellow {
    background-color: #daa520;
}
.color-column-grey {
    background-color: #9b9b9b;
}
.involved-page {
    overflow-x: hidden;
}
</style>

<script>

import cddColors from "@/components/form/elements/cddScreeningColors";
import importedHeaders from "@/components/views/dialogs/headers/KYCScreeningStatusDialogHeaders.json";

export default {
    "props": {
        "dialog": {"default": false},
        "screeningHits": []
    },
    "methods": {
        async closeDialog () {
            await this.$emit("update:dialog", false);
        },
        getCddColor (item) {
            if (item.cdd_color === cddColors.redStatus) {
                return "color-column-red";
            } else if (item.cdd_color === cddColors.yellowStatus) {
                return "color-column-yellow";
            } else if (item.cdd_color === cddColors.greenStatus) {
                return "color-column-green";
            }
            return "color-column-grey";
        }
    },
    "computed": {
        cddColors () {
            return cddColors;
        },
        allHits () {
            return this.screeningHits;
        }
    },
    "data" () {
        return {
            "loading": false,
            "headers": importedHeaders
        };
    }
};
</script>
