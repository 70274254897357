<template>
  <v-container fluid >
    <HeaderBar
      :breadcrumbs="breadcrumbs"
      title="Rebate"
      data-nw="editRebate"
    >
      <template v-slot:button>
        <LogDailog
            label="Rebate Data Logs"
            :id="rebateId"
            table="rebate"
            v-if="$can('log', 'rebate')"
        >
        </LogDailog>
        <v-btn
          v-if="$can('update', 'rebate')"
          id="submit-rebate-button"
          class="text-capitalize btn-block"
          rounded color="primary"
          @click="submitRebateForm()"
          data-nw="saveRebate"
        >
          Save Rebate
        </v-btn>
      </template>
    </HeaderBar>
    <BaseForm
      type="update"
      store-module="rebateFormValues"
      :form-segments="formSegments"
      :editable="true"
    />
  </v-container>
</template>
<script>
import BaseForm from "../../baseComponents/BaseForm";
import HeaderBar from "../../uiElements/HeaderBar";
import LogDailog from "../dialogs/LogDialog";
import editRebateForm from "../../../formConfig/editRebateForm";
import gql from "graphql-tag";

export default {
    "name": "EditRebate",
    "components": {
        BaseForm,
        HeaderBar,
        LogDailog
    },
    "methods": {
        submitRebateForm () {
            this.$root.$emit("submitBaseForm");
        }
    },
    "computed": {
        formSegments () {
            return this.$store.getters.getFormSegments("rebatesForm");
        },
        siteId () {
            return this.$store.state.rebateFormValues.siteId;
        },
        rebateId () {
            return this.$store.state.rebateFormValues.rebateId;
        }
    },
    data () {
        return {
            "editRebateFormRebateIndices": [0, 1],
            "editRebateFormSiteIndices": [2],
            "rebateLogDialog": false,
            "siteLogDialog": false,
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "Rebates",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "ListRebates"}
                },
                {
                    "text": "Edit rebate",
                    "disabled": true
                }
            ]
        };
    },
    "apollo": {
        "rebateFormValues": {
            query () {
                let queryParameters = "";
                for (const index of this.editRebateFormRebateIndices) {
                    const segment = editRebateForm.formSegments[index];
                    // eslint-disable-next-line no-loop-func
                    Object.keys(segment.fields).forEach((field) => {
                        queryParameters = queryParameters.concat(
                            " ",
                            field
                        );
                    });
                    const visibilityFields = this.$store.getters["formSegments/getVisibilityFields"](segment);
                    if (visibilityFields.length) {
                        queryParameters = queryParameters.concat(
                            " ",
                            visibilityFields.join(" ")
                        );
                    }
                }

                // eslint-disable-next-line max-len
                return gql`query {rebateFormValues: rebate (id: ${this.$route.params.id}) {data{id site_id ${queryParameters}}}}`;
            },
            update (data) {
                this.$store.dispatch("rebateFormValues/deleteFormValues");
                this.$store.dispatch(
                    "rebateFormValues/setFormValues",
                    data.rebateFormValues.data[0]
                );
                this.$store.dispatch(
                    "rebateFormValues/setSiteIdValue",
                    data.rebateFormValues.data[0].site_id === null ? null : data.rebateFormValues.data[0].site_id
                );
                this.$store.dispatch(
                    "rebateFormValues/setRebateIdValue",
                    data.rebateFormValues.data[0].id
                );
            },
            "fetchPolicy": "no-cache"
        },
        "siteFormValues": {
            query () {
                let queryParameters = "";
                for (const index of this.editRebateFormSiteIndices) {
                    // eslint-disable-next-line no-loop-func
                    Object.keys(editRebateForm.formSegments[index].fields).forEach((field) => {
                        if (!editRebateForm.formSegments[index].fields[field].exclude) {
                            queryParameters = queryParameters.concat(
                                " ",
                                field
                            );
                        }
                    });
                }
                return gql`query {siteFormValues: site (id: ${this.siteId} ) {data{id ${queryParameters}}}}`;
            },
            update (data) {
                if (data.siteFormValues.data.length > 0) {
                    this.$store.dispatch(
                        "rebateFormValues/setFormValues",
                        data.siteFormValues.data[0]
                    );
                }
            },
            "fetchPolicy": "no-cache"
        }
    }
};
</script>
