<template>
    <v-container fluid v-if="$can('winshuttleBulkReset', 'systemtools')">
        <HeaderBar
            :breadcrumbs="breadcrumbs"
            title="Reset Bulk Applications"
            data-nw="winshuttleBulkResetHeader"
        />
        <v-layout align-center class="background-grey mt-12">
            <v-layout>
                <v-container>
                    <v-autocomplete
                        id="bulk-reset-country"
                        label="Country to reset bulk applications"
                        name="country_code"
                        :items=countries
                        v-model="selectedCountryBulk"
                        @change="getBulkApplications()"
                        return-object
                        :data-nw="'bulk'"
                        :data-nw-value="selectedCountryBulk.text"
                    ></v-autocomplete>
                </v-container>
            </v-layout>
            <v-layout justify-end>
                <v-container>
                    <v-layout justify-center>
                    <v-btn
                        id="reset-bulk-applications"
                        rounded
                        color="primary"
                        :disabled="!selectedCountryBulk || !results || results.length === 0"
                        @click="resetBulkApplicationsForCountry"
                    >
                        Reset Bulk Applications Now
                    </v-btn>
                    </v-layout>
                </v-container>
            </v-layout>
        </v-layout>
        <v-layout align-center fluid class="background-grey mt-5">
            <v-card v-if="!results"></v-card>
                <v-card v-else-if="results && results.length === 0" disabled>
                    <v-card-title>
                    No bulk applications for {{ selectedCountryBulk.text }} found
                    </v-card-title>
                </v-card>
                <v-card v-else-if="results && results.length > 0" width="100%" class="background-grey">
                    <v-card-title>
                        Applications found to be reset
                    </v-card-title>
                    <v-data-table
                        :headers="getHeaders(results)"
                        :items="results"
                        :items-per-page="5"
                        class="elevation-1"
                    ></v-data-table>
                </v-card>
        </v-layout>
    </v-container>
</template>
<script>
import HeaderBar from "../../uiElements/HeaderBar";
import gql from "graphql-tag";

export default {
    "name": "WinshuttleBulkReset",
    "components": {HeaderBar},
    data () {
        return {
            "breadcrumbs": [
                {
                    "text": "Home",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "Dashboard"}
                },
                {
                    "text": "System Tools",
                    "disabled": false,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                },
                {
                    "text": "Reset Bulk Applications",
                    "disabled": true,
                    "exact": true,
                    "to": {"name": "SystemTools"}
                }
            ],
            "selectedCountryBulk": {},
            "results": null,
            "resultIds": []
        };
    },
    "computed": {
        countries () {
            return this.$store.getters["countries/getCountriesSelectOptions"].slice().sort((a, b) => {
                const countryA = a.text;
                const countryB = b.text;
                if (countryA < countryB) {
                    return -1;
                }
                if (countryA > countryB) {
                    return 1;
                }
                return 0;
            });
        }
    },
    mounted () {
        this.getBulkApplications();
    },
    "methods": {
        getHeaders (results) {
            return Object.keys(results[0]).map((key) => {
                if (key === "id") {
                    return {
                        "text": "FormID",
                        "align": "start",
                        "value": "id"
                    };
                }
                return {"text": key, "value": key};
            });
        },
        async getBulkApplications () {
            const countryId = this.selectedCountryBulk.value;
            if (!countryId) {
                return;
            }
            await this.$store.dispatch(
                "loader/setLoading",
                true
            );
            // eslint-disable-next-line max-len
            const mutation = gql`mutation{getWinshuttleBulk (country_id: ${countryId}){count result{
                                    id,
                                    company_name,
                                    status,
                                    country,
                                    campaign,
                                    modified,
                                    created
                                } errors}}`;
            await this.$apollo.mutate({mutation})
                .then((data) => {
                    this.results = data.data.getWinshuttleBulk.result;
                    this.resultIds = this.results.map((result) => result.id);
                    this.$store.dispatch(
                        "loader/setLoading",
                        false
                    );
                }).catch(() => {
                    this.$store.dispatch(
                        "loader/setLoading",
                        false
                    );
                    this.$toast(
                        // eslint-disable-next-line line-comment-position
                        "Something went wrong getting Winshuttle Bulk Applications",
                        {"color": "error"}
                    );
                });
        },
        async resetBulkApplicationsForCountry () {
            await this.$store.dispatch(
                "loader/setLoading",
                true
            );
            // eslint-disable-next-line max-len
            const mutation = gql`mutation{ resetWinshuttleBulk (form_ids: [${this.resultIds}]){responseString, nrOfSavingFailed}}`;
            await this.$apollo.mutate({mutation})
                .then((data) => {
                    this.$store.dispatch(
                        "loader/setLoading",
                        false
                    );
                    const nrOfSavingFailed = data.data.resetWinshuttleBulk.nrOfSavingFailed;
                    if (nrOfSavingFailed > 0) {
                        this.$toast(
                            // eslint-disable-next-line max-len
                            `Reset Winshuttle bulk error: ${nrOfSavingFailed} out of ${this.results.length} application(s) did not reset correctly`,
                            {"color": "error"}
                        );
                    } else {
                        this.$toast(
                            // eslint-disable-next-line line-comment-position
                            `Bulk applications for ${this.selectedCountryBulk.text} have been reset successfully`,
                            {"color": "success"}
                        );
                    }
                }).catch(() => {
                    this.$store.dispatch(
                        "loader/setLoading",
                        false
                    );
                    this.$toast(
                        // eslint-disable-next-line line-comment-position
                        "Something went wrong",
                        {"color": "error"}
                    );
                });
        }
    }
};
</script>
