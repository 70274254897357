<template>
    <v-dialog scrollable :value="dialog" persistent max-width="1300">
        <v-card height="80vh">
            <v-card-title>
                <v-col lg="10">
                    <v-tabs
                            slot="extension"
                            v-model="tabs"
                    >
                        <v-tab>Predefined</v-tab>
                        <v-tab>Personal</v-tab>
                    </v-tabs>
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           id="closeFilterManagementDialog"
                           @click.stop="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text class="my-0">
                    <v-tabs-items v-model="tabs">
                        <v-tab-item>
                            <EditableFilterList :filters="filters.predefinedFilters"
                                                :tabs.sync="tabs"
                                                name="predefined"
                                                @closeDialog="closeDialog"
                                                :editable="false"></EditableFilterList>
                        </v-tab-item>
                        <v-tab-item>
                            <EditableFilterList :filters="filters.personalFilters"
                                                :tabs.sync="tabs"
                                                name="personal"
                                                @closeDialog="closeDialog"
                                                :editable="true"></EditableFilterList>
                        </v-tab-item>
                    </v-tabs-items>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import EditableFilterList from "../../uiElements/EditablefilterList";

export default {
    "props": {"dialog": {"default": false}},
    "components": {EditableFilterList},
    "methods": {
        closeDialog () {
            this.$emit(
                "update:dialog",
                false
            );
        }
    },
    "data" () {
        return {
            "tabs": 0,
            "selectedFilter": null,
            "selectedEditItem": null,
            "selectedCloneItem": null
        };
    },
    "computed": {
        filters () {
            return this.$store.state.listFilters.applications;
        }
    }
};
</script>
