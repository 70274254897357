<template>
    <v-col v-if="userIsGlobalAdmin" cols="4" class="header-bar__user-role">
        <v-autocomplete
            :item-text="item => item.first_name + ' ' + item.last_name"
            :items="users"
            :value="user.id"
            @change="changeUser"
            id="currentUserSelect"
            item-value="id"
            label="Logged in as"
            aria-autocomplete="none"
            autocomplete="new-password"
            return-object
            style="width: 50%"
        />
        <v-select
            :items=userRoles
            :value="user.role"
            @change="changeUserRole"
            data-nw="currentUserRoleSelect"
            id="currentUserRoleSelect"
            label="Role"
            style="width: 50%"
            class="user-role-selector"
        />
    </v-col>
    <v-col v-else cols="4" class="header-bar__user-role">
        <v-text-field
            id="loggedInUser"
            :value="user.first_name + ' ' + user.last_name"
            label="Logged in as"
            :disabled="true"
            style="width: 50%"
        />
        <v-text-field
            id="loggedInUserRole"
            :value="user.role"
            label="Role"
            :disabled="true"
            style="width: 50%"
        />
    </v-col>
</template>

<script>
export default {
    "name": "UserRole",
    "data" () {
        return {
            "userRoles": [
                {
                    "text": "Global Admin",
                    "value": "ROLE_GLOBAL_ADMIN"
                },
                {
                    "text": "Admin",
                    "value": "ROLE_ADMIN"
                },
                {
                    "text": "GBS User",
                    "value": "ROLE_GBS_USER"
                },
                {
                    "text": "User",
                    "value": "ROLE_USER"
                },
                {
                    "text": "Telesales User",
                    "value": "ROLE_TELESALES_USER"
                },
                {
                    "text": "Read Only",
                    "value": "ROLE_READONLY"
                },
                {
                    "text": "CDD User",
                    "value": "ROLE_CDD_USER"
                }
            ]
        };
    },
    "computed": {
        user () {
            return this.$store.state.session.user;
        },
        users () {
            return this.$store.state.session.users;
        },
        userIsGlobalAdmin () {
            if (this.user.metaUser) {
                return this.user.role === "ROLE_GLOBAL_ADMIN" || this.user.metaUser.role === "ROLE_GLOBAL_ADMIN";
            }
            return this.user.role === "ROLE_GLOBAL_ADMIN";
        }
    },
    created () {
        // eslint-disable-next-line no-extra-parens
        if (this.user.role === "ROLE_GLOBAL_ADMIN" || (this.user.metaUser && this.user.metaUser.role === "ROLE_GLOBAL_ADMIN")) {
            this.$store.dispatch("session/getUsers");
        }
    },
    "methods": {
        reroute () {
            this.$router.push({"name": "ListApplication"});
        },
        changeUser (newValue) {
            if (newValue) {
                this.$store.dispatch("session/logInAsUser", newValue);
                this.reroute();
            } else if (this.user.metaUser) {
                const newUser = this.user.metaUser;
                newUser.id = newUser.user_id;
                this.$store.dispatch("session/logInAsUser", newUser);
                this.reroute();
            }
        },
        changeUserRole (newValue) {
            if (newValue) {
                const user = {...this.user};
                user.role = newValue;
                this.$store.dispatch("session/logInAsUser", user);
                this.reroute();
            } else if (this.user.metaUser) {
                const newUser = this.user;
                delete newUser.role;
                this.$store.dispatch("session/logInAsUser", this.user);
                this.reroute();
            }
        }
    }
};
</script>
<style lang="scss">
    .header-bar__user-role {
        display: flex;
        & > .v-input {
           padding: 0 4px;
        }
    }
</style>
