import Vue from "vue";
import apolloClient from "../../ApolloClient";
import {buildCampaignFilterTree} from "@/service/treeBuilder.services";
import gql from "graphql-tag";
import setUserPermissions from "../../userPermissions";

const defaultState = {
    "user": {},
    "users": [],
    "session_loaded": false
};

const mutations = {
    SET_USER (state, data) {
        Vue.set(state, "user", data);
    },
    SET_USER_LOGOUT (currentstate) {
        Vue.set(currentstate, "user", null);
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("jwt");
        location.reload();
        window.localStorage.setItem("loggedOut", "true");
    },
    SET_USERS (state, data) {
        Vue.set(state, "users", data);
    },
    SET_SESSION_LOADED (state, data) {
        Vue.set(state, "session_loaded", data);
    }
};

const getters = {};

const actions = {
    putUser (context, user) {
        context.commit("SET_USER", user);
    },
    sessionLoaded ({commit}, sessionLoaded) {
        commit("SET_SESSION_LOADED", sessionLoaded);
    },
    async logout ({commit}) {
        await commit("SET_USER_LOGOUT");
    },
    async buildSession ({dispatch}, user) {
        await dispatch("putUser", user);
        await setUserPermissions();
        dispatch("getSession");
        this.dispatch("dropdownOptions/getDropdownOptions");
        this.dispatch("listFilters/setPersonalFilters");
        this.dispatch("legalTypes/getLegalTypes");
        this.dispatch("legalForms/getLegalForms");
        this.dispatch("tollBox/setTollBoxes");
        dispatch("sessionLoaded", true);
    },

    // eslint-disable-next-line no-unused-vars
    async logInAsUser ({commit, dispatch}, newUser) {
        const mutation = gql`mutation($id: Int! $role: String) {
            loginAsUserMutation (id : $id role: $role ) {
                responseString jwt emailaddress first_name role last_name id metaUser
            }
        }`;
        const variables = {"id": newUser.id, "role": newUser.role};
        await apolloClient.mutate({mutation, variables})
            .then((data) => {
                const user = data.data.loginAsUserMutation;
                user.metaUser = JSON.parse(user.metaUser);
                dispatch("buildSession", user);
            }).catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
            });
    },
    async getUsers ({commit}) {
        const query = gql` query { user (first: 10000) { data {first_name last_name id emailaddress role } } }`;
        const data = await apolloClient.query({query});
        commit("SET_USERS", data.data.user.data);
    },
    // eslint-disable-next-line max-lines-per-function
    async getSession () {
        const sessionGql = gql`query{session:
            session {
                campaigns{id category_id url_prefix name}
                categories{id name country_id label}
                countries{id name code iso_country_code}
                statusGroups{id name}
                statusses{id key status translation group_id sla_warning sla_escalation permission_level}
                telesalesAgencies{id name}
                zipcodes{id,zip_code,region_code,region_name,county_code,county_name,country_id}
                formTypes
            }}`;
        const session = await apolloClient.query({"query": sessionGql});
        this.dispatch(
            "countries/setCountries",
            session.data.session.countries
        );
        this.dispatch(
            "campaigns/setCampaigns",
            session.data.session.campaigns
        );
        this.dispatch(
            "categories/setCategories",
            session.data.session.categories
        );
        this.dispatch(
            "statusses/setStatusses",
            session.data.session.statusses
        );
        this.dispatch(
            "statusGroups/setStatusGroups",
            session.data.session.statusGroups
        );
        this.dispatch(
            "telesalesAgencies/setTelesalesAgencies",
            session.data.session.telesalesAgencies
        );
        this.dispatch(
            "session/buildTree",
            session
        );
        this.dispatch(
            "zipcode/setZipCodes",
            session.data.session.zipcodes
        );
        this.dispatch(
            "formTypes/setFormTypes",
            session.data.session.formTypes
        );
        this.dispatch("application/setSessionLoaded");
        this.dispatch("templates/fetchTemplates");
    },
    buildTree (commit, session) {
        const tree = buildCampaignFilterTree(session.data.session);
        this.dispatch(
            "listFilters/setCampaignFilterTree",
            tree
        );
    }
};

export default {
    "namespaced": true,
    "state": defaultState,
    getters,
    actions,
    mutations
};
