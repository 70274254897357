import Vue from "vue";
const state = {
    "userRole": null,
    "userRoles": [
        {
            "text": "Global Admin",
            "value": "ROLE_GLOBAL_ADMIN"
        },
        {
            "text": "Admin",
            "value": "ROLE_ADMIN"
        },
        {
            "text": "GBS User",
            "value": "ROLE_GBS_USER"
        },
        {
            "text": "User",
            "value": "ROLE_USER"
        },
        {
            "text": "Telesales User",
            "value": "ROLE_TELESALES_USER"
        },
        {
            "text": "Read Only",
            "value": "ROLE_READONLY"
        },
        {
            "text": "CDD User",
            "value": "ROLE_CDD_USER"
        }
    ]

};

const getters = {
    "getUserRole": (currentState) => currentState.userRole,
    // eslint-disable-next-line array-callback-return,consistent-return
    "getUserRoles": (currentState) => {
        const ind = currentState.userRoles.findIndex((role) => role.value === currentState.userRole);
        return currentState.userRoles.slice(ind, currentState.userRoles.length);
    }
};

const mutations = {
    SET_USER_ROLE (currentState, userRole) {
        Vue.set(currentState, "userRole", userRole);
    }
};

const actions = {
    async setUserRole ({commit}, userRole) {
        await commit(
            "SET_USER_ROLE",
            userRole
        );
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
