<template>
    <v-dialog :value="dialog" max-width="400" :persistent="true">
        <v-card id="change-template-dialog" color="primary">
            <v-card-title class="white--text">Change campaign</v-card-title>
            <v-card-text>
                <v-select
                        id="change-template-select-country"
                        v-model="selectedCountry"
                        dark
                        return-object
                        :items=linkedCountries
                        item-text="name"
                        item-value="id"
                        label="Choose country">
                </v-select>
                <v-select
                        v-if="selectedCountry"
                        id="change-template-select-template"
                        v-model="selectedCampaign"
                        dark
                        :items=campaignsForCountry
                        item-text="name"
                        :item-value="getCampaignValue"
                        :label=campaignSelectLabel
                        :disabled=disabledCampaignSelect>
                </v-select>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn id="change-template-cancel-button"
                       class="text-capitalize" color="white" text @click.stop="closeDialog"
                       data-nw="closeChangeTemplate"
                >Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn id="change-template-apply-button"
                       class="text-capitalize" color="white" text @click.stop="changeTemplate"
                       :disabled="disabledCreateButton">Apply
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    "props": {"dialog": {"default": false}},
    "methods": {
        closeDialog () {
            this.$emit(
                "update:dialog",
                false
            );
        },
        getCampaignValue (item) {
            return {"id": item.id, "category_id": item.category_id};
        },
        changeTemplate () {
            this.$store.dispatch("applicationFormValues/clearFees");
            this.$store.dispatch(
                "applicationFormValues/changeDefaultValues",
                {
                    "campaignId": this.selectedCampaign.id
                }
            );
            this.$store.dispatch(
                "applicationFormValues/updateFormValue",
                {
                    "name": "campaign",
                    "value": this.selectedCampaign.id
                }
            );
            this.$store.dispatch(
                "applicationFormValues/updateFormValue",
                {
                    "name": "category_id",
                    "value": this.selectedCampaign.category_id
                }
            );
            this.$store.dispatch(
                "applicationFormValues/updateFormValue",
                {
                    "name": "country",
                    "value": this.selectedCountry.iso_country_code
                }
            );
            this.$store.dispatch(
                "applicationFormValues/updateFormValue",
                {
                    "name": "application_template_id",
                    "value": null
                }
            );
            this.$emit(
                "update:dialog",
                false
            );
        },
        setCampaignOptions (campaigns) {
            this.selectedCampaign = null;
            if (campaigns.length === 0) {
                this.campaignSelectLabel = "No campaigns available";
            } else {
                this.campaignSelectLabel = "Choose campaign";
            }
            this.disabledCampaignSelect = campaigns.length === 0;
        }
    },
    "data" () {
        return {
            "disabledCampaignSelect": true,
            "disabledCreateButton": true,
            "selectedCountry": null,
            "selectedCampaign": null,
            "campaignSelectLabel": "Choose template"
        };
    },
    "computed": {
        "user" () {
            return this.$store.state.session.user;
        },
        "countries" () {
            return this.$store.state.countries.countries;
        },
        "categories" () {
            return this.$store.state.categories.categories;
        },
        "linkedCountries" () {

            /**
             * Retrieve the necessary category objects to see what country they are linked to
             * and filter countries based on that information
             */
            return this.$store.getters["countries/getLinkedCountries"];
        },
        "campaignsForCountry" () {
            const campaigns = this.$store.getters["campaigns/campaignsForCountry"](this.selectedCountry.id);
            this.setCampaignOptions(campaigns);
            return campaigns;
        }
    },
    "watch": {
        "selectedCampaign" () {
            this.disabledCreateButton = !this.selectedCampaign;
        }
    }
};
</script>
