import Vue from "vue";
import apolloClient from "@/ApolloClient";
import gql from "graphql-tag";

const state = {
    "score": "",
    "description": ""
};

const mutations = {
    SET_NET_PROMOTER_SCORE (currentState, data) {
        Vue.set(currentState, "score", data?.score);
        Vue.set(currentState, "description", data?.description);
    },
    CLEAR_OLD_VALUES (currentState) {
        Vue.set(currentState, "score", "");
        Vue.set(currentState, "description", "");
    }
};

const getters = {
    "getNetPromoterScore": (currentState) => () => ({"name": currentState.score ? currentState.score : "Not applicable"}),
    "getNetPromoterDescription": (currentState) => () => ({"name": currentState.description ? currentState.description : "No data available"})
};

const actions = {
    async fetchNetPromoterScore ({commit}, formId) {
        const query = gql`query {net_promoter_score(form_id: ${formId}) {data {score description} }}`;
        const response = await apolloClient.query({
            query
        });
        await commit("SET_NET_PROMOTER_SCORE", response.data.net_promoter_score.data[0]);
    },
    async clearOldValues ({commit}) {
        await commit("CLEAR_OLD_VALUES");
    }
};

export default {
    "namespaced": true,
    state,
    getters,
    actions,
    mutations
};
