<template>
    <div>
        <v-row>
            <v-col>
                <v-data-table id="form_address_list"
                              :disable-pagination="true"
                              :headers="headersWithActions"
                              :hide-default-footer="true"
                              :items="formAddresses"
                              :loading="loading"
                              item-key="id">
                    <template v-slot:item.address_type="{item}">
                        <v-select
                            class="form-address-select-style-wide"
                            :value="item.address_type"
                            @change="save({id: item.id, address_type: $event})"
                            :items="addressTypeOptions"
                            :disabled="!canEdit"
                        />
                    </template>

                    <template v-slot:item.address="{item}">
                        <v-edit-dialog
                            @close="save({id: item.id, address: activeEdit})"
                            @open="open(item.address)"
                        > {{ item.address|formatNoValue }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="activeEdit"
                                    :disabled="!canEdit"
                                    :value="item.address"
                                    autofocus
                                    placeholder="Address"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.zipcode="{item}">
                        <v-edit-dialog
                            @close="save({id: item.id, zipcode: activeEdit})"
                            @open="open(item.zipcode)"
                        > {{ item.zipcode|formatNoValue }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="activeEdit"
                                    :disabled="!canEdit"
                                    :value="item.zipcode"
                                    autofocus
                                    placeholder="Zipcode"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.city="{item}">
                        <v-edit-dialog
                            @close="save({id: item.id, city: activeEdit})"
                            @open="open(item.city)"
                        > {{ item.city|formatNoValue }}
                            <template v-slot:input>
                                <v-text-field
                                    v-model="activeEdit"
                                    :disabled="!canEdit"
                                    :value="item.city"
                                    autofocus
                                    placeholder="City"
                                    single-line
                                ></v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.country="{item}">
                        <v-select
                            class="form-address-select-style"
                            :value="item.country"
                            @change="save({id: item.id, country: $event})"
                            :items="countries"
                            :disabled="!canEdit"
                        />
                    </template>

                    <template v-slot:item.action="{item}">
                        <v-btn :id="`remove_form_address_btn${item.id}`"
                               :disabled="!canEdit"
                               class="error--text mx-2" icon
                               text
                               @click="removeFormAddress(item)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-btn id="add_form_address_btn"
                   :disabled="!canEdit"
                   class="text-capitalize mr-5" color="primary" right
                   rounded
                   @click.stop="addFormAddress">Add
            </v-btn>
        </v-row>
    </div>
</template>

<script>
import BaseElement from "../../baseComponents/BaseElement";
import {getFieldsFromTableHeaders} from "@/service/queryBuilder.service";
import gql from "graphql-tag";

export default {
    "extends": BaseElement,
    data () {
        return {
            "error": null,
            "item": {},
            "loading": false,
            "skipQuery": false,
            "activeEdit": null
        };
    },
    "apollo": {
        "getFormAddresses": {
            // eslint-disable-next-line consistent-return
            query () {
                const fields = getFieldsFromTableHeaders(this.fields);
                if (this.formId) {
                    this.loading = true;
                    return gql`query {getFormAddresses: form_address(form_id: ${this.formId}) {data{${fields}}}}`;
                }
            },
            update (data) {
                this.loading = false;
                this.$store.dispatch(
                    "applicationFormValues/setFormAddresses",
                    data.getFormAddresses.data
                );
            },
            skip () {
                return this.skipQuery;
            },
            "fetchPolicy": "no-cache"
        }
    },
    "computed": {
        formId () {
            if (this.$route.params.id) {
                return this.$route.params.id;
            }
            return this.$route.params.template_id;
        },
        formAddresses () {
            return this.$store.state.applicationFormValues.formAddresses;
        },
        headersWithActions () {
            const newHeaders = JSON.parse(JSON.stringify(this.tableHeaders));
            newHeaders.forEach((header) => {
                header.sortable = false;
            });
            newHeaders.push({
                "value": "action",
                "text": "",
                "sortable": false
            });
            return newHeaders;
        },
        fields () {
            return this.field.tableHeaders.filter((field) => !field.exclude);
        },
        tableHeaders () {
            return this.field.tableHeaders.filter(({displayTable}) => displayTable);
        },
        "addressTypeOptions" () {
            return this.$store.getters["dropdownOptions/getDropdownOptionsByName"]("addressType");
        },
        "countries" () {
            return this.$store.getters["countries/getCountriesSelectOptionsString"];
        }
    },
    "methods": {
        addFormAddress () {
            this.$store.dispatch("applicationFormValues/addEmptyFormAddress");
        },
        open (value) {
            this.activeEdit = value;
        },
        save (item) {
            this.$store.dispatch(
                "applicationFormValues/updateFormAddress",
                item
            );
            this.activeEdit = null;
        },
        removeFormAddress (item) {
            this.$store.dispatch(
                "applicationFormValues/deleteFormAddress",
                item.id
            );
        }
    }
};
</script>
<style>
.form-address-select-style-wide {
    width: 300px;
}
.form-address-select-style {
    width: 180px;
}
</style>
